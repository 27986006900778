/*!
   * Bootstrap  v5.3.2 (https://getbootstrap.com/)
   * Copyright 2011-2023 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
/* CUSTOM CODE START -------------------------------------------------------------- */
/* Spacers */
/* Colors - gray defaults */
/* Tertiary theme color */
/* Primary color palette */
/* Surface colors */
/* Roundings */
/* CUSTOM CODE END ---------------------------------------------------------------- */
:root,
[data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #74879A;
  --bs-gray-dark: #39434E;
  --bs-gray-100: #F8F9FA;
  --bs-gray-200: #F4F4F4;
  --bs-gray-300: #E9ECEF;
  --bs-gray-400: #C2CAD3;
  --bs-gray-500: #9BA9B6;
  --bs-gray-600: #74879A;
  --bs-gray-700: #556575;
  --bs-gray-800: #39434E;
  --bs-gray-900: #1C2227;
  --bs-primary: #ff6600;
  --bs-secondary: #333;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #F8F9FA;
  --bs-dark: #1C2227;
  --bs-tertiary: #ff6840;
  --bs-primary-rgb: 255, 102, 0;
  --bs-secondary-rgb: 51, 51, 51;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 28, 34, 39;
  --bs-tertiary-rgb: 255, 104, 64;
  --bs-primary-text-emphasis: #662900;
  --bs-secondary-text-emphasis: #141414;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #556575;
  --bs-dark-text-emphasis: #556575;
  --bs-tertiary-text-emphasis: #662a1a;
  --bs-primary-bg-subtle: #ffe0cc;
  --bs-secondary-bg-subtle: #d6d6d6;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #C2CAD3;
  --bs-tertiary-bg-subtle: #ffe1d9;
  --bs-primary-border-subtle: #ffc299;
  --bs-secondary-border-subtle: #adadad;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #F4F4F4;
  --bs-dark-border-subtle: #9BA9B6;
  --bs-tertiary-border-subtle: #ffc3b3;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #1C2227;
  --bs-body-color-rgb: 28, 34, 39;
  --bs-body-bg: #F4F4F4;
  --bs-body-bg-rgb: 244, 244, 244;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(28, 34, 39, 0.75);
  --bs-secondary-color-rgb: 28, 34, 39;
  --bs-secondary-bg: #F4F4F4;
  --bs-secondary-bg-rgb: 244, 244, 244;
  --bs-tertiary-color: rgba(28, 34, 39, 0.5);
  --bs-tertiary-color-rgb: 28, 34, 39;
  --bs-tertiary-bg: #F8F9FA;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #ff6600;
  --bs-link-color-rgb: 255, 102, 0;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #cc5200;
  --bs-link-hover-color-rgb: 204, 82, 0;
  --bs-code-color: #d63384;
  --bs-highlight-color: #1C2227;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #C2CAD3;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(255, 102, 0, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545; }

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #E9ECEF;
  --bs-body-color-rgb: 233, 236, 239;
  --bs-body-bg: #1C2227;
  --bs-body-bg-rgb: 28, 34, 39;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(233, 236, 239, 0.75);
  --bs-secondary-color-rgb: 233, 236, 239;
  --bs-secondary-bg: #39434E;
  --bs-secondary-bg-rgb: 57, 67, 78;
  --bs-tertiary-color: rgba(233, 236, 239, 0.5);
  --bs-tertiary-color-rgb: 233, 236, 239;
  --bs-tertiary-bg: #2b333b;
  --bs-tertiary-bg-rgb: 43, 51, 59;
  --bs-primary-text-emphasis: #ffa366;
  --bs-secondary-text-emphasis: #858585;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #F8F9FA;
  --bs-dark-text-emphasis: #E9ECEF;
  --bs-primary-bg-subtle: #331400;
  --bs-secondary-bg-subtle: #0a0a0a;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #39434E;
  --bs-dark-bg-subtle: #1d2227;
  --bs-primary-border-subtle: #993d00;
  --bs-secondary-border-subtle: #1f1f1f;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #556575;
  --bs-dark-border-subtle: #39434E;
  --bs-heading-color: inherit;
  --bs-link-color: #ffa366;
  --bs-link-hover-color: #ffb585;
  --bs-link-color-rgb: 255, 163, 102;
  --bs-link-hover-color-rgb: 255, 181, 133;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #E9ECEF;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #556575;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color); }

h1, .h1 {
  font-size: calc(1.34375rem + 1.125vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.1875rem; } }

h2, .h2 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 1.75rem; } }

h3, .h3 {
  font-size: calc(1.27813rem + 0.3375vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.53125rem; } }

h4, .h4 {
  font-size: calc(1.25625rem + 0.075vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.3125rem; } }

h5, .h5 {
  font-size: 1.09375rem; }

h6, .h6 {
  font-size: 0.875rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline; }
  a:hover {
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb); }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #74879A; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color); }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px; }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.grid {
  display: grid;
  grid-template-rows: repeat(var(--bs-rows, 1), 1fr);
  grid-template-columns: repeat(var(--bs-columns, 12), 1fr);
  gap: var(--bs-gap, 1.5rem); }
  .grid .g-col-1 {
    grid-column: auto/span 1; }
  .grid .g-col-2 {
    grid-column: auto/span 2; }
  .grid .g-col-3 {
    grid-column: auto/span 3; }
  .grid .g-col-4 {
    grid-column: auto/span 4; }
  .grid .g-col-5 {
    grid-column: auto/span 5; }
  .grid .g-col-6 {
    grid-column: auto/span 6; }
  .grid .g-col-7 {
    grid-column: auto/span 7; }
  .grid .g-col-8 {
    grid-column: auto/span 8; }
  .grid .g-col-9 {
    grid-column: auto/span 9; }
  .grid .g-col-10 {
    grid-column: auto/span 10; }
  .grid .g-col-11 {
    grid-column: auto/span 11; }
  .grid .g-col-12 {
    grid-column: auto/span 12; }
  .grid .g-start-1 {
    grid-column-start: 1; }
  .grid .g-start-2 {
    grid-column-start: 2; }
  .grid .g-start-3 {
    grid-column-start: 3; }
  .grid .g-start-4 {
    grid-column-start: 4; }
  .grid .g-start-5 {
    grid-column-start: 5; }
  .grid .g-start-6 {
    grid-column-start: 6; }
  .grid .g-start-7 {
    grid-column-start: 7; }
  .grid .g-start-8 {
    grid-column-start: 8; }
  .grid .g-start-9 {
    grid-column-start: 9; }
  .grid .g-start-10 {
    grid-column-start: 10; }
  .grid .g-start-11 {
    grid-column-start: 11; }
  @media (min-width: 576px) {
    .grid .g-col-sm-1 {
      grid-column: auto/span 1; }
    .grid .g-col-sm-2 {
      grid-column: auto/span 2; }
    .grid .g-col-sm-3 {
      grid-column: auto/span 3; }
    .grid .g-col-sm-4 {
      grid-column: auto/span 4; }
    .grid .g-col-sm-5 {
      grid-column: auto/span 5; }
    .grid .g-col-sm-6 {
      grid-column: auto/span 6; }
    .grid .g-col-sm-7 {
      grid-column: auto/span 7; }
    .grid .g-col-sm-8 {
      grid-column: auto/span 8; }
    .grid .g-col-sm-9 {
      grid-column: auto/span 9; }
    .grid .g-col-sm-10 {
      grid-column: auto/span 10; }
    .grid .g-col-sm-11 {
      grid-column: auto/span 11; }
    .grid .g-col-sm-12 {
      grid-column: auto/span 12; }
    .grid .g-start-sm-1 {
      grid-column-start: 1; }
    .grid .g-start-sm-2 {
      grid-column-start: 2; }
    .grid .g-start-sm-3 {
      grid-column-start: 3; }
    .grid .g-start-sm-4 {
      grid-column-start: 4; }
    .grid .g-start-sm-5 {
      grid-column-start: 5; }
    .grid .g-start-sm-6 {
      grid-column-start: 6; }
    .grid .g-start-sm-7 {
      grid-column-start: 7; }
    .grid .g-start-sm-8 {
      grid-column-start: 8; }
    .grid .g-start-sm-9 {
      grid-column-start: 9; }
    .grid .g-start-sm-10 {
      grid-column-start: 10; }
    .grid .g-start-sm-11 {
      grid-column-start: 11; } }
  @media (min-width: 768px) {
    .grid .g-col-md-1 {
      grid-column: auto/span 1; }
    .grid .g-col-md-2 {
      grid-column: auto/span 2; }
    .grid .g-col-md-3 {
      grid-column: auto/span 3; }
    .grid .g-col-md-4 {
      grid-column: auto/span 4; }
    .grid .g-col-md-5 {
      grid-column: auto/span 5; }
    .grid .g-col-md-6 {
      grid-column: auto/span 6; }
    .grid .g-col-md-7 {
      grid-column: auto/span 7; }
    .grid .g-col-md-8 {
      grid-column: auto/span 8; }
    .grid .g-col-md-9 {
      grid-column: auto/span 9; }
    .grid .g-col-md-10 {
      grid-column: auto/span 10; }
    .grid .g-col-md-11 {
      grid-column: auto/span 11; }
    .grid .g-col-md-12 {
      grid-column: auto/span 12; }
    .grid .g-start-md-1 {
      grid-column-start: 1; }
    .grid .g-start-md-2 {
      grid-column-start: 2; }
    .grid .g-start-md-3 {
      grid-column-start: 3; }
    .grid .g-start-md-4 {
      grid-column-start: 4; }
    .grid .g-start-md-5 {
      grid-column-start: 5; }
    .grid .g-start-md-6 {
      grid-column-start: 6; }
    .grid .g-start-md-7 {
      grid-column-start: 7; }
    .grid .g-start-md-8 {
      grid-column-start: 8; }
    .grid .g-start-md-9 {
      grid-column-start: 9; }
    .grid .g-start-md-10 {
      grid-column-start: 10; }
    .grid .g-start-md-11 {
      grid-column-start: 11; } }
  @media (min-width: 992px) {
    .grid .g-col-lg-1 {
      grid-column: auto/span 1; }
    .grid .g-col-lg-2 {
      grid-column: auto/span 2; }
    .grid .g-col-lg-3 {
      grid-column: auto/span 3; }
    .grid .g-col-lg-4 {
      grid-column: auto/span 4; }
    .grid .g-col-lg-5 {
      grid-column: auto/span 5; }
    .grid .g-col-lg-6 {
      grid-column: auto/span 6; }
    .grid .g-col-lg-7 {
      grid-column: auto/span 7; }
    .grid .g-col-lg-8 {
      grid-column: auto/span 8; }
    .grid .g-col-lg-9 {
      grid-column: auto/span 9; }
    .grid .g-col-lg-10 {
      grid-column: auto/span 10; }
    .grid .g-col-lg-11 {
      grid-column: auto/span 11; }
    .grid .g-col-lg-12 {
      grid-column: auto/span 12; }
    .grid .g-start-lg-1 {
      grid-column-start: 1; }
    .grid .g-start-lg-2 {
      grid-column-start: 2; }
    .grid .g-start-lg-3 {
      grid-column-start: 3; }
    .grid .g-start-lg-4 {
      grid-column-start: 4; }
    .grid .g-start-lg-5 {
      grid-column-start: 5; }
    .grid .g-start-lg-6 {
      grid-column-start: 6; }
    .grid .g-start-lg-7 {
      grid-column-start: 7; }
    .grid .g-start-lg-8 {
      grid-column-start: 8; }
    .grid .g-start-lg-9 {
      grid-column-start: 9; }
    .grid .g-start-lg-10 {
      grid-column-start: 10; }
    .grid .g-start-lg-11 {
      grid-column-start: 11; } }
  @media (min-width: 1200px) {
    .grid .g-col-xl-1 {
      grid-column: auto/span 1; }
    .grid .g-col-xl-2 {
      grid-column: auto/span 2; }
    .grid .g-col-xl-3 {
      grid-column: auto/span 3; }
    .grid .g-col-xl-4 {
      grid-column: auto/span 4; }
    .grid .g-col-xl-5 {
      grid-column: auto/span 5; }
    .grid .g-col-xl-6 {
      grid-column: auto/span 6; }
    .grid .g-col-xl-7 {
      grid-column: auto/span 7; }
    .grid .g-col-xl-8 {
      grid-column: auto/span 8; }
    .grid .g-col-xl-9 {
      grid-column: auto/span 9; }
    .grid .g-col-xl-10 {
      grid-column: auto/span 10; }
    .grid .g-col-xl-11 {
      grid-column: auto/span 11; }
    .grid .g-col-xl-12 {
      grid-column: auto/span 12; }
    .grid .g-start-xl-1 {
      grid-column-start: 1; }
    .grid .g-start-xl-2 {
      grid-column-start: 2; }
    .grid .g-start-xl-3 {
      grid-column-start: 3; }
    .grid .g-start-xl-4 {
      grid-column-start: 4; }
    .grid .g-start-xl-5 {
      grid-column-start: 5; }
    .grid .g-start-xl-6 {
      grid-column-start: 6; }
    .grid .g-start-xl-7 {
      grid-column-start: 7; }
    .grid .g-start-xl-8 {
      grid-column-start: 8; }
    .grid .g-start-xl-9 {
      grid-column-start: 9; }
    .grid .g-start-xl-10 {
      grid-column-start: 10; }
    .grid .g-start-xl-11 {
      grid-column-start: 11; } }
  @media (min-width: 1400px) {
    .grid .g-col-xxl-1 {
      grid-column: auto/span 1; }
    .grid .g-col-xxl-2 {
      grid-column: auto/span 2; }
    .grid .g-col-xxl-3 {
      grid-column: auto/span 3; }
    .grid .g-col-xxl-4 {
      grid-column: auto/span 4; }
    .grid .g-col-xxl-5 {
      grid-column: auto/span 5; }
    .grid .g-col-xxl-6 {
      grid-column: auto/span 6; }
    .grid .g-col-xxl-7 {
      grid-column: auto/span 7; }
    .grid .g-col-xxl-8 {
      grid-column: auto/span 8; }
    .grid .g-col-xxl-9 {
      grid-column: auto/span 9; }
    .grid .g-col-xxl-10 {
      grid-column: auto/span 10; }
    .grid .g-col-xxl-11 {
      grid-column: auto/span 11; }
    .grid .g-col-xxl-12 {
      grid-column: auto/span 12; }
    .grid .g-start-xxl-1 {
      grid-column-start: 1; }
    .grid .g-start-xxl-2 {
      grid-column-start: 2; }
    .grid .g-start-xxl-3 {
      grid-column-start: 3; }
    .grid .g-start-xxl-4 {
      grid-column-start: 4; }
    .grid .g-start-xxl-5 {
      grid-column-start: 5; }
    .grid .g-start-xxl-6 {
      grid-column-start: 6; }
    .grid .g-start-xxl-7 {
      grid-column-start: 7; }
    .grid .g-start-xxl-8 {
      grid-column-start: 8; }
    .grid .g-start-xxl-9 {
      grid-column-start: 9; }
    .grid .g-start-xxl-10 {
      grid-column-start: 10; }
    .grid .g-start-xxl-11 {
      grid-column-start: 11; } }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1px,
.gx-1px {
  --bs-gutter-x: 0.0625rem; }

.g-1px,
.gy-1px {
  --bs-gutter-y: 0.0625rem; }

.g-2px,
.gx-2px {
  --bs-gutter-x: 0.125rem; }

.g-2px,
.gy-2px {
  --bs-gutter-y: 0.125rem; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 2rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 2rem; }

.g-6,
.gx-6 {
  --bs-gutter-x: 3rem; }

.g-6,
.gy-6 {
  --bs-gutter-y: 3rem; }

.g-7,
.gx-7 {
  --bs-gutter-x: 4rem; }

.g-7,
.gy-7 {
  --bs-gutter-y: 4rem; }

.g-8,
.gx-8 {
  --bs-gutter-x: 6rem; }

.g-8,
.gy-8 {
  --bs-gutter-y: 6rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1px,
  .gx-sm-1px {
    --bs-gutter-x: 0.0625rem; }
  .g-sm-1px,
  .gy-sm-1px {
    --bs-gutter-y: 0.0625rem; }
  .g-sm-2px,
  .gx-sm-2px {
    --bs-gutter-x: 0.125rem; }
  .g-sm-2px,
  .gy-sm-2px {
    --bs-gutter-y: 0.125rem; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 2rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 2rem; }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 3rem; }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 3rem; }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 4rem; }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 4rem; }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 6rem; }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 6rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1px,
  .gx-md-1px {
    --bs-gutter-x: 0.0625rem; }
  .g-md-1px,
  .gy-md-1px {
    --bs-gutter-y: 0.0625rem; }
  .g-md-2px,
  .gx-md-2px {
    --bs-gutter-x: 0.125rem; }
  .g-md-2px,
  .gy-md-2px {
    --bs-gutter-y: 0.125rem; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 2rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 2rem; }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 3rem; }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 3rem; }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 4rem; }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 4rem; }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 6rem; }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 6rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1px,
  .gx-lg-1px {
    --bs-gutter-x: 0.0625rem; }
  .g-lg-1px,
  .gy-lg-1px {
    --bs-gutter-y: 0.0625rem; }
  .g-lg-2px,
  .gx-lg-2px {
    --bs-gutter-x: 0.125rem; }
  .g-lg-2px,
  .gy-lg-2px {
    --bs-gutter-y: 0.125rem; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 2rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 2rem; }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 3rem; }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 3rem; }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 4rem; }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 4rem; }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 6rem; }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 6rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1px,
  .gx-xl-1px {
    --bs-gutter-x: 0.0625rem; }
  .g-xl-1px,
  .gy-xl-1px {
    --bs-gutter-y: 0.0625rem; }
  .g-xl-2px,
  .gx-xl-2px {
    --bs-gutter-x: 0.125rem; }
  .g-xl-2px,
  .gy-xl-2px {
    --bs-gutter-y: 0.125rem; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 2rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 2rem; }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 3rem; }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 3rem; }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 4rem; }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 4rem; }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 6rem; }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 6rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1px,
  .gx-xxl-1px {
    --bs-gutter-x: 0.0625rem; }
  .g-xxl-1px,
  .gy-xxl-1px {
    --bs-gutter-y: 0.0625rem; }
  .g-xxl-2px,
  .gx-xxl-2px {
    --bs-gutter-x: 0.125rem; }
  .g-xxl-2px,
  .gy-xxl-2px {
    --bs-gutter-y: 0.125rem; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 2rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 2rem; }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 3rem; }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 3rem; }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 4rem; }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 4rem; }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 6rem; }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 6rem; } }

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg))); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 var(--bs-border-width); }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg); }

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg); }

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #ffe0cc;
  --bs-table-border-color: #ccb3a3;
  --bs-table-striped-bg: #f2d5c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6cab8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eccfbd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #d6d6d6;
  --bs-table-border-color: #ababab;
  --bs-table-striped-bg: #cbcbcb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1c1c1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6c6c6;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #F8F9FA;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #1C2227;
  --bs-table-border-color: #494e52;
  --bs-table-striped-bg: #272d32;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #33383d;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #2d3337;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.75rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color); }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #ffb380;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25); }
  .form-control::-webkit-date-and-time-value {
    min-width: 85px;
    height: 1.5em;
    margin: 0; }
  .form-control::-webkit-datetime-edit {
    display: block;
    padding: 0; }
  .form-control::placeholder {
    color: var(--bs-secondary-color);
    opacity: 1; }
  .form-control:disabled {
    background-color: var(--bs-secondary-bg);
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: var(--bs-body-color);
    background-color: var(--bs-tertiary-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: var(--bs-border-width);
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--bs-secondary-bg); }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: var(--bs-border-radius-sm); }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: var(--bs-border-radius-lg); }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2)); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2)); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2)); }

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: var(--bs-border-radius); }
  .form-control-color::-webkit-color-swatch {
    border: 0 !important;
    border-radius: var(--bs-border-radius); }
  .form-control-color.form-control-sm {
    height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2)); }
  .form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2)); }

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2339434E' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #ffb380;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: var(--bs-secondary-bg); }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--bs-body-color); }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.75rem;
  border-radius: var(--bs-border-radius-sm); }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
  border-radius: var(--bs-border-radius-lg); }

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23E9ECEF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"); }

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #ffb380;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25); }
  .form-check-input:checked {
    background-color: #ff6600;
    border-color: #ff6600; }
    .form-check-input:checked[type="checkbox"] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #ff6600;
    border-color: #ff6600;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    width: 2em;
    margin-left: -2.5em;
    background-image: var(--bs-form-switch-bg);
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffb380'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e"); }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #F4F4F4, 0 0 0 0.25rem rgba(255, 102, 0, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #F4F4F4, 0 0 0 0.25rem rgba(255, 102, 0, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    appearance: none;
    background-color: #ff6600;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #ffd1b3; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-secondary-bg);
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    appearance: none;
    background-color: #ff6600;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #ffd1b3; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-secondary-bg);
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: var(--bs-secondary-color); }
    .form-range:disabled::-moz-range-thumb {
      background-color: var(--bs-secondary-color); }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
    .form-floating > .form-control:focus ~ label::after,
    .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
    .form-floating > .form-control-plaintext ~ label::after,
    .form-floating > .form-select ~ label::after {
      position: absolute;
      inset: 1rem 0.375rem;
      z-index: -1;
      height: 1.5em;
      content: "";
      background-color: var(--bs-body-bg);
      border-radius: var(--bs-border-radius); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: var(--bs-border-width) 0; }
  .form-floating > :disabled ~ label,
  .form-floating > .form-control:disabled ~ label {
    color: #74879A; }
    .form-floating > :disabled ~ label::after,
    .form-floating > .form-control:disabled ~ label::after {
      background-color: var(--bs-secondary-bg); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 5; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 5; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius); }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: var(--bs-border-radius-lg); }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: var(--bs-border-radius-sm); }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color); }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius); }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color); }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    padding-right: 4.125rem;
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color); }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: var(--bs-form-valid-color); }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: var(--bs-form-valid-color); }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color); }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius); }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color); }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    padding-right: 4.125rem;
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color); }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: var(--bs-form-invalid-color); }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: var(--bs-form-invalid-color); }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4; }

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn-check + .btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color); }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color); }
    .btn-check:checked + .btn:focus-visible,
    :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
      box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity); }

.btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #ff6600;
  --bs-btn-border-color: #ff6600;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff7d26;
  --bs-btn-hover-border-color: #ff751a;
  --bs-btn-focus-shadow-rgb: 217, 87, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff8533;
  --bs-btn-active-border-color: #ff751a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ff6600;
  --bs-btn-disabled-border-color: #ff6600; }

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #333;
  --bs-btn-border-color: #333;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2b2b2b;
  --bs-btn-hover-border-color: #292929;
  --bs-btn-focus-shadow-rgb: 82, 82, 82;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #292929;
  --bs-btn-active-border-color: #262626;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #333;
  --bs-btn-disabled-border-color: #333; }

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754; }

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0; }

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107; }

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545; }

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #F8F9FA;
  --bs-btn-border-color: #F8F9FA;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #F8F9FA;
  --bs-btn-disabled-border-color: #F8F9FA; }

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1C2227;
  --bs-btn-border-color: #1C2227;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3e4347;
  --bs-btn-hover-border-color: #33383d;
  --bs-btn-focus-shadow-rgb: 62, 67, 71;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #494e52;
  --bs-btn-active-border-color: #33383d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1C2227;
  --bs-btn-disabled-border-color: #1C2227; }

.btn-tertiary {
  --bs-btn-color: #000;
  --bs-btn-bg: #ff6840;
  --bs-btn-border-color: #ff6840;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff7f5d;
  --bs-btn-hover-border-color: #ff7753;
  --bs-btn-focus-shadow-rgb: 217, 88, 54;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff8666;
  --bs-btn-active-border-color: #ff7753;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ff6840;
  --bs-btn-disabled-border-color: #ff6840; }

.btn-outline-primary {
  --bs-btn-color: #ff6600;
  --bs-btn-border-color: #ff6600;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff6600;
  --bs-btn-hover-border-color: #ff6600;
  --bs-btn-focus-shadow-rgb: 255, 102, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff6600;
  --bs-btn-active-border-color: #ff6600;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff6600;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff6600;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #333;
  --bs-btn-border-color: #333;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #333;
  --bs-btn-hover-border-color: #333;
  --bs-btn-focus-shadow-rgb: 51, 51, 51;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #333;
  --bs-btn-active-border-color: #333;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #333;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #333;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none; }

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: #F8F9FA;
  --bs-btn-border-color: #F8F9FA;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F8F9FA;
  --bs-btn-hover-border-color: #F8F9FA;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #F8F9FA;
  --bs-btn-active-border-color: #F8F9FA;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F8F9FA;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F8F9FA;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #1C2227;
  --bs-btn-border-color: #1C2227;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1C2227;
  --bs-btn-hover-border-color: #1C2227;
  --bs-btn-focus-shadow-rgb: 28, 34, 39;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1C2227;
  --bs-btn-active-border-color: #1C2227;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1C2227;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1C2227;
  --bs-gradient: none; }

.btn-outline-tertiary {
  --bs-btn-color: #ff6840;
  --bs-btn-border-color: #ff6840;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff6840;
  --bs-btn-hover-border-color: #ff6840;
  --bs-btn-focus-shadow-rgb: 255, 104, 64;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff6840;
  --bs-btn-active-border-color: #ff6840;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff6840;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff6840;
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #74879A;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 217, 87, 0;
  text-decoration: underline; }
  .btn-link:focus-visible {
    color: var(--bs-btn-color); }
  .btn-link:hover {
    color: var(--bs-btn-hover-color); }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg); }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.75rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm); }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #ff6600;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #74879A;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0); }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.75rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #E9ECEF;
  --bs-dropdown-bg: #39434E;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #E9ECEF;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #ff6600;
  --bs-dropdown-link-disabled-color: #9BA9B6;
  --bs-dropdown-header-color: #9BA9B6; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group {
  border-radius: var(--bs-border-radius); }
  .btn-group > :not(.btn-check:first-child) + .btn,
  .btn-group > .btn-group:not(:first-child) {
    margin-left: calc(var(--bs-border-width) * -1); }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn.dropdown-toggle-split:first-child,
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: calc(var(--bs-border-width) * -1); }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color); }
  .nav-link:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25); }
  .nav-link.disabled, .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #ff6600; }
  .nav-pills .nav-link {
    border-radius: var(--bs-nav-pills-border-radius); }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap); }
  .nav-underline .nav-link {
    padding-right: 0;
    padding-left: 0;
    border-bottom: var(--bs-nav-underline-border-width) solid transparent; }
    .nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
      border-bottom-color: currentcolor; }
  .nav-underline .nav-link.active,
  .nav-underline .show > .nav-link {
    font-weight: 700;
    color: var(--bs-nav-underline-link-active-color);
    border-bottom-color: currentcolor; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.40625rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2828, 34, 39, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color); }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color); }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0; }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius); }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231C2227'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23662900'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #ffb380;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle); }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.875rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius); }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius); }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: var(--bs-accordion-border-radius);
      border-bottom-left-radius: var(--bs-accordion-border-radius); }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0; }

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffa366'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffa366'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #ff6600;
  --bs-pagination-active-border-color: #ff6600;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1); }

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius); }

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg); }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.75rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm); }

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color); }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis); }

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis); }

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis); }

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis); }

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis); }

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis); }

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis); }

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis); }

.alert-tertiary {
  --bs-alert-color: var(--bs-tertiary-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-text-emphasis); }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.65625rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #ff6600;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition); }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height); }

.progress-stacked > .progress {
  overflow: visible; }

.progress-stacked > .progress > .progress-bar {
  width: 100%; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #ff6600;
  --bs-list-group-active-border-color: #ff6600;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis); }

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis); }

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis); }

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis); }

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis); }

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis); }

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis); }

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis); }

.list-group-item-tertiary {
  --bs-list-group-color: var(--bs-tertiary-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-text-emphasis); }

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity); }
  .btn-close:hover {
    color: var(--bs-btn-close-color);
    text-decoration: none;
    opacity: var(--bs-btn-close-hover-opacity); }
  .btn-close:focus {
    outline: 0;
    box-shadow: var(--bs-btn-close-focus-shadow);
    opacity: var(--bs-btn-close-focus-opacity); }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: var(--bs-btn-close-disabled-opacity); }

.btn-close-white {
  filter: var(--bs-btn-close-white-filter); }

[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter); }

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius); }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing); }

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width)); }
  .toast-header .btn-close {
    margin-right: calc(-.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x); }

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word; }

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0; }

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity); }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius); }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto; }

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height); }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); }
  .modal-footer > * {
    margin: calc(var(--bs-modal-footer-gap) * .5); }

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow); }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header,
  .modal-fullscreen .modal-footer {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header,
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header,
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header,
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header,
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header,
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; } }

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.75rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: var(--bs-tooltip-opacity); }
  .tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height); }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height)); }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height)); }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius); }

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.75rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.875rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius); }
  .popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height); }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg); }

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg); }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg); }

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color); }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000; }

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000; }

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name); }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent; }

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0; }

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s; } }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5; }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      transition: none; } }

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      transition: none; } }

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      transition: none; } }

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      transition: none; } }

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
      transition: none; } }

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
      visibility: visible; } }

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxl .offcanvas-header {
      display: none; }
    .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition); }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
    margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y)); }

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height); }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-tertiary {
  color: #000 !important;
  background-color: RGBA(var(--bs-tertiary-rgb), var(--bs-bg-opacity, 1)) !important; }

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-primary:hover, .link-primary:focus {
    color: RGBA(255, 133, 51, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(255, 133, 51, var(--bs-link-underline-opacity, 1)) !important; }

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important; }

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-success:hover, .link-success:focus {
    color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important; }

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-info:hover, .link-info:focus {
    color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important; }

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-warning:hover, .link-warning:focus {
    color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important; }

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-danger:hover, .link-danger:focus {
    color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important; }

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-light:hover, .link-light:focus {
    color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important; }

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-dark:hover, .link-dark:focus {
    color: RGBA(22, 27, 31, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(22, 27, 31, var(--bs-link-underline-opacity, 1)) !important; }

.link-tertiary {
  color: RGBA(var(--bs-tertiary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-tertiary:hover, .link-tertiary:focus {
    color: RGBA(255, 134, 102, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(255, 134, 102, var(--bs-link-underline-opacity, 1)) !important; }

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-body-emphasis:hover, .link-body-emphasis:focus {
    color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
    text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important; }

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color); }

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden; }
  .icon-link > .bi {
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    fill: currentcolor;
    transition: 0.2s ease-in-out transform; }
    @media (prefers-reduced-motion: reduce) {
      .icon-link > .bi {
        transition: none; } }

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0)); }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }
  .visually-hidden:not(caption),
  .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
    position: absolute !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25; }

/* CUSTOM CODE START -------------------------------------------------------------- */
.text-bg-gray-100 {
  color: #000 !important;
  background-color: RGBA(var(--bs-gray-100-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-200 {
  color: #000 !important;
  background-color: RGBA(var(--bs-gray-200-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-300 {
  color: #000 !important;
  background-color: RGBA(var(--bs-gray-300-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-400 {
  color: #000 !important;
  background-color: RGBA(var(--bs-gray-400-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-500 {
  color: #000 !important;
  background-color: RGBA(var(--bs-gray-500-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-600 {
  color: #000 !important;
  background-color: RGBA(var(--bs-gray-600-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-700 {
  color: #fff !important;
  background-color: RGBA(var(--bs-gray-700-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-800 {
  color: #fff !important;
  background-color: RGBA(var(--bs-gray-800-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-900 {
  color: #fff !important;
  background-color: RGBA(var(--bs-gray-900-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-blue {
  color: #fff !important;
  background-color: RGBA(var(--bs-blue-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-indigo {
  color: #fff !important;
  background-color: RGBA(var(--bs-indigo-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-purple {
  color: #fff !important;
  background-color: RGBA(var(--bs-purple-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-pink {
  color: #fff !important;
  background-color: RGBA(var(--bs-pink-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-red {
  color: #fff !important;
  background-color: RGBA(var(--bs-red-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-orange {
  color: #000 !important;
  background-color: RGBA(var(--bs-orange-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-yellow {
  color: #000 !important;
  background-color: RGBA(var(--bs-yellow-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-green {
  color: #fff !important;
  background-color: RGBA(var(--bs-green-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-teal {
  color: #000 !important;
  background-color: RGBA(var(--bs-teal-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-cyan {
  color: #000 !important;
  background-color: RGBA(var(--bs-cyan-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-black {
  color: #fff !important;
  background-color: RGBA(var(--bs-black-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-white {
  color: #000 !important;
  background-color: RGBA(var(--bs-white-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray {
  color: #000 !important;
  background-color: RGBA(var(--bs-gray-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-gray-dark-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-surface-lowest {
  color: #000 !important;
  background-color: RGBA(var(--bs-surface-lowest-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-surface-low {
  color: #000 !important;
  background-color: RGBA(var(--bs-surface-low-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-surface {
  color: #000 !important;
  background-color: RGBA(var(--bs-surface-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-surface-high {
  color: #000 !important;
  background-color: RGBA(var(--bs-surface-high-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-surface-highest {
  color: #000 !important;
  background-color: RGBA(var(--bs-surface-highest-rgb), var(--bs-bg-opacity, 1)) !important; }

.bg-primary-hover:hover {
  background-color: #ff7d26 !important; }

.border-primary-hover:hover {
  border-color: #ff7d26 !important; }

.bg-secondary-hover:hover {
  background-color: #2b2b2b !important; }

.border-secondary-hover:hover {
  border-color: #2b2b2b !important; }

.bg-success-hover:hover {
  background-color: #157347 !important; }

.border-success-hover:hover {
  border-color: #157347 !important; }

.bg-info-hover:hover {
  background-color: #31d2f2 !important; }

.border-info-hover:hover {
  border-color: #31d2f2 !important; }

.bg-warning-hover:hover {
  background-color: #ffca2c !important; }

.border-warning-hover:hover {
  border-color: #ffca2c !important; }

.bg-danger-hover:hover {
  background-color: #bb2d3b !important; }

.border-danger-hover:hover {
  border-color: #bb2d3b !important; }

.bg-light-hover:hover {
  background-color: #f9fafb !important; }

.border-light-hover:hover {
  border-color: #f9fafb !important; }

.bg-dark-hover:hover {
  background-color: #181d21 !important; }

.border-dark-hover:hover {
  border-color: #181d21 !important; }

.bg-tertiary-hover:hover {
  background-color: #ff7f5d !important; }

.border-tertiary-hover:hover {
  border-color: #ff7f5d !important; }

.bg-primary-subtle-hover:hover {
  background-color: #ffe5d4 !important; }

.bg-secondary-subtle-hover:hover {
  background-color: gainsboro !important; }

.bg-success-subtle-hover:hover {
  background-color: #d8ebe2 !important; }

.bg-info-subtle-hover:hover {
  background-color: #d6f6fc !important; }

.bg-warning-subtle-hover:hover {
  background-color: #fff5d5 !important; }

.bg-danger-subtle-hover:hover {
  background-color: #f9dde0 !important; }

.bg-light-subtle-hover:hover {
  background-color: #fcfcfd !important; }

.bg-dark-subtle-hover:hover {
  background-color: #cbd2da !important; }

.bg-tertiary-subtle-hover:hover {
  background-color: #ffe6df !important; }

.border-primary-subtle-hover:hover {
  border-color: #ffcba8 !important; }

.border-secondary-subtle-hover:hover {
  border-color: #b9b9b9 !important; }

.border-success-subtle-hover:hover {
  border-color: #b1d6c5 !important; }

.border-info-subtle-hover:hover {
  border-color: #adedfa !important; }

.border-warning-subtle-hover:hover {
  border-color: #ffeaab !important; }

.border-danger-subtle-hover:hover {
  border-color: #f3bac0 !important; }

.border-light-subtle-hover:hover {
  border-color: #f6f6f6 !important; }

.border-dark-subtle-hover:hover {
  border-color: #aab6c1 !important; }

.border-tertiary-subtle-hover:hover {
  border-color: #ffccbe !important; }

.bg-gray-100-hover:hover {
  background-color: #f9fafb !important; }

.border-gray-100-hover:hover {
  border-color: #f9fafb !important; }

.bg-gray-200-hover:hover {
  background-color: #f6f6f6 !important; }

.border-gray-200-hover:hover {
  border-color: #f6f6f6 !important; }

.bg-gray-300-hover:hover {
  background-color: #eceff1 !important; }

.border-gray-300-hover:hover {
  border-color: #eceff1 !important; }

.bg-gray-400-hover:hover {
  background-color: #cbd2da !important; }

.border-gray-400-hover:hover {
  border-color: #cbd2da !important; }

.bg-gray-500-hover:hover {
  background-color: #aab6c1 !important; }

.border-gray-500-hover:hover {
  border-color: #aab6c1 !important; }

.bg-gray-600-hover:hover {
  background-color: #8999a9 !important; }

.border-gray-600-hover:hover {
  border-color: #8999a9 !important; }

.bg-gray-700-hover:hover {
  background-color: #485663 !important; }

.border-gray-700-hover:hover {
  border-color: #485663 !important; }

.bg-gray-800-hover:hover {
  background-color: #303942 !important; }

.border-gray-800-hover:hover {
  border-color: #303942 !important; }

.bg-gray-900-hover:hover {
  background-color: #181d21 !important; }

.border-gray-900-hover:hover {
  border-color: #181d21 !important; }

.bg-surface-lowest-hover:hover {
  background-color: #aab6c1 !important; }

.bg-surface-low-hover:hover {
  background-color: #cbd2da !important; }

.bg-surface-hover:hover {
  background-color: white !important; }

.bg-surface-high-hover:hover {
  background-color: #f9fafb !important; }

.bg-surface-highest-hover:hover {
  background-color: #eceff1 !important; }

.subtitle-1 {
  font-size: 1.09375rem; }

.subtitle-2 {
  font-size: 0.98438rem; }

.subtitle-3 {
  font-size: 0.875rem; }

.subtitle-4 {
  font-size: 0.76562rem; }

.subtitle-5 {
  font-size: 0.76562rem; }

.subtitle-5 {
  font-size: 0.76562rem; }

.cursor-auto {
  cursor: auto; }

.cursor-default {
  cursor: default; }

.cursor-none {
  cursor: none; }

.cursor-not-allowed {
  cursor: not-allowed; }

.cursor-pointer {
  cursor: pointer; }

/* CUSTOM CODE END ---------------------------------------------------------------- */
.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.object-fit-contain {
  object-fit: contain !important; }

.object-fit-cover {
  object-fit: cover !important; }

.object-fit-fill {
  object-fit: fill !important; }

.object-fit-scale {
  object-fit: scale-down !important; }

.object-fit-none {
  object-fit: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.overflow-x-auto {
  overflow-x: auto !important; }

.overflow-x-hidden {
  overflow-x: hidden !important; }

.overflow-x-visible {
  overflow-x: visible !important; }

.overflow-x-scroll {
  overflow-x: scroll !important; }

.overflow-y-auto {
  overflow-y: auto !important; }

.overflow-y-hidden {
  overflow-y: hidden !important; }

.overflow-y-visible {
  overflow-y: visible !important; }

.overflow-y-scroll {
  overflow-y: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-inline-grid {
  display: inline-grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow-none {
  box-shadow: none !important; }

.shadow-1 {
  box-shadow: var(--ims-sys-elevation-1) !important; }

.shadow-2 {
  box-shadow: var(--ims-sys-elevation-2) !important; }

.shadow-3 {
  box-shadow: var(--ims-sys-elevation-3) !important; }

.shadow-4 {
  box-shadow: var(--ims-sys-elevation-4) !important; }

.shadow-5 {
  box-shadow: var(--ims-sys-elevation-5) !important; }

.shadow-6 {
  box-shadow: var(--ims-sys-elevation-6) !important; }

.shadow-sm {
  box-shadow: var(--ims-sys-elevation-1) !important; }

.shadow {
  box-shadow: var(--ims-sys-elevation-3) !important; }

.shadow-lg {
  box-shadow: var(--ims-sys-elevation-5) !important; }

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-tertiary {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-rgb), var(--bs-focus-ring-opacity)); }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-tertiary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-rgb), var(--bs-border-opacity)) !important; }

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-subtle {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-border-color-subtle-rgb), var(--bs-border-opacity)) !important; }

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important; }

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important; }

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important; }

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important; }

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important; }

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important; }

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important; }

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-10 {
  width: 10% !important; }

.w-20 {
  width: 20% !important; }

.w-33 {
  width: 33% !important; }

.w-66 {
  width: 66% !important; }

.w-1px {
  width: 1px !important; }

.w-2px {
  width: 2px !important; }

.w-4px {
  width: 4px !important; }

.w-8px {
  width: 8px !important; }

.w-16px {
  width: 16px !important; }

.w-24px {
  width: 24px !important; }

.w-32px {
  width: 32px !important; }

.w-48px {
  width: 48px !important; }

.w-64px {
  width: 64px !important; }

.w-96px {
  width: 96px !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.h-1px {
  height: 1px !important; }

.h-2px {
  height: 2px !important; }

.h-4px {
  height: 4px !important; }

.h-8px {
  height: 8px !important; }

.h-16px {
  height: 16px !important; }

.h-24px {
  height: 24px !important; }

.h-32px {
  height: 32px !important; }

.h-48px {
  height: 48px !important; }

.h-64px {
  height: 64px !important; }

.h-96px {
  height: 96px !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1px {
  margin: 0.0625rem !important; }

.m-2px {
  margin: 0.125rem !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 2rem !important; }

.m-6 {
  margin: 3rem !important; }

.m-7 {
  margin: 4rem !important; }

.m-8 {
  margin: 6rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1px {
  margin-right: 0.0625rem !important;
  margin-left: 0.0625rem !important; }

.mx-2px {
  margin-right: 0.125rem !important;
  margin-left: 0.125rem !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.mx-6 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-7 {
  margin-right: 4rem !important;
  margin-left: 4rem !important; }

.mx-8 {
  margin-right: 6rem !important;
  margin-left: 6rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1px {
  margin-top: 0.0625rem !important;
  margin-bottom: 0.0625rem !important; }

.my-2px {
  margin-top: 0.125rem !important;
  margin-bottom: 0.125rem !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-7 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important; }

.my-8 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1px {
  margin-top: 0.0625rem !important; }

.mt-2px {
  margin-top: 0.125rem !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 2rem !important; }

.mt-6 {
  margin-top: 3rem !important; }

.mt-7 {
  margin-top: 4rem !important; }

.mt-8 {
  margin-top: 6rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1px {
  margin-right: 0.0625rem !important; }

.me-2px {
  margin-right: 0.125rem !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 2rem !important; }

.me-6 {
  margin-right: 3rem !important; }

.me-7 {
  margin-right: 4rem !important; }

.me-8 {
  margin-right: 6rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1px {
  margin-bottom: 0.0625rem !important; }

.mb-2px {
  margin-bottom: 0.125rem !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 2rem !important; }

.mb-6 {
  margin-bottom: 3rem !important; }

.mb-7 {
  margin-bottom: 4rem !important; }

.mb-8 {
  margin-bottom: 6rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1px {
  margin-left: 0.0625rem !important; }

.ms-2px {
  margin-left: 0.125rem !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 2rem !important; }

.ms-6 {
  margin-left: 3rem !important; }

.ms-7 {
  margin-left: 4rem !important; }

.ms-8 {
  margin-left: 6rem !important; }

.ms-auto {
  margin-left: auto !important; }

.m-n1px {
  margin: -0.0625rem !important; }

.m-n2px {
  margin: -0.125rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.m-n5 {
  margin: -2rem !important; }

.m-n6 {
  margin: -3rem !important; }

.m-n7 {
  margin: -4rem !important; }

.m-n8 {
  margin: -6rem !important; }

.mx-n1px {
  margin-right: -0.0625rem !important;
  margin-left: -0.0625rem !important; }

.mx-n2px {
  margin-right: -0.125rem !important;
  margin-left: -0.125rem !important; }

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important; }

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important; }

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important; }

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important; }

.mx-n5 {
  margin-right: -2rem !important;
  margin-left: -2rem !important; }

.mx-n6 {
  margin-right: -3rem !important;
  margin-left: -3rem !important; }

.mx-n7 {
  margin-right: -4rem !important;
  margin-left: -4rem !important; }

.mx-n8 {
  margin-right: -6rem !important;
  margin-left: -6rem !important; }

.my-n1px {
  margin-top: -0.0625rem !important;
  margin-bottom: -0.0625rem !important; }

.my-n2px {
  margin-top: -0.125rem !important;
  margin-bottom: -0.125rem !important; }

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important; }

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important; }

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important; }

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important; }

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important; }

.my-n6 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important; }

.my-n7 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important; }

.my-n8 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important; }

.mt-n1px {
  margin-top: -0.0625rem !important; }

.mt-n2px {
  margin-top: -0.125rem !important; }

.mt-n1 {
  margin-top: -0.25rem !important; }

.mt-n2 {
  margin-top: -0.5rem !important; }

.mt-n3 {
  margin-top: -1rem !important; }

.mt-n4 {
  margin-top: -1.5rem !important; }

.mt-n5 {
  margin-top: -2rem !important; }

.mt-n6 {
  margin-top: -3rem !important; }

.mt-n7 {
  margin-top: -4rem !important; }

.mt-n8 {
  margin-top: -6rem !important; }

.me-n1px {
  margin-right: -0.0625rem !important; }

.me-n2px {
  margin-right: -0.125rem !important; }

.me-n1 {
  margin-right: -0.25rem !important; }

.me-n2 {
  margin-right: -0.5rem !important; }

.me-n3 {
  margin-right: -1rem !important; }

.me-n4 {
  margin-right: -1.5rem !important; }

.me-n5 {
  margin-right: -2rem !important; }

.me-n6 {
  margin-right: -3rem !important; }

.me-n7 {
  margin-right: -4rem !important; }

.me-n8 {
  margin-right: -6rem !important; }

.mb-n1px {
  margin-bottom: -0.0625rem !important; }

.mb-n2px {
  margin-bottom: -0.125rem !important; }

.mb-n1 {
  margin-bottom: -0.25rem !important; }

.mb-n2 {
  margin-bottom: -0.5rem !important; }

.mb-n3 {
  margin-bottom: -1rem !important; }

.mb-n4 {
  margin-bottom: -1.5rem !important; }

.mb-n5 {
  margin-bottom: -2rem !important; }

.mb-n6 {
  margin-bottom: -3rem !important; }

.mb-n7 {
  margin-bottom: -4rem !important; }

.mb-n8 {
  margin-bottom: -6rem !important; }

.ms-n1px {
  margin-left: -0.0625rem !important; }

.ms-n2px {
  margin-left: -0.125rem !important; }

.ms-n1 {
  margin-left: -0.25rem !important; }

.ms-n2 {
  margin-left: -0.5rem !important; }

.ms-n3 {
  margin-left: -1rem !important; }

.ms-n4 {
  margin-left: -1.5rem !important; }

.ms-n5 {
  margin-left: -2rem !important; }

.ms-n6 {
  margin-left: -3rem !important; }

.ms-n7 {
  margin-left: -4rem !important; }

.ms-n8 {
  margin-left: -6rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1px {
  padding: 0.0625rem !important; }

.p-2px {
  padding: 0.125rem !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 2rem !important; }

.p-6 {
  padding: 3rem !important; }

.p-7 {
  padding: 4rem !important; }

.p-8 {
  padding: 6rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1px {
  padding-right: 0.0625rem !important;
  padding-left: 0.0625rem !important; }

.px-2px {
  padding-right: 0.125rem !important;
  padding-left: 0.125rem !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.px-6 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.px-7 {
  padding-right: 4rem !important;
  padding-left: 4rem !important; }

.px-8 {
  padding-right: 6rem !important;
  padding-left: 6rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1px {
  padding-top: 0.0625rem !important;
  padding-bottom: 0.0625rem !important; }

.py-2px {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.py-7 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important; }

.py-8 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1px {
  padding-top: 0.0625rem !important; }

.pt-2px {
  padding-top: 0.125rem !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 2rem !important; }

.pt-6 {
  padding-top: 3rem !important; }

.pt-7 {
  padding-top: 4rem !important; }

.pt-8 {
  padding-top: 6rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1px {
  padding-right: 0.0625rem !important; }

.pe-2px {
  padding-right: 0.125rem !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 2rem !important; }

.pe-6 {
  padding-right: 3rem !important; }

.pe-7 {
  padding-right: 4rem !important; }

.pe-8 {
  padding-right: 6rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1px {
  padding-bottom: 0.0625rem !important; }

.pb-2px {
  padding-bottom: 0.125rem !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 2rem !important; }

.pb-6 {
  padding-bottom: 3rem !important; }

.pb-7 {
  padding-bottom: 4rem !important; }

.pb-8 {
  padding-bottom: 6rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1px {
  padding-left: 0.0625rem !important; }

.ps-2px {
  padding-left: 0.125rem !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 2rem !important; }

.ps-6 {
  padding-left: 3rem !important; }

.ps-7 {
  padding-left: 4rem !important; }

.ps-8 {
  padding-left: 6rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1px {
  gap: 0.0625rem !important; }

.gap-2px {
  gap: 0.125rem !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 2rem !important; }

.gap-6 {
  gap: 3rem !important; }

.gap-7 {
  gap: 4rem !important; }

.gap-8 {
  gap: 6rem !important; }

.row-gap-0 {
  row-gap: 0 !important; }

.row-gap-1px {
  row-gap: 0.0625rem !important; }

.row-gap-2px {
  row-gap: 0.125rem !important; }

.row-gap-1 {
  row-gap: 0.25rem !important; }

.row-gap-2 {
  row-gap: 0.5rem !important; }

.row-gap-3 {
  row-gap: 1rem !important; }

.row-gap-4 {
  row-gap: 1.5rem !important; }

.row-gap-5 {
  row-gap: 2rem !important; }

.row-gap-6 {
  row-gap: 3rem !important; }

.row-gap-7 {
  row-gap: 4rem !important; }

.row-gap-8 {
  row-gap: 6rem !important; }

.column-gap-0 {
  column-gap: 0 !important; }

.column-gap-1px {
  column-gap: 0.0625rem !important; }

.column-gap-2px {
  column-gap: 0.125rem !important; }

.column-gap-1 {
  column-gap: 0.25rem !important; }

.column-gap-2 {
  column-gap: 0.5rem !important; }

.column-gap-3 {
  column-gap: 1rem !important; }

.column-gap-4 {
  column-gap: 1.5rem !important; }

.column-gap-5 {
  column-gap: 2rem !important; }

.column-gap-6 {
  column-gap: 3rem !important; }

.column-gap-7 {
  column-gap: 4rem !important; }

.column-gap-8 {
  column-gap: 6rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.34375rem + 1.125vw) !important; }

.fs-2 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-3 {
  font-size: calc(1.27813rem + 0.3375vw) !important; }

.fs-4 {
  font-size: calc(1.25625rem + 0.075vw) !important; }

.fs-5 {
  font-size: 1.09375rem !important; }

.fs-6 {
  font-size: 0.875rem !important; }

.fs-4-5 {
  font-size: 1.125rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-medium {
  font-weight: 500 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-tertiary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important; }

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important; }

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important; }

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important; }

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important; }

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important; }

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important; }

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important; }

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important; }

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important; }

.text-teal {
  color: var(--bs-teal) !important; }

.text-blue {
  color: var(--bs-blue) !important; }

.link-opacity-10 {
  --bs-link-opacity: 0.1; }

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1; }

.link-opacity-25 {
  --bs-link-opacity: 0.25; }

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25; }

.link-opacity-50 {
  --bs-link-opacity: 0.5; }

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5; }

.link-opacity-75 {
  --bs-link-opacity: 0.75; }

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75; }

.link-opacity-100 {
  --bs-link-opacity: 1; }

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1; }

.link-offset-1 {
  text-underline-offset: 0.125em !important; }

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important; }

.link-offset-2 {
  text-underline-offset: 0.25em !important; }

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important; }

.link-offset-3 {
  text-underline-offset: 0.375em !important; }

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important; }

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-tertiary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important; }

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0; }

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0; }

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1; }

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1; }

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25; }

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25; }

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5; }

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5; }

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75; }

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75; }

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1; }

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-100-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-200-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-300-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-400-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-500-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-600-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-700-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-800-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-900-rgb), var(--bs-bg-opacity)) !important; }

.bg-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity)) !important; }

.bg-indigo {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-rgb), var(--bs-bg-opacity)) !important; }

.bg-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important; }

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important; }

.bg-red {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important; }

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important; }

.bg-yellow {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-rgb), var(--bs-bg-opacity)) !important; }

.bg-green {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important; }

.bg-teal {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important; }

.bg-cyan {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-surface-lowest {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-surface-lowest-rgb), var(--bs-bg-opacity)) !important; }

.bg-surface-low {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-surface-low-rgb), var(--bs-bg-opacity)) !important; }

.bg-surface {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-surface-rgb), var(--bs-bg-opacity)) !important; }

.bg-surface-high {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-surface-high-rgb), var(--bs-bg-opacity)) !important; }

.bg-surface-highest {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-surface-highest-rgb), var(--bs-bg-opacity)) !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important; }

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important; }

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important; }

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important; }

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important; }

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important; }

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important; }

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important; }

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important; }

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important; }

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important; }

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important; }

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important; }

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important; }

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important; }

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important; }

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important; }

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important; }

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important; }

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important; }

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important; }

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important; }

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important; }

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important; }

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important; }

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important; }

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important; }

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important; }

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.z-n1 {
  z-index: -1 !important; }

.z-0 {
  z-index: 0 !important; }

.z-1 {
  z-index: 1 !important; }

.z-2 {
  z-index: 2 !important; }

.z-3 {
  z-index: 3 !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .object-fit-sm-contain {
    object-fit: contain !important; }
  .object-fit-sm-cover {
    object-fit: cover !important; }
  .object-fit-sm-fill {
    object-fit: fill !important; }
  .object-fit-sm-scale {
    object-fit: scale-down !important; }
  .object-fit-sm-none {
    object-fit: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-inline-grid {
    display: inline-grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .w-sm-25 {
    width: 25% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .w-sm-100 {
    width: 100% !important; }
  .w-sm-auto {
    width: auto !important; }
  .w-sm-10 {
    width: 10% !important; }
  .w-sm-20 {
    width: 20% !important; }
  .w-sm-33 {
    width: 33% !important; }
  .w-sm-66 {
    width: 66% !important; }
  .w-sm-1px {
    width: 1px !important; }
  .w-sm-2px {
    width: 2px !important; }
  .w-sm-4px {
    width: 4px !important; }
  .w-sm-8px {
    width: 8px !important; }
  .w-sm-16px {
    width: 16px !important; }
  .w-sm-24px {
    width: 24px !important; }
  .w-sm-32px {
    width: 32px !important; }
  .w-sm-48px {
    width: 48px !important; }
  .w-sm-64px {
    width: 64px !important; }
  .w-sm-96px {
    width: 96px !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1px {
    margin: 0.0625rem !important; }
  .m-sm-2px {
    margin: 0.125rem !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 2rem !important; }
  .m-sm-6 {
    margin: 3rem !important; }
  .m-sm-7 {
    margin: 4rem !important; }
  .m-sm-8 {
    margin: 6rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1px {
    margin-right: 0.0625rem !important;
    margin-left: 0.0625rem !important; }
  .mx-sm-2px {
    margin-right: 0.125rem !important;
    margin-left: 0.125rem !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-sm-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-sm-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1px {
    margin-top: 0.0625rem !important;
    margin-bottom: 0.0625rem !important; }
  .my-sm-2px {
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-sm-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-sm-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1px {
    margin-top: 0.0625rem !important; }
  .mt-sm-2px {
    margin-top: 0.125rem !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 2rem !important; }
  .mt-sm-6 {
    margin-top: 3rem !important; }
  .mt-sm-7 {
    margin-top: 4rem !important; }
  .mt-sm-8 {
    margin-top: 6rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1px {
    margin-right: 0.0625rem !important; }
  .me-sm-2px {
    margin-right: 0.125rem !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 2rem !important; }
  .me-sm-6 {
    margin-right: 3rem !important; }
  .me-sm-7 {
    margin-right: 4rem !important; }
  .me-sm-8 {
    margin-right: 6rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1px {
    margin-bottom: 0.0625rem !important; }
  .mb-sm-2px {
    margin-bottom: 0.125rem !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 2rem !important; }
  .mb-sm-6 {
    margin-bottom: 3rem !important; }
  .mb-sm-7 {
    margin-bottom: 4rem !important; }
  .mb-sm-8 {
    margin-bottom: 6rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1px {
    margin-left: 0.0625rem !important; }
  .ms-sm-2px {
    margin-left: 0.125rem !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 2rem !important; }
  .ms-sm-6 {
    margin-left: 3rem !important; }
  .ms-sm-7 {
    margin-left: 4rem !important; }
  .ms-sm-8 {
    margin-left: 6rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1px {
    margin: -0.0625rem !important; }
  .m-sm-n2px {
    margin: -0.125rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .m-sm-n5 {
    margin: -2rem !important; }
  .m-sm-n6 {
    margin: -3rem !important; }
  .m-sm-n7 {
    margin: -4rem !important; }
  .m-sm-n8 {
    margin: -6rem !important; }
  .mx-sm-n1px {
    margin-right: -0.0625rem !important;
    margin-left: -0.0625rem !important; }
  .mx-sm-n2px {
    margin-right: -0.125rem !important;
    margin-left: -0.125rem !important; }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-sm-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-sm-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-sm-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-sm-n8 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .my-sm-n1px {
    margin-top: -0.0625rem !important;
    margin-bottom: -0.0625rem !important; }
  .my-sm-n2px {
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important; }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-sm-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-sm-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-sm-n8 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .mt-sm-n1px {
    margin-top: -0.0625rem !important; }
  .mt-sm-n2px {
    margin-top: -0.125rem !important; }
  .mt-sm-n1 {
    margin-top: -0.25rem !important; }
  .mt-sm-n2 {
    margin-top: -0.5rem !important; }
  .mt-sm-n3 {
    margin-top: -1rem !important; }
  .mt-sm-n4 {
    margin-top: -1.5rem !important; }
  .mt-sm-n5 {
    margin-top: -2rem !important; }
  .mt-sm-n6 {
    margin-top: -3rem !important; }
  .mt-sm-n7 {
    margin-top: -4rem !important; }
  .mt-sm-n8 {
    margin-top: -6rem !important; }
  .me-sm-n1px {
    margin-right: -0.0625rem !important; }
  .me-sm-n2px {
    margin-right: -0.125rem !important; }
  .me-sm-n1 {
    margin-right: -0.25rem !important; }
  .me-sm-n2 {
    margin-right: -0.5rem !important; }
  .me-sm-n3 {
    margin-right: -1rem !important; }
  .me-sm-n4 {
    margin-right: -1.5rem !important; }
  .me-sm-n5 {
    margin-right: -2rem !important; }
  .me-sm-n6 {
    margin-right: -3rem !important; }
  .me-sm-n7 {
    margin-right: -4rem !important; }
  .me-sm-n8 {
    margin-right: -6rem !important; }
  .mb-sm-n1px {
    margin-bottom: -0.0625rem !important; }
  .mb-sm-n2px {
    margin-bottom: -0.125rem !important; }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-sm-n3 {
    margin-bottom: -1rem !important; }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-sm-n5 {
    margin-bottom: -2rem !important; }
  .mb-sm-n6 {
    margin-bottom: -3rem !important; }
  .mb-sm-n7 {
    margin-bottom: -4rem !important; }
  .mb-sm-n8 {
    margin-bottom: -6rem !important; }
  .ms-sm-n1px {
    margin-left: -0.0625rem !important; }
  .ms-sm-n2px {
    margin-left: -0.125rem !important; }
  .ms-sm-n1 {
    margin-left: -0.25rem !important; }
  .ms-sm-n2 {
    margin-left: -0.5rem !important; }
  .ms-sm-n3 {
    margin-left: -1rem !important; }
  .ms-sm-n4 {
    margin-left: -1.5rem !important; }
  .ms-sm-n5 {
    margin-left: -2rem !important; }
  .ms-sm-n6 {
    margin-left: -3rem !important; }
  .ms-sm-n7 {
    margin-left: -4rem !important; }
  .ms-sm-n8 {
    margin-left: -6rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1px {
    padding: 0.0625rem !important; }
  .p-sm-2px {
    padding: 0.125rem !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 2rem !important; }
  .p-sm-6 {
    padding: 3rem !important; }
  .p-sm-7 {
    padding: 4rem !important; }
  .p-sm-8 {
    padding: 6rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1px {
    padding-right: 0.0625rem !important;
    padding-left: 0.0625rem !important; }
  .px-sm-2px {
    padding-right: 0.125rem !important;
    padding-left: 0.125rem !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-sm-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-sm-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-sm-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1px {
    padding-top: 0.0625rem !important;
    padding-bottom: 0.0625rem !important; }
  .py-sm-2px {
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-sm-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-sm-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-sm-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1px {
    padding-top: 0.0625rem !important; }
  .pt-sm-2px {
    padding-top: 0.125rem !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 2rem !important; }
  .pt-sm-6 {
    padding-top: 3rem !important; }
  .pt-sm-7 {
    padding-top: 4rem !important; }
  .pt-sm-8 {
    padding-top: 6rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1px {
    padding-right: 0.0625rem !important; }
  .pe-sm-2px {
    padding-right: 0.125rem !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 2rem !important; }
  .pe-sm-6 {
    padding-right: 3rem !important; }
  .pe-sm-7 {
    padding-right: 4rem !important; }
  .pe-sm-8 {
    padding-right: 6rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1px {
    padding-bottom: 0.0625rem !important; }
  .pb-sm-2px {
    padding-bottom: 0.125rem !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 2rem !important; }
  .pb-sm-6 {
    padding-bottom: 3rem !important; }
  .pb-sm-7 {
    padding-bottom: 4rem !important; }
  .pb-sm-8 {
    padding-bottom: 6rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1px {
    padding-left: 0.0625rem !important; }
  .ps-sm-2px {
    padding-left: 0.125rem !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 2rem !important; }
  .ps-sm-6 {
    padding-left: 3rem !important; }
  .ps-sm-7 {
    padding-left: 4rem !important; }
  .ps-sm-8 {
    padding-left: 6rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1px {
    gap: 0.0625rem !important; }
  .gap-sm-2px {
    gap: 0.125rem !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 2rem !important; }
  .gap-sm-6 {
    gap: 3rem !important; }
  .gap-sm-7 {
    gap: 4rem !important; }
  .gap-sm-8 {
    gap: 6rem !important; }
  .row-gap-sm-0 {
    row-gap: 0 !important; }
  .row-gap-sm-1px {
    row-gap: 0.0625rem !important; }
  .row-gap-sm-2px {
    row-gap: 0.125rem !important; }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important; }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important; }
  .row-gap-sm-3 {
    row-gap: 1rem !important; }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important; }
  .row-gap-sm-5 {
    row-gap: 2rem !important; }
  .row-gap-sm-6 {
    row-gap: 3rem !important; }
  .row-gap-sm-7 {
    row-gap: 4rem !important; }
  .row-gap-sm-8 {
    row-gap: 6rem !important; }
  .column-gap-sm-0 {
    column-gap: 0 !important; }
  .column-gap-sm-1px {
    column-gap: 0.0625rem !important; }
  .column-gap-sm-2px {
    column-gap: 0.125rem !important; }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important; }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important; }
  .column-gap-sm-3 {
    column-gap: 1rem !important; }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important; }
  .column-gap-sm-5 {
    column-gap: 2rem !important; }
  .column-gap-sm-6 {
    column-gap: 3rem !important; }
  .column-gap-sm-7 {
    column-gap: 4rem !important; }
  .column-gap-sm-8 {
    column-gap: 6rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .object-fit-md-contain {
    object-fit: contain !important; }
  .object-fit-md-cover {
    object-fit: cover !important; }
  .object-fit-md-fill {
    object-fit: fill !important; }
  .object-fit-md-scale {
    object-fit: scale-down !important; }
  .object-fit-md-none {
    object-fit: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-inline-grid {
    display: inline-grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .w-md-25 {
    width: 25% !important; }
  .w-md-50 {
    width: 50% !important; }
  .w-md-75 {
    width: 75% !important; }
  .w-md-100 {
    width: 100% !important; }
  .w-md-auto {
    width: auto !important; }
  .w-md-10 {
    width: 10% !important; }
  .w-md-20 {
    width: 20% !important; }
  .w-md-33 {
    width: 33% !important; }
  .w-md-66 {
    width: 66% !important; }
  .w-md-1px {
    width: 1px !important; }
  .w-md-2px {
    width: 2px !important; }
  .w-md-4px {
    width: 4px !important; }
  .w-md-8px {
    width: 8px !important; }
  .w-md-16px {
    width: 16px !important; }
  .w-md-24px {
    width: 24px !important; }
  .w-md-32px {
    width: 32px !important; }
  .w-md-48px {
    width: 48px !important; }
  .w-md-64px {
    width: 64px !important; }
  .w-md-96px {
    width: 96px !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1px {
    margin: 0.0625rem !important; }
  .m-md-2px {
    margin: 0.125rem !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 2rem !important; }
  .m-md-6 {
    margin: 3rem !important; }
  .m-md-7 {
    margin: 4rem !important; }
  .m-md-8 {
    margin: 6rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1px {
    margin-right: 0.0625rem !important;
    margin-left: 0.0625rem !important; }
  .mx-md-2px {
    margin-right: 0.125rem !important;
    margin-left: 0.125rem !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-md-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-md-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1px {
    margin-top: 0.0625rem !important;
    margin-bottom: 0.0625rem !important; }
  .my-md-2px {
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-md-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-md-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1px {
    margin-top: 0.0625rem !important; }
  .mt-md-2px {
    margin-top: 0.125rem !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 2rem !important; }
  .mt-md-6 {
    margin-top: 3rem !important; }
  .mt-md-7 {
    margin-top: 4rem !important; }
  .mt-md-8 {
    margin-top: 6rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1px {
    margin-right: 0.0625rem !important; }
  .me-md-2px {
    margin-right: 0.125rem !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 2rem !important; }
  .me-md-6 {
    margin-right: 3rem !important; }
  .me-md-7 {
    margin-right: 4rem !important; }
  .me-md-8 {
    margin-right: 6rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1px {
    margin-bottom: 0.0625rem !important; }
  .mb-md-2px {
    margin-bottom: 0.125rem !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 2rem !important; }
  .mb-md-6 {
    margin-bottom: 3rem !important; }
  .mb-md-7 {
    margin-bottom: 4rem !important; }
  .mb-md-8 {
    margin-bottom: 6rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1px {
    margin-left: 0.0625rem !important; }
  .ms-md-2px {
    margin-left: 0.125rem !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 2rem !important; }
  .ms-md-6 {
    margin-left: 3rem !important; }
  .ms-md-7 {
    margin-left: 4rem !important; }
  .ms-md-8 {
    margin-left: 6rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .m-md-n1px {
    margin: -0.0625rem !important; }
  .m-md-n2px {
    margin: -0.125rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .m-md-n5 {
    margin: -2rem !important; }
  .m-md-n6 {
    margin: -3rem !important; }
  .m-md-n7 {
    margin: -4rem !important; }
  .m-md-n8 {
    margin: -6rem !important; }
  .mx-md-n1px {
    margin-right: -0.0625rem !important;
    margin-left: -0.0625rem !important; }
  .mx-md-n2px {
    margin-right: -0.125rem !important;
    margin-left: -0.125rem !important; }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-md-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-md-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-md-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-md-n8 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .my-md-n1px {
    margin-top: -0.0625rem !important;
    margin-bottom: -0.0625rem !important; }
  .my-md-n2px {
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important; }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-md-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-md-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-md-n8 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .mt-md-n1px {
    margin-top: -0.0625rem !important; }
  .mt-md-n2px {
    margin-top: -0.125rem !important; }
  .mt-md-n1 {
    margin-top: -0.25rem !important; }
  .mt-md-n2 {
    margin-top: -0.5rem !important; }
  .mt-md-n3 {
    margin-top: -1rem !important; }
  .mt-md-n4 {
    margin-top: -1.5rem !important; }
  .mt-md-n5 {
    margin-top: -2rem !important; }
  .mt-md-n6 {
    margin-top: -3rem !important; }
  .mt-md-n7 {
    margin-top: -4rem !important; }
  .mt-md-n8 {
    margin-top: -6rem !important; }
  .me-md-n1px {
    margin-right: -0.0625rem !important; }
  .me-md-n2px {
    margin-right: -0.125rem !important; }
  .me-md-n1 {
    margin-right: -0.25rem !important; }
  .me-md-n2 {
    margin-right: -0.5rem !important; }
  .me-md-n3 {
    margin-right: -1rem !important; }
  .me-md-n4 {
    margin-right: -1.5rem !important; }
  .me-md-n5 {
    margin-right: -2rem !important; }
  .me-md-n6 {
    margin-right: -3rem !important; }
  .me-md-n7 {
    margin-right: -4rem !important; }
  .me-md-n8 {
    margin-right: -6rem !important; }
  .mb-md-n1px {
    margin-bottom: -0.0625rem !important; }
  .mb-md-n2px {
    margin-bottom: -0.125rem !important; }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-md-n3 {
    margin-bottom: -1rem !important; }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-md-n5 {
    margin-bottom: -2rem !important; }
  .mb-md-n6 {
    margin-bottom: -3rem !important; }
  .mb-md-n7 {
    margin-bottom: -4rem !important; }
  .mb-md-n8 {
    margin-bottom: -6rem !important; }
  .ms-md-n1px {
    margin-left: -0.0625rem !important; }
  .ms-md-n2px {
    margin-left: -0.125rem !important; }
  .ms-md-n1 {
    margin-left: -0.25rem !important; }
  .ms-md-n2 {
    margin-left: -0.5rem !important; }
  .ms-md-n3 {
    margin-left: -1rem !important; }
  .ms-md-n4 {
    margin-left: -1.5rem !important; }
  .ms-md-n5 {
    margin-left: -2rem !important; }
  .ms-md-n6 {
    margin-left: -3rem !important; }
  .ms-md-n7 {
    margin-left: -4rem !important; }
  .ms-md-n8 {
    margin-left: -6rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1px {
    padding: 0.0625rem !important; }
  .p-md-2px {
    padding: 0.125rem !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 2rem !important; }
  .p-md-6 {
    padding: 3rem !important; }
  .p-md-7 {
    padding: 4rem !important; }
  .p-md-8 {
    padding: 6rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1px {
    padding-right: 0.0625rem !important;
    padding-left: 0.0625rem !important; }
  .px-md-2px {
    padding-right: 0.125rem !important;
    padding-left: 0.125rem !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-md-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-md-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-md-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1px {
    padding-top: 0.0625rem !important;
    padding-bottom: 0.0625rem !important; }
  .py-md-2px {
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-md-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-md-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-md-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1px {
    padding-top: 0.0625rem !important; }
  .pt-md-2px {
    padding-top: 0.125rem !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 2rem !important; }
  .pt-md-6 {
    padding-top: 3rem !important; }
  .pt-md-7 {
    padding-top: 4rem !important; }
  .pt-md-8 {
    padding-top: 6rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1px {
    padding-right: 0.0625rem !important; }
  .pe-md-2px {
    padding-right: 0.125rem !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 2rem !important; }
  .pe-md-6 {
    padding-right: 3rem !important; }
  .pe-md-7 {
    padding-right: 4rem !important; }
  .pe-md-8 {
    padding-right: 6rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1px {
    padding-bottom: 0.0625rem !important; }
  .pb-md-2px {
    padding-bottom: 0.125rem !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 2rem !important; }
  .pb-md-6 {
    padding-bottom: 3rem !important; }
  .pb-md-7 {
    padding-bottom: 4rem !important; }
  .pb-md-8 {
    padding-bottom: 6rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1px {
    padding-left: 0.0625rem !important; }
  .ps-md-2px {
    padding-left: 0.125rem !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 2rem !important; }
  .ps-md-6 {
    padding-left: 3rem !important; }
  .ps-md-7 {
    padding-left: 4rem !important; }
  .ps-md-8 {
    padding-left: 6rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1px {
    gap: 0.0625rem !important; }
  .gap-md-2px {
    gap: 0.125rem !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 2rem !important; }
  .gap-md-6 {
    gap: 3rem !important; }
  .gap-md-7 {
    gap: 4rem !important; }
  .gap-md-8 {
    gap: 6rem !important; }
  .row-gap-md-0 {
    row-gap: 0 !important; }
  .row-gap-md-1px {
    row-gap: 0.0625rem !important; }
  .row-gap-md-2px {
    row-gap: 0.125rem !important; }
  .row-gap-md-1 {
    row-gap: 0.25rem !important; }
  .row-gap-md-2 {
    row-gap: 0.5rem !important; }
  .row-gap-md-3 {
    row-gap: 1rem !important; }
  .row-gap-md-4 {
    row-gap: 1.5rem !important; }
  .row-gap-md-5 {
    row-gap: 2rem !important; }
  .row-gap-md-6 {
    row-gap: 3rem !important; }
  .row-gap-md-7 {
    row-gap: 4rem !important; }
  .row-gap-md-8 {
    row-gap: 6rem !important; }
  .column-gap-md-0 {
    column-gap: 0 !important; }
  .column-gap-md-1px {
    column-gap: 0.0625rem !important; }
  .column-gap-md-2px {
    column-gap: 0.125rem !important; }
  .column-gap-md-1 {
    column-gap: 0.25rem !important; }
  .column-gap-md-2 {
    column-gap: 0.5rem !important; }
  .column-gap-md-3 {
    column-gap: 1rem !important; }
  .column-gap-md-4 {
    column-gap: 1.5rem !important; }
  .column-gap-md-5 {
    column-gap: 2rem !important; }
  .column-gap-md-6 {
    column-gap: 3rem !important; }
  .column-gap-md-7 {
    column-gap: 4rem !important; }
  .column-gap-md-8 {
    column-gap: 6rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .object-fit-lg-contain {
    object-fit: contain !important; }
  .object-fit-lg-cover {
    object-fit: cover !important; }
  .object-fit-lg-fill {
    object-fit: fill !important; }
  .object-fit-lg-scale {
    object-fit: scale-down !important; }
  .object-fit-lg-none {
    object-fit: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-inline-grid {
    display: inline-grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .w-lg-25 {
    width: 25% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .w-lg-100 {
    width: 100% !important; }
  .w-lg-auto {
    width: auto !important; }
  .w-lg-10 {
    width: 10% !important; }
  .w-lg-20 {
    width: 20% !important; }
  .w-lg-33 {
    width: 33% !important; }
  .w-lg-66 {
    width: 66% !important; }
  .w-lg-1px {
    width: 1px !important; }
  .w-lg-2px {
    width: 2px !important; }
  .w-lg-4px {
    width: 4px !important; }
  .w-lg-8px {
    width: 8px !important; }
  .w-lg-16px {
    width: 16px !important; }
  .w-lg-24px {
    width: 24px !important; }
  .w-lg-32px {
    width: 32px !important; }
  .w-lg-48px {
    width: 48px !important; }
  .w-lg-64px {
    width: 64px !important; }
  .w-lg-96px {
    width: 96px !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1px {
    margin: 0.0625rem !important; }
  .m-lg-2px {
    margin: 0.125rem !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 2rem !important; }
  .m-lg-6 {
    margin: 3rem !important; }
  .m-lg-7 {
    margin: 4rem !important; }
  .m-lg-8 {
    margin: 6rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1px {
    margin-right: 0.0625rem !important;
    margin-left: 0.0625rem !important; }
  .mx-lg-2px {
    margin-right: 0.125rem !important;
    margin-left: 0.125rem !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-lg-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-lg-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1px {
    margin-top: 0.0625rem !important;
    margin-bottom: 0.0625rem !important; }
  .my-lg-2px {
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-lg-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-lg-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1px {
    margin-top: 0.0625rem !important; }
  .mt-lg-2px {
    margin-top: 0.125rem !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 2rem !important; }
  .mt-lg-6 {
    margin-top: 3rem !important; }
  .mt-lg-7 {
    margin-top: 4rem !important; }
  .mt-lg-8 {
    margin-top: 6rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1px {
    margin-right: 0.0625rem !important; }
  .me-lg-2px {
    margin-right: 0.125rem !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 2rem !important; }
  .me-lg-6 {
    margin-right: 3rem !important; }
  .me-lg-7 {
    margin-right: 4rem !important; }
  .me-lg-8 {
    margin-right: 6rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1px {
    margin-bottom: 0.0625rem !important; }
  .mb-lg-2px {
    margin-bottom: 0.125rem !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 2rem !important; }
  .mb-lg-6 {
    margin-bottom: 3rem !important; }
  .mb-lg-7 {
    margin-bottom: 4rem !important; }
  .mb-lg-8 {
    margin-bottom: 6rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1px {
    margin-left: 0.0625rem !important; }
  .ms-lg-2px {
    margin-left: 0.125rem !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 2rem !important; }
  .ms-lg-6 {
    margin-left: 3rem !important; }
  .ms-lg-7 {
    margin-left: 4rem !important; }
  .ms-lg-8 {
    margin-left: 6rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1px {
    margin: -0.0625rem !important; }
  .m-lg-n2px {
    margin: -0.125rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .m-lg-n5 {
    margin: -2rem !important; }
  .m-lg-n6 {
    margin: -3rem !important; }
  .m-lg-n7 {
    margin: -4rem !important; }
  .m-lg-n8 {
    margin: -6rem !important; }
  .mx-lg-n1px {
    margin-right: -0.0625rem !important;
    margin-left: -0.0625rem !important; }
  .mx-lg-n2px {
    margin-right: -0.125rem !important;
    margin-left: -0.125rem !important; }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-lg-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-lg-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-lg-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-lg-n8 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .my-lg-n1px {
    margin-top: -0.0625rem !important;
    margin-bottom: -0.0625rem !important; }
  .my-lg-n2px {
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important; }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-lg-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-lg-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-lg-n8 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .mt-lg-n1px {
    margin-top: -0.0625rem !important; }
  .mt-lg-n2px {
    margin-top: -0.125rem !important; }
  .mt-lg-n1 {
    margin-top: -0.25rem !important; }
  .mt-lg-n2 {
    margin-top: -0.5rem !important; }
  .mt-lg-n3 {
    margin-top: -1rem !important; }
  .mt-lg-n4 {
    margin-top: -1.5rem !important; }
  .mt-lg-n5 {
    margin-top: -2rem !important; }
  .mt-lg-n6 {
    margin-top: -3rem !important; }
  .mt-lg-n7 {
    margin-top: -4rem !important; }
  .mt-lg-n8 {
    margin-top: -6rem !important; }
  .me-lg-n1px {
    margin-right: -0.0625rem !important; }
  .me-lg-n2px {
    margin-right: -0.125rem !important; }
  .me-lg-n1 {
    margin-right: -0.25rem !important; }
  .me-lg-n2 {
    margin-right: -0.5rem !important; }
  .me-lg-n3 {
    margin-right: -1rem !important; }
  .me-lg-n4 {
    margin-right: -1.5rem !important; }
  .me-lg-n5 {
    margin-right: -2rem !important; }
  .me-lg-n6 {
    margin-right: -3rem !important; }
  .me-lg-n7 {
    margin-right: -4rem !important; }
  .me-lg-n8 {
    margin-right: -6rem !important; }
  .mb-lg-n1px {
    margin-bottom: -0.0625rem !important; }
  .mb-lg-n2px {
    margin-bottom: -0.125rem !important; }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-lg-n3 {
    margin-bottom: -1rem !important; }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-lg-n5 {
    margin-bottom: -2rem !important; }
  .mb-lg-n6 {
    margin-bottom: -3rem !important; }
  .mb-lg-n7 {
    margin-bottom: -4rem !important; }
  .mb-lg-n8 {
    margin-bottom: -6rem !important; }
  .ms-lg-n1px {
    margin-left: -0.0625rem !important; }
  .ms-lg-n2px {
    margin-left: -0.125rem !important; }
  .ms-lg-n1 {
    margin-left: -0.25rem !important; }
  .ms-lg-n2 {
    margin-left: -0.5rem !important; }
  .ms-lg-n3 {
    margin-left: -1rem !important; }
  .ms-lg-n4 {
    margin-left: -1.5rem !important; }
  .ms-lg-n5 {
    margin-left: -2rem !important; }
  .ms-lg-n6 {
    margin-left: -3rem !important; }
  .ms-lg-n7 {
    margin-left: -4rem !important; }
  .ms-lg-n8 {
    margin-left: -6rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1px {
    padding: 0.0625rem !important; }
  .p-lg-2px {
    padding: 0.125rem !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 2rem !important; }
  .p-lg-6 {
    padding: 3rem !important; }
  .p-lg-7 {
    padding: 4rem !important; }
  .p-lg-8 {
    padding: 6rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1px {
    padding-right: 0.0625rem !important;
    padding-left: 0.0625rem !important; }
  .px-lg-2px {
    padding-right: 0.125rem !important;
    padding-left: 0.125rem !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-lg-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-lg-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-lg-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1px {
    padding-top: 0.0625rem !important;
    padding-bottom: 0.0625rem !important; }
  .py-lg-2px {
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-lg-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-lg-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-lg-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1px {
    padding-top: 0.0625rem !important; }
  .pt-lg-2px {
    padding-top: 0.125rem !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 2rem !important; }
  .pt-lg-6 {
    padding-top: 3rem !important; }
  .pt-lg-7 {
    padding-top: 4rem !important; }
  .pt-lg-8 {
    padding-top: 6rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1px {
    padding-right: 0.0625rem !important; }
  .pe-lg-2px {
    padding-right: 0.125rem !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 2rem !important; }
  .pe-lg-6 {
    padding-right: 3rem !important; }
  .pe-lg-7 {
    padding-right: 4rem !important; }
  .pe-lg-8 {
    padding-right: 6rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1px {
    padding-bottom: 0.0625rem !important; }
  .pb-lg-2px {
    padding-bottom: 0.125rem !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 2rem !important; }
  .pb-lg-6 {
    padding-bottom: 3rem !important; }
  .pb-lg-7 {
    padding-bottom: 4rem !important; }
  .pb-lg-8 {
    padding-bottom: 6rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1px {
    padding-left: 0.0625rem !important; }
  .ps-lg-2px {
    padding-left: 0.125rem !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 2rem !important; }
  .ps-lg-6 {
    padding-left: 3rem !important; }
  .ps-lg-7 {
    padding-left: 4rem !important; }
  .ps-lg-8 {
    padding-left: 6rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1px {
    gap: 0.0625rem !important; }
  .gap-lg-2px {
    gap: 0.125rem !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 2rem !important; }
  .gap-lg-6 {
    gap: 3rem !important; }
  .gap-lg-7 {
    gap: 4rem !important; }
  .gap-lg-8 {
    gap: 6rem !important; }
  .row-gap-lg-0 {
    row-gap: 0 !important; }
  .row-gap-lg-1px {
    row-gap: 0.0625rem !important; }
  .row-gap-lg-2px {
    row-gap: 0.125rem !important; }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important; }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important; }
  .row-gap-lg-3 {
    row-gap: 1rem !important; }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important; }
  .row-gap-lg-5 {
    row-gap: 2rem !important; }
  .row-gap-lg-6 {
    row-gap: 3rem !important; }
  .row-gap-lg-7 {
    row-gap: 4rem !important; }
  .row-gap-lg-8 {
    row-gap: 6rem !important; }
  .column-gap-lg-0 {
    column-gap: 0 !important; }
  .column-gap-lg-1px {
    column-gap: 0.0625rem !important; }
  .column-gap-lg-2px {
    column-gap: 0.125rem !important; }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important; }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important; }
  .column-gap-lg-3 {
    column-gap: 1rem !important; }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important; }
  .column-gap-lg-5 {
    column-gap: 2rem !important; }
  .column-gap-lg-6 {
    column-gap: 3rem !important; }
  .column-gap-lg-7 {
    column-gap: 4rem !important; }
  .column-gap-lg-8 {
    column-gap: 6rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .object-fit-xl-contain {
    object-fit: contain !important; }
  .object-fit-xl-cover {
    object-fit: cover !important; }
  .object-fit-xl-fill {
    object-fit: fill !important; }
  .object-fit-xl-scale {
    object-fit: scale-down !important; }
  .object-fit-xl-none {
    object-fit: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-inline-grid {
    display: inline-grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .w-xl-25 {
    width: 25% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .w-xl-100 {
    width: 100% !important; }
  .w-xl-auto {
    width: auto !important; }
  .w-xl-10 {
    width: 10% !important; }
  .w-xl-20 {
    width: 20% !important; }
  .w-xl-33 {
    width: 33% !important; }
  .w-xl-66 {
    width: 66% !important; }
  .w-xl-1px {
    width: 1px !important; }
  .w-xl-2px {
    width: 2px !important; }
  .w-xl-4px {
    width: 4px !important; }
  .w-xl-8px {
    width: 8px !important; }
  .w-xl-16px {
    width: 16px !important; }
  .w-xl-24px {
    width: 24px !important; }
  .w-xl-32px {
    width: 32px !important; }
  .w-xl-48px {
    width: 48px !important; }
  .w-xl-64px {
    width: 64px !important; }
  .w-xl-96px {
    width: 96px !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1px {
    margin: 0.0625rem !important; }
  .m-xl-2px {
    margin: 0.125rem !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 2rem !important; }
  .m-xl-6 {
    margin: 3rem !important; }
  .m-xl-7 {
    margin: 4rem !important; }
  .m-xl-8 {
    margin: 6rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1px {
    margin-right: 0.0625rem !important;
    margin-left: 0.0625rem !important; }
  .mx-xl-2px {
    margin-right: 0.125rem !important;
    margin-left: 0.125rem !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xl-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xl-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1px {
    margin-top: 0.0625rem !important;
    margin-bottom: 0.0625rem !important; }
  .my-xl-2px {
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xl-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1px {
    margin-top: 0.0625rem !important; }
  .mt-xl-2px {
    margin-top: 0.125rem !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 2rem !important; }
  .mt-xl-6 {
    margin-top: 3rem !important; }
  .mt-xl-7 {
    margin-top: 4rem !important; }
  .mt-xl-8 {
    margin-top: 6rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1px {
    margin-right: 0.0625rem !important; }
  .me-xl-2px {
    margin-right: 0.125rem !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 2rem !important; }
  .me-xl-6 {
    margin-right: 3rem !important; }
  .me-xl-7 {
    margin-right: 4rem !important; }
  .me-xl-8 {
    margin-right: 6rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1px {
    margin-bottom: 0.0625rem !important; }
  .mb-xl-2px {
    margin-bottom: 0.125rem !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 2rem !important; }
  .mb-xl-6 {
    margin-bottom: 3rem !important; }
  .mb-xl-7 {
    margin-bottom: 4rem !important; }
  .mb-xl-8 {
    margin-bottom: 6rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1px {
    margin-left: 0.0625rem !important; }
  .ms-xl-2px {
    margin-left: 0.125rem !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 2rem !important; }
  .ms-xl-6 {
    margin-left: 3rem !important; }
  .ms-xl-7 {
    margin-left: 4rem !important; }
  .ms-xl-8 {
    margin-left: 6rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1px {
    margin: -0.0625rem !important; }
  .m-xl-n2px {
    margin: -0.125rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .m-xl-n5 {
    margin: -2rem !important; }
  .m-xl-n6 {
    margin: -3rem !important; }
  .m-xl-n7 {
    margin: -4rem !important; }
  .m-xl-n8 {
    margin: -6rem !important; }
  .mx-xl-n1px {
    margin-right: -0.0625rem !important;
    margin-left: -0.0625rem !important; }
  .mx-xl-n2px {
    margin-right: -0.125rem !important;
    margin-left: -0.125rem !important; }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-xl-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-xl-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-xl-n8 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .my-xl-n1px {
    margin-top: -0.0625rem !important;
    margin-bottom: -0.0625rem !important; }
  .my-xl-n2px {
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important; }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-xl-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-xl-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-xl-n8 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .mt-xl-n1px {
    margin-top: -0.0625rem !important; }
  .mt-xl-n2px {
    margin-top: -0.125rem !important; }
  .mt-xl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xl-n3 {
    margin-top: -1rem !important; }
  .mt-xl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xl-n5 {
    margin-top: -2rem !important; }
  .mt-xl-n6 {
    margin-top: -3rem !important; }
  .mt-xl-n7 {
    margin-top: -4rem !important; }
  .mt-xl-n8 {
    margin-top: -6rem !important; }
  .me-xl-n1px {
    margin-right: -0.0625rem !important; }
  .me-xl-n2px {
    margin-right: -0.125rem !important; }
  .me-xl-n1 {
    margin-right: -0.25rem !important; }
  .me-xl-n2 {
    margin-right: -0.5rem !important; }
  .me-xl-n3 {
    margin-right: -1rem !important; }
  .me-xl-n4 {
    margin-right: -1.5rem !important; }
  .me-xl-n5 {
    margin-right: -2rem !important; }
  .me-xl-n6 {
    margin-right: -3rem !important; }
  .me-xl-n7 {
    margin-right: -4rem !important; }
  .me-xl-n8 {
    margin-right: -6rem !important; }
  .mb-xl-n1px {
    margin-bottom: -0.0625rem !important; }
  .mb-xl-n2px {
    margin-bottom: -0.125rem !important; }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xl-n5 {
    margin-bottom: -2rem !important; }
  .mb-xl-n6 {
    margin-bottom: -3rem !important; }
  .mb-xl-n7 {
    margin-bottom: -4rem !important; }
  .mb-xl-n8 {
    margin-bottom: -6rem !important; }
  .ms-xl-n1px {
    margin-left: -0.0625rem !important; }
  .ms-xl-n2px {
    margin-left: -0.125rem !important; }
  .ms-xl-n1 {
    margin-left: -0.25rem !important; }
  .ms-xl-n2 {
    margin-left: -0.5rem !important; }
  .ms-xl-n3 {
    margin-left: -1rem !important; }
  .ms-xl-n4 {
    margin-left: -1.5rem !important; }
  .ms-xl-n5 {
    margin-left: -2rem !important; }
  .ms-xl-n6 {
    margin-left: -3rem !important; }
  .ms-xl-n7 {
    margin-left: -4rem !important; }
  .ms-xl-n8 {
    margin-left: -6rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1px {
    padding: 0.0625rem !important; }
  .p-xl-2px {
    padding: 0.125rem !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 2rem !important; }
  .p-xl-6 {
    padding: 3rem !important; }
  .p-xl-7 {
    padding: 4rem !important; }
  .p-xl-8 {
    padding: 6rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1px {
    padding-right: 0.0625rem !important;
    padding-left: 0.0625rem !important; }
  .px-xl-2px {
    padding-right: 0.125rem !important;
    padding-left: 0.125rem !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xl-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xl-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xl-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1px {
    padding-top: 0.0625rem !important;
    padding-bottom: 0.0625rem !important; }
  .py-xl-2px {
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xl-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xl-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1px {
    padding-top: 0.0625rem !important; }
  .pt-xl-2px {
    padding-top: 0.125rem !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 2rem !important; }
  .pt-xl-6 {
    padding-top: 3rem !important; }
  .pt-xl-7 {
    padding-top: 4rem !important; }
  .pt-xl-8 {
    padding-top: 6rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1px {
    padding-right: 0.0625rem !important; }
  .pe-xl-2px {
    padding-right: 0.125rem !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 2rem !important; }
  .pe-xl-6 {
    padding-right: 3rem !important; }
  .pe-xl-7 {
    padding-right: 4rem !important; }
  .pe-xl-8 {
    padding-right: 6rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1px {
    padding-bottom: 0.0625rem !important; }
  .pb-xl-2px {
    padding-bottom: 0.125rem !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 2rem !important; }
  .pb-xl-6 {
    padding-bottom: 3rem !important; }
  .pb-xl-7 {
    padding-bottom: 4rem !important; }
  .pb-xl-8 {
    padding-bottom: 6rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1px {
    padding-left: 0.0625rem !important; }
  .ps-xl-2px {
    padding-left: 0.125rem !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 2rem !important; }
  .ps-xl-6 {
    padding-left: 3rem !important; }
  .ps-xl-7 {
    padding-left: 4rem !important; }
  .ps-xl-8 {
    padding-left: 6rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1px {
    gap: 0.0625rem !important; }
  .gap-xl-2px {
    gap: 0.125rem !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 2rem !important; }
  .gap-xl-6 {
    gap: 3rem !important; }
  .gap-xl-7 {
    gap: 4rem !important; }
  .gap-xl-8 {
    gap: 6rem !important; }
  .row-gap-xl-0 {
    row-gap: 0 !important; }
  .row-gap-xl-1px {
    row-gap: 0.0625rem !important; }
  .row-gap-xl-2px {
    row-gap: 0.125rem !important; }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important; }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important; }
  .row-gap-xl-3 {
    row-gap: 1rem !important; }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important; }
  .row-gap-xl-5 {
    row-gap: 2rem !important; }
  .row-gap-xl-6 {
    row-gap: 3rem !important; }
  .row-gap-xl-7 {
    row-gap: 4rem !important; }
  .row-gap-xl-8 {
    row-gap: 6rem !important; }
  .column-gap-xl-0 {
    column-gap: 0 !important; }
  .column-gap-xl-1px {
    column-gap: 0.0625rem !important; }
  .column-gap-xl-2px {
    column-gap: 0.125rem !important; }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important; }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important; }
  .column-gap-xl-3 {
    column-gap: 1rem !important; }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important; }
  .column-gap-xl-5 {
    column-gap: 2rem !important; }
  .column-gap-xl-6 {
    column-gap: 3rem !important; }
  .column-gap-xl-7 {
    column-gap: 4rem !important; }
  .column-gap-xl-8 {
    column-gap: 6rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .object-fit-xxl-contain {
    object-fit: contain !important; }
  .object-fit-xxl-cover {
    object-fit: cover !important; }
  .object-fit-xxl-fill {
    object-fit: fill !important; }
  .object-fit-xxl-scale {
    object-fit: scale-down !important; }
  .object-fit-xxl-none {
    object-fit: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-inline-grid {
    display: inline-grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .w-xxl-25 {
    width: 25% !important; }
  .w-xxl-50 {
    width: 50% !important; }
  .w-xxl-75 {
    width: 75% !important; }
  .w-xxl-100 {
    width: 100% !important; }
  .w-xxl-auto {
    width: auto !important; }
  .w-xxl-10 {
    width: 10% !important; }
  .w-xxl-20 {
    width: 20% !important; }
  .w-xxl-33 {
    width: 33% !important; }
  .w-xxl-66 {
    width: 66% !important; }
  .w-xxl-1px {
    width: 1px !important; }
  .w-xxl-2px {
    width: 2px !important; }
  .w-xxl-4px {
    width: 4px !important; }
  .w-xxl-8px {
    width: 8px !important; }
  .w-xxl-16px {
    width: 16px !important; }
  .w-xxl-24px {
    width: 24px !important; }
  .w-xxl-32px {
    width: 32px !important; }
  .w-xxl-48px {
    width: 48px !important; }
  .w-xxl-64px {
    width: 64px !important; }
  .w-xxl-96px {
    width: 96px !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1px {
    margin: 0.0625rem !important; }
  .m-xxl-2px {
    margin: 0.125rem !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 2rem !important; }
  .m-xxl-6 {
    margin: 3rem !important; }
  .m-xxl-7 {
    margin: 4rem !important; }
  .m-xxl-8 {
    margin: 6rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1px {
    margin-right: 0.0625rem !important;
    margin-left: 0.0625rem !important; }
  .mx-xxl-2px {
    margin-right: 0.125rem !important;
    margin-left: 0.125rem !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xxl-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xxl-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1px {
    margin-top: 0.0625rem !important;
    margin-bottom: 0.0625rem !important; }
  .my-xxl-2px {
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xxl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xxl-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1px {
    margin-top: 0.0625rem !important; }
  .mt-xxl-2px {
    margin-top: 0.125rem !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 2rem !important; }
  .mt-xxl-6 {
    margin-top: 3rem !important; }
  .mt-xxl-7 {
    margin-top: 4rem !important; }
  .mt-xxl-8 {
    margin-top: 6rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1px {
    margin-right: 0.0625rem !important; }
  .me-xxl-2px {
    margin-right: 0.125rem !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 2rem !important; }
  .me-xxl-6 {
    margin-right: 3rem !important; }
  .me-xxl-7 {
    margin-right: 4rem !important; }
  .me-xxl-8 {
    margin-right: 6rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1px {
    margin-bottom: 0.0625rem !important; }
  .mb-xxl-2px {
    margin-bottom: 0.125rem !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 2rem !important; }
  .mb-xxl-6 {
    margin-bottom: 3rem !important; }
  .mb-xxl-7 {
    margin-bottom: 4rem !important; }
  .mb-xxl-8 {
    margin-bottom: 6rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1px {
    margin-left: 0.0625rem !important; }
  .ms-xxl-2px {
    margin-left: 0.125rem !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 2rem !important; }
  .ms-xxl-6 {
    margin-left: 3rem !important; }
  .ms-xxl-7 {
    margin-left: 4rem !important; }
  .ms-xxl-8 {
    margin-left: 6rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .m-xxl-n1px {
    margin: -0.0625rem !important; }
  .m-xxl-n2px {
    margin: -0.125rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -2rem !important; }
  .m-xxl-n6 {
    margin: -3rem !important; }
  .m-xxl-n7 {
    margin: -4rem !important; }
  .m-xxl-n8 {
    margin: -6rem !important; }
  .mx-xxl-n1px {
    margin-right: -0.0625rem !important;
    margin-left: -0.0625rem !important; }
  .mx-xxl-n2px {
    margin-right: -0.125rem !important;
    margin-left: -0.125rem !important; }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xxl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-xxl-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-xxl-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-xxl-n8 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .my-xxl-n1px {
    margin-top: -0.0625rem !important;
    margin-bottom: -0.0625rem !important; }
  .my-xxl-n2px {
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important; }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-xxl-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-xxl-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-xxl-n8 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .mt-xxl-n1px {
    margin-top: -0.0625rem !important; }
  .mt-xxl-n2px {
    margin-top: -0.125rem !important; }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xxl-n3 {
    margin-top: -1rem !important; }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xxl-n5 {
    margin-top: -2rem !important; }
  .mt-xxl-n6 {
    margin-top: -3rem !important; }
  .mt-xxl-n7 {
    margin-top: -4rem !important; }
  .mt-xxl-n8 {
    margin-top: -6rem !important; }
  .me-xxl-n1px {
    margin-right: -0.0625rem !important; }
  .me-xxl-n2px {
    margin-right: -0.125rem !important; }
  .me-xxl-n1 {
    margin-right: -0.25rem !important; }
  .me-xxl-n2 {
    margin-right: -0.5rem !important; }
  .me-xxl-n3 {
    margin-right: -1rem !important; }
  .me-xxl-n4 {
    margin-right: -1.5rem !important; }
  .me-xxl-n5 {
    margin-right: -2rem !important; }
  .me-xxl-n6 {
    margin-right: -3rem !important; }
  .me-xxl-n7 {
    margin-right: -4rem !important; }
  .me-xxl-n8 {
    margin-right: -6rem !important; }
  .mb-xxl-n1px {
    margin-bottom: -0.0625rem !important; }
  .mb-xxl-n2px {
    margin-bottom: -0.125rem !important; }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xxl-n5 {
    margin-bottom: -2rem !important; }
  .mb-xxl-n6 {
    margin-bottom: -3rem !important; }
  .mb-xxl-n7 {
    margin-bottom: -4rem !important; }
  .mb-xxl-n8 {
    margin-bottom: -6rem !important; }
  .ms-xxl-n1px {
    margin-left: -0.0625rem !important; }
  .ms-xxl-n2px {
    margin-left: -0.125rem !important; }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important; }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important; }
  .ms-xxl-n3 {
    margin-left: -1rem !important; }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important; }
  .ms-xxl-n5 {
    margin-left: -2rem !important; }
  .ms-xxl-n6 {
    margin-left: -3rem !important; }
  .ms-xxl-n7 {
    margin-left: -4rem !important; }
  .ms-xxl-n8 {
    margin-left: -6rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1px {
    padding: 0.0625rem !important; }
  .p-xxl-2px {
    padding: 0.125rem !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 2rem !important; }
  .p-xxl-6 {
    padding: 3rem !important; }
  .p-xxl-7 {
    padding: 4rem !important; }
  .p-xxl-8 {
    padding: 6rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1px {
    padding-right: 0.0625rem !important;
    padding-left: 0.0625rem !important; }
  .px-xxl-2px {
    padding-right: 0.125rem !important;
    padding-left: 0.125rem !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xxl-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xxl-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xxl-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1px {
    padding-top: 0.0625rem !important;
    padding-bottom: 0.0625rem !important; }
  .py-xxl-2px {
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xxl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xxl-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xxl-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1px {
    padding-top: 0.0625rem !important; }
  .pt-xxl-2px {
    padding-top: 0.125rem !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 2rem !important; }
  .pt-xxl-6 {
    padding-top: 3rem !important; }
  .pt-xxl-7 {
    padding-top: 4rem !important; }
  .pt-xxl-8 {
    padding-top: 6rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1px {
    padding-right: 0.0625rem !important; }
  .pe-xxl-2px {
    padding-right: 0.125rem !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 2rem !important; }
  .pe-xxl-6 {
    padding-right: 3rem !important; }
  .pe-xxl-7 {
    padding-right: 4rem !important; }
  .pe-xxl-8 {
    padding-right: 6rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1px {
    padding-bottom: 0.0625rem !important; }
  .pb-xxl-2px {
    padding-bottom: 0.125rem !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 2rem !important; }
  .pb-xxl-6 {
    padding-bottom: 3rem !important; }
  .pb-xxl-7 {
    padding-bottom: 4rem !important; }
  .pb-xxl-8 {
    padding-bottom: 6rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1px {
    padding-left: 0.0625rem !important; }
  .ps-xxl-2px {
    padding-left: 0.125rem !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 2rem !important; }
  .ps-xxl-6 {
    padding-left: 3rem !important; }
  .ps-xxl-7 {
    padding-left: 4rem !important; }
  .ps-xxl-8 {
    padding-left: 6rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1px {
    gap: 0.0625rem !important; }
  .gap-xxl-2px {
    gap: 0.125rem !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 2rem !important; }
  .gap-xxl-6 {
    gap: 3rem !important; }
  .gap-xxl-7 {
    gap: 4rem !important; }
  .gap-xxl-8 {
    gap: 6rem !important; }
  .row-gap-xxl-0 {
    row-gap: 0 !important; }
  .row-gap-xxl-1px {
    row-gap: 0.0625rem !important; }
  .row-gap-xxl-2px {
    row-gap: 0.125rem !important; }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important; }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important; }
  .row-gap-xxl-3 {
    row-gap: 1rem !important; }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important; }
  .row-gap-xxl-5 {
    row-gap: 2rem !important; }
  .row-gap-xxl-6 {
    row-gap: 3rem !important; }
  .row-gap-xxl-7 {
    row-gap: 4rem !important; }
  .row-gap-xxl-8 {
    row-gap: 6rem !important; }
  .column-gap-xxl-0 {
    column-gap: 0 !important; }
  .column-gap-xxl-1px {
    column-gap: 0.0625rem !important; }
  .column-gap-xxl-2px {
    column-gap: 0.125rem !important; }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important; }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important; }
  .column-gap-xxl-3 {
    column-gap: 1rem !important; }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important; }
  .column-gap-xxl-5 {
    column-gap: 2rem !important; }
  .column-gap-xxl-6 {
    column-gap: 3rem !important; }
  .column-gap-xxl-7 {
    column-gap: 4rem !important; }
  .column-gap-xxl-8 {
    column-gap: 6rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.1875rem !important; }
  .fs-2 {
    font-size: 1.75rem !important; }
  .fs-3 {
    font-size: 1.53125rem !important; }
  .fs-4 {
    font-size: 1.3125rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-inline-grid {
    display: inline-grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

:root,
[data-bs-theme="light"] {
  --bs-primary-100: #ffb380;
  --bs-primary-200: #ff944d;
  --bs-primary-300: #ff8533;
  --bs-primary-400: #ff751a;
  --bs-primary-500: #ff6600;
  --bs-primary-600: #e65c00;
  --bs-primary-700: #cc5200;
  --bs-primary-800: #b34700;
  --bs-primary-900: #803300;
  --bs-blue-rgb: 13, 110, 253;
  --bs-indigo-rgb: 102, 16, 242;
  --bs-purple-rgb: 111, 66, 193;
  --bs-pink-rgb: 214, 51, 132;
  --bs-red-rgb: 220, 53, 69;
  --bs-orange-rgb: 253, 126, 20;
  --bs-yellow-rgb: 255, 193, 7;
  --bs-green-rgb: 25, 135, 84;
  --bs-teal-rgb: 32, 201, 151;
  --bs-cyan-rgb: 13, 202, 240;
  --bs-black-rgb: 0, 0, 0;
  --bs-white-rgb: 255, 255, 255;
  --bs-gray-rgb: 116, 135, 154;
  --bs-gray-dark-rgb: 57, 67, 78;
  --bs-primary-hover: #ff7d26;
  --bs-secondary-hover: #2b2b2b;
  --bs-success-hover: #157347;
  --bs-info-hover: #31d2f2;
  --bs-warning-hover: #ffca2c;
  --bs-danger-hover: #bb2d3b;
  --bs-light-hover: #f9fafb;
  --bs-dark-hover: #181d21;
  --bs-tertiary-hover: #ff7f5d;
  --bs-primary-bg-subtle-hover: #ffe5d4;
  --bs-secondary-bg-subtle-hover: gainsboro;
  --bs-success-bg-subtle-hover: #d8ebe2;
  --bs-info-bg-subtle-hover: #d6f6fc;
  --bs-warning-bg-subtle-hover: #fff5d5;
  --bs-danger-bg-subtle-hover: #f9dde0;
  --bs-light-bg-subtle-hover: #fcfcfd;
  --bs-dark-bg-subtle-hover: #cbd2da;
  --bs-tertiary-bg-subtle-hover: #ffe6df;
  --bs-primary-border-subtle-hover: #ffcba8;
  --bs-secondary-border-subtle-hover: #b9b9b9;
  --bs-success-border-subtle-hover: #b1d6c5;
  --bs-info-border-subtle-hover: #adedfa;
  --bs-warning-border-subtle-hover: #ffeaab;
  --bs-danger-border-subtle-hover: #f3bac0;
  --bs-light-border-subtle-hover: #f6f6f6;
  --bs-dark-border-subtle-hover: #aab6c1;
  --bs-tertiary-border-subtle-hover: #ffccbe;
  --bs-gray-100-rgb: 248, 249, 250;
  --bs-gray-200-rgb: 244, 244, 244;
  --bs-gray-300-rgb: 233, 236, 239;
  --bs-gray-400-rgb: 194, 202, 211;
  --bs-gray-500-rgb: 155, 169, 182;
  --bs-gray-600-rgb: 116, 135, 154;
  --bs-gray-700-rgb: 85, 101, 117;
  --bs-gray-800-rgb: 57, 67, 78;
  --bs-gray-900-rgb: 28, 34, 39;
  --bs-gray-100-hover: #f9fafb;
  --bs-gray-200-hover: #f6f6f6;
  --bs-gray-300-hover: #eceff1;
  --bs-gray-400-hover: #cbd2da;
  --bs-gray-500-hover: #aab6c1;
  --bs-gray-600-hover: #8999a9;
  --bs-gray-700-hover: #485663;
  --bs-gray-800-hover: #303942;
  --bs-gray-900-hover: #181d21;
  --bs-surface-lowest: #9BA9B6;
  --bs-surface-low: #C2CAD3;
  --bs-surface: #fff;
  --bs-surface-high: #F8F9FA;
  --bs-surface-highest: #E9ECEF;
  --bs-surface-lowest-rgb: 155, 169, 182;
  --bs-surface-low-rgb: 194, 202, 211;
  --bs-surface-rgb: 255, 255, 255;
  --bs-surface-high-rgb: 248, 249, 250;
  --bs-surface-highest-rgb: 233, 236, 239;
  --bs-surface-lowest-hover: #aab6c1;
  --bs-surface-low-hover: #cbd2da;
  --bs-surface-hover: white;
  --bs-surface-high-hover: #f9fafb;
  --bs-surface-highest-hover: #eceff1;
  --bs-on-primary: #000;
  --bs-on-secondary: #fff;
  --bs-on-tertiary: #000;
  --bs-primary-dark: #e65c00;
  --bs-primary-light: #ff751a;
  --bs-primary-lightest: #ff8533;
  --bs-border-color-rgb: 194, 202, 211;
  --bs-border-color-subtle: #E9ECEF;
  --bs-border-color-subtle-rgb: 233, 236, 239;
  --ims-button__border-radius: 0.375rem;
  --ims-form-input__border-radius: 0.25rem;
  --ims-badge__border-radius: 0.25rem;
  --ims-toggler__border-radius: 0.25rem;
  --ims-form-input__hover: rgba(var(--bs-gray-400-rgb), 0.3);
  --ims-key-card__border-radius: 0;
  --ims-panel__border-radius: 0;
  --ims-panel__border-radius-sm: 0.25rem;
  --ims-panel__border-radius-lg: 0.5rem;
  --ims-panel__border-radius-xl: 1rem;
  --ims-card--image-horizontal__border-radius: 0 0 0 0;
  --ims-card--image-vertical__border-radius: 0 0 0 0;
  --ims-card--image-horizontal__border-radius-sm: 0.25rem 0 0 0.25rem;
  --ims-card--image-vertical__border-radius-sm: 0.25rem 0.25rem 0 0;
  --ims-card--image-horizontal__border-radius-lg: 0.5rem 0 0 0.5rem;
  --ims-card--image-vertical__border-radius-lg: 0.5rem 0.5rem 0 0;
  --ims-modal__border-radius: --ims-panel__border-radius-xl; }

:root {
  --bs-primary-light: #ff6840; }

/*# sourceMappingURL=maps/bootstrap.css.map */

@charset "UTF-8";
@font-face {
  font-family: 'fontello';
  src: url("/content/themes/common/font-icons/fontello/font/fontello.eot?37389135");
  src: url("/content/themes/common/font-icons/fontello/font/fontello.eot?37389135#iefix") format("embedded-opentype"), url("/content/themes/common/font-icons/fontello/font/fontello.woff2?37389135") format("woff2"), url("/content/themes/common/font-icons/fontello/font/fontello.woff?37389135") format("woff"), url("/content/themes/common/font-icons/fontello/font/fontello.ttf?37389135") format("truetype"), url("/content/themes/common/font-icons/fontello/font/fontello.svg?37389135#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?37389135#fontello') format('svg');
  }
}
*/
[class^="ims-icon-"]:before, [class*=" ims-icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.ims-icon-company:before {
  content: '\e800'; }

/* '' */
.ims-icon-task:before, .ims-icon.ims-icon-testdetails:before, .ims-icon-testdetails.ims-icon-arrow-left:before, .ims-icon-testdetails.ims-icon-arrow-right:before {
  content: '\e801'; }

/* '' */
.ims-icon-email:before {
  content: '\e802'; }

/* '' */
.ims-icon-language:before {
  content: '\e803'; }

/* '' */
.ims-icon-window:before {
  content: '\e804'; }

/* '' */
.ims-icon-asterisk:before {
  content: '\e805'; }

/* '' */
.ims-icon-logout:before {
  content: '\e806'; }

/* '' */
.ims-icon-address:before {
  content: '\e807'; }

/* '' */
.ims-icon-export:before {
  content: '\e808'; }

/* '' */
.ims-icon-bookmarks:before {
  content: '\e809'; }

/* '' */
.ims-icon-box:before {
  content: '\e80a'; }

/* '' */
.ims-icon-quotas:before {
  content: '\e80b'; }

/* '' */
.ims-icon-bar-1:before {
  content: '\e80c'; }

/* '' */
.ims-icon-layers:before {
  content: '\e80d'; }

/* '' */
.ims-icon-flow-cascade:before {
  content: '\e80e'; }

/* '' */
.ims-icon-install:before {
  content: '\e80f'; }

/* '' */
.ims-icon-bar:before {
  content: '\e810'; }

/* '' */
.ims-icon-music:before {
  content: '\e811'; }

/* '' */
.ims-icon-search:before {
  content: '\e812'; }

/* '' */
.ims-icon-mail:before {
  content: '\e813'; }

/* '' */
.ims-icon-mail-alt:before {
  content: '\e814'; }

/* '' */
.ims-icon-mail-squared:before {
  content: '\e815'; }

/* '' */
.ims-icon-heart:before {
  content: '\e816'; }

/* '' */
.ims-icon-heart-empty:before {
  content: '\e817'; }

/* '' */
.ims-icon-star:before {
  content: '\e818'; }

/* '' */
.ims-icon-star-empty:before {
  content: '\e819'; }

/* '' */
.ims-icon-star-half:before {
  content: '\e81a'; }

/* '' */
.ims-icon-star-half-alt:before {
  content: '\e81b'; }

/* '' */
.ims-icon-user-alt:before {
  content: '\e81c'; }

/* '' */
.ims-icon-supervisor:before {
  content: '\e81d'; }

/* '' */
.ims-icon-male:before {
  content: '\e81e'; }

/* '' */
.ims-icon-female:before {
  content: '\e81f'; }

/* '' */
.ims-icon-child:before {
  content: '\e820'; }

/* '' */
.ims-icon-video:before {
  content: '\e821'; }

/* '' */
.ims-icon-videocam:before {
  content: '\e822'; }

/* '' */
.ims-icon-picture-1:before {
  content: '\e823'; }

/* '' */
.ims-icon-camera:before {
  content: '\e824'; }

/* '' */
.ims-icon-camera-alt:before {
  content: '\e825'; }

/* '' */
.ims-icon-th-large:before {
  content: '\e826'; }

/* '' */
.ims-icon-matrix:before {
  content: '\e827'; }

/* '' */
.ims-icon-th-list:before {
  content: '\e828'; }

/* '' */
.ims-icon-ok:before {
  content: '\e829'; }

/* '' */
.ims-icon-ok-circled:before {
  content: '\e82a'; }

/* '' */
.ims-icon-ok-circled2:before {
  content: '\e82b'; }

/* '' */
.ims-icon-ok-squared:before {
  content: '\e82c'; }

/* '' */
.ims-icon-cancel-1:before {
  content: '\e82d'; }

/* '' */
.ims-icon-cancel-circled:before {
  content: '\e82e'; }

/* '' */
.ims-icon-cancel-circled2:before {
  content: '\e82f'; }

/* '' */
.ims-icon-plus:before {
  content: '\e830'; }

/* '' */
.ims-icon-plus-circled:before {
  content: '\e831'; }

/* '' */
.ims-icon-plus-squared-alt:before {
  content: '\e832'; }

/* '' */
.ims-icon-plus-squared-alt-1:before {
  content: '\e833'; }

/* '' */
.ims-icon-minus:before {
  content: '\e834'; }

/* '' */
.ims-icon-minus-circled:before {
  content: '\e835'; }

/* '' */
.ims-icon-minus-squared:before {
  content: '\e836'; }

/* '' */
.ims-icon-minus-squared-alt:before {
  content: '\e837'; }

/* '' */
.ims-icon-help:before {
  content: '\e838'; }

/* '' */
.ims-icon-info-circled:before {
  content: '\e839'; }

/* '' */
.ims-icon-help-circled:before {
  content: '\e83a'; }

/* '' */
.ims-icon-info:before {
  content: '\e83b'; }

/* '' */
.ims-icon-home:before {
  content: '\e83c'; }

/* '' */
.ims-icon-link:before {
  content: '\e83d'; }

/* '' */
.ims-icon-unlink:before {
  content: '\e83e'; }

/* '' */
.ims-icon-link-ext:before {
  content: '\e83f'; }

/* '' */
.ims-icon-link-ext-alt:before {
  content: '\e840'; }

/* '' */
.ims-icon-attach:before {
  content: '\e841'; }

/* '' */
.ims-icon-lock:before {
  content: '\e842'; }

/* '' */
.ims-icon-lock-open:before {
  content: '\e843'; }

/* '' */
.ims-icon-lock-open-alt:before {
  content: '\e844'; }

/* '' */
.ims-icon-pin:before {
  content: '\e845'; }

/* '' */
.ims-icon-displaypreview:before {
  content: '\e846'; }

/* '' */
.ims-icon-eye-off:before {
  content: '\e847'; }

/* '' */
.ims-icon-tag:before {
  content: '\e848'; }

/* '' */
.ims-icon-brands:before {
  content: '\e849'; }

/* '' */
.ims-icon-bookmark:before {
  content: '\e84a'; }

/* '' */
.ims-icon-bookmark-empty:before {
  content: '\e84b'; }

/* '' */
.ims-icon-flag:before {
  content: '\e84c'; }

/* '' */
.ims-icon-flag-empty:before {
  content: '\e84d'; }

/* '' */
.ims-icon-flag-checkered:before {
  content: '\e84e'; }

/* '' */
.ims-icon-thumbs-up:before {
  content: '\e84f'; }

/* '' */
.ims-icon-thumbs-down:before {
  content: '\e850'; }

/* '' */
.ims-icon-thumbs-up-alt:before {
  content: '\e851'; }

/* '' */
.ims-icon-thumbs-down-alt:before {
  content: '\e852'; }

/* '' */
.ims-icon-download-1:before {
  content: '\e853'; }

/* '' */
.ims-icon-upload:before {
  content: '\e854'; }

/* '' */
.ims-icon-download-cloud:before {
  content: '\e855'; }

/* '' */
.ims-icon-upload-cloud:before {
  content: '\e856'; }

/* '' */
.ims-icon-import-1:before {
  content: '\e857'; }

/* '' */
.ims-icon-reply-all:before {
  content: '\e858'; }

/* '' */
.ims-icon-forward:before {
  content: '\e859'; }

/* '' */
.ims-icon-quote-left:before {
  content: '\e85a'; }

/* '' */
.ims-icon-quote-right:before {
  content: '\e85b'; }

/* '' */
.ims-icon-code:before {
  content: '\e85c'; }

/* '' */
.ims-icon-export-1:before {
  content: '\e85d'; }

/* '' */
.ims-icon-export-alt:before {
  content: '\e85e'; }

/* '' */
.ims-icon-share:before {
  content: '\e85f'; }

/* '' */
.ims-icon-share-squared:before {
  content: '\e860'; }

/* '' */
.ims-icon-contentmanagement:before {
  content: '\e861'; }

/* '' */
.ims-icon-pencil-squared:before {
  content: '\e862'; }

/* '' */
.ims-icon-edit-1:before {
  content: '\e863'; }

/* '' */
.ims-icon-print:before {
  content: '\e864'; }

/* '' */
.ims-icon-retweet:before {
  content: '\e865'; }

/* '' */
.ims-icon-keyboard:before {
  content: '\e866'; }

/* '' */
.ims-icon-gamepad:before {
  content: '\e867'; }

/* '' */
.ims-icon-comment:before {
  content: '\e868'; }

/* '' */
.ims-icon-chat:before {
  content: '\e869'; }

/* '' */
.ims-icon-comment-empty:before {
  content: '\e86a'; }

/* '' */
.ims-icon-chat-empty:before {
  content: '\e86b'; }

/* '' */
.ims-icon-bell:before {
  content: '\e86c'; }

/* '' */
.ims-icon-bell-alt:before {
  content: '\e86d'; }

/* '' */
.ims-icon-attention-alt:before {
  content: '\e86e'; }

/* '' */
.ims-icon-attention:before {
  content: '\e86f'; }

/* '' */
.ims-icon-attention-circled:before {
  content: '\e870'; }

/* '' */
.ims-icon-location:before {
  content: '\e871'; }

/* '' */
.ims-icon-direction:before {
  content: '\e872'; }

/* '' */
.ims-icon-compass:before {
  content: '\e873'; }

/* '' */
.ims-icon-delete:before {
  content: '\e874'; }

/* '' */
.ims-icon-doc:before {
  content: '\e875'; }

/* '' */
.ims-icon-duplicate:before {
  content: '\e876'; }

/* '' */
.ims-icon-reports:before {
  content: '\e877'; }

/* '' */
.ims-icon-doc-inv:before {
  content: '\e878'; }

/* '' */
.ims-icon-doc-text-inv:before {
  content: '\e879'; }

/* '' */
.ims-icon-file-pdf:before {
  content: '\e87a'; }

/* '' */
.ims-icon-file-word:before {
  content: '\e87b'; }

/* '' */
.ims-icon-file-excel:before {
  content: '\e87c'; }

/* '' */
.ims-icon-file-powerpoint:before {
  content: '\e87d'; }

/* '' */
.ims-icon-file-image:before {
  content: '\e87e'; }

/* '' */
.ims-icon-file-archive:before {
  content: '\e87f'; }

/* '' */
.ims-icon-file-audio:before {
  content: '\e880'; }

/* '' */
.ims-icon-file-video:before {
  content: '\e881'; }

/* '' */
.ims-icon-file-code:before {
  content: '\e882'; }

/* '' */
.ims-icon-folder:before {
  content: '\e883'; }

/* '' */
.ims-icon-folder-open:before {
  content: '\e884'; }

/* '' */
.ims-icon-folder-empty:before {
  content: '\e885'; }

/* '' */
.ims-icon-folder-open-empty:before {
  content: '\e886'; }

/* '' */
.ims-icon-box-1:before {
  content: '\e887'; }

/* '' */
.ims-icon-rss:before {
  content: '\e888'; }

/* '' */
.ims-icon-rss-squared:before {
  content: '\e889'; }

/* '' */
.ims-icon-phone:before {
  content: '\e88a'; }

/* '' */
.ims-icon-phone-squared:before {
  content: '\e88b'; }

/* '' */
.ims-icon-fax:before {
  content: '\e88c'; }

/* '' */
.ims-icon-menu:before {
  content: '\e88d'; }

/* '' */
.ims-icon-cog:before {
  content: '\e88e'; }

/* '' */
.ims-icon-settings:before {
  content: '\e88f'; }

/* '' */
.ims-icon-wrench:before {
  content: '\e890'; }

/* '' */
.ims-icon-sliders:before {
  content: '\e891'; }

/* '' */
.ims-icon-basket:before {
  content: '\e892'; }

/* '' */
.ims-icon-calendar:before {
  content: '\e893'; }

/* '' */
.ims-icon-calendar-empty:before {
  content: '\e894'; }

/* '' */
.ims-icon-assign:before {
  content: '\e895'; }

/* '' */
.ims-icon-unassign:before {
  content: '\e896'; }

/* '' */
.ims-icon-mic:before {
  content: '\e897'; }

/* '' */
.ims-icon-mute:before {
  content: '\e898'; }

/* '' */
.ims-icon-volume-off:before {
  content: '\e899'; }

/* '' */
.ims-icon-volume-down:before {
  content: '\e89a'; }

/* '' */
.ims-icon-volume-up:before {
  content: '\e89b'; }

/* '' */
.ims-icon-headphones:before {
  content: '\e89c'; }

/* '' */
.ims-icon-clock:before {
  content: '\e89d'; }

/* '' */
.ims-icon-lightbulb:before {
  content: '\e89e'; }

/* '' */
.ims-icon-cancel:before {
  content: '\e89f'; }

/* '' */
.ims-icon-resize-full:before {
  content: '\e8a0'; }

/* '' */
.ims-icon-resize-full-alt:before {
  content: '\e8a1'; }

/* '' */
.ims-icon-resize-small:before {
  content: '\e8a2'; }

/* '' */
.ims-icon-resize-vertical:before {
  content: '\e8a3'; }

/* '' */
.ims-icon-resize-horizontal:before {
  content: '\e8a4'; }

/* '' */
.ims-icon-move:before {
  content: '\e8a5'; }

/* '' */
.ims-icon-zoom-in:before {
  content: '\e8a6'; }

/* '' */
.ims-icon-zoom-out:before {
  content: '\e8a7'; }

/* '' */
.ims-icon-down-circled2:before {
  content: '\e8a8'; }

/* '' */
.ims-icon-up-circled2:before {
  content: '\e8a9'; }

/* '' */
.ims-icon-left-circled2:before {
  content: '\e8aa'; }

/* '' */
.ims-icon-right-circled2:before {
  content: '\e8ab'; }

/* '' */
.ims-icon-down-dir:before {
  content: '\e8ac'; }

/* '' */
.ims-icon-up-dir:before {
  content: '\e8ad'; }

/* '' */
.ims-icon-left-dir:before {
  content: '\e8ae'; }

/* '' */
.ims-icon-right-dir:before {
  content: '\e8af'; }

/* '' */
.ims-icon-down-open:before {
  content: '\e8b0'; }

/* '' */
.ims-icon-left-open:before {
  content: '\e8b1'; }

/* '' */
.ims-icon-right-open:before {
  content: '\e8b2'; }

/* '' */
.ims-icon-up-open:before {
  content: '\e8b3'; }

/* '' */
.ims-icon-angle-left:before, .ims-icon-arrow-left:before {
  content: '\e8b4'; }

/* '' */
.ims-icon-angle-right:before, .ims-icon-arrow-right:before {
  content: '\e8b5'; }

/* '' */
.ims-icon-angle-up:before {
  content: '\e8b6'; }

/* '' */
.ims-icon-angle-down:before {
  content: '\e8b7'; }

/* '' */
.ims-icon-angle-circled-left:before {
  content: '\e8b8'; }

/* '' */
.ims-icon-angle-circled-right:before {
  content: '\e8b9'; }

/* '' */
.ims-icon-angle-circled-up:before {
  content: '\e8ba'; }

/* '' */
.ims-icon-angle-circled-down:before {
  content: '\e8bb'; }

/* '' */
.ims-icon-angle-double-left:before {
  content: '\e8bc'; }

/* '' */
.ims-icon-angle-double-right:before {
  content: '\e8bd'; }

/* '' */
.ims-icon-angle-double-up:before {
  content: '\e8be'; }

/* '' */
.ims-icon-angle-double-down:before {
  content: '\e8bf'; }

/* '' */
.ims-icon-down:before {
  content: '\e8c0'; }

/* '' */
.ims-icon-left:before {
  content: '\e8c1'; }

/* '' */
.ims-icon-right:before {
  content: '\e8c2'; }

/* '' */
.ims-icon-up:before {
  content: '\e8c3'; }

/* '' */
.ims-icon-down-big:before {
  content: '\e8c4'; }

/* '' */
.ims-icon-left-big:before {
  content: '\e8c5'; }

/* '' */
.ims-icon-right-big:before {
  content: '\e8c6'; }

/* '' */
.ims-icon-up-big:before {
  content: '\e8c7'; }

/* '' */
.ims-icon-right-hand:before {
  content: '\e8c8'; }

/* '' */
.ims-icon-left-hand:before {
  content: '\e8c9'; }

/* '' */
.ims-icon-up-hand:before {
  content: '\e8ca'; }

/* '' */
.ims-icon-down-hand:before {
  content: '\e8cb'; }

/* '' */
.ims-icon-left-circled:before {
  content: '\e8cc'; }

/* '' */
.ims-icon-right-circled:before {
  content: '\e8cd'; }

/* '' */
.ims-icon-up-circled:before {
  content: '\e8ce'; }

/* '' */
.ims-icon-down-circled:before {
  content: '\e8cf'; }

/* '' */
.ims-icon-cw:before {
  content: '\e8d0'; }

/* '' */
.ims-icon-reset:before {
  content: '\e8d1'; }

/* '' */
.ims-icon-arrows-cw:before {
  content: '\e8d2'; }

/* '' */
.ims-icon-level-up:before {
  content: '\e8d3'; }

/* '' */
.ims-icon-level-down:before {
  content: '\e8d4'; }

/* '' */
.ims-icon-shuffle:before {
  content: '\e8d5'; }

/* '' */
.ims-icon-exchange:before {
  content: '\e8d6'; }

/* '' */
.ims-icon-history:before {
  content: '\e8d7'; }

/* '' */
.ims-icon-expand:before {
  content: '\e8d8'; }

/* '' */
.ims-icon-collapse:before {
  content: '\e8d9'; }

/* '' */
.ims-icon-expand-right:before {
  content: '\e8da'; }

/* '' */
.ims-icon-collapse-left:before {
  content: '\e8db'; }

/* '' */
.ims-icon-play:before {
  content: '\e8dc'; }

/* '' */
.ims-icon-play-circled:before {
  content: '\e8dd'; }

/* '' */
.ims-icon-play-circled2:before {
  content: '\e8de'; }

/* '' */
.ims-icon-stop:before {
  content: '\e8df'; }

/* '' */
.ims-icon-pause:before {
  content: '\e8e0'; }

/* '' */
.ims-icon-to-end:before {
  content: '\e8e1'; }

/* '' */
.ims-icon-to-end-alt:before {
  content: '\e8e2'; }

/* '' */
.ims-icon-to-start:before {
  content: '\e8e3'; }

/* '' */
.ims-icon-to-start-alt:before {
  content: '\e8e4'; }

/* '' */
.ims-icon-fast-fw:before {
  content: '\e8e5'; }

/* '' */
.ims-icon-fast-bw:before {
  content: '\e8e6'; }

/* '' */
.ims-icon-eject:before {
  content: '\e8e7'; }

/* '' */
.ims-icon-target:before {
  content: '\e8e8'; }

/* '' */
.ims-icon-signal:before {
  content: '\e8e9'; }

/* '' */
.ims-icon-award:before {
  content: '\e8ea'; }

/* '' */
.ims-icon-frontend:before {
  content: '\e8eb'; }

/* '' */
.ims-icon-laptop:before {
  content: '\e8ec'; }

/* '' */
.ims-icon-tablet:before {
  content: '\e8ed'; }

/* '' */
.ims-icon-mobile:before {
  content: '\e8ee'; }

/* '' */
.ims-icon-inbox:before {
  content: '\e8ef'; }

/* '' */
.ims-icon-globe:before {
  content: '\e8f0'; }

/* '' */
.ims-icon-sun:before {
  content: '\e8f1'; }

/* '' */
.ims-icon-cloud:before {
  content: '\e8f2'; }

/* '' */
.ims-icon-flash:before {
  content: '\e8f3'; }

/* '' */
.ims-icon-moon:before {
  content: '\e8f4'; }

/* '' */
.ims-icon-umbrella:before {
  content: '\e8f5'; }

/* '' */
.ims-icon-flight:before {
  content: '\e8f6'; }

/* '' */
.ims-icon-fighter-jet:before {
  content: '\e8f7'; }

/* '' */
.ims-icon-paper-plane:before {
  content: '\e8f8'; }

/* '' */
.ims-icon-paper-plane-empty:before {
  content: '\e8f9'; }

/* '' */
.ims-icon-space-shuttle:before {
  content: '\e8fa'; }

/* '' */
.ims-icon-leaf:before {
  content: '\e8fb'; }

/* '' */
.ims-icon-font:before {
  content: '\e8fc'; }

/* '' */
.ims-icon-bold:before {
  content: '\e8fd'; }

/* '' */
.ims-icon-italic:before {
  content: '\e8fe'; }

/* '' */
.ims-icon-header:before {
  content: '\e8ff'; }

/* '' */
.ims-icon-paragraph:before {
  content: '\e900'; }

/* '' */
.ims-icon-text-height:before {
  content: '\e901'; }

/* '' */
.ims-icon-text-width:before {
  content: '\e902'; }

/* '' */
.ims-icon-align-left:before {
  content: '\e903'; }

/* '' */
.ims-icon-align-center:before {
  content: '\e904'; }

/* '' */
.ims-icon-align-right:before {
  content: '\e905'; }

/* '' */
.ims-icon-align-justify:before, .ims-long_answer:before {
  content: '\e906'; }

/* '' */
.ims-icon-list:before {
  content: '\e907'; }

/* '' */
.ims-icon-indent-left:before {
  content: '\e908'; }

/* '' */
.ims-icon-indent-right:before {
  content: '\e909'; }

/* '' */
.ims-icon-list-bullet:before {
  content: '\e90a'; }

/* '' */
.ims-icon-list-numbered:before {
  content: '\e90b'; }

/* '' */
.ims-icon-strike:before {
  content: '\e90c'; }

/* '' */
.ims-icon-underline:before {
  content: '\e90d'; }

/* '' */
.ims-icon-superscript:before {
  content: '\e90e'; }

/* '' */
.ims-icon-subscript:before {
  content: '\e90f'; }

/* '' */
.ims-icon-table:before {
  content: '\e910'; }

/* '' */
.ims-icon-page:before {
  content: '\e911'; }

/* '' */
.ims-icon-crop:before {
  content: '\e912'; }

/* '' */
.ims-icon-scissors:before {
  content: '\e913'; }

/* '' */
.ims-icon-paste:before {
  content: '\e914'; }

/* '' */
.ims-icon-briefcase:before {
  content: '\e915'; }

/* '' */
.ims-icon-suitcase:before {
  content: '\e916'; }

/* '' */
.ims-icon-ellipsis:before {
  content: '\e917'; }

/* '' */
.ims-icon-ellipsis-vert:before {
  content: '\e918'; }

/* '' */
.ims-icon-off:before {
  content: '\e919'; }

/* '' */
.ims-icon-road:before {
  content: '\e91a'; }

/* '' */
.ims-icon-list-alt:before {
  content: '\e91b'; }

/* '' */
.ims-icon-qrcode:before {
  content: '\e91c'; }

/* '' */
.ims-icon-barcode:before {
  content: '\e91d'; }

/* '' */
.ims-icon-learningmaterials:before {
  content: '\e91e'; }

/* '' */
.ims-icon-ajust:before {
  content: '\e91f'; }

/* '' */
.ims-icon-tint:before {
  content: '\e920'; }

/* '' */
.ims-icon-check:before {
  content: '\e921'; }

/* '' */
.ims-icon-check-empty:before {
  content: '\e922'; }

/* '' */
.ims-icon-circle:before {
  content: '\e923'; }

/* '' */
.ims-icon-circle-empty:before, .ims-linear_scale:before {
  content: '\e924'; }

/* '' */
.ims-icon-circle-thin:before {
  content: '\e925'; }

/* '' */
.ims-icon-circle-notch:before {
  content: '\e926'; }

/* '' */
.ims-icon-dot-circled:before, .ims-single_choice:before {
  content: '\e927'; }

/* '' */
.ims-icon-asterisk-1:before {
  content: '\e928'; }

/* '' */
.ims-icon-gift:before {
  content: '\e929'; }

/* '' */
.ims-icon-fire:before {
  content: '\e92a'; }

/* '' */
.ims-icon-magnet:before {
  content: '\e92b'; }

/* '' */
.ims-icon-chart-bar:before {
  content: '\e92c'; }

/* '' */
.ims-icon-ticket:before {
  content: '\e92d'; }

/* '' */
.ims-icon-credit-card:before {
  content: '\e92e'; }

/* '' */
.ims-icon-floppy:before {
  content: '\e92f'; }

/* '' */
.ims-icon-megaphone:before {
  content: '\e930'; }

/* '' */
.ims-icon-hardware:before {
  content: '\e931'; }

/* '' */
.ims-icon-password:before {
  content: '\e932'; }

/* '' */
.ims-icon-privileges:before {
  content: '\e933'; }

/* '' */
.ims-icon-rocket:before {
  content: '\e934'; }

/* '' */
.ims-icon-bug:before {
  content: '\e935'; }

/* '' */
.ims-icon-certificate:before {
  content: '\e936'; }

/* '' */
.ims-icon-parameter:before {
  content: '\e937'; }

/* '' */
.ims-icon-filter:before {
  content: '\e938'; }

/* '' */
.ims-icon-beaker:before {
  content: '\e939'; }

/* '' */
.ims-icon-magic:before {
  content: '\e93a'; }

/* '' */
.ims-icon-cab:before {
  content: '\e93b'; }

/* '' */
.ims-icon-taxi:before {
  content: '\e93c'; }

/* '' */
.ims-icon-truck:before {
  content: '\e93d'; }

/* '' */
.ims-icon-money:before {
  content: '\e93e'; }

/* '' */
.ims-icon-euro:before {
  content: '\e93f'; }

/* '' */
.ims-icon-pound:before {
  content: '\e940'; }

/* '' */
.ims-icon-dollar:before {
  content: '\e941'; }

/* '' */
.ims-icon-rupee:before {
  content: '\e942'; }

/* '' */
.ims-icon-yen:before {
  content: '\e943'; }

/* '' */
.ims-icon-rouble:before {
  content: '\e944'; }

/* '' */
.ims-icon-try:before {
  content: '\e945'; }

/* '' */
.ims-icon-won:before {
  content: '\e946'; }

/* '' */
.ims-icon-bitcoin:before {
  content: '\e947'; }

/* '' */
.ims-icon-sort:before {
  content: '\e948'; }

/* '' */
.ims-icon-sort-down:before {
  content: '\e949'; }

/* '' */
.ims-icon-sort-up:before {
  content: '\e94a'; }

/* '' */
.ims-icon-sort-alt-up:before {
  content: '\e94b'; }

/* '' */
.ims-icon-sort-alt-down:before {
  content: '\e94c'; }

/* '' */
.ims-icon-sort-name-up:before {
  content: '\e94d'; }

/* '' */
.ims-icon-sort-name-down:before {
  content: '\e94e'; }

/* '' */
.ims-icon-sort-number-up:before {
  content: '\e94f'; }

/* '' */
.ims-icon-sort-number-down:before {
  content: '\e950'; }

/* '' */
.ims-icon-legal:before {
  content: '\e951'; }

/* '' */
.ims-icon-dashboard:before {
  content: '\e952'; }

/* '' */
.ims-icon-sitemap:before {
  content: '\e953'; }

/* '' */
.ims-icon-spinner:before {
  content: '\e954'; }

/* '' */
.ims-icon-coffee:before {
  content: '\e955'; }

/* '' */
.ims-icon-food:before {
  content: '\e956'; }

/* '' */
.ims-icon-beer:before {
  content: '\e957'; }

/* '' */
.ims-icon-user-md:before {
  content: '\e958'; }

/* '' */
.ims-icon-stethoscope:before {
  content: '\e959'; }

/* '' */
.ims-icon-ambulance:before {
  content: '\e95a'; }

/* '' */
.ims-icon-medkit:before {
  content: '\e95b'; }

/* '' */
.ims-icon-h-sigh:before {
  content: '\e95c'; }

/* '' */
.ims-icon-hospital:before {
  content: '\e95d'; }

/* '' */
.ims-icon-hotels:before {
  content: '\e95e'; }

/* '' */
.ims-icon-backend:before {
  content: '\e95f'; }

/* '' */
.ims-icon-trainingvenues:before {
  content: '\e960'; }

/* '' */
.ims-icon-smile:before, .ims-happy_sheet:before {
  content: '\e961'; }

/* '' */
.ims-icon-frown:before {
  content: '\e962'; }

/* '' */
.ims-icon-meh:before {
  content: '\e963'; }

/* '' */
.ims-icon-anchor:before {
  content: '\e964'; }

/* '' */
.ims-icon-terminal:before {
  content: '\e965'; }

/* '' */
.ims-icon-eraser:before {
  content: '\e966'; }

/* '' */
.ims-icon-customize:before {
  content: '\e967'; }

/* '' */
.ims-icon-shield:before {
  content: '\e968'; }

/* '' */
.ims-icon-extinguisher:before {
  content: '\e969'; }

/* '' */
.ims-icon-bullseye:before {
  content: '\e96a'; }

/* '' */
.ims-icon-wheelchair:before {
  content: '\e96b'; }

/* '' */
.ims-icon-translations:before, .ims-icon-locales:before {
  content: '\e96c'; }

/* '' */
/*BE: .ims-icon.ims-icon-locales:before { content: '\e96c'; } */
.ims-icon-trainings:before {
  content: '\e96d'; }

/* '' */
.ims-icon-paw:before {
  content: '\e96e'; }

/* '' */
.ims-icon-spoon:before {
  content: '\e96f'; }

/* '' */
.ims-icon-cube:before {
  content: '\e970'; }

/* '' */
.ims-icon-cubes:before {
  content: '\e971'; }

/* '' */
.ims-icon-recycle:before {
  content: '\e972'; }

/* '' */
.ims-icon-tree:before {
  content: '\e973'; }

/* '' */
.ims-icon-database:before {
  content: '\e974'; }

/* '' */
.ims-icon-lifebuoy:before {
  content: '\e975'; }

/* '' */
.ims-icon-rebel:before {
  content: '\e976'; }

/* '' */
.ims-icon-empire:before {
  content: '\e977'; }

/* '' */
.ims-icon-bomb:before {
  content: '\e978'; }

/* '' */
.ims-icon-adn:before {
  content: '\e979'; }

/* '' */
.ims-icon-android:before {
  content: '\e97a'; }

/* '' */
.ims-icon-apple:before {
  content: '\e97b'; }

/* '' */
.ims-icon-behance:before {
  content: '\e97c'; }

/* '' */
.ims-icon-behance-squared:before {
  content: '\e97d'; }

/* '' */
.ims-icon-bitbucket:before {
  content: '\e97e'; }

/* '' */
.ims-icon-bitbucket-squared:before {
  content: '\e97f'; }

/* '' */
.ims-icon-dimension:before {
  content: '\e980'; }

/* '' */
.ims-icon-css3:before {
  content: '\e981'; }

/* '' */
.ims-icon-delicious:before {
  content: '\e982'; }

/* '' */
.ims-icon-deviantart:before {
  content: '\e983'; }

/* '' */
.ims-icon-digg:before {
  content: '\e984'; }

/* '' */
.ims-icon-dribbble:before {
  content: '\e985'; }

/* '' */
.ims-icon-dropbox:before {
  content: '\e986'; }

/* '' */
.ims-icon-drupal:before {
  content: '\e987'; }

/* '' */
.ims-icon-facebook:before {
  content: '\e988'; }

/* '' */
.ims-icon-facebook-squared:before {
  content: '\e989'; }

/* '' */
.ims-icon-flickr:before {
  content: '\e98a'; }

/* '' */
.ims-icon-foursquare:before {
  content: '\e98b'; }

/* '' */
.ims-icon-git-squared:before {
  content: '\e98c'; }

/* '' */
.ims-icon-git:before {
  content: '\e98d'; }

/* '' */
.ims-icon-github:before {
  content: '\e98e'; }

/* '' */
.ims-icon-github-squared:before {
  content: '\e98f'; }

/* '' */
.ims-icon-github-circled:before {
  content: '\e990'; }

/* '' */
.ims-icon-gittip:before {
  content: '\e991'; }

/* '' */
.ims-icon-google:before {
  content: '\e992'; }

/* '' */
.ims-icon-gplus:before {
  content: '\e993'; }

/* '' */
.ims-icon-gplus-squared:before {
  content: '\e994'; }

/* '' */
.ims-icon-hacker-news:before {
  content: '\e995'; }

/* '' */
.ims-icon-html5:before {
  content: '\e996'; }

/* '' */
.ims-icon-instagramm:before {
  content: '\e997'; }

/* '' */
.ims-icon-joomla:before {
  content: '\e998'; }

/* '' */
.ims-icon-jsfiddle:before {
  content: '\e999'; }

/* '' */
.ims-icon-linkedin-squared:before {
  content: '\e99a'; }

/* '' */
.ims-icon-linux:before {
  content: '\e99b'; }

/* '' */
.ims-icon-linkedin:before {
  content: '\e99c'; }

/* '' */
.ims-icon-maxcdn:before {
  content: '\e99d'; }

/* '' */
.ims-icon-openid:before {
  content: '\e99e'; }

/* '' */
.ims-icon-pagelines:before {
  content: '\e99f'; }

/* '' */
.ims-icon-pied-piper-squared:before {
  content: '\e9a0'; }

/* '' */
.ims-icon-pied-piper-alt:before {
  content: '\e9a1'; }

/* '' */
.ims-icon-pinterest-circled:before {
  content: '\e9a2'; }

/* '' */
.ims-icon-pinterest-squared:before {
  content: '\e9a3'; }

/* '' */
.ims-icon-qq:before {
  content: '\e9a4'; }

/* '' */
.ims-icon-reddit:before {
  content: '\e9a5'; }

/* '' */
.ims-icon-reddit-squared:before {
  content: '\e9a6'; }

/* '' */
.ims-icon-renren:before {
  content: '\e9a7'; }

/* '' */
.ims-icon-skype:before {
  content: '\e9a8'; }

/* '' */
.ims-icon-slack:before {
  content: '\e9a9'; }

/* '' */
.ims-icon-soundclowd:before {
  content: '\e9aa'; }

/* '' */
.ims-icon-spotify:before {
  content: '\e9ab'; }

/* '' */
.ims-icon-stackexchange:before {
  content: '\e9ac'; }

/* '' */
.ims-icon-stackoverflow:before {
  content: '\e9ad'; }

/* '' */
.ims-icon-steam:before {
  content: '\e9ae'; }

/* '' */
.ims-icon-steam-squared:before {
  content: '\e9af'; }

/* '' */
.ims-icon-stumbleupon:before {
  content: '\e9b0'; }

/* '' */
.ims-icon-stumbleupon-circled:before {
  content: '\e9b1'; }

/* '' */
.ims-icon-tencent-weibo:before {
  content: '\e9b2'; }

/* '' */
.ims-icon-trello:before {
  content: '\e9b3'; }

/* '' */
.ims-icon-tumblr:before {
  content: '\e9b4'; }

/* '' */
.ims-icon-tumblr-squared:before {
  content: '\e9b5'; }

/* '' */
.ims-icon-twitter-squared:before {
  content: '\e9b6'; }

/* '' */
.ims-icon-twitter:before {
  content: '\e9b7'; }

/* '' */
.ims-icon-vimeo-squared:before {
  content: '\e9b8'; }

/* '' */
.ims-icon-vine:before {
  content: '\e9b9'; }

/* '' */
.ims-icon-vkontakte:before {
  content: '\e9ba'; }

/* '' */
.ims-icon-wechat:before {
  content: '\e9bb'; }

/* '' */
.ims-icon-weibo:before {
  content: '\e9bc'; }

/* '' */
.ims-icon-windows:before {
  content: '\e9bd'; }

/* '' */
.ims-icon-wordpress:before {
  content: '\e9be'; }

/* '' */
.ims-icon-xing:before {
  content: '\e9bf'; }

/* '' */
.ims-icon-xing-squared:before {
  content: '\e9c0'; }

/* '' */
.ims-icon-youtube:before {
  content: '\e9c1'; }

/* '' */
.ims-icon-yahoo:before {
  content: '\e9c2'; }

/* '' */
.ims-icon-youtube-squared:before {
  content: '\e9c3'; }

/* '' */
.ims-icon-youtube-play:before {
  content: '\e9c4'; }

/* '' */
.ims-icon-blank:before, .ims-icon.ims-icon-square:before, .ims-icon-square.ims-icon-arrow-left:before, .ims-icon-square.ims-icon-arrow-right:before {
  content: '\e9c5'; }

/* '' */
.ims-icon-lemon:before {
  content: '\e9c6'; }

/* '' */
.ims-icon-plus-squared-1:before {
  content: '\e9c7'; }

/* '' */
.ims-icon-user:before {
  content: '\e9c8'; }

/* '' */
.ims-icon-toggle-off:before {
  content: '\e9c9'; }

/* '' */
.ims-icon-toggle-on:before {
  content: '\e9ca'; }

/* '' */
.ims-icon-roles:before {
  content: '\e9cb'; }

/* '' */
.ims-icon-edit:before {
  content: '\e9cc'; }

/* '' */
.ims-icon-pagetemplate:before {
  content: '\e9cd'; }

/* '' */
.ims-icon-person-crm:before {
  content: '\e9ce'; }

/* '' */
.ims-icon-tr-virtuell:before {
  content: '\e9cf'; }

/* '' */
.ims-icon-tr-praesenz:before {
  content: '\e9d0'; }

/* '' */
.ims-icon-medialibrary:before {
  content: '\e9d1'; }

/* '' */
.ims-icon-import:before {
  content: '\e9d2'; }

/* '' */
.ims-icon-matrixsignature:before {
  content: '\e9d3'; }

/* '' */
.ims-icon-notification:before {
  content: '\e9d4'; }

/* '' */
.ims-icon-map:before {
  content: '\e9d5'; }

/* '' */
.ims-icon-trainingtemplates:before {
  content: '\e9d6'; }

/* '' */
.ims-icon-department:before {
  content: '\e9d7'; }

/* '' */
.ims-icon-statesprovinces:before {
  content: '\e9d8'; }

/* '' */
.ims-icon-tr-online:before {
  content: '\e9d9'; }

/* '' */
.ims-icon-pfeil-duravit:before {
  content: '\e9da'; }

/* '' */
.ims-icon-pfeil_zurueck_duravit:before {
  content: '\e9db'; }

/* '' */
.ims-icon-spin1:before {
  content: '\e9dc'; }

/* '' */
.ims-icon-spin2:before {
  content: '\e9dd'; }

/* '' */
.ims-icon-spin3:before {
  content: '\e9de'; }

/* '' */
.ims-icon-spin4:before {
  content: '\e9df'; }

/* '' */
.ims-icon-spin5:before {
  content: '\e9e0'; }

/* '' */
.ims-icon-spin6:before, .ims-icon.ims-icon-spinner:before, .ims-icon-spinner.ims-icon-arrow-left:before, .ims-icon-spinner.ims-icon-arrow-right:before {
  content: '\e9e1'; }

/* '' */
.ims-icon-firefox:before {
  content: '\e9e2'; }

/* '' */
.ims-icon-chrome:before {
  content: '\e9e3'; }

/* '' */
.ims-icon-opera:before {
  content: '\e9e4'; }

/* '' */
.ims-icon-ie:before {
  content: '\e9e5'; }

/* '' */
.ims-icon-equipment:before {
  content: '\e9e6'; }

/* '' */
.ims-icon-active_user:before {
  content: '\e9e7'; }

/* '' */
.ims-icon-spectator_user:before {
  content: '\e9e8'; }

/* '' */
.ims-icon-promotioncode:before {
  content: '\e9e9'; }

/* '' */
.ims-icon-provider:before, .ims-icon-virtualclassroomproviders:before {
  content: '\e9ea'; }

/* '' */
.ims-icon-vc_meeting:before {
  content: '\e9eb'; }

/* '' */
.ims-icon-voucher:before {
  content: '\e9ec'; }

/* '' */
.ims-icon-crm_logout:before {
  content: '\e9ed'; }

/* '' */
.ims-icon-crm_login:before {
  content: '\e9ee'; }

/* '' */
.ims-icon-crm_farbwahl:before {
  content: '\e9ef'; }

/* '' */
.ims-icon-crm_kalender:before {
  content: '\e9f0'; }

/* '' */
.ims-icon-crm_info_i:before {
  content: '\e9f1'; }

/* '' */
.ims-icon-crm_info_i_blank:before {
  content: '\e9f2'; }

/* '' */
.ims-icon-crm_kalender2:before {
  content: '\e9f3'; }

/* '' */
.ims-icon-crm_teilen_button:before {
  content: '\e9f4'; }

/* '' */
.ims-icon-reisebuchung:before {
  content: '\e9f5'; }

/* '' */
.ims-icon-entertainment:before {
  content: '\e9f6'; }

/* '' */
.ims-icon-presentation:before {
  content: '\e9f7'; }

/* '' */
.ims-icon-show:before {
  content: '\e9f8'; }

/* '' */
.ims-icon-workshop:before {
  content: '\e9f9'; }

/* '' */
.ims-icon-plan_visit:before {
  content: '\e9fa'; }

/* '' */
.ims-icon-programmplaner:before {
  content: '\e9fb'; }

/* '' */
.ims-icon-show_event:before {
  content: '\e9fc'; }

/* '' */
.ims-icon-arrowback:before, .ims-icon-back:before {
  content: '\e9fd'; }

/* '' */
.ims-icon-arrowforward:before, .ims-icon-details:before {
  content: '\e9fe'; }

/* '' */
.ims-icon-attention-1:before {
  content: '\e9ff'; }

/* '' */
.ims-icon-availibilty:before {
  content: '\ea00'; }

/* '' */
.ims-icon-contact-1:before {
  content: '\ea01'; }

/* '' */
.ims-icon-classroomsession:before {
  content: '\ea02'; }

/* '' */
.ims-icon-onlinecont:before {
  content: '\ea03'; }

/* '' */
.ims-icon-virtclassroomsess:before {
  content: '\ea04'; }

/* '' */
.ims-icon-close:before, .ims-icon-failed:before, .ims-survey-question {
  content: '\ea05'; }

/* '' */
.ims-icon-complete:before {
  content: '\ea06'; }

/* '' */
.ims-icon-bubbles:before {
  content: '\ea07'; }

/* '' */
.ims-icon-delete-1:before {
  content: '\ea08'; }

/* '' */
.ims-icon-download:before {
  content: '\ea09'; }

/* '' */
.ims-icon-location-1:before {
  content: '\ea0a'; }

/* '' */
.ims-icon-morelocations:before {
  content: '\ea0b'; }

/* '' */
.ims-icon-plus-1:before, .ims-icon-add:before {
  content: '\ea0c'; }

/* '' */
.ims-icon-search-1:before {
  content: '\ea0d'; }

/* '' */
.ims-icon-status:before {
  content: '\ea0e'; }

/* '' */
.ims-icon-time:before {
  content: '\ea0f'; }

/* '' */
.ims-icon-trainer:before {
  content: '\ea10'; }

/* '' */
.ims-icon-trainerrounded:before {
  content: '\ea11'; }

/* '' */
.ims-icon-hourglass:before, .ims-icon-waiting:before {
  content: '\ea12'; }

/* '' */
.ims-icon-download_new:before, .ims-icon-download:before,
.ims-resource-download .ims-icon-linkStyle:before {
  content: '\ea13'; }

/* '' */
.ims-icon-room:before {
  content: '\ea14'; }

/* '' */
.ims-icon-recently_visited:before {
  content: '\ea15'; }

/* '' */
.ims-icon-unselect_refresh:before {
  content: '\ea16'; }

/* '' */
.ims-icon-wbt:before {
  content: '\ea17'; }

/* '' */
.ims-icon-list-1:before {
  content: '\ea18'; }

/* '' */
.ims-icon-ppt:before {
  content: '\ea19'; }

/* '' */
.ims-icon-copy:before {
  content: '\ea1a'; }

/* '' */
.ims-icon-add_participant:before {
  content: '\ea1b'; }

/* '' */
.ims-icon-certificate-1:before {
  content: '\ea1c'; }

/* '' */
.ims-icon-24_7:before {
  content: '\ea1d'; }

/* '' */
.ims-icon-globe-1:before {
  content: '\ea1e'; }

/* '' */
.ims-icon-globe_pure:before {
  content: '\ea1f'; }

/* '' */
.ims-icon-hamburger_menue:before {
  content: '\ea20'; }

/* '' */
.ims-icon-home-1:before {
  content: '\ea21'; }

/* '' */
.ims-icon-home_bold:before {
  content: '\ea22'; }

/* '' */
.ims-icon-location-2:before {
  content: '\ea23'; }

/* '' */
.ims-icon-arrow_right:before {
  content: '\ea24'; }

/* '' */
.ims-icon-arrow_left:before {
  content: '\ea25'; }

/* '' */
.ims-icon-arrow_left_bold:before {
  content: '\ea26'; }

/* '' */
.ims-icon-arrow_right_bold:before {
  content: '\ea27'; }

/* '' */
.ims-icon-person:before {
  content: '\ea28'; }

/* '' */
.ims-icon-megaphone-1:before {
  content: '\ea29'; }

/* '' */
.ims-icon-megaphone_fine:before {
  content: '\ea2a'; }

/* '' */
.ims-icon-fax-1:before {
  content: '\ea2b'; }

/* '' */
.ims-icon-fax_bold:before {
  content: '\ea2c'; }

/* '' */
.ims-icon-folder-1:before {
  content: '\ea2d'; }

/* '' */
.ims-icon-folder_bold:before {
  content: '\ea2e'; }

/* '' */
.ims-icon-phone_bold:before {
  content: '\ea2f'; }

/* '' */
.ims-icon-phone-1:before {
  content: '\ea30'; }

/* '' */
.ims-icon-print-1:before {
  content: '\ea31'; }

/* '' */
.ims-icon-print_bold:before {
  content: '\ea32'; }

/* '' */
.ims-icon-rocket-1:before {
  content: '\ea33'; }

/* '' */
.ims-icon-statistic:before {
  content: '\ea34'; }

/* '' */
.ims-icon-statistic_notebook:before {
  content: '\ea35'; }

/* '' */
.ims-icon-subtitles:before {
  content: '\ea36'; }

/* '' */
.ims-icon-subtitles_bold:before {
  content: '\ea37'; }

/* '' */
.ims-icon-upload-1:before {
  content: '\ea38'; }

/* '' */
.ims-icon-video-1:before {
  content: '\ea39'; }

/* '' */
.ims-icon-calendar_light:before {
  content: '\ea3a'; }

/* '' */
.ims-icon-coursetype_oc:before {
  content: '\ea3b'; }

/* '' */
.ims-icon-coursetype_vc:before {
  content: '\ea3c'; }

/* '' */
.ims-icon-coursetype_cs:before {
  content: '\ea3d'; }

/* '' */
.ims-icon-company_building:before {
  content: '\ea3e'; }

/* '' */
.ims-icon-equipping:before {
  content: '\ea3f'; }

/* '' */
.ims-icon-exercise_folder:before {
  content: '\ea40'; }

/* '' */
.ims-icon-exercise_page:before {
  content: '\ea41'; }

/* '' */
.ims-icon-sessionlevel_statistic:before {
  content: '\ea42'; }

/* '' */
.ims-icon-analysis:before {
  content: '\ea43'; }

/* '' */
.ims-icon-adjustments:before {
  content: '\ea44'; }

/* '' */
.ims-icon-folder_filled:before {
  content: '\ea45'; }

/* '' */
.ims-icon-close_minus:before {
  content: '\ea46'; }

/* '' */
.ims-icon-share-1:before {
  content: '\ea47'; }

/* '' */
.ims-icon-ok_circle:before {
  content: '\ea48'; }

/* '' */
.ims-icon-mobile_exercise:before {
  content: '\ea49'; }

/* '' */
.ims-icon-book:before {
  content: '\ea4a'; }

/* '' */
.ims-icon-cloud-1:before {
  content: '\ea4b'; }

/* '' */
.ims-icon-save:before {
  content: '\ea4c'; }

/* '' */
.ims-icon-long_answer:before {
  content: '\ea4d'; }

/* '' */
.ims-icon-single_choice:before {
  content: '\ea4e'; }

/* '' */
.ims-icon-multiple_choice:before {
  content: '\ea4f'; }

/* '' */
.ims-icon-linear_scale:before {
  content: '\ea50'; }

/* '' */
.ims-icon-add_page:before {
  content: '\ea51'; }

/* '' */
.ims-icon-dropdown:before {
  content: '\ea52'; }

/* '' */
.ims-icon-pagebreak:before {
  content: '\ea53'; }

/* '' */
.ims-icon-multiplechoice_grid:before {
  content: '\ea54'; }

/* '' */
.ims-icon-singlechoice_grid:before {
  content: '\ea55'; }

/* '' */
.ims-icon-move_panel:before {
  content: '\ea56'; }

/* '' */
.ims-icon-views:before {
  content: '\ea57'; }

/* '' */
.ims-icon-registrations:before {
  content: '\ea58'; }

/* '' */
.ims-icon-online_help:before {
  content: '\ea59'; }

/* '' */
.ims-icon-vip_usergroup:before {
  content: '\ea5a'; }

/* '' */
.ims-icon-help-1:before {
  content: '\ea5b'; }

/* '' */
.ims-icon-communication:before {
  content: '\ea5c'; }

/* '' */
.ims-icon-course_bookings:before {
  content: '\ea5d'; }

/* '' */
.ims-icon-user_registrations:before {
  content: '\ea5e'; }

/* '' */
.ims-icon-summary:before {
  content: '\ea5f'; }

/* '' */
.ims-icon-billing_address:before {
  content: '\ea60'; }

/* '' */
.ims-icon-payment:before {
  content: '\ea61'; }

/* '' */
.ims-icon-multiactiondropdown:before {
  content: '\ea62'; }

/* '' */
.ims-icon-arrow_enlarge:before {
  content: '\ea63'; }

/* '' */
.ims-icon-arrow_minimize:before {
  content: '\ea64'; }

/* '' */
.ims-icon-rename:before {
  content: '\ea65'; }

/* '' */
.ims-icon-filtersearchreset:before {
  content: '\ea66'; }

/* '' */
.ims-icon-blendedlearning_fr_tri:before {
  content: '\ea67'; }

/* '' */
.ims-icon-blendedlearning_fr:before {
  content: '\ea68'; }

/* '' */
.ims-icon-blendedlearning:before {
  content: '\ea69'; }

/* '' */
.ims-icon-blendedlearning_tr:before {
  content: '\ea6a'; }

/* '' */
.ims-icon-availibleseats:before {
  content: '\ea6b'; }

/* '' */
.ims-icon-blendedlearningcourses:before {
  content: '\ea6c'; }

/* '' */
.ims-icon-requestforms:before {
  content: '\ea6d'; }

/* '' */
.ims-icon-remove_bookmark:before {
  content: '\ea6e'; }

/* '' */
.ims-icon-clear_all:before {
  content: '\ea6f'; }

/* '' */
.ims-icon-costs:before {
  content: '\ea70'; }

/* '' */
.ims-icon-available_until:before {
  content: '\ea71'; }

/* '' */
.ims-icon-stopwatch:before {
  content: '\ea72'; }

/* '' */
.ims-icon-open_badge:before {
  content: '\ecc0'; }

/* '' */
.ims-icon-badge:before {
  content: '\ecc1'; }

/* '' */
.ims-icon-webxr:before {
  content: '\ea73'; }

/* '' */
.ims-icon-video360:before {
  content: '\ea74'; }

/* '' */
.ims-icon-channels:before {
  content: '\ea75'; }

/* '' */
.ims-icon-ellipsis_horizontal:before {
  content: '\ea76'; }

/* '' */
.ims-icon-ellipsis_vertical:before {
  content: '\ea77'; }

/* '' */
.ims-icon-toggle_headerrow:before {
  content: '\ea78'; }

/* '' */
.ims-icon-toggle_headercolumn:before {
  content: '\ea79'; }

/* '' */
.ims-icon-merge_split_cells:before {
  content: '\ea7a'; }

/* '' */
.ims-icon-add_row_above:before {
  content: '\ea7b'; }

/* '' */
.ims-icon-add_row_below:before {
  content: '\ea7c'; }

/* '' */
.ims-icon-delete_row:before {
  content: '\ea7d'; }

/* '' */
.ims-icon-delete_column:before {
  content: '\ea7e'; }

/* '' */
.ims-icon-add_column_before:before {
  content: '\ea7f'; }

/* '' */
.ims-icon-add_column_after:before {
  content: '\ea80'; }

/* '' */
.ims-icon-open_badge:before {
  content: '\ecc0'; }

/* '' */
.ims-icon-badge:before {
  content: '\ecc1'; }

/* '' */
.ims-icon-state_available:before {
  content: '\ea81'; }

/* '' */
.ims-icon-state_inprogress:before {
  content: '\ea82'; }

/* '' */
.ims-icon-state_completed:before {
  content: '\ea83'; }

/* '' */
.ims-icon-state_failed:before {
  content: '\ea84'; }

/* '' */
.ims-icon-text_color:before {
  content: '\ea85'; }

/* '' */
.ims-icon-bg_color:before {
  content: '\ea86'; }

/* '' */
.ims-icon-three_columns:before {
  content: '\ea87'; }

/* '' */
.ims-icon-link_internal_arrow:before {
  content: '\ea88'; }

/* '' */
.ims-icon-unlimited:before {
  content: '\ea89'; }

/* '' */
.ims-icon-calender_unlimited:before {
  content: '\ea8a'; }

/* '' */
.ims-icon-open_arrow:before {
  content: '\ea8b'; }

/* '' */
.ims-icon-available_from:before {
  content: '\ea8c'; }

/* '' */
.ims-icon-time-1:before {
  content: '\ea8d'; }

/* '' */
.ims-icon-two-cols-33-66:before {
  content: '\ea8e'; }

/* '' */
.ims-icon-two-cols-66-33:before {
  content: '\ea8f'; }

/* '' */
.ims-icon-three_cols-25-50-25:before {
  content: '\ea90'; }

/* '' */
.flags {
  background-image: url("/content/themes/common/images/flags/sprite.png");
  display: inline-block;
  text-indent: -9999px; }

.image.flags {
  width: 16px;
  height: 15px; }

.flagName {
  display: inline-block;
  padding-left: 2px; }

.flags.ad {
  background-position: 0 0; }

.flags.ad_faded {
  background-position: -16px 0; }

.flags.ae {
  background-position: -32px 0; }

.flags.ae_faded {
  background-position: -48px 0; }

.flags.af {
  background-position: -64px 0; }

.flags.af_faded {
  background-position: -80px 0; }

.flags.ag {
  background-position: -96px 0; }

.flags.ag_faded {
  background-position: -112px 0; }

.flags.ai {
  background-position: -128px 0; }

.flags.ai_faded {
  background-position: -144px 0; }

.flags.al {
  background-position: -160px 0; }

.flags.al_faded {
  background-position: -176px 0; }

.flags.am {
  background-position: -192px 0; }

.flags.am_faded {
  background-position: -208px 0; }

.flags.an {
  background-position: -224px 0; }

.flags.an_faded {
  background-position: -240px 0; }

.flags.ao {
  background-position: -256px 0; }

.flags.ao_faded {
  background-position: -272px 0; }

.flags.ar {
  background-position: -288px 0; }

.flags.ar_faded {
  background-position: -304px 0; }

.flags.as {
  background-position: -320px 0; }

.flags.as_faded {
  background-position: -336px 0; }

.flags.at {
  background-position: -352px 0; }

.flags.at_faded {
  background-position: -368px 0; }

.flags.au {
  background-position: -384px 0; }

.flags.au_faded {
  background-position: -400px 0; }

.flags.aw {
  background-position: -416px 0; }

.flags.aw_faded {
  background-position: -432px 0; }

.flags.ax {
  background-position: -448px 0; }

.flags.ax_faded {
  background-position: -464px 0; }

.flags.az {
  background-position: -480px 0; }

.flags.az_faded {
  background-position: -496px 0; }

.flags.ba {
  background-position: -512px 0; }

.flags.ba_faded {
  background-position: -528px 0; }

.flags.bb {
  background-position: -544px 0; }

.flags.bb_faded {
  background-position: -560px 0; }

.flags.bd {
  background-position: -576px 0; }

.flags.bd_faded {
  background-position: -592px 0; }

.flags.be {
  background-position: -608px 0; }

.flags.be_faded {
  background-position: -624px 0; }

.flags.bf {
  background-position: -640px 0; }

.flags.bf_faded {
  background-position: -656px 0; }

.flags.bg {
  background-position: -672px 0; }

.flags.bg_faded {
  background-position: -688px 0; }

.flags.bh {
  background-position: -704px 0; }

.flags.bh_faded {
  background-position: -720px 0; }

.flags.bi {
  background-position: -736px 0; }

.flags.bi_faded {
  background-position: -752px 0; }

.flags.bj {
  background-position: -768px 0; }

.flags.bj_faded {
  background-position: -784px 0; }

.flags.bm {
  background-position: -800px 0; }

.flags.bm_faded {
  background-position: -816px 0; }

.flags.bn {
  background-position: -832px 0; }

.flags.bn_faded {
  background-position: -848px 0; }

.flags.bo {
  background-position: -864px 0; }

.flags.bo_faded {
  background-position: -880px 0; }

.flags.br {
  background-position: -896px 0; }

.flags.br_faded {
  background-position: -912px 0; }

.flags.bs {
  background-position: -928px 0; }

.flags.bs_faded {
  background-position: -944px 0; }

.flags.bt {
  background-position: -960px 0; }

.flags.bt_faded {
  background-position: -976px 0; }

.flags.bv {
  background-position: -992px 0; }

.flags.bv_faded {
  background-position: -1008px 0; }

.flags.bw {
  background-position: -1024px 0; }

.flags.bw_faded {
  background-position: -1040px 0; }

.flags.by {
  background-position: -1056px 0; }

.flags.by_faded {
  background-position: -1072px 0; }

.flags.bz {
  background-position: -1088px 0; }

.flags.bz_faded {
  background-position: -1104px 0; }

.flags.ca {
  background-position: -1120px 0; }

.flags.ca_faded {
  background-position: -1136px 0; }

.flags.catalonia {
  background-position: -1152px 0; }

.flags.catalonia_faded {
  background-position: -1168px 0; }

.flags.cc {
  background-position: -1184px 0; }

.flags.cc_faded {
  background-position: -1200px 0; }

.flags.cd {
  background-position: -1216px 0; }

.flags.cd_faded {
  background-position: -1232px 0; }

.flags.cf {
  background-position: -1248px 0; }

.flags.cf_faded {
  background-position: -1264px 0; }

.flags.cg {
  background-position: -1280px 0; }

.flags.cg_faded {
  background-position: -1296px 0; }

.flags.ch {
  background-position: -1312px 0; }

.flags.ch_faded {
  background-position: -1328px 0; }

.flags.ci {
  background-position: -1344px 0; }

.flags.ci_faded {
  background-position: -1360px 0; }

.flags.ck {
  background-position: -1376px 0; }

.flags.ck_faded {
  background-position: -1392px 0; }

.flags.cl {
  background-position: -1408px 0; }

.flags.cl_faded {
  background-position: -1424px 0; }

.flags.cm {
  background-position: -1440px 0; }

.flags.cm_faded {
  background-position: -1456px 0; }

.flags.cn {
  background-position: -1472px 0; }

.flags.cn_faded {
  background-position: -1488px 0; }

.flags.co {
  background-position: -1504px 0; }

.flags.co_faded {
  background-position: -1520px 0; }

.flags.cr {
  background-position: -1536px 0; }

.flags.cr_faded {
  background-position: -1552px 0; }

.flags.cs {
  background-position: -1568px 0; }

.flags.cs_faded {
  background-position: -1584px 0; }

.flags.cu {
  background-position: -1600px 0; }

.flags.cu_faded {
  background-position: -1616px 0; }

.flags.cv {
  background-position: -1632px 0; }

.flags.cv_faded {
  background-position: -1648px 0; }

.flags.cx {
  background-position: -1664px 0; }

.flags.cx_faded {
  background-position: -1680px 0; }

.flags.cy {
  background-position: -1696px 0; }

.flags.cy_faded {
  background-position: -1712px 0; }

.flags.cz {
  background-position: -1728px 0; }

.flags.cz_faded {
  background-position: -1744px 0; }

.flags.de {
  background-position: -1760px 0; }

.flags.de_faded {
  background-position: -1776px 0; }

.flags.dj {
  background-position: -1792px 0; }

.flags.dj_faded {
  background-position: -1808px 0; }

.flags.dk {
  background-position: -1824px 0; }

.flags.dk_faded {
  background-position: -1840px 0; }

.flags.dm {
  background-position: -1856px 0; }

.flags.dm_faded {
  background-position: -1872px 0; }

.flags.do {
  background-position: -1888px 0; }

.flags.do_faded {
  background-position: -1904px 0; }

.flags.dz {
  background-position: -1920px 0; }

.flags.dz_faded {
  background-position: -1936px 0; }

.flags.ec {
  background-position: -1952px 0; }

.flags.ec_faded {
  background-position: -1968px 0; }

.flags.ee {
  background-position: -1984px 0; }

.flags.ee_faded {
  background-position: -2000px 0; }

.flags.eg {
  background-position: -2016px 0; }

.flags.eg_faded {
  background-position: -2032px 0; }

.flags.eh {
  background-position: -2048px 0; }

.flags.eh_faded {
  background-position: -2064px 0; }

.flags.en {
  background-position: -2496px 0; }

.flags.en_faded {
  background-position: -2512px 0; }

.flags.england {
  background-position: -2080px 0; }

.flags.england_faded {
  background-position: -2096px 0; }

.flags.er {
  background-position: -2112px 0; }

.flags.er_faded {
  background-position: -2128px 0; }

.flags.es {
  background-position: -2144px 0; }

.flags.es_faded {
  background-position: -2160px 0; }

.flags.et {
  background-position: -2176px 0; }

.flags.et_faded {
  background-position: -2192px 0; }

.flags.europeanunion {
  background-position: -2208px 0; }

.flags.europeanunion_faded {
  background-position: -2224px 0; }

.flags.fam {
  background-position: -2240px 0; }

.flags.fam_faded {
  background-position: -2256px 0; }

.flags.fi {
  background-position: -2272px 0; }

.flags.fi_faded {
  background-position: -2288px 0; }

.flags.fj {
  background-position: -2304px 0; }

.flags.fj_faded {
  background-position: -2320px 0; }

.flags.fk {
  background-position: -2336px 0; }

.flags.fk_faded {
  background-position: -2352px 0; }

.flags.fm {
  background-position: -2368px 0; }

.flags.fm_faded {
  background-position: -2384px 0; }

.flags.fo {
  background-position: -2400px 0; }

.flags.fo_faded {
  background-position: -2416px 0; }

.flags.fr {
  background-position: -2432px 0; }

.flags.fr_faded {
  background-position: -2448px 0; }

.flags.ga {
  background-position: -2464px 0; }

.flags.ga_faded {
  background-position: -2480px 0; }

.flags.gb {
  background-position: -2496px 0; }

.flags.gb_faded {
  background-position: -2512px 0; }

.flags.gd {
  background-position: -2528px 0; }

.flags.gd_faded {
  background-position: -2544px 0; }

.flags.ge {
  background-position: -2560px 0; }

.flags.ge_faded {
  background-position: -2576px 0; }

.flags.gf {
  background-position: -2592px 0; }

.flags.gf_faded {
  background-position: -2608px 0; }

.flags.gh {
  background-position: -2624px 0; }

.flags.gh_faded {
  background-position: -2640px 0; }

.flags.gi {
  background-position: -2656px 0; }

.flags.gi_faded {
  background-position: -2672px 0; }

.flags.gl {
  background-position: -2688px 0; }

.flags.gl_faded {
  background-position: -2704px 0; }

.flags.gm {
  background-position: -2720px 0; }

.flags.gm_faded {
  background-position: -2736px 0; }

.flags.gn {
  background-position: -2752px 0; }

.flags.gn_faded {
  background-position: -2768px 0; }

.flags.gp {
  background-position: -2784px 0; }

.flags.gp_faded {
  background-position: -2800px 0; }

.flags.gq {
  background-position: -2816px 0; }

.flags.gq_faded {
  background-position: -2832px 0; }

.flags.gr {
  background-position: -2848px 0; }

.flags.gr_faded {
  background-position: -2864px 0; }

.flags.gs {
  background-position: -2880px 0; }

.flags.gs_faded {
  background-position: -2896px 0; }

.flags.gt {
  background-position: -2912px 0; }

.flags.gt_faded {
  background-position: -2928px 0; }

.flags.gu {
  background-position: -2944px 0; }

.flags.gu_faded {
  background-position: -2960px 0; }

.flags.gw {
  background-position: -2976px 0; }

.flags.gw_faded {
  background-position: -2992px 0; }

.flags.gy {
  background-position: -3008px 0; }

.flags.gy_faded {
  background-position: -3024px 0; }

.flags.hk {
  background-position: -3040px 0; }

.flags.hk_faded {
  background-position: -3056px 0; }

.flags.hm {
  background-position: -3072px 0; }

.flags.hm_faded {
  background-position: -3088px 0; }

.flags.hn {
  background-position: -3104px 0; }

.flags.hn_faded {
  background-position: -3120px 0; }

.flags.hr {
  background-position: -3136px 0; }

.flags.hr_faded {
  background-position: -3152px 0; }

.flags.ht {
  background-position: -3168px 0; }

.flags.ht_faded {
  background-position: -3184px 0; }

.flags.hu {
  background-position: -3200px 0; }

.flags.hu_faded {
  background-position: -3216px 0; }

.flags.id {
  background-position: -3232px 0; }

.flags.id_faded {
  background-position: -3248px 0; }

.flags.ie {
  background-position: -3264px 0; }

.flags.ie_faded {
  background-position: -3280px 0; }

.flags.il {
  background-position: -3296px 0; }

.flags.il_faded {
  background-position: -3312px 0; }

.flags.in {
  background-position: -3328px 0; }

.flags.in_faded {
  background-position: -3344px 0; }

.flags.io {
  background-position: -3360px 0; }

.flags.io_faded {
  background-position: -3376px 0; }

.flags.iq {
  background-position: -3392px 0; }

.flags.iq_faded {
  background-position: -3408px 0; }

.flags.ir {
  background-position: -3424px 0; }

.flags.ir_faded {
  background-position: -3440px 0; }

.flags.is {
  background-position: -3456px 0; }

.flags.is_faded {
  background-position: -3472px 0; }

.flags.it {
  background-position: -3488px 0; }

.flags.it_faded {
  background-position: -3504px 0; }

.flags.je {
  background-position: -3520px 0; }

.flags.je_faded {
  background-position: -3536px 0; }

.flags.jersey {
  background-position: -3552px 0; }

.flags.jersey_faded {
  background-position: -3568px 0; }

.flags.jm {
  background-position: -3584px 0; }

.flags.jm_faded {
  background-position: -3600px 0; }

.flags.jo {
  background-position: -3616px 0; }

.flags.jo_faded {
  background-position: -3632px 0; }

.flags.jp {
  background-position: -3648px 0; }

.flags.jp_faded {
  background-position: -3664px 0; }

.flags.ke {
  background-position: -3680px 0; }

.flags.ke_faded {
  background-position: -3696px 0; }

.flags.kg {
  background-position: -3712px 0; }

.flags.kg_faded {
  background-position: -3728px 0; }

.flags.kh {
  background-position: -3744px 0; }

.flags.kh_faded {
  background-position: -3760px 0; }

.flags.ki {
  background-position: -3776px 0; }

.flags.ki_faded {
  background-position: -3792px 0; }

.flags.km {
  background-position: -3808px 0; }

.flags.km_faded {
  background-position: -3824px 0; }

.flags.kn {
  background-position: -3840px 0; }

.flags.kn_faded {
  background-position: -3856px 0; }

.flags.kp {
  background-position: -3872px 0; }

.flags.kp_faded {
  background-position: -3888px 0; }

.flags.kr {
  background-position: -3904px 0; }

.flags.kr_faded {
  background-position: -3920px 0; }

.flags.kw {
  background-position: -3936px 0; }

.flags.kw_faded {
  background-position: -3952px 0; }

.flags.ky {
  background-position: -3968px 0; }

.flags.ky_faded {
  background-position: -3984px 0; }

.flags.kz {
  background-position: -4000px 0; }

.flags.kz_faded {
  background-position: -4016px 0; }

.flags.la {
  background-position: -4032px 0; }

.flags.la_faded {
  background-position: -4048px 0; }

.flags.lb {
  background-position: -4064px 0; }

.flags.lb_faded {
  background-position: -4080px 0; }

.flags.lc {
  background-position: -4096px 0; }

.flags.lc_faded {
  background-position: -4112px 0; }

.flags.li {
  background-position: -4128px 0; }

.flags.li_faded {
  background-position: -4144px 0; }

.flags.lk {
  background-position: -4160px 0; }

.flags.lk_faded {
  background-position: -4176px 0; }

.flags.lr {
  background-position: -4192px 0; }

.flags.lr_faded {
  background-position: -4208px 0; }

.flags.ls {
  background-position: -4224px 0; }

.flags.ls_faded {
  background-position: -4240px 0; }

.flags.lt {
  background-position: -4256px 0; }

.flags.lt_faded {
  background-position: -4272px 0; }

.flags.lu {
  background-position: -4288px 0; }

.flags.lu_faded {
  background-position: -4304px 0; }

.flags.lv {
  background-position: -4320px 0; }

.flags.lv_faded {
  background-position: -4336px 0; }

.flags.ly {
  background-position: -4352px 0; }

.flags.ly_faded {
  background-position: -4368px 0; }

.flags.ma {
  background-position: -4384px 0; }

.flags.ma_faded {
  background-position: -4400px 0; }

.flags.mc {
  background-position: -4416px 0; }

.flags.mc_faded {
  background-position: -4432px 0; }

.flags.md {
  background-position: -4448px 0; }

.flags.md_faded {
  background-position: -4464px 0; }

.flags.me {
  background-position: -4480px 0; }

.flags.me_faded {
  background-position: -4496px 0; }

.flags.mg {
  background-position: -4512px 0; }

.flags.mg_faded {
  background-position: -4528px 0; }

.flags.mh {
  background-position: -4544px 0; }

.flags.mh_faded {
  background-position: -4560px 0; }

.flags.mk {
  background-position: -4576px 0; }

.flags.mk_faded {
  background-position: -4592px 0; }

.flags.ml {
  background-position: -4608px 0; }

.flags.ml_faded {
  background-position: -4624px 0; }

.flags.mm {
  background-position: -4640px 0; }

.flags.mm_faded {
  background-position: -4656px 0; }

.flags.mn {
  background-position: -4672px 0; }

.flags.mn_faded {
  background-position: -4688px 0; }

.flags.mo {
  background-position: -4704px 0; }

.flags.mo_faded {
  background-position: -4720px 0; }

.flags.mp {
  background-position: -4736px 0; }

.flags.mp_faded {
  background-position: -4752px 0; }

.flags.mq {
  background-position: -4768px 0; }

.flags.mq_faded {
  background-position: -4784px 0; }

.flags.mr {
  background-position: -4800px 0; }

.flags.mr_faded {
  background-position: -4816px 0; }

.flags.ms {
  background-position: -4832px 0; }

.flags.ms_faded {
  background-position: -4848px 0; }

.flags.mt {
  background-position: -4864px 0; }

.flags.mt_faded {
  background-position: -4880px 0; }

.flags.mu {
  background-position: -4896px 0; }

.flags.mu_faded {
  background-position: -4912px 0; }

.flags.mv {
  background-position: -4928px 0; }

.flags.mv_faded {
  background-position: -4944px 0; }

.flags.mw {
  background-position: -4960px 0; }

.flags.mw_faded {
  background-position: -4976px 0; }

.flags.mx {
  background-position: -4992px 0; }

.flags.mx_faded {
  background-position: -5008px 0; }

.flags.my {
  background-position: -5024px 0; }

.flags.my_faded {
  background-position: -5040px 0; }

.flags.mz {
  background-position: -5056px 0; }

.flags.mz_faded {
  background-position: -5072px 0; }

.flags.na {
  background-position: -5088px 0; }

.flags.na_faded {
  background-position: -5104px 0; }

.flags.nc {
  background-position: -5120px 0; }

.flags.nc_faded {
  background-position: -5136px 0; }

.flags.ne {
  background-position: -5152px 0; }

.flags.ne_faded {
  background-position: -5168px 0; }

.flags.nf {
  background-position: -5184px 0; }

.flags.nf_faded {
  background-position: -5200px 0; }

.flags.ng {
  background-position: -5216px 0; }

.flags.ng_faded {
  background-position: -5232px 0; }

.flags.ni {
  background-position: -5248px 0; }

.flags.ni_faded {
  background-position: -5264px 0; }

.flags.nl {
  background-position: -5280px 0; }

.flags.nl_faded {
  background-position: -5296px 0; }

.flags.no {
  background-position: -5312px 0; }

.flags.no_faded {
  background-position: -5328px 0; }

.flags.np {
  background-position: -5344px 0; }

.flags.np_faded {
  background-position: -5360px 0; }

.flags.nr {
  background-position: -5376px 0; }

.flags.nr_faded {
  background-position: -5392px 0; }

.flags.nu {
  background-position: -5408px 0; }

.flags.nu_faded {
  background-position: -5424px 0; }

.flags.nz {
  background-position: -5440px 0; }

.flags.nz_faded {
  background-position: -5456px 0; }

.flags.om {
  background-position: -5472px 0; }

.flags.om_faded {
  background-position: -5488px 0; }

.flags.pa {
  background-position: -5504px 0; }

.flags.pa_faded {
  background-position: -5520px 0; }

.flags.pe {
  background-position: -5536px 0; }

.flags.pe_faded {
  background-position: -5552px 0; }

.flags.pf {
  background-position: -5568px 0; }

.flags.pf_faded {
  background-position: -5584px 0; }

.flags.pg {
  background-position: -5600px 0; }

.flags.pg_faded {
  background-position: -5616px 0; }

.flags.ph {
  background-position: -5632px 0; }

.flags.ph_faded {
  background-position: -5648px 0; }

.flags.pk {
  background-position: -5664px 0; }

.flags.pk_faded {
  background-position: -5680px 0; }

.flags.pl {
  background-position: -5696px 0; }

.flags.pl_faded {
  background-position: -5712px 0; }

.flags.pm {
  background-position: -5728px 0; }

.flags.pm_faded {
  background-position: -5744px 0; }

.flags.pn {
  background-position: -5760px 0; }

.flags.pn_faded {
  background-position: -5776px 0; }

.flags.pr {
  background-position: -5792px 0; }

.flags.pr_faded {
  background-position: -5808px 0; }

.flags.ps {
  background-position: -5824px 0; }

.flags.ps_faded {
  background-position: -5840px 0; }

.flags.pt {
  background-position: -5856px 0; }

.flags.pt_faded {
  background-position: -5872px 0; }

.flags.pw {
  background-position: -5888px 0; }

.flags.pw_faded {
  background-position: -5904px 0; }

.flags.py {
  background-position: -5920px 0; }

.flags.py_faded {
  background-position: -5936px 0; }

.flags.qa {
  background-position: -5952px 0; }

.flags.qa_faded {
  background-position: -5968px 0; }

.flags.re {
  background-position: -5984px 0; }

.flags.re_faded {
  background-position: -6000px 0; }

.flags.ro {
  background-position: -6016px 0; }

.flags.ro_faded {
  background-position: -6032px 0; }

.flags.rs {
  background-position: -6048px 0; }

.flags.rs_faded {
  background-position: -6064px 0; }

.flags.ru {
  background-position: -6080px 0; }

.flags.ru_faded {
  background-position: -6096px 0; }

.flags.rw {
  background-position: -6112px 0; }

.flags.rw_faded {
  background-position: -6128px 0; }

.flags.sa {
  background-position: -6144px 0; }

.flags.sa_faded {
  background-position: -6160px 0; }

.flags.sb {
  background-position: -6176px 0; }

.flags.sb_faded {
  background-position: -6192px 0; }

.flags.sc {
  background-position: -6208px 0; }

.flags.sc_faded {
  background-position: -6224px 0; }

.flags.scotland {
  background-position: -6240px 0; }

.flags.scotland_faded {
  background-position: -6256px 0; }

.flags.sd {
  background-position: -6272px 0; }

.flags.sd_faded {
  background-position: -6288px 0; }

.flags.se {
  background-position: -6304px 0; }

.flags.se_faded {
  background-position: -6320px 0; }

.flags.sg {
  background-position: -6336px 0; }

.flags.sg_faded {
  background-position: -6352px 0; }

.flags.sh {
  background-position: -6368px 0; }

.flags.sh_faded {
  background-position: -6384px 0; }

.flags.si {
  background-position: -6400px 0; }

.flags.si_faded {
  background-position: -6416px 0; }

.flags.sj {
  background-position: -6432px 0; }

.flags.sj_faded {
  background-position: -6448px 0; }

.flags.sk {
  background-position: -6464px 0; }

.flags.sk_faded {
  background-position: -6480px 0; }

.flags.sl {
  background-position: -6496px 0; }

.flags.sl_faded {
  background-position: -6512px 0; }

.flags.sm {
  background-position: -6528px 0; }

.flags.sm_faded {
  background-position: -6544px 0; }

.flags.sn {
  background-position: -6560px 0; }

.flags.sn_faded {
  background-position: -6576px 0; }

.flags.so {
  background-position: -6592px 0; }

.flags.so_faded {
  background-position: -6608px 0; }

.flags.sr {
  background-position: -6624px 0; }

.flags.sr_faded {
  background-position: -6640px 0; }

.flags.st {
  background-position: -6656px 0; }

.flags.st_faded {
  background-position: -6672px 0; }

.flags.sv {
  background-position: -6688px 0; }

.flags.sv_faded {
  background-position: -6704px 0; }

.flags.sy {
  background-position: -6720px 0; }

.flags.sy_faded {
  background-position: -6736px 0; }

.flags.sz {
  background-position: -6752px 0; }

.flags.sz_faded {
  background-position: -6768px 0; }

.flags.tc {
  background-position: -6784px 0; }

.flags.tc_faded {
  background-position: -6800px 0; }

.flags.td {
  background-position: -6816px 0; }

.flags.td_faded {
  background-position: -6832px 0; }

.flags.tf {
  background-position: -6848px 0; }

.flags.tf_faded {
  background-position: -6864px 0; }

.flags.tg {
  background-position: -6880px 0; }

.flags.tg_faded {
  background-position: -6896px 0; }

.flags.th {
  background-position: -6912px 0; }

.flags.th_faded {
  background-position: -6928px 0; }

.flags.tj {
  background-position: -6944px 0; }

.flags.tj_faded {
  background-position: -6960px 0; }

.flags.tk {
  background-position: -6976px 0; }

.flags.tk_faded {
  background-position: -6992px 0; }

.flags.tl {
  background-position: -7008px 0; }

.flags.tl_faded {
  background-position: -7024px 0; }

.flags.tm {
  background-position: -7040px 0; }

.flags.tm_faded {
  background-position: -7056px 0; }

.flags.tn {
  background-position: -7072px 0; }

.flags.tn_faded {
  background-position: -7088px 0; }

.flags.to {
  background-position: -7104px 0; }

.flags.to_faded {
  background-position: -7120px 0; }

.flags.tr {
  background-position: -7136px 0; }

.flags.tr_faded {
  background-position: -7152px 0; }

.flags.tt {
  background-position: -7168px 0; }

.flags.tt_faded {
  background-position: -7184px 0; }

.flags.tv {
  background-position: -7200px 0; }

.flags.tv_faded {
  background-position: -7216px 0; }

.flags.tw {
  background-position: -7232px 0; }

.flags.tw_faded {
  background-position: -7248px 0; }

.flags.tz {
  background-position: -7264px 0; }

.flags.tz_faded {
  background-position: -7280px 0; }

.flags.ua {
  background-position: -7296px 0; }

.flags.ua_faded {
  background-position: -7312px 0; }

.flags.ug {
  background-position: -7328px 0; }

.flags.ug_faded {
  background-position: -7344px 0; }

.flags.um {
  background-position: -7360px 0; }

.flags.um_faded {
  background-position: -7376px 0; }

.flags.us {
  background-position: -7392px 0; }

.flags.us_faded {
  background-position: -7408px 0; }

.flags.uy {
  background-position: -7424px 0; }

.flags.uy_faded {
  background-position: -7440px 0; }

.flags.uz {
  background-position: -7456px 0; }

.flags.uz_faded {
  background-position: -7472px 0; }

.flags.va {
  background-position: -7488px 0; }

.flags.va_faded {
  background-position: -7504px 0; }

.flags.vc {
  background-position: -7520px 0; }

.flags.vc_faded {
  background-position: -7536px 0; }

.flags.ve {
  background-position: -7552px 0; }

.flags.ve_faded {
  background-position: -7568px 0; }

.flags.vg {
  background-position: -7584px 0; }

.flags.vg_faded {
  background-position: -7600px 0; }

.flags.vi {
  background-position: -7616px 0; }

.flags.vi_faded {
  background-position: -7632px 0; }

.flags.vn {
  background-position: -7648px 0; }

.flags.vn_faded {
  background-position: -7664px 0; }

.flags.vu {
  background-position: -7680px 0; }

.flags.vu_faded {
  background-position: -7696px 0; }

.flags.wales {
  background-position: -7712px 0; }

.flags.wales_faded {
  background-position: -7728px 0; }

.flags.wf {
  background-position: -7744px 0; }

.flags.wf_faded {
  background-position: -7760px 0; }

.flags.ws {
  background-position: -7776px 0; }

.flags.ws_faded {
  background-position: -7792px 0; }

.flags.ye {
  background-position: -7808px 0; }

.flags.ye_faded {
  background-position: -7824px 0; }

.flags.yt {
  background-position: -7840px 0; }

.flags.yt_faded {
  background-position: -7856px 0; }

.flags.za {
  background-position: -7872px 0; }

.flags.za_faded {
  background-position: -7888px 0; }

.flags.zm {
  background-position: -7904px 0; }

.flags.zm_faded {
  background-position: -7920px 0; }

.flags.zw {
  background-position: -7936px 0; }

.flags.zw_faded {
  background-position: -7952px 0; }

/* --------------- shared variable + function ---------------- */
/* ----------------------------------------------------------- */
/* list of all mixins */
/* list of all extends */
.ims-course-progress--sm .ims-progress-bar-info, .ims-course-progress--md .ims-progress-bar-info, .ims-course-progress--lg .ims-progress-bar-info {
  position: absolute;
  margin-top: 0.3rem;
  flex: 1;
  min-width: 0; }
  .ims-course-progress--sm .ims-progress-bar-info .ims-progressbar-text, .ims-course-progress--md .ims-progress-bar-info .ims-progressbar-text, .ims-course-progress--lg .ims-progress-bar-info .ims-progressbar-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 10rem; }
    .ims-course-progress--sm .ims-progress-bar-info .ims-progressbar-text span.ims-progressbar-label, .ims-course-progress--md .ims-progress-bar-info .ims-progressbar-text span.ims-progressbar-label, .ims-course-progress--lg .ims-progress-bar-info .ims-progressbar-text span.ims-progressbar-label {
      line-height: 1rem;
      margin-left: 0.4rem; }

.k-numerictextbox .k-numeric-wrap {
  height: 2.3125rem; }

.ims-label, input,
select,
.k-dropdown {
  font-size: var(--ims-font__size--100) !important; }

.ims-label {
  font-weight: 600 !important;
  margin-bottom: var(--ims-spacing--10); }

@media (max-width: 575px) {
  .ims-course-progress--sm .ims-progress-bar-info .ims-progressbar-text, .ims-course-progress--md .ims-progress-bar-info .ims-progressbar-text, .ims-course-progress--lg .ims-progress-bar-info .ims-progressbar-text {
    width: 10rem; } }

.formulate-input[data-type="checkbox"]:not([data-classification="group"]) .formulate-input-wrapper .formulate-input-label,
.formulate-input[data-type="radio"]:not([data-classification="group"]) .formulate-input-wrapper .formulate-input-label, .formulate-input[data-classification="select"] select, .formulate-input-help, .form-element-container .ims-checkbox-container {
  font-weight: var(--ims-font__weight--medium); }

label, .tagsDisplayWrapper .tagsDisplayLabel, .formulate-input-label, .form-element-container label {
  margin-bottom: var(--ims-spacing--10);
  font-size: var(--ims-font__size--100);
  line-height: var(--ims-font__line-height--label);
  font-weight: var(--ims-font__weight--semibold); }
  label.formulate-input-label--after, .tagsDisplayWrapper .formulate-input-label--after.tagsDisplayLabel, .formulate-input-label--after.formulate-input-label {
    margin-bottom: var(--ims-spacing--0); }

.ims-error-message,
.formulate-input-error,
.formulate-form-error {
  color: var(--bs-danger);
  font-size: var(--ims-font__size--100);
  line-height: var(--ims-font__line-height--label);
  font-weight: var(--ims-font__weight--medium); }
  .ims-error-message:before,
  .formulate-input-error:before,
  .formulate-form-error:before {
    font-family: fontello;
    content: '\e870';
    margin-right: 0.2rem; }

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="date"]:focus,
input.k-input:focus,
textarea:focus-visible,
select:focus,
.k-textbox.k-valid:focus,
input.k-textbox:focus,
.k-multiselect-wrap:focus, .formulate-input-element--customComboBox .k-combobox.k-state-focused,
.formulate-input-element--customComboBox .k-combobox .k-state-active {
  outline: 0.125rem solid transparent;
  outline-offset: 0.125rem;
  transition: box-shadow 200ms;
  box-shadow: 0px 0px 0px 0.125rem rgba(70, 140, 220, 0.5) !important; }

.text-truncate.white-space-normal {
  white-space: normal !important; }

.ims-text-truncate {
  text-overflow: ellipsis;
  /* Needed to make it work */
  overflow: hidden;
  white-space: nowrap; }

.ims-line-clamp {
  /* This is for Internet Explorer only. */ }
  .ims-line-clamp--3 {
    overflow: hidden;
    max-height: calc(3 * 25.6px);
    /* 3 x line-height */ }
  .ims-line-clamp--2 {
    overflow: hidden;
    max-height: calc(2 * 25.6px);
    /* 2 x line-height */ }

/* Chrome, Edge, FireFox, Safari */
@supports (-webkit-line-clamp: 3) {
  .ims-line-clamp--3 {
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: initial;
    /* Reset max-height which was set for Internet Explorer */ } }

/* Chrome, Edge, FireFox, Safari */
@supports (-webkit-line-clamp: 2) {
  .ims-line-clamp--2 {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: initial;
    /* Reset max-height which was set for Internet Explorer */ } }

.ims-last-child-mb-0 *:last-child {
  margin-bottom: var(--ims-spacing--0) !important; }

.ims-last-child-pb-0 *:last-child {
  padding-bottom: var(--ims-spacing--0) !important; }

.ims-last-child-mb-pb-0 *:last-child {
  margin-bottom: var(--ims-spacing--0) !important;
  padding-bottom: var(--ims-spacing--0) !important; }

.ims-reset-last-child-py:last-child {
  padding-bottom: var(--ims-spacing--0) !important; }
  .ims-reset-last-child-py:last-child div div:last-child {
    padding-bottom: var(--ims-spacing--0) !important; }

.ims-input-with-icon .formulate-input-wrapper .formulate-input-element {
  display: flex; }
  .ims-input-with-icon .formulate-input-wrapper .formulate-input-element .ims-input-icon-wrapper {
    display: flex;
    align-content: center;
    background-color: var(--bs-surface-high);
    padding: var(--ims-spacing--20) var(--ims-spacing--20);
    cursor: pointer; }
    .ims-input-with-icon .formulate-input-wrapper .formulate-input-element .ims-input-icon-wrapper span {
      align-self: center; }

.ims-input-with-no-border .formulate-input-wrapper .formulate-input-element textarea {
  border: none; }

.ims-input-font-size-1rem .formulate-input-wrapper .formulate-input-element textarea {
  font-size: var(--ims-font__size--200); }

.ims-textarea-custom-disabled-hover .formulate-input-wrapper .formulate-input-element textarea:hover {
  background-color: var(--bs-light-bg-subtle) !important; }

.right {
  float: right;
  margin-left: 5px; }

.ims-no-outline {
  outline: none !important; }

.w-30 {
  width: 30% !important; }

.w-33 {
  width: 33.33% !important; }

.w-40 {
  width: 40% !important; }

.w-60 {
  width: 60% !important; }

.w-85 {
  width: 85% !important; }

.w-95 {
  width: 95% !important; }

.alert-dismissible .close {
  z-index: 0; }

.alert-dismissible button:hover {
  background-color: transparent; }

.hide-k-upload-status .k-upload-status-total {
  visibility: hidden; }

@media all and (-ms-high-contrast: none) {
  .global-search-container .search-bar-expand {
    top: 0; }
  .ims-scheduler-header .week-block:first-of-type,
  .ims-scheduler-first {
    margin-left: 15.625rem; }
  .ims-scheduler-header > * {
    overflow: hidden; }
  .ims-scheduler-legend,
  .ims-scheduler-title {
    position: absolute; } }

.ims-shape-extra-small {
  border-radius: var(--ims-sys-shape-extra-small); }

.ims-shape-small {
  border-radius: var(--ims-sys-shape-small); }

.ims-shape-medium {
  border-radius: var(--ims-sys-shape-medium); }

.ims-shape-large {
  border-radius: var(--ims-sys-shape-large); }

.ims-shape-extra-large {
  border-radius: var(--ims-sys-shape-extra-large); }

.ims-shape-full {
  border-radius: var(--ims-sys-shape-full); }

.ims-shape-extra-large-top {
  border-radius: var(--ims-sys-shape-extra-large-top); }

.ims-shape-large-top {
  border-radius: var(--ims-sys-shape-large-top); }

.ims-shape-large-start {
  border-radius: var(--ims-sys-shape-large-start); }

.ims-shape-large-end {
  border-radius: var(--ims-sys-shape-large-end); }

.ims-shape-extra-small-top {
  border-radius: var(--ims-sys-shape-extra-small-top); }

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; }

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; }

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; }

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; }

.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5; }

.line-clamp-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6; }

.line-clamp-none {
  overflow: visible;
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-line-clamp: none; }

/* open-sans-regular - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  src: url("/content/themes/common/webfonts/open-sans-v34-regular.eot");
  /* IE9 Compat Modes */
  src: url("/content/themes/common/webfonts/open-sans-v34-regular.eot?#iefix") format("embedded-opentype"), url("/content/themes/common/webfonts/open-sans-v34-regular.woff2") format("woff2"), url("/content/themes/common/webfonts/open-sans-v34-regular.woff") format("woff"), url("/content/themes/common/webfonts/open-sans-v34-regular.ttf") format("truetype"), url("/content/themes/common/webfonts/open-sans-v34-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Open Sans';
  src: url("/content/themes/common/webfonts/open-sans-v34-italic.eot");
  /* IE9 Compat Modes */
  src: url("/content/themes/common/webfonts/open-sans-v34-italic.eot?#iefix") format("embedded-opentype"), url("/content/themes/common/webfonts/open-sans-v34-italic.woff2") format("woff2"), url("/content/themes/common/webfonts/open-sans-v34-italic.woff") format("woff"), url("/content/themes/common/webfonts/open-sans-v34-italic.ttf") format("truetype"), url("/content/themes/common/webfonts/open-sans-v34-italic.svg#OpenSans") format("svg");
  /* Legacy iOS */
  font-style: italic;
  font-weight: 400; }

@font-face {
  font-family: 'Open Sans';
  src: url("/content/themes/common/webfonts/open-sans-v34-300.eot");
  /* IE9 Compat Modes */
  src: url("/content/themes/common/webfonts/open-sans-v34-300.eot?#iefix") format("embedded-opentype"), url("/content/themes/common/webfonts/open-sans-v34-300.woff2") format("woff2"), url("/content/themes/common/webfonts/open-sans-v34-300.woff") format("woff"), url("/content/themes/common/webfonts/open-sans-v34-300.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'Open Sans';
  src: url("/content/themes/common/webfonts/open-sans-v34-300italic.eot");
  /* IE9 Compat Modes */
  src: url("/content/themes/common/webfonts/open-sans-v34-300italic.eot?#iefix") format("embedded-opentype"), url("/content/themes/common/webfonts/open-sans-v34-300italic.woff2") format("woff2"), url("/content/themes/common/webfonts/open-sans-v34-300italic.woff") format("woff"), url("/content/themes/common/webfonts/open-sans-v34-300italic.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: italic;
  font-weight: 300; }

@font-face {
  font-family: 'Open Sans';
  src: url("/content/themes/common/webfonts/open-sans-v34-600.eot");
  /* IE9 Compat Modes */
  src: url("/content/themes/common/webfonts/open-sans-v34-600.eot?#iefix") format("embedded-opentype"), url("/content/themes/common/webfonts/open-sans-v34-600.woff2") format("woff2"), url("/content/themes/common/webfonts/open-sans-v34-600.woff") format("woff"), url("/content/themes/common/webfonts/open-sans-v34-600.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: 'Open Sans';
  src: url("/content/themes/common/webfonts/open-sans-v34-600italic.eot");
  /* IE9 Compat Modes */
  src: url("/content/themes/common/webfonts/open-sans-v34-600italic.eot?#iefix") format("embedded-opentype"), url("/content/themes/common/webfonts/open-sans-v34-600italic.woff2") format("woff2"), url("/content/themes/common/webfonts/open-sans-v34-600italic.woff") format("woff"), url("/content/themes/common/webfonts/open-sans-v34-600italic.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: italic;
  font-weight: 600; }

@font-face {
  font-family: 'Open Sans';
  src: url("/content/themes/common/webfonts/open-sans-v34-700.eot");
  /* IE9 Compat Modes */
  src: url("/content/themes/common/webfonts/open-sans-v34-700.eot?#iefix") format("embedded-opentype"), url("/content/themes/common/webfonts/open-sans-v34-700.woff2") format("woff2"), url("/content/themes/common/webfonts/open-sans-v34-700.woff") format("woff"), url("/content/themes/common/webfonts/open-sans-v34-700.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: 'Open Sans';
  src: url("./content/themes/common/webfonts/open-sans-v34-700italic.eot");
  /* IE9 Compat Modes */
  src: url("/content/themes/common/webfonts/open-sans-v34-700italic.eot?#iefix") format("embedded-opentype"), url("/content/themes/common/webfonts/open-sans-v34-700italic.woff2") format("woff2"), url("/content/themes/common/webfonts/open-sans-v34-700italic.woff") format("woff"), url("/content/themes/common/webfonts/open-sans-v34-700italic.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: italic;
  font-weight: 700; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.25;
  font-weight: 700; }

th {
  font-weight: var(--ims-font__weight--semibold); }

.ims-error-message {
  color: var(--bs-danger); }

.ims-success-message {
  color: var(--bs-green); }

.ims-orangeHighlight-message {
  color: var(--bs-orange); }

.ims-small-font,
.custom-text-muted {
  font-size: var(--ims-font__size--100); }

.ims-info-highlight-text {
  color: var(--bs-primary); }

a:not(.btn),
.ims-link {
  color: var(--bs-primary);
  -webkit-text-decoration: var(--ims-link-decoration);
          text-decoration: var(--ims-link-decoration); }
  a:not(.btn):hover:not([disabled]),
  .ims-link:hover:not([disabled]) {
    -webkit-text-decoration: var(--ims-link-decoration-hover);
            text-decoration: var(--ims-link-decoration-hover);
    cursor: pointer; }
  a:not(.btn)[disabled], a:not(.btn)[disabled]:hover,
  .ims-link[disabled],
  .ims-link[disabled]:hover {
    color: var(--bs-border-color);
    text-decoration: none; }
  a:not(.btn).nav-link,
  .ims-link.nav-link {
    text-decoration: none;
    color: var(--bs-body-color); }
  a:not(.btn).nav-link, a:not(.btn).nav-link:hover, a:not(.btn).dropdown-item:hover,
  .ims-link.nav-link,
  .ims-link.nav-link:hover,
  .ims-link.dropdown-item:hover {
    text-decoration: none; }

label {
  margin: 0; }

.category-label {
  font-size: var(--ims-font__size--80); }

.label-filled {
  padding: 0.1875rem var(--ims-spacing--25);
  border-radius: 0.3125rem;
  background-color: var(--bs-border-color);
  color: var(--bs-on-primary); }

.ims-label-badge {
  border-radius: 3.125rem;
  padding: var(--ims-spacing--0) var(--ims-spacing--15);
  font-size: var(--ims-font__size--60);
  font-weight: 800;
  background-color: var(--bs-border-color);
  color: var(--bs-on-primary); }

.choice-input-information {
  margin-top: 0.6875rem; }

.input-information {
  margin-top: 0.1875rem; }

.ims-hyperlink {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.ims-overflow-break-item {
  overflow-wrap: anywhere; }

.ims-user-text li {
  list-style: initial; }

.ims-user-text ol li {
  list-style: decimal; }

.ims-user-text ul,
.ims-user-text ol {
  padding-inline-start: 1.5625rem;
  margin-bottom: var(--ims-spacing--20); }

.ims-user-text p {
  margin-bottom: var(--ims-spacing--10); }

.label-filled-wSmoke {
  background-color: var(--bs-surface-high);
  color: var(--bs-secondary); }

.courses-list-title {
  background-color: var(--bs-white); }

.ims-read-only {
  opacity: 0.5 !important; }
  .ims-read-only a {
    pointer-events: none; }

.fs-8 {
  font-size: var(--ims-font__size--60); }

@media (max-width: 767px) {
  .ims-grid-description {
    margin-top: var(--ims-spacing--50); } }

html {
  font-size: 100%; }

body {
  font-size: var(--ims-body__font-size);
  font-weight: 400;
  font-family: var(--ims-body__font-family); }

h1 {
  font-family: var(--ims-h1__font-family);
  font-size: var(--ims-h1__font-size);
  text-transform: var(--ims-h1__text-transform); }

h2 {
  font-family: var(--ims-h2__font-family);
  font-size: var(--ims-h2__font-size);
  text-transform: var(--ims-h2__text-transform); }

h3 {
  font-family: var(--ims-h3__font-family);
  font-size: var(--ims-h3__font-size);
  text-transform: var(--ims-h3__text-transform); }

h4 {
  font-family: var(--ims-h4__font-family);
  font-size: var(--ims-h4__font-size);
  text-transform: var(--ims-h4__text-transform); }

h5 {
  font-family: var(--ims-h5__font-family);
  font-size: var(--ims-h5__font-size);
  text-transform: var(--ims-h5__text-transform); }

h6 {
  font-family: var(--ims-h6__font-family);
  font-size: var(--ims-h6__font-size);
  text-transform: var(--ims-h6__text-transform); }

p {
  margin: 0; }

ul {
  margin: 0;
  padding: 0; }

li {
  list-style: none; }

hr {
  margin: 0; }

.inline-container {
  flex-direction: row; }

.space-between {
  justify-content: space-between; }

.vertical-align-center {
  align-items: center; }

.k-multiselect-wrap li span {
  margin-right: 0; }

iframe {
  border: 0;
  padding: 0;
  margin: 0;
  flex-shrink: 0;
  max-width: 100%; }

.ims-map-frame {
  width: 32.5rem;
  height: 24.375rem; }

.ims-map-frame-container {
  padding-top: 56.25%; }
  .ims-map-frame-container iframe {
    width: calc(100% - 1.875rem);
    height: 100%;
    position: absolute;
    top: 0; }

.grid .g-row-start-1 {
  grid-row-start: 1; }

@media only screen and (max-width: 829px) {
  .ims-map-frame {
    width: 100%;
    height: calc(100% / 4 * 3); } }

/* https://docs.telerik.com/kendo-ui/third-party/using-kendo-with-twitter-bootstrap */
/* set a border-box model only to elements that need it */
.form-control,
.container,
.container-fluid,
.row,
.col-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  box-sizing: border-box; }

ul.list-menu {
  padding-left: var(--ims-spacing--0); }
  ul.list-menu li {
    list-style-type: none; }

/* main parent container */
.admin-area {
  padding-left: var(--ims-spacing--0); }
  .admin-area.ims-grid-view {
    overflow: hidden; }

/* AM 14.02.2018*/
/*################## Wichtig für die Buttons und content Headers color #########################*/
.k-content:not(.panelSection) .k-header {
  color: inherit; }
  .k-content:not(.panelSection) .k-header .k-link {
    color: inherit; }

#ProgramEventCalendar .k-header .k-link {
  color: var(--bs-on-primary); }

.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

/* top navi */
.page-header {
  height: 66px; }

/* single column wrapper views  */
.content-header {
  height: 50px; }
  .content-header .title-icon.image.ims-icon:before, .content-header .title-icon.image.ims-icon-arrow-left:before, .content-header .title-icon.image.ims-icon-arrow-right:before {
    font-size: var(--ims-font__size--400); }
  .content-header .title-text {
    font-size: var(--ims-font__size--400); }

/* left pane in splitter view */
.left-pane-actionbar {
  height: 35px;
  box-sizing: border-box; }

.left-pane-contentarea {
  min-height: 5rem;
  height: calc(100% - 4.375rem);
  box-sizing: border-box;
  overflow-y: auto; }

/* styles for left pane tool bar */
.left-pane .searchFilterArea {
  position: absolute;
  z-index: 1;
  left: 26.875rem;
  right: 0;
  width: 50%;
  overflow-x: hidden;
  overflow-y: visible;
  box-sizing: border-box;
  background-color: var(--bs-surface-high);
  box-shadow: 0 0.375rem 0.5rem 0.125rem rgba(var(--bs-body-color-rgb), 0.1); }
  .left-pane .searchFilterArea .dataColumn .k-multiselect {
    background-color: var(--bs-light-bg-subtle); }
  .left-pane .searchFilterArea hr {
    display: inline-block;
    height: 1px;
    margin: 20px 0;
    width: 100%; }

.left-pane .left-pane-header .title-icon.image.ims-icon:before, .left-pane .left-pane-header .title-icon.image.ims-icon-arrow-left:before, .left-pane .left-pane-header .title-icon.image.ims-icon-arrow-right:before {
  font-size: var(--ims-font__size--400); }

.left-pane .left-pane-header .title-text {
  font-size: var(--ims-font__size--400);
  vertical-align: middle; }

.left-pane .treelist-placeholder {
  height: calc(100% - 63px);
  position: relative;
  margin-bottom: 20px; }

/* general form styling */
div.detailsRow > div.dataColumn > input:not([type="radio"]):not([type="checkbox"]),
div.detailsRow > div.dataColumn > textarea,
div.detailsRow > div.dataColumn > span.k-widget.k-numerictextbox,
div.detailsRow > div.dataColumn > span.k-widget.k-combobox,
div.detailsRow > div.dataColumn > span.k-widget.k-dropdown,
div.detailsRow > div.dataColumn > span.k-widget.k-datepicker,
div.detailsRow > div.dataColumn > span.k-widget.k-datetimepicker,
div.form-group > span.k-widget.k-numerictextbox,
div.form-group > span.k-widget.k-dropdown,
div.form-group > span.k-widget.k-datepicker,
div.form-group > div.k-widget.k-multiselect,
.k-picker-wrap.k-state-default {
  border: 0.0625rem solid var(--bs-border-color);
  box-sizing: border-box;
  width: 100%;
  border-radius: var(--ims-form-input__border-radius); }

div.detailsRow > div.dataColumn > span.k-widget.k-dropdown :hover,
div.detailsRow > div.dataColumn > span.k-widget.k-datepicker :hover {
  background-color: rgba(var(--bs-surface-low-rgb), 0.3); }

div.detailsRow > div.dataColumn > span.k-widget.k-dropdown span.k-picker-wrap,
div.detailsRow > div.dataColumn > span.k-widget.k-dropdown span.k-dropdown-wrap,
div.detailsRow > div.dataColumn > span.k-widget.k-dropdown span.k-dropdown-wrap .k-input,
div.detailsRow > div.dataColumn > span.k-widget.k-datepicker span.k-picker-wrap,
div.detailsRow > div.dataColumn > span.k-widget.k-datepicker span.k-dropdown-wrap,
div.detailsRow > div.dataColumn > span.k-widget.k-datepicker span.k-dropdown-wrap .k-input {
  height: 100%; }
  div.detailsRow > div.dataColumn > span.k-widget.k-dropdown span.k-picker-wrap .k-icon,
  div.detailsRow > div.dataColumn > span.k-widget.k-dropdown span.k-dropdown-wrap .k-icon,
  div.detailsRow > div.dataColumn > span.k-widget.k-dropdown span.k-dropdown-wrap .k-input .k-icon,
  div.detailsRow > div.dataColumn > span.k-widget.k-datepicker span.k-picker-wrap .k-icon,
  div.detailsRow > div.dataColumn > span.k-widget.k-datepicker span.k-dropdown-wrap .k-icon,
  div.detailsRow > div.dataColumn > span.k-widget.k-datepicker span.k-dropdown-wrap .k-input .k-icon {
    position: static; }

div.detailsRow > div.dataColumn > span.k-widget.k-dropdown span.k-picker-wrap,
div.detailsRow > div.dataColumn > span.k-widget.k-datepicker span.k-picker-wrap {
  border: none; }
  div.detailsRow > div.dataColumn > span.k-widget.k-dropdown span.k-picker-wrap .k-input,
  div.detailsRow > div.dataColumn > span.k-widget.k-datepicker span.k-picker-wrap .k-input {
    height: 100%; }

div.detailsRow > div.dataColumn > input:not([type="radio"]):not([type="checkbox"]).withTranslationIcon,
div.detailsRow > div.dataColumn > textarea.withTranslationIcon {
  width: calc(100% - 1.5625rem); }

div.detailsRow > div.dataColumn > input:not([type="radio"]):not([type="checkbox"]).withIcon,
div.detailsRow > div.dataColumn > textarea.withIcon {
  width: calc(100% - 1.875rem); }

.dataColumn .ims-icon.ims-icon-password, .dataColumn .ims-icon-password.ims-icon-arrow-left, .dataColumn .ims-icon-password.ims-icon-arrow-right {
  right: 3.125rem;
  height: 1.875rem; }
  .dataColumn .ims-icon.ims-icon-password:before, .dataColumn .ims-icon-password.ims-icon-arrow-left:before, .dataColumn .ims-icon-password.ims-icon-arrow-right:before {
    line-height: 1.875rem; }

.dataColumn a.simpleTooltip {
  height: 1.875rem;
  width: 1.25rem; }
  .dataColumn a.simpleTooltip:hover {
    text-decoration: none; }
  .dataColumn a.simpleTooltip .k-i-warning:before {
    line-height: 1.875rem;
    height: inherit;
    width: inherit; }

.dataColumn textarea {
  resize: none;
  padding-left: var(--ims-spacing--25); }

table.table td.ims-text-overflow-container {
  position: relative;
  width: 100%; }

.ims-text-overflow {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 1.15em;
  line-height: 1.2; }

/* end tool area for grids in details-pane and pop ups */
/* styles for mobile browsers smaller than 768px; */
@media only screen and (max-width: 768px) {
  #pageHeaderArea {
    /*Page Header - Left area*/
    /*Page Header - Right area*/ }
    #pageHeaderArea .topLeftContainer {
      float: none; }
    #pageHeaderArea .topRightContainer {
      float: none; } }

/* styles for browsers larger than 769px; */
@media only screen and (min-width: 769px) {
  .wrapper {
    max-width: 769px; } }

/* styles for browsers larger than 1024px; */
@media only screen and (min-width: 1024px) {
  .wrapper {
    max-width: 1024px; } }

/* styles for browsers larger than 1024px; */
@media only screen and (min-width: 1200px) {
  .wrapper {
    max-width: 1200px; } }

/* styles for browsers larger than 1024px; */
@media only screen and (min-width: 1500px) {
  .wrapper {
    max-width: 1500px; } }

/* bootstrap extension */
@media (min-width: 1500px) {
  .container {
    max-width: 1460px; } }

input[type=text]::-ms-clear {
  display: none; }

.block {
  display: block; }

.inlineBlock {
  display: inline-block; }

.k-widget .ddMenu {
  top: 0; }

.toolArea {
  z-index: 999999; }

.toolButtonArea > span:not(.selectedArrow) {
  padding: 0.4375rem; }
  .toolButtonArea > span:not(.selectedArrow).right-nomargin:first-of-type {
    margin-right: 0; }

.toolButtonArea .k-menu > .k-item > .k-link {
  padding: 0.28125rem 0.5625rem 0.28125rem 0.28125rem; }

.rightPane .toolButtonArea > span:not(.selectedArrow) {
  padding: 0.28125rem; }
  .rightPane .toolButtonArea > span:not(.selectedArrow).right-nomargin:first-of-type {
    margin-right: 0; }

.rightPane .toolButtonArea > .k-button:last-of-type {
  margin-right: 0; }

.searchBoxArea .k-autocomplete.k-state-hover:hover {
  box-shadow: none; }

.searchBoxArea .k-autocomplete .k-input {
  padding: .25em 0; }

.pageEditor div.containerBar .ddMenu .k-menu > li.k-item > .k-link {
  padding: .5em; }

.ims-drag-handle {
  cursor: move; }

#rightPaneTitle > div > span.ims-icon.buttonType, #rightPaneTitle > div > span.buttonType.ims-icon-arrow-left, #rightPaneTitle > div > span.buttonType.ims-icon-arrow-right,
#rightPaneTitle > div > div.k-button {
  vertical-align: middle; }

#rightPaneTitle > span.titleText {
  display: block;
  width: calc(100% - 350px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left; }

/* footer */
body > span.ims-crm-version {
  color: var(--bs-border-color);
  padding: 2px 15px; }

/* override color for kendo menu items */
ul.dark-menu.k-menu {
  background-color: var(--bs-surface-high); }

ul.dark-menu.k-menu .k-item > a {
  color: var(--bs-body-color); }

/* alerts */
.infoField {
  margin-bottom: var(--ims-spacing--25);
  border-radius: 0.15625rem; }
  .infoField > ul {
    margin-top: var(--ims-spacing--40);
    margin-bottom: var(--ims-spacing--40);
    padding-left: var(--ims-spacing--40); }
    .infoField > ul > li {
      list-style-type: none; }
  .infoField.success {
    background-color: var(--bs-success-container);
    color: var(--ims-sys-color-success-on-primary-container); }
  .infoField.warning {
    background-color: var(--ims-sys-color-warning-primary-container);
    color: var(--ims-sys-color-warning-on-primary-container); }
  .infoField.error {
    background-color: var(--bs-danger);
    color: var(--bs-white); }

.disabled {
  opacity: 0.4; }

.objectDetailsContainer .objectTab,
.trainingLists .objectTab {
  display: none; }

/* menu header admin area */
@media only screen and (max-width: 1150px) {
  .page-outer-container {
    margin-top: -43px;
    padding-top: 43px; }
  .page-header {
    height: 43px; } }

@media only screen and (max-width: 1365px) {
  .row.main {
    margin-left: 5rem; }
  .ims-crm-version {
    display: none; }
  .left-pane .left-pane-actionbar .searchFilterArea {
    left: 25%;
    right: var(--ims-spacing--75);
    width: auto; } }

@media only screen and (max-width: 720px) {
  .row.main {
    margin-left: 0; }
  .pageMain {
    left: 0rem; }
  .left-pane .left-pane-actionbar .searchFilterArea {
    left: var(--ims-spacing--75);
    right: var(--ims-spacing--75);
    width: auto; } }

@media only screen and (max-height: 650px) {
  .admin-area {
    display: flex; }
  .row.main {
    flex-basis: 0;
    flex-grow: 1;
    margin-left: 0; } }

/*** start mobile device specific styling ***/
@media (pointer: coarse) and (max-height: 600px) {
  nav.ims-navigation {
    overflow-y: auto; } }

/*** end mobile device specific styling ***/
/*** start small screen styling ***/
@media only screen and (max-width: 720px) {
  div.rightPane .contentArea {
    box-shadow: none; }
  div.ims-right-pane-container .fullSBtn {
    background-color: var(--bs-primary-dark); }
    div.ims-right-pane-container .fullSBtn .k-link {
      flex-direction: row-reverse;
      justify-content: space-between; }
      div.ims-right-pane-container .fullSBtn .k-link:before {
        margin-right: 0; } }

@media only screen and (max-width: 605px) {
  .left-pane.ims-grid-container .toolArea .input-element {
    display: none; }
    .left-pane.ims-grid-container .toolArea .input-element.add-button {
      display: inline-block; }
  .left-pane.ims-grid-container .k-pager-wrap.k-grid-pager .k-pager-refresh,
  .left-pane.ims-grid-container .k-pager-wrap.k-grid-pager .pager-action-button {
    display: none; }
  div#tooltipContainer {
    border: none;
    min-width: 100%; } }

@media only screen and (max-width: 360px) {
  #editTooltip {
    display: none; } }

/*** end small screen styling ***/
.ims-flex-1-cols {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ims-spacing--30) var(--ims-spacing--30); }
  .ims-flex-1-cols > * {
    width: 98%;
    box-sizing: border-box; }

.ims-flex-2-cols {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ims-spacing--30) var(--ims-spacing--30); }
  .ims-flex-2-cols > * {
    width: 49%;
    box-sizing: border-box; }

.ims-flex-3-cols {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ims-spacing--30) var(--ims-spacing--30); }
  .ims-flex-3-cols > * {
    width: 32.66667%;
    box-sizing: border-box; }

.ims-flex-4-cols {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ims-spacing--30) var(--ims-spacing--30); }
  .ims-flex-4-cols > * {
    width: 24.5%;
    box-sizing: border-box; }

.ims-flex-5-cols {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ims-spacing--30) var(--ims-spacing--30); }
  .ims-flex-5-cols > * {
    width: 19.6%;
    box-sizing: border-box; }

.ims-flex-6-cols {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ims-spacing--30) var(--ims-spacing--30); }
  .ims-flex-6-cols > * {
    width: 16.33333%;
    box-sizing: border-box; }

.ims-flex-7-cols {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ims-spacing--30) var(--ims-spacing--30); }
  .ims-flex-7-cols > * {
    width: 14%;
    box-sizing: border-box; }

.ims-flex-8-cols {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ims-spacing--30) var(--ims-spacing--30); }
  .ims-flex-8-cols > * {
    width: 12.25%;
    box-sizing: border-box; }

.ims-flex-9-cols {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ims-spacing--30) var(--ims-spacing--30); }
  .ims-flex-9-cols > * {
    width: 10.88889%;
    box-sizing: border-box; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

.ims-container-layout {
  display: flex;
  flex-wrap: wrap;
  margin: var(--ims-spacing--30) var(--ims-spacing--10); }

.ims-dynamic-gap-box {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 8px; }
  .ims-dynamic-gap-box > div {
    flex: 1 1;
    width: 100%; }

#pageEditorMenue {
  top: 9.624rem !important;
  padding-right: var(--ims-spacing--40); }
  #pageEditorMenue .k-widget.k-dropdown {
    margin-top: 0.64rem !important;
    margin-right: 0.4rem !important; }
  #pageEditorMenue .k-dropdown-wrap.k-state-default {
    background-color: var(--bs-light-bg-subtle);
    height: 1.86rem;
    line-height: 1.86rem; }
  #pageEditorMenue .k-dropdown-wrap .k-input {
    line-height: 1.86rem; }
  #pageEditorMenue .k-dropdown-wrap .k-select {
    line-height: 1.86rem; }

.tiptap {
  /* menu bubble */ }
  .tiptap .button:hover {
    background-color: rgba(var(--bs-body-color-rgb), 0.15); }
  .tiptap .ProseMirror {
    background: var(--bs-white);
    height: 2.3125rem;
    color: var(--bs-body-color);
    padding: var(--ims-spacing--25) var(--ims-spacing--20);
    width: 100%;
    border-width: var(--ims-inputBorderWidth);
    border-style: solid;
    border-radius: var(--ims-form-input__border-radius);
    border: var(--bs-border-width) solid var(--bs-border-color);
    background-color: var(--bs-white);
    height: auto; }
    .tiptap .ProseMirror.not-resizable {
      resize: none; }
    .tiptap .ProseMirror:focus {
      border-color: var(--ims-colInputFocus) !important;
      box-shadow: 0px 0px 0px 0.125rem rgba(var(--ims-colInputFocus-rgb), 0.5) !important; }
    .tiptap .ProseMirror:hover {
      background-color: var(--ims-form-input__hover); }
  .tiptap .editor__content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word; }
  .tiptap .editor__content * {
    caret-color: currentColor; }
  .tiptap .editor__content pre {
    padding: 0.7rem var(--ims-spacing--40);
    border-radius: 5px;
    background: var(--bs-body-color);
    color: var(--bs-on-primary);
    font-size: 0.8rem;
    overflow-x: auto; }
  .tiptap .editor__content pre code {
    display: block; }
  .tiptap .editor__content p code {
    padding: 0.2rem 0.4rem;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    background: rgba(var(--bs-body-color-rgb), 0.1);
    color: rgba(var(--bs-body-color-rgb), 0.8); }
  .tiptap .editor__content ul,
  .tiptap .editor__content ol {
    padding-left: var(--ims-spacing--40); }
  .tiptap .editor__content li > p,
  .tiptap .editor__content li > ol,
  .tiptap .editor__content li > ul {
    margin: 0; }
  .tiptap .editor__content a {
    color: inherit; }
  .tiptap .editor__content blockquote {
    border-left: 3px solid rgba(var(--bs-body-color-rgb), 0.1);
    color: rgba(var(--bs-body-color-rgb), 0.8);
    padding-left: 0.8rem;
    font-style: italic; }
  .tiptap .editor__content blockquote p {
    margin: 0; }
  .tiptap .editor__content img {
    max-width: 100%;
    border-radius: 3px; }
  .tiptap .editor__content table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden; }
  .tiptap .editor__content table td,
  .tiptap .editor__content table th {
    min-width: 1em;
    border: 2px solid #dddddd;
    padding: 3px 5px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative; }
  .tiptap .editor__content table td > *,
  .tiptap .editor__content table th > * {
    margin-bottom: 0; }
  .tiptap .editor__content table th {
    font-weight: bold;
    text-align: left; }
  .tiptap .editor__content table .selectedCell:after {
    z-index: 2;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(200, 200, 255, 0.4);
    pointer-events: none; }
  .tiptap .editor__content table .column-resize-handle {
    position: absolute;
    right: -2px;
    top: 0;
    bottom: 0;
    width: 4px;
    z-index: 20;
    background-color: #adf;
    pointer-events: none; }
  .tiptap .editor__content .tableWrapper {
    margin: 1em 0;
    overflow-x: auto; }
  .tiptap .editor__content .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize; }
  .tiptap .menububble {
    position: relative;
    background-color: var(--bs-primary);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s; }
  .tiptap .menububble.is-active {
    opacity: 1;
    visibility: visible; }
  .tiptap .menububble__button {
    background: transparent;
    border: 0;
    color: var(--bs-on-primary);
    padding: 0.1875rem 0.1875rem;
    margin-right: 0.125rem;
    border-radius: 0.1875rem;
    cursor: pointer; }
  .tiptap .menububble__button:last-child {
    margin-right: 0; }
  .tiptap .menububble__button:hover {
    background-color: rgba(var(--bs-white-rgb), 0.1); }
  .tiptap .menububble__button.is-active {
    background-color: rgba(var(--bs-white-rgb), 0.2); }
  .tiptap .menububble__form {
    display: flex;
    align-items: center; }
  .tiptap .menububble__input {
    font: inherit;
    border: none;
    background: transparent;
    color: var(--bs-on-primary); }

.leftPane,
.rightPane {
  overflow: visible !important; }

.flex-container {
  display: flex; }

.align-right {
  float: right; }

.ims-header-bar,
.ims-panel-header {
  padding-bottom: var(--ims-spacing--40); }

.ims-panel-header-slim {
  padding-bottom: var(--ims-spacing--0); }

.ims-panel-header,
.ims-panel-body,
.panel-row {
  padding-left: var(--ims-spacing--0);
  padding-right: var(--ims-spacing--0); }

.courses-list-header {
  position: absolute;
  display: flex; }
  .courses-list-header > * {
    padding: var(--ims-spacing--10) var(--ims-spacing--20); }

.ims-panel-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center; }
  .ims-panel-header > .ims-icon, .ims-panel-header > .ims-icon-arrow-left, .ims-panel-header > .ims-icon-arrow-right {
    margin-right: 0.9375rem; }
  .ims-panel-header h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-bottom: var(--ims-spacing--15); }

.session-header {
  display: flex; }

.list-entry-header {
  display: flex;
  align-items: center; }
  .list-entry-header.slim .ims-metadata {
    margin-bottom: var(--ims-spacing--0); }
  .list-entry-header.slim > .ims-icon, .list-entry-header.slim > .ims-icon-arrow-left, .list-entry-header.slim > .ims-icon-arrow-right {
    font-size: var(--ims-font__size--400); }

.ims-course-header .inline-container > * {
  margin-right: var(--ims-spacing--15); }

.ims-course-body {
  margin-bottom: var(--ims-spacing--15); }

.ims-metadata,
.ims-block-text {
  margin-bottom: var(--ims-spacing--25);
  overflow-wrap: anywhere; }
  .ims-metadata > *,
  .ims-block-text > * {
    display: block; }

#trainingContentBlock .ims-session-panel .ims-course-body .ims-metadata div span,
#trainingContentBlock .ims-session-panel .ims-course-body .ims-metadata span span {
  display: block; }

.panel-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: var(--ims-spacing--30);
  padding-bottom: var(--ims-spacing--30);
  margin-bottom: -1.5rem; }
  .panel-footer > label {
    margin-right: var(--ims-spacing--15); }

.inline-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .inline-container h2 {
    margin-right: var(--ims-spacing--15); }
  .inline-container.button-row {
    flex-wrap: nowrap; }
    .inline-container.button-row button:not(:last-of-type),
    .inline-container.button-row a:not(:last-of-type) {
      margin-right: var(--ims-spacing--25); }
  .inline-container > .row {
    flex: 1; }

.entry-list-details {
  margin-bottom: var(--ims-spacing--25); }
  .entry-list-details > * {
    display: block; }

.download-link-list-item {
  display: flex; }
  .download-link-list-item .file-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    margin-right: var(--ims-spacing--15); }
  .download-link-list-item ~ .download-link-list-item {
    margin-top: var(--ims-spacing--15); }

.ims-inline-content {
  display: flex;
  align-items: center; }
  .ims-inline-content.space-between {
    justify-content: space-between; }
  .ims-inline-content.space-around {
    justify-content: space-around; }
  .ims-inline-content.button-row button {
    margin-left: var(--ims-spacing--15);
    margin-right: var(--ims-spacing--15); }

.ims-custom-scheduler .k-datepicker .k-select {
  background-color: transparent !important;
  border-color: transparent !important; }

.vertical-align-stretch {
  align-items: stretch; }

.no-wrap {
  flex-wrap: nowrap; }

.tab-navigation:not(.sticked-to-content) {
  margin-bottom: var(--ims-spacing--75); }

.checkbox-list > * {
  padding-bottom: var(--ims-spacing--20); }

.ims-session-panel {
  margin-bottom: var(--ims-spacing--50);
  padding-bottom: var(--ims-spacing--50); }

.ims-custom-toolbar {
  margin-bottom: var(--ims-spacing--75); }

.space-between {
  justify-content: space-between; }

.flex-end,
.header-container.flex-end {
  justify-content: flex-end; }

.vertical-align-center {
  align-items: center; }

.vertical-align-bottom {
  align-items: flex-end; }

.vertical-align-top,
.header-container.vertical-align-top {
  align-items: flex-start; }

.survey-header-toolbar > * {
  margin-left: var(--ims-spacing--75); }

.header-inline-action-buttons > a,
.header-inline-action-buttons > button {
  margin-left: 0.9375rem; }

.full-width-container {
  width: 100%; }
  .full-width-container .row {
    margin-right: var(--ims-spacing--0) !important;
    margin-left: var(--ims-spacing--0) !important; }
    .full-width-container .row .col-12 {
      padding-right: var(--ims-spacing--0) !important;
      padding-left: var(--ims-spacing--0) !important; }

.flags-list {
  margin-left: 0.125rem;
  margin-right: 0.125rem; }
  .flags-list .image.flags {
    margin-left: 0.125rem;
    margin-right: 0.125rem; }

.ims-inline-link-list > a {
  margin-right: var(--ims-spacing--20); }

.ims-inline-link-list.flex-end > a {
  margin-right: var(--ims-spacing--0);
  margin-left: var(--ims-spacing--20); }

.ims-text-ellipsis-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 17.5rem; }

.no-bottom-margin {
  margin-bottom: var(--ims-spacing--0); }

.ims-margin-1-1 {
  margin-top: var(--ims-spacing--25) !important;
  margin-bottom: var(--ims-spacing--25) !important; }

.ims-resource-player-container {
  flex-grow: 8;
  flex-basis: auto; }

.ims-session-header-row {
  max-width: calc(100% - 2.5rem); }

.narrow-schedule-block:focus {
  min-width: 3.75rem; }
  .narrow-schedule-block:focus .schedule-block-title {
    display: none; }

.narrow-schedule-block-resized {
  min-width: 3.75rem;
  outline: none; }

.ims-clickable-item {
  cursor: pointer; }

.ims-break-flex {
  flex-basis: 100%;
  height: 0; }

.ims-horizontal-divider {
  border-top: 0.0625rem solid var(--bs-dark-border-subtle); }

.ims-items-with-gab-container {
  display: flex;
  gap: 0.4rem 0.4rem;
  justify-content: space-between; }
  .ims-items-with-gab-container div:first-child {
    flex-grow: 3; }
  .ims-items-with-gab-container div:nth-child(2) {
    flex-grow: 0; }
  .ims-items-with-gab-container div:last-child {
    flex-grow: 3; }

.ims-flex-break {
  flex-basis: 100%;
  height: 0;
  width: 100%; }

.k-gantt-header {
  z-index: 0 !important; }

.ims-widget-filterTop .k-picker-wrap.k-state-default,
.ims-widget-filterTop .k-picker-wrap.k-state-default,
.ims-widget-filterTop .k-picker-wrap {
  background-color: var(--bs-white); }

.ims-widget-filterTop .k-datepicker .k-input {
  background-color: var(--bs-white); }

.collapsible-filterlist .k-multiselect,
.collapsible-filterlist .k-datepicker {
  box-shadow: 0 0.1875rem 0.375rem rgba(var(--bs-body-color-rgb), 0.5); }

.ims-detail-block .label-icon {
  font-size: 1.6875rem; }

.ims-detail-block .ims-detail-label {
  padding-top: var(--ims-spacing--15);
  padding-bottom: var(--ims-spacing--15); }

.list-group .content .ims-icon, .list-group .content .ims-icon-arrow-left, .list-group .content .ims-icon-arrow-right {
  font-size: 1.4375rem; }

.ims-gallery-img {
  max-width: 15.625rem;
  height: auto;
  margin: 0.1875rem; }

.htmlEditorWidget {
  margin-bottom: var(--ims-spacing--75); }

.eventOverviewWidget .ims-icon, .eventOverviewWidget .ims-icon-arrow-left, .eventOverviewWidget .ims-icon-arrow-right {
  color: var(--bs-on-primary); }

.eventOverviewWidget .ims-info-col > .ims-icon, .eventOverviewWidget .ims-info-col > .ims-icon-arrow-left, .eventOverviewWidget .ims-info-col > .ims-icon-arrow-right {
  color: var(--bs-on-primary); }

.availableTrainingsListWidget .carousel-inner,
.resourceListWidget .carousel-inner,
.myTrainingsListWidget .carousel-inner {
  padding: var(--ims-spacing--25) var(--ims-spacing--0);
  height: auto;
  border: none !important; }
  .availableTrainingsListWidget .carousel-inner ul.dropdown-menu,
  .resourceListWidget .carousel-inner ul.dropdown-menu,
  .myTrainingsListWidget .carousel-inner ul.dropdown-menu {
    right: -1.875rem !important;
    min-width: 9.375rem;
    left: auto !important; }
  .availableTrainingsListWidget .carousel-inner .dropdown-menu > li > a:hover,
  .availableTrainingsListWidget .carousel-inner .dropdown-menu > li > a:focus,
  .availableTrainingsListWidget .carousel-inner .dropdown-menu > li > a.selected,
  .resourceListWidget .carousel-inner .dropdown-menu > li > a:hover,
  .resourceListWidget .carousel-inner .dropdown-menu > li > a:focus,
  .resourceListWidget .carousel-inner .dropdown-menu > li > a.selected,
  .myTrainingsListWidget .carousel-inner .dropdown-menu > li > a:hover,
  .myTrainingsListWidget .carousel-inner .dropdown-menu > li > a:focus,
  .myTrainingsListWidget .carousel-inner .dropdown-menu > li > a.selected {
    background-color: var(--bs-white) !important; }

.availableTrainingsListWidget .ims-resource-img,
.resourceListWidget .ims-resource-img,
.myTrainingsListWidget .ims-resource-img {
  height: 12.5rem;
  padding-left: var(--ims-spacing--25);
  padding-right: var(--ims-spacing--25);
  margin-bottom: var(--ims-spacing--25); }

.availableTrainingsListWidget .ims-list-row-title,
.resourceListWidget .ims-list-row-title,
.myTrainingsListWidget .ims-list-row-title {
  margin-bottom: var(--ims-spacing--25); }

.ims-ghost-dnd {
  background-color: var(--bs-primary-lightest);
  opacity: .5; }

.fullscreen-visible {
  display: none; }

:fullscreen .fullscreen-visible {
  display: unset; }

:fullscreen .fullscreen-hidden {
  display: none; }

.ims-google-iframe iframe {
  width: 100%; }

.ims-reset-spacing--px-3 {
  margin-left: -1.25rem;
  margin-right: -1.25rem; }

.ims-reset-spacing--px-4 {
  margin-left: -1.5rem;
  margin-right: -1.5rem; }

.ims-reset-spacing--pt-4 {
  margin-top: -1.5rem; }

.ims-mw-20r {
  max-width: 20rem; }

.ims-bulletlist {
  padding-left: var(--ims-spacing--80);
  margin-left: var(--ims-spacing--40); }
  .ims-bulletlist > li {
    list-style: disc; }

@media (min-width: 1200px) {
  .mobile-only {
    display: none; } }

@media (max-width: 991px) {
  .ims-layout__container--33-33-33 {
    display: flex;
    flex-wrap: wrap;
    gap: var(--ims-spacing--10); }
    .ims-layout__container--33-33-33 > div {
      width: calc((100% / 2) - (2rem / 2)); }
  .ims-layout__container--25-25-25-25 {
    display: flex;
    flex-wrap: wrap;
    gap: var(--ims-spacing--10); }
    .ims-layout__container--25-25-25-25 > div {
      width: calc((100% / 2) - (2rem / 2)); }
  .fullscreen-only {
    display: none; }
  .ims-text-ellipsis-item {
    max-width: 31.25rem; }
  .no-wrap-lg {
    flex-wrap: nowrap; }
  .ims-mw-brand-logo {
    max-width: 18rem; } }

@media (min-width: 992px) {
  .ims-flex-layout-md-left-sm-center {
    display: flex;
    flex-direction: column; }
    .ims-flex-layout-md-left-sm-center * {
      display: inline-flex;
      justify-content: flex-start;
      text-align: start; }
  .ims-mw-brand-logo {
    max-width: 18rem; } }

@media (max-width: 575px) {
  .ims-flex-2-cols, .ims-flex-3-cols, .ims-flex-4-cols, .ims-flex-5-cols, .ims-flex-6-cols, .ims-flex-7-cols, .ims-flex-8-cols, .ims-flex-9-cols {
    gap: var(--ims-spacing--30) var(--ims-spacing--30); }
    .ims-flex-2-cols > *, .ims-flex-3-cols > *, .ims-flex-4-cols > *, .ims-flex-5-cols > *, .ims-flex-6-cols > *, .ims-flex-7-cols > *, .ims-flex-8-cols > *, .ims-flex-9-cols > * {
      width: 100%; }
  .ims-catalog-list .ims-course .course-thumbnail {
    padding-bottom: 0.9375rem; }
  .ims-layout__container--default, .ims-layout__container--25-75, .ims-layout__container--50-50, .ims-layout__container--75-25, .ims-layout__container--50-50, .ims-layout__container--33-33-33, .ims-layout__container--25-25-25-25 {
    display: flex;
    flex-direction: column; }
    .ims-layout__container--default > div, .ims-layout__container--25-75 > div, .ims-layout__container--50-50 > div, .ims-layout__container--75-25 > div, .ims-layout__container--50-50 > div, .ims-layout__container--33-33-33 > div, .ims-layout__container--25-25-25-25 > div {
      width: 100% !important;
      margin-right: var(--ims-spacing--0); }
  .survey-header-toolbar.flex-end {
    justify-content: flex-start; }
    .survey-header-toolbar.flex-end > * {
      margin-left: var(--ims-spacing--0);
      margin-right: var(--ims-spacing--75); }
  .inline-container.button-row {
    flex-direction: column; }
    .inline-container.button-row button,
    .inline-container.button-row a {
      width: 100%; }
      .inline-container.button-row button:not(:last-of-type),
      .inline-container.button-row a:not(:last-of-type) {
        margin-bottom: var(--ims-spacing--60); }
  .additional-course-info-ims-variation .ims-note-data {
    display: none; }
  .ims-flex-layout-md-left-sm-center {
    display: flex;
    flex-direction: column; }
    .ims-flex-layout-md-left-sm-center * {
      display: inline-flex;
      justify-content: center;
      text-align: center; }
  .btn-toolbar .btn {
    margin-top: var(--ims-spacing--20); }
  .ims-inline-content.m-vertically-centered {
    flex-direction: column;
    justify-content: center; }
  .ims-mw-brand-logo {
    max-width: 14rem; } }

@media (min-width: 992px) {
  .ims-grid-8-4 {
    grid-template-columns: 66.66667% 33.33333%;
    grid-template-rows: auto auto; }
  .ims-grid-slot-1 {
    margin-right: 1.5rem;
    margin-bottom: 1.5rem; }
  .ims-grid-slot-2 {
    margin-right: 1.5rem; }
  .ims-grid-slot-3 {
    margin-bottom: 1.5rem; }
  .ims-grid-slot-4 {
    margin-bottom: 1.5rem; } }

@media (max-width: 991px) {
  .ims-grid-slot-1 {
    margin-right: 0rem;
    margin-top: 1.5rem; }
  .ims-grid-slot-3 {
    margin-top: 1.5rem; }
  .ims-grid-slot-4 {
    grid-row: 3;
    margin-top: 1.5rem; }
  .ims-grid-8-4 {
    grid-template-columns: 100%;
    grid-template-rows: auto auto; } }

@supports (-moz-appearance: button) and (contain: paint) {
  #userLoginArea .ims-navigation-item .dropdown-menu {
    top: auto;
    bottom: 0; } }

.k-content {
  color: var(--bs-body-color); }

.k-block,
.k-draghandle,
.k-grouping-header,
.k-header,
.k-toolbar,
.k-treemap-tile,
html .km-pane-wrapper .k-header,
.k-progressbar .k-state-selected,
.k-splitbar.k-state-focused {
  background-color: var(--bs-surface-high); }

.k-progressbar .k-state-selected {
  border-color: var(--bs-primary-lightest); }

.k-grid .k-grouping-header {
  background-color: var(--bs-primary-lightest);
  color: var(--bs-on-primary); }

.k-scheduler-layout .k-state-selected {
  background-color: var(--bs-primary-lightest); }

.headerContainer .k-menu .k-item > .k-link:hover,
.headerContainer .k-menu .k-item.k-state-hover,
.headerContainer .k-menu .k-item.k-state-hover:hover,
.k-menu .k-menu-group .k-item.k-state-hover,
.k-menu .k-menu-group .k-item.k-state-hover:hover,
.objectDetailsContainer .k-tabstrip li.k-state-active,
.k-window-action.k-state-hover,
.k-scheduler .k-today.k-state-selected {
  background-color: var(--bs-primary-dark) !important; }

.k-grid .k-header .k-button,
.k-scheduler .k-header li,
.k-scheduler .k-header .k-link,
.k-scheduler .k-scheduler-toolbar li.k-state-default,
.k-overflow-container .k-primary,
.k-primary {
  background-color: var(--bs-primary-lightest);
  color: var(--bs-on-primary);
  border-color: var(--bs-primary-lightest); }
  .k-grid .k-header .k-button:hover, .k-grid .k-header .k-button:focus,
  .k-scheduler .k-header li:hover,
  .k-scheduler .k-header li:focus,
  .k-scheduler .k-header .k-link:hover,
  .k-scheduler .k-header .k-link:focus,
  .k-scheduler .k-scheduler-toolbar li.k-state-default:hover,
  .k-scheduler .k-scheduler-toolbar li.k-state-default:focus,
  .k-overflow-container .k-primary:hover,
  .k-overflow-container .k-primary:focus,
  .k-primary:hover,
  .k-primary:focus {
    color: var(--bs-on-primary);
    background-color: var(--bs-primary-dark);
    border-color: var(--bs-primary-dark); }
  .k-grid .k-header .k-button.k-state-hover,
  .k-grid .k-header .k-button.k-state-hover .k-link,
  .k-scheduler .k-header li.k-state-hover,
  .k-scheduler .k-header li.k-state-hover .k-link,
  .k-scheduler .k-header .k-link.k-state-hover,
  .k-scheduler .k-header .k-link.k-state-hover .k-link,
  .k-scheduler .k-scheduler-toolbar li.k-state-default.k-state-hover,
  .k-scheduler .k-scheduler-toolbar li.k-state-default.k-state-hover .k-link,
  .k-overflow-container .k-primary.k-state-hover,
  .k-overflow-container .k-primary.k-state-hover .k-link,
  .k-primary.k-state-hover,
  .k-primary.k-state-hover .k-link {
    background-color: var(--bs-primary-dark); }
  .k-grid .k-header .k-button.k-state-selected,
  .k-scheduler .k-header li.k-state-selected,
  .k-scheduler .k-header .k-link.k-state-selected,
  .k-scheduler .k-scheduler-toolbar li.k-state-default.k-state-selected,
  .k-overflow-container .k-primary.k-state-selected,
  .k-primary.k-state-selected {
    border-bottom-color: var(--bs-primary-dark); }

.k-state-disabled,
.k-state-disabled span,
-state-disabled span.k-icon {
  cursor: not-allowed; }

.k-window input.k-radio,
.k-radio {
  display: none; }

textarea.k-textbox:hover {
  background-color: var(--bs-light-bg-subtle); }

.k-context-menu.k-menu-vertical > li.k-item > .k-link,
.k-menu .k-menu-group li.k-item > .k-link {
  padding-left: var(--ims-spacing--25);
  text-align: left; }
  .k-context-menu.k-menu-vertical > li.k-item > .k-link > span.ims-icon, .k-context-menu.k-menu-vertical > li.k-item > .k-link > span.ims-icon-arrow-left, .k-context-menu.k-menu-vertical > li.k-item > .k-link > span.ims-icon-arrow-right,
  .k-menu .k-menu-group li.k-item > .k-link > span.ims-icon,
  .k-menu .k-menu-group li.k-item > .k-link > span.ims-icon-arrow-left,
  .k-menu .k-menu-group li.k-item > .k-link > span.ims-icon-arrow-right {
    margin-right: var(--ims-spacing--25); }

.k-header.k-scheduler-toolbar {
  background-color: var(--bs-primary-lightest); }

.k-scheduler .k-header .k-button,
.k-scheduler .k-header .k-button:hover {
  background-color: transparent; }
  .k-scheduler .k-header .k-button:active, .k-scheduler .k-header .k-button:focus, .k-scheduler .k-header .k-button:active:focus,
  .k-scheduler .k-header .k-button:hover:active,
  .k-scheduler .k-header .k-button:hover:focus,
  .k-scheduler .k-header .k-button:hover:active:focus {
    box-shadow: none; }

.k-widget.k-upload.k-header,
.k-widget .k-status {
  background-color: var(--bs-light-bg-subtle); }

.k-dropzone .k-button,
span.k-widget.k-header {
  background-color: var(--bs-light-bg-subtle); }

.imageUploadContainer {
  border-radius: var(--ims-form-input__border-radius); }
  .imageUploadContainer .k-widget {
    border-color: varvar(--bs-border-color); }
  .imageUploadContainer .fileDropDox {
    position: static; }
  .imageUploadContainer .k-widget.k-header.k-upload {
    background-color: transparent;
    border: none;
    width: inherit; }
  .imageUploadContainer .k-button.k-upload-button {
    min-width: 2.5rem;
    height: 2.25rem;
    border-radius: var(--ims-form-input__border-radius);
    color: var(--bs-body-color);
    background-color: var(--bs-surface-high); }
    .imageUploadContainer .k-button.k-upload-button > span:before {
      font-size: var(--ims-font__size--80);
      content: "\ea0c";
      font-family: fontello;
      font-weight: normal; }
    .imageUploadContainer .k-button.k-upload-button > span {
      line-height: 1.85742857rem; }
    .imageUploadContainer .k-button.k-upload-button:hover {
      color: var(--bs-body-color) !important;
      background-color: var(--bs-surface-highest); }
  .imageUploadContainer .k-upload-status.k-upload-status-total {
    font-size: var(--ims-font__size--100);
    font-weight: 400;
    color: var(--bs-body-color); }
    .imageUploadContainer .k-upload-status.k-upload-status-total .k-icon.k-i-check {
      background-color: var(--bs-success);
      color: var(--bs-on-primary);
      border-radius: 3px; }
      .imageUploadContainer .k-upload-status.k-upload-status-total .k-icon.k-i-check:before {
        font-size: 0.75rem; }
  .imageUploadContainer .uploadPreviewImageContainer {
    padding: var(--ims-spacing--25); }
  .imageUploadContainer .profilePhotoProgressbar,
  .imageUploadContainer .imagePreview {
    position: relative;
    background-color: var(--bs-surface-high); }
    .imageUploadContainer .profilePhotoProgressbar .ims-icon, .imageUploadContainer .profilePhotoProgressbar .ims-icon-arrow-left, .imageUploadContainer .profilePhotoProgressbar .ims-icon-arrow-right,
    .imageUploadContainer .imagePreview .ims-icon,
    .imageUploadContainer .imagePreview .ims-icon-arrow-left,
    .imageUploadContainer .imagePreview .ims-icon-arrow-right {
      right: 0.375rem;
      top: calc(45% - 0.5rem);
      margin-top: -0.0625rem;
      line-height: 1.25rem;
      color: var(--bs-primary); }
      .imageUploadContainer .profilePhotoProgressbar .ims-icon:before, .imageUploadContainer .profilePhotoProgressbar .ims-icon-arrow-left:before, .imageUploadContainer .profilePhotoProgressbar .ims-icon-arrow-right:before,
      .imageUploadContainer .imagePreview .ims-icon:before,
      .imageUploadContainer .imagePreview .ims-icon-arrow-left:before,
      .imageUploadContainer .imagePreview .ims-icon-arrow-right:before {
        font-size: var(--ims-font__size--400); }
      .imageUploadContainer .profilePhotoProgressbar .ims-icon:hover, .imageUploadContainer .profilePhotoProgressbar .ims-icon-arrow-left:hover, .imageUploadContainer .profilePhotoProgressbar .ims-icon-arrow-right:hover,
      .imageUploadContainer .imagePreview .ims-icon:hover,
      .imageUploadContainer .imagePreview .ims-icon-arrow-left:hover,
      .imageUploadContainer .imagePreview .ims-icon-arrow-right:hover {
        color: var(--bs-primary-dark); }
  .imageUploadContainer .imagePreview {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .imageUploadContainer .progressBar {
    border-top: 2px solid var(--bs-success);
    color: var(--bs-success);
    width: 0%;
    text-align: center; }

.k-widget.k-upload.k-header,
.k-widget .k-status {
  background-color: var(--bs-white); }

.k-dropzone .k-button,
span.k-widget.k-header {
  background-color: var(--bs-light-bg-subtle); }

.k-widget.k-colorpicker.k-header {
  height: 1.875rem;
  border-radius: 2px; }
  .k-widget.k-colorpicker.k-header .k-picker-wrap {
    margin: 0;
    border: none;
    height: inherit;
    background-color: var(--bs-white); }
  .k-widget.k-colorpicker.k-header .k-selected-color {
    width: 1.875rem; }
  .k-widget.k-colorpicker.k-header .k-select {
    border: none; }

.k-multiselect-wrap li {
  padding: 0 1.6rem 0 0.4rem; }

.k-treelist .k-alt.k-state-selected,
.k-treelist .k-alt.k-state-selected > td,
.k-treelist .k-state-selected,
.k-treelist .k-state-selected td,
.k-grid td.k-state-selected,
.k-grid tr.k-state-selected,
.k-grid tr.k-state-selected > td,
.k-grid td.k-state-selected:hover,
.k-grid tr.k-state-selected > td:hover,
.k-grid tr.k-state-selected:hover td {
  border-color: RGBA(var(--bs-surface-high-rgb), 0.8) !important;
  background-color: RGBA(var(--bs-surface-high-rgb), 0.8) !important;
  color: var(--bs-body-color); }

.k-grid tr:hover {
  background-color: RGBA(var(--bs-surface-high-rgb), 0.8) !important; }

.k-link:link,
.k-calendar .k-footer .k-nav-today,
.k-state-selected,
.k-list > .k-state-selected,
.k-list > .k-state-selected.k-state-focused {
  color: var(--bs-primary); }

#programActivityScheduler .k-scheduler-layout .k-scheduler-content .k-event {
  color: var(--bs-on-primary);
  background-color: var(--bs-primary-lightest);
  border-color: var(--bs-primary-lightest); }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .k-list-scroller {
    overflow-y: overlay; } }

.k-widget.k-grid .k-grid-header th.k-header:first-child {
  border: none; }

.k-widget.k-grid .k-grid-header .k-header .k-grid-filter,
.k-widget.k-grid .k-grid-header .k-header .k-header-column-menu {
  z-index: 0;
  display: inline;
  margin: -0.786em; }
  .k-widget.k-grid .k-grid-header .k-header .k-grid-filter .k-icon,
  .k-widget.k-grid .k-grid-header .k-header .k-header-column-menu .k-icon {
    color: var(--bs-border-color);
    opacity: 1;
    font-size: var(--ims-font__size--80);
    position: static; }
    .k-widget.k-grid .k-grid-header .k-header .k-grid-filter .k-icon:hover,
    .k-widget.k-grid .k-grid-header .k-header .k-header-column-menu .k-icon:hover {
      color: var(--bs-body-color); }

.k-widget.k-grid .k-grid-header .k-header .k-link {
  float: none;
  display: inline;
  padding: 0.714em 1.8em 0.714em 0.2em;
  margin: -0.714em;
  font-size: var(--ims-font__size--80); }

#gridMenu .k-animation-container {
  border-top: 1px solid var(--bs-border-color); }
  #gridMenu .k-animation-container .k-item {
    background-color: var(--bs-white); }
    #gridMenu .k-animation-container .k-item.k-state-hover {
      background-color: var(--bs-primary); }

.k-filter-menu-container label {
  margin-top: var(--ims-spacing--25);
  width: 50%;
  vertical-align: middle; }

.k-widget.k-datepicker.k-header {
  height: 1.875rem; }
  .k-widget.k-datepicker.k-header .k-picker-wrap {
    border: none;
    height: inherit;
    margin: 0;
    padding: 0; }
  .k-widget.k-datepicker.k-header .k-input {
    padding: 0; }
  .k-widget.k-datepicker.k-header span.k-select {
    margin: 0;
    padding: 0;
    border: 0; }

.k-grid-header,
.k-grid-header .k-header {
  background-color: var(--bs-white); }

.k-grid table th.k-header .ims-icon:before, .k-grid table th.k-header .ims-icon-arrow-left:before, .k-grid table th.k-header .ims-icon-arrow-right:before {
  font-size: var(--ims-font__size--300); }

.k-grid .k-state-selected {
  box-shadow: none; }

.k-grid td {
  padding: 0.5em 1em;
  height: 2.1875rem; }

.k-grid table td {
  border-color: var(--bs-border-color); }

.with-thumbnailLibrary .k-dropzone {
  border: none; }
  .with-thumbnailLibrary .k-dropzone .k-upload-status {
    right: 30px; }

.with-thumbnailLibrary .imageUploadContainer .k-widget {
  border-bottom-left-radius: var(--ims-form-input__border-radius);
  border-bottom-right-radius: var(--ims-form-input__border-radius); }

.k-dropzone em {
  display: inline; }

.k-pager-wrap {
  padding-right: 0.4375rem; }
  .k-pager-wrap .k-pager-numbers-wrap {
    z-index: 1; }
  .k-pager-wrap .k-pager-numbers {
    align-self: auto; }
  .k-pager-wrap select {
    border-color: none !important; }

.k-pager-wrap.k-grid-pager,
.k-pager-wrap.k-grid-pager .k-link {
  background-color: var(--bs-white);
  opacity: 1; }
  .k-pager-wrap.k-grid-pager .k-icon,
  .k-pager-wrap.k-grid-pager .k-link .k-icon {
    color: inherit;
    opacity: 1; }
    .k-pager-wrap.k-grid-pager .k-icon.k-i-arrow-60-down,
    .k-pager-wrap.k-grid-pager .k-link .k-icon.k-i-arrow-60-down {
      position: initial !important; }
  .k-pager-wrap.k-grid-pager .k-select .k-icon,
  .k-pager-wrap.k-grid-pager.k-state-disabled .k-icon,
  .k-pager-wrap.k-grid-pager .k-link .k-select .k-icon,
  .k-pager-wrap.k-grid-pager .k-link.k-state-disabled .k-icon {
    color: inherit;
    opacity: 1; }
  .k-pager-wrap.k-grid-pager .k-pager-refresh .k-icon,
  .k-pager-wrap.k-grid-pager .k-pager-refresh .ims-icon,
  .k-pager-wrap.k-grid-pager .k-pager-refresh .ims-icon-arrow-left,
  .k-pager-wrap.k-grid-pager .k-pager-refresh .ims-icon-arrow-right,
  .k-pager-wrap.k-grid-pager .pager-action-button .k-icon,
  .k-pager-wrap.k-grid-pager .pager-action-button .ims-icon,
  .k-pager-wrap.k-grid-pager .pager-action-button .ims-icon-arrow-left,
  .k-pager-wrap.k-grid-pager .pager-action-button .ims-icon-arrow-right,
  .k-pager-wrap.k-grid-pager .k-link .k-pager-refresh .k-icon,
  .k-pager-wrap.k-grid-pager .k-link .k-pager-refresh .ims-icon,
  .k-pager-wrap.k-grid-pager .k-link .k-pager-refresh .ims-icon-arrow-left,
  .k-pager-wrap.k-grid-pager .k-link .k-pager-refresh .ims-icon-arrow-right,
  .k-pager-wrap.k-grid-pager .k-link .pager-action-button .k-icon,
  .k-pager-wrap.k-grid-pager .k-link .pager-action-button .ims-icon,
  .k-pager-wrap.k-grid-pager .k-link .pager-action-button .ims-icon-arrow-left,
  .k-pager-wrap.k-grid-pager .k-link .pager-action-button .ims-icon-arrow-right {
    color: var(--bs-body-color);
    height: 1.875rem;
    width: 1.875rem;
    text-align: center;
    vertical-align: middle;
    display: inline-block; }
    .k-pager-wrap.k-grid-pager .k-pager-refresh .k-icon:before,
    .k-pager-wrap.k-grid-pager .k-pager-refresh .ims-icon:before,
    .k-pager-wrap.k-grid-pager .k-pager-refresh .ims-icon-arrow-left:before,
    .k-pager-wrap.k-grid-pager .k-pager-refresh .ims-icon-arrow-right:before,
    .k-pager-wrap.k-grid-pager .pager-action-button .k-icon:before,
    .k-pager-wrap.k-grid-pager .pager-action-button .ims-icon:before,
    .k-pager-wrap.k-grid-pager .pager-action-button .ims-icon-arrow-left:before,
    .k-pager-wrap.k-grid-pager .pager-action-button .ims-icon-arrow-right:before,
    .k-pager-wrap.k-grid-pager .k-link .k-pager-refresh .k-icon:before,
    .k-pager-wrap.k-grid-pager .k-link .k-pager-refresh .ims-icon:before,
    .k-pager-wrap.k-grid-pager .k-link .k-pager-refresh .ims-icon-arrow-left:before,
    .k-pager-wrap.k-grid-pager .k-link .k-pager-refresh .ims-icon-arrow-right:before,
    .k-pager-wrap.k-grid-pager .k-link .pager-action-button .k-icon:before,
    .k-pager-wrap.k-grid-pager .k-link .pager-action-button .ims-icon:before,
    .k-pager-wrap.k-grid-pager .k-link .pager-action-button .ims-icon-arrow-left:before,
    .k-pager-wrap.k-grid-pager .k-link .pager-action-button .ims-icon-arrow-right:before {
      font-size: var(--ims-font__size--300);
      width: 1.125rem;
      height: 1.125rem;
      line-height: 1.125rem;
      margin: 0;
      margin-top: 0.4375rem;
      position: static; }
  .k-pager-wrap.k-grid-pager .k-link,
  .k-pager-wrap.k-grid-pager .pager-action-button,
  .k-pager-wrap.k-grid-pager .k-link .k-link,
  .k-pager-wrap.k-grid-pager .k-link .pager-action-button {
    margin-left: 0.4375rem;
    margin-right: 0.4375rem;
    height: 1.875rem;
    width: 1.875rem;
    color: inherit;
    background-color: var(--bs-surface-high);
    cursor: pointer; }
  .k-pager-wrap.k-grid-pager.k-state-disabled, .k-pager-wrap.k-grid-pager.k-state-disabled:hover,
  .k-pager-wrap.k-grid-pager .k-link.k-state-disabled,
  .k-pager-wrap.k-grid-pager .k-link.k-state-disabled:hover {
    opacity: 0.6; }
  .k-pager-wrap.k-grid-pager .k-pager-numbers,
  .k-pager-wrap.k-grid-pager .k-link .k-pager-numbers {
    margin: 0;
    margin-left: 0.4375rem;
    margin-right: 0.4375rem; }
    .k-pager-wrap.k-grid-pager .k-pager-numbers .k-state-selected,
    .k-pager-wrap.k-grid-pager .k-link .k-pager-numbers .k-state-selected {
      color: inherit;
      background-color: var(--bs-surface-low);
      cursor: default; }
    .k-pager-wrap.k-grid-pager .k-pager-numbers span,
    .k-pager-wrap.k-grid-pager .k-pager-numbers a,
    .k-pager-wrap.k-grid-pager .k-link .k-pager-numbers span,
    .k-pager-wrap.k-grid-pager .k-link .k-pager-numbers a {
      padding: 0;
      margin-left: 0.4375rem;
      margin-right: 0.4375rem;
      margin-top: 0;
      border: none;
      height: 1.875rem;
      line-height: 1.875rem; }
  .k-pager-wrap.k-grid-pager span.k-widget.k-dropdown.k-header .k-dropdown-wrap,
  .k-pager-wrap.k-grid-pager .k-link span.k-widget.k-dropdown.k-header .k-dropdown-wrap {
    background-color: var(--bs-white); }
  .k-pager-wrap.k-grid-pager .k-dropdown,
  .k-pager-wrap.k-grid-pager .k-link .k-dropdown {
    width: 6.25rem; }
  .k-pager-wrap.k-grid-pager .k-dropdown-wrap,
  .k-pager-wrap.k-grid-pager .k-link .k-dropdown-wrap {
    background-color: var(--bs-surface-high);
    border-color: transparent; }
    .k-pager-wrap.k-grid-pager .k-dropdown-wrap .k-input,
    .k-pager-wrap.k-grid-pager .k-link .k-dropdown-wrap .k-input {
      background-color: var(--bs-surface-high);
      height: 1.85742857rem !important; }

.k-pager-sizes .k-widget.k-combobox,
.k-pager-sizes .k-widget.k-dropdown {
  margin-top: 0;
  margin-left: 1.6875rem;
  margin-right: 0.4375rem; }

/*#region ims grid container styles*/
.ims-grid-container .left-pane-actionbar,
.popup-actionbar .left-pane-actionbar,
.tab-actionbar .left-pane-actionbar,
.navbar .left-pane-actionbar {
  height: 2.80rem;
  color: var(--bs-on-primary);
  margin-top: 15px; }

.ims-grid-container .toolArea,
.popup-actionbar .toolArea,
.tab-actionbar .toolArea,
.navbar .toolArea {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }
  .ims-grid-container .toolArea .input-element,
  .ims-grid-container .toolArea .ims-gridMenuEdit,
  .popup-actionbar .toolArea .input-element,
  .popup-actionbar .toolArea .ims-gridMenuEdit,
  .tab-actionbar .toolArea .input-element,
  .tab-actionbar .toolArea .ims-gridMenuEdit,
  .navbar .toolArea .input-element,
  .navbar .toolArea .ims-gridMenuEdit {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: var(--ims-spacing--25);
    background-color: var(--bs-primary); }
    .ims-grid-container .toolArea .input-element:last-child,
    .ims-grid-container .toolArea .ims-gridMenuEdit:last-child,
    .popup-actionbar .toolArea .input-element:last-child,
    .popup-actionbar .toolArea .ims-gridMenuEdit:last-child,
    .tab-actionbar .toolArea .input-element:last-child,
    .tab-actionbar .toolArea .ims-gridMenuEdit:last-child,
    .navbar .toolArea .input-element:last-child,
    .navbar .toolArea .ims-gridMenuEdit:last-child {
      margin-right: 0; }
    .ims-grid-container .toolArea .input-element:hover,
    .ims-grid-container .toolArea .ims-gridMenuEdit:hover,
    .popup-actionbar .toolArea .input-element:hover,
    .popup-actionbar .toolArea .ims-gridMenuEdit:hover,
    .tab-actionbar .toolArea .input-element:hover,
    .tab-actionbar .toolArea .ims-gridMenuEdit:hover,
    .navbar .toolArea .input-element:hover,
    .navbar .toolArea .ims-gridMenuEdit:hover {
      background-color: var(--bs-primary-dark); }
      .ims-grid-container .toolArea .input-element:hover .ims-icon, .ims-grid-container .toolArea .input-element:hover .ims-icon-arrow-left, .ims-grid-container .toolArea .input-element:hover .ims-icon-arrow-right,
      .ims-grid-container .toolArea .ims-gridMenuEdit:hover .ims-icon,
      .ims-grid-container .toolArea .ims-gridMenuEdit:hover .ims-icon-arrow-left,
      .ims-grid-container .toolArea .ims-gridMenuEdit:hover .ims-icon-arrow-right,
      .popup-actionbar .toolArea .input-element:hover .ims-icon,
      .popup-actionbar .toolArea .input-element:hover .ims-icon-arrow-left,
      .popup-actionbar .toolArea .input-element:hover .ims-icon-arrow-right,
      .popup-actionbar .toolArea .ims-gridMenuEdit:hover .ims-icon,
      .popup-actionbar .toolArea .ims-gridMenuEdit:hover .ims-icon-arrow-left,
      .popup-actionbar .toolArea .ims-gridMenuEdit:hover .ims-icon-arrow-right,
      .tab-actionbar .toolArea .input-element:hover .ims-icon,
      .tab-actionbar .toolArea .input-element:hover .ims-icon-arrow-left,
      .tab-actionbar .toolArea .input-element:hover .ims-icon-arrow-right,
      .tab-actionbar .toolArea .ims-gridMenuEdit:hover .ims-icon,
      .tab-actionbar .toolArea .ims-gridMenuEdit:hover .ims-icon-arrow-left,
      .tab-actionbar .toolArea .ims-gridMenuEdit:hover .ims-icon-arrow-right,
      .navbar .toolArea .input-element:hover .ims-icon,
      .navbar .toolArea .input-element:hover .ims-icon-arrow-left,
      .navbar .toolArea .input-element:hover .ims-icon-arrow-right,
      .navbar .toolArea .ims-gridMenuEdit:hover .ims-icon,
      .navbar .toolArea .ims-gridMenuEdit:hover .ims-icon-arrow-left,
      .navbar .toolArea .ims-gridMenuEdit:hover .ims-icon-arrow-right {
        color: var(--bs-on-primary); }
    .ims-grid-container .toolArea .input-element .k-state-default,
    .ims-grid-container .toolArea .ims-gridMenuEdit .k-state-default,
    .popup-actionbar .toolArea .input-element .k-state-default,
    .popup-actionbar .toolArea .ims-gridMenuEdit .k-state-default,
    .tab-actionbar .toolArea .input-element .k-state-default,
    .tab-actionbar .toolArea .ims-gridMenuEdit .k-state-default,
    .navbar .toolArea .input-element .k-state-default,
    .navbar .toolArea .ims-gridMenuEdit .k-state-default {
      color: var(--bs-on-primary); }
    .ims-grid-container .toolArea .input-element .k-state-disabled,
    .ims-grid-container .toolArea .input-element:hover .k-state-disabled,
    .ims-grid-container .toolArea .ims-gridMenuEdit .k-state-disabled,
    .ims-grid-container .toolArea .ims-gridMenuEdit:hover .k-state-disabled,
    .popup-actionbar .toolArea .input-element .k-state-disabled,
    .popup-actionbar .toolArea .input-element:hover .k-state-disabled,
    .popup-actionbar .toolArea .ims-gridMenuEdit .k-state-disabled,
    .popup-actionbar .toolArea .ims-gridMenuEdit:hover .k-state-disabled,
    .tab-actionbar .toolArea .input-element .k-state-disabled,
    .tab-actionbar .toolArea .input-element:hover .k-state-disabled,
    .tab-actionbar .toolArea .ims-gridMenuEdit .k-state-disabled,
    .tab-actionbar .toolArea .ims-gridMenuEdit:hover .k-state-disabled,
    .navbar .toolArea .input-element .k-state-disabled,
    .navbar .toolArea .input-element:hover .k-state-disabled,
    .navbar .toolArea .ims-gridMenuEdit .k-state-disabled,
    .navbar .toolArea .ims-gridMenuEdit:hover .k-state-disabled {
      background-color: var(--bs-surface-high);
      opacity: 1;
      color: inherit; }
      .ims-grid-container .toolArea .input-element .k-state-disabled .ims-icon, .ims-grid-container .toolArea .input-element .k-state-disabled .ims-icon-arrow-left, .ims-grid-container .toolArea .input-element .k-state-disabled .ims-icon-arrow-right,
      .ims-grid-container .toolArea .input-element:hover .k-state-disabled .ims-icon,
      .ims-grid-container .toolArea .input-element:hover .k-state-disabled .ims-icon-arrow-left,
      .ims-grid-container .toolArea .input-element:hover .k-state-disabled .ims-icon-arrow-right,
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-state-disabled .ims-icon,
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-state-disabled .ims-icon-arrow-left,
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-state-disabled .ims-icon-arrow-right,
      .ims-grid-container .toolArea .ims-gridMenuEdit:hover .k-state-disabled .ims-icon,
      .ims-grid-container .toolArea .ims-gridMenuEdit:hover .k-state-disabled .ims-icon-arrow-left,
      .ims-grid-container .toolArea .ims-gridMenuEdit:hover .k-state-disabled .ims-icon-arrow-right,
      .popup-actionbar .toolArea .input-element .k-state-disabled .ims-icon,
      .popup-actionbar .toolArea .input-element .k-state-disabled .ims-icon-arrow-left,
      .popup-actionbar .toolArea .input-element .k-state-disabled .ims-icon-arrow-right,
      .popup-actionbar .toolArea .input-element:hover .k-state-disabled .ims-icon,
      .popup-actionbar .toolArea .input-element:hover .k-state-disabled .ims-icon-arrow-left,
      .popup-actionbar .toolArea .input-element:hover .k-state-disabled .ims-icon-arrow-right,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-state-disabled .ims-icon,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-state-disabled .ims-icon-arrow-left,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-state-disabled .ims-icon-arrow-right,
      .popup-actionbar .toolArea .ims-gridMenuEdit:hover .k-state-disabled .ims-icon,
      .popup-actionbar .toolArea .ims-gridMenuEdit:hover .k-state-disabled .ims-icon-arrow-left,
      .popup-actionbar .toolArea .ims-gridMenuEdit:hover .k-state-disabled .ims-icon-arrow-right,
      .tab-actionbar .toolArea .input-element .k-state-disabled .ims-icon,
      .tab-actionbar .toolArea .input-element .k-state-disabled .ims-icon-arrow-left,
      .tab-actionbar .toolArea .input-element .k-state-disabled .ims-icon-arrow-right,
      .tab-actionbar .toolArea .input-element:hover .k-state-disabled .ims-icon,
      .tab-actionbar .toolArea .input-element:hover .k-state-disabled .ims-icon-arrow-left,
      .tab-actionbar .toolArea .input-element:hover .k-state-disabled .ims-icon-arrow-right,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-state-disabled .ims-icon,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-state-disabled .ims-icon-arrow-left,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-state-disabled .ims-icon-arrow-right,
      .tab-actionbar .toolArea .ims-gridMenuEdit:hover .k-state-disabled .ims-icon,
      .tab-actionbar .toolArea .ims-gridMenuEdit:hover .k-state-disabled .ims-icon-arrow-left,
      .tab-actionbar .toolArea .ims-gridMenuEdit:hover .k-state-disabled .ims-icon-arrow-right,
      .navbar .toolArea .input-element .k-state-disabled .ims-icon,
      .navbar .toolArea .input-element .k-state-disabled .ims-icon-arrow-left,
      .navbar .toolArea .input-element .k-state-disabled .ims-icon-arrow-right,
      .navbar .toolArea .input-element:hover .k-state-disabled .ims-icon,
      .navbar .toolArea .input-element:hover .k-state-disabled .ims-icon-arrow-left,
      .navbar .toolArea .input-element:hover .k-state-disabled .ims-icon-arrow-right,
      .navbar .toolArea .ims-gridMenuEdit .k-state-disabled .ims-icon,
      .navbar .toolArea .ims-gridMenuEdit .k-state-disabled .ims-icon-arrow-left,
      .navbar .toolArea .ims-gridMenuEdit .k-state-disabled .ims-icon-arrow-right,
      .navbar .toolArea .ims-gridMenuEdit:hover .k-state-disabled .ims-icon,
      .navbar .toolArea .ims-gridMenuEdit:hover .k-state-disabled .ims-icon-arrow-left,
      .navbar .toolArea .ims-gridMenuEdit:hover .k-state-disabled .ims-icon-arrow-right {
        color: var(--bs-body-color);
        opacity: 0.5; }
    .ims-grid-container .toolArea .input-element .k-state-disabled::before,
    .ims-grid-container .toolArea .ims-gridMenuEdit .k-state-disabled::before,
    .popup-actionbar .toolArea .input-element .k-state-disabled::before,
    .popup-actionbar .toolArea .ims-gridMenuEdit .k-state-disabled::before,
    .tab-actionbar .toolArea .input-element .k-state-disabled::before,
    .tab-actionbar .toolArea .ims-gridMenuEdit .k-state-disabled::before,
    .navbar .toolArea .input-element .k-state-disabled::before,
    .navbar .toolArea .ims-gridMenuEdit .k-state-disabled::before {
      color: var(--bs-body-color);
      opacity: 0.5;
      -webkit-user-select: none !important;
         -moz-user-select: none !important;
              user-select: none !important;
      pointer-events: none !important; }
  .ims-grid-container .toolArea button,
  .popup-actionbar .toolArea button,
  .tab-actionbar .toolArea button,
  .navbar .toolArea button {
    height: 2.5rem;
    width: auto; }
  .ims-grid-container .toolArea .searchBoxArea,
  .popup-actionbar .toolArea .searchBoxArea,
  .tab-actionbar .toolArea .searchBoxArea,
  .navbar .toolArea .searchBoxArea {
    width: 17.5rem;
    background-color: transparent; }
    .ims-grid-container .toolArea .searchBoxArea .k-textbox.k-space-right,
    .popup-actionbar .toolArea .searchBoxArea .k-textbox.k-space-right,
    .tab-actionbar .toolArea .searchBoxArea .k-textbox.k-space-right,
    .navbar .toolArea .searchBoxArea .k-textbox.k-space-right {
      background-color: var(--bs-primary);
      padding-right: var(--ims-spacing--100);
      height: 100%;
      border-radius: 2px 0 0 2px; }
      .ims-grid-container .toolArea .searchBoxArea .k-textbox.k-space-right:hover,
      .popup-actionbar .toolArea .searchBoxArea .k-textbox.k-space-right:hover,
      .tab-actionbar .toolArea .searchBoxArea .k-textbox.k-space-right:hover,
      .navbar .toolArea .searchBoxArea .k-textbox.k-space-right:hover {
        box-shadow: none; }
      .ims-grid-container .toolArea .searchBoxArea .k-textbox.k-space-right .k-icon,
      .popup-actionbar .toolArea .searchBoxArea .k-textbox.k-space-right .k-icon,
      .tab-actionbar .toolArea .searchBoxArea .k-textbox.k-space-right .k-icon,
      .navbar .toolArea .searchBoxArea .k-textbox.k-space-right .k-icon {
        right: 0;
        margin-top: -0.625rem; }
    .ims-grid-container .toolArea .searchBoxArea span.k-widget,
    .ims-grid-container .toolArea .searchBoxArea input.k-input,
    .popup-actionbar .toolArea .searchBoxArea span.k-widget,
    .popup-actionbar .toolArea .searchBoxArea input.k-input,
    .tab-actionbar .toolArea .searchBoxArea span.k-widget,
    .tab-actionbar .toolArea .searchBoxArea input.k-input,
    .navbar .toolArea .searchBoxArea span.k-widget,
    .navbar .toolArea .searchBoxArea input.k-input {
      height: 100%;
      padding: 0;
      border: none; }
      .ims-grid-container .toolArea .searchBoxArea span.k-widget.k-state-focused, .ims-grid-container .toolArea .searchBoxArea span.k-widget.k-state-hover,
      .ims-grid-container .toolArea .searchBoxArea input.k-input.k-state-focused,
      .ims-grid-container .toolArea .searchBoxArea input.k-input.k-state-hover,
      .popup-actionbar .toolArea .searchBoxArea span.k-widget.k-state-focused,
      .popup-actionbar .toolArea .searchBoxArea span.k-widget.k-state-hover,
      .popup-actionbar .toolArea .searchBoxArea input.k-input.k-state-focused,
      .popup-actionbar .toolArea .searchBoxArea input.k-input.k-state-hover,
      .tab-actionbar .toolArea .searchBoxArea span.k-widget.k-state-focused,
      .tab-actionbar .toolArea .searchBoxArea span.k-widget.k-state-hover,
      .tab-actionbar .toolArea .searchBoxArea input.k-input.k-state-focused,
      .tab-actionbar .toolArea .searchBoxArea input.k-input.k-state-hover,
      .navbar .toolArea .searchBoxArea span.k-widget.k-state-focused,
      .navbar .toolArea .searchBoxArea span.k-widget.k-state-hover,
      .navbar .toolArea .searchBoxArea input.k-input.k-state-focused,
      .navbar .toolArea .searchBoxArea input.k-input.k-state-hover {
        box-shadow: none; }
    .ims-grid-container .toolArea .searchBoxArea a:not([href]):not([tabindex]),
    .popup-actionbar .toolArea .searchBoxArea a:not([href]):not([tabindex]),
    .tab-actionbar .toolArea .searchBoxArea a:not([href]):not([tabindex]),
    .navbar .toolArea .searchBoxArea a:not([href]):not([tabindex]) {
      width: 2.5rem;
      font-size: 1.125rem; }
      .ims-grid-container .toolArea .searchBoxArea a:not([href]):not([tabindex])::before,
      .popup-actionbar .toolArea .searchBoxArea a:not([href]):not([tabindex])::before,
      .tab-actionbar .toolArea .searchBoxArea a:not([href]):not([tabindex])::before,
      .navbar .toolArea .searchBoxArea a:not([href]):not([tabindex])::before {
        margin-left: auto;
        margin-right: auto;
        font-size: 1.125rem;
        line-height: 1.125rem; }
  .ims-grid-container .toolArea .ims-gridMenuEdit,
  .popup-actionbar .toolArea .ims-gridMenuEdit,
  .tab-actionbar .toolArea .ims-gridMenuEdit,
  .navbar .toolArea .ims-gridMenuEdit {
    background-color: var(--bs-primary); }
    .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu,
    .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item,
    .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link,
    .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu,
    .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item,
    .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link,
    .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu,
    .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item,
    .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link,
    .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu,
    .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item,
    .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      float: none;
      position: static; }
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-animation-container,
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-animation-container,
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link > .k-animation-container,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-animation-container,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-animation-container,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link > .k-animation-container,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-animation-container,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-animation-container,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link > .k-animation-container,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-animation-container,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-animation-container,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link > .k-animation-container {
        margin-left: 0; }
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon, .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon-arrow-left, .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon-arrow-right,
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon,
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon-arrow-left,
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon-arrow-right,
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon-arrow-left,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon-arrow-right,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon-arrow-left,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon-arrow-right,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon-arrow-left,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon-arrow-right,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon-arrow-left,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon-arrow-right,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon-arrow-left,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon-arrow-right,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon-arrow-left,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon-arrow-right,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right {
        float: none; }
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .k-icon,
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .k-icon,
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .k-icon,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .k-icon,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .k-icon,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .k-icon,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .k-icon,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .k-icon,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .k-icon,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .k-icon,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .k-icon,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .k-icon {
        display: none; }
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .menuSeparator .k-icon,
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .menuSeparator .k-icon,
      .ims-grid-container .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .menuSeparator .k-icon,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .menuSeparator .k-icon,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .menuSeparator .k-icon,
      .popup-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .menuSeparator .k-icon,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .menuSeparator .k-icon,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .menuSeparator .k-icon,
      .tab-actionbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .menuSeparator .k-icon,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu .menuSeparator .k-icon,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .menuSeparator .k-icon,
      .navbar .toolArea .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .menuSeparator .k-icon {
        display: block; }
  .ims-grid-container .toolArea .ims-dropdown-menu,
  .popup-actionbar .toolArea .ims-dropdown-menu,
  .tab-actionbar .toolArea .ims-dropdown-menu,
  .navbar .toolArea .ims-dropdown-menu {
    width: auto; }
    .ims-grid-container .toolArea .ims-dropdown-menu .k-widget.k-dropdown,
    .popup-actionbar .toolArea .ims-dropdown-menu .k-widget.k-dropdown,
    .tab-actionbar .toolArea .ims-dropdown-menu .k-widget.k-dropdown,
    .navbar .toolArea .ims-dropdown-menu .k-widget.k-dropdown {
      background-color: transparent;
      margin-right: var(--ims-spacing--25); }
      .ims-grid-container .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap,
      .popup-actionbar .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap,
      .tab-actionbar .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap,
      .navbar .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap {
        margin: 0;
        background-color: transparent;
        border-color: var(--bs-primary);
        border: 1px solid var(--bs-border-color) !important; }
        .ims-grid-container .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap .k-input,
        .popup-actionbar .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap .k-input,
        .tab-actionbar .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap .k-input,
        .navbar .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap .k-input {
          color: var(--bs-primary);
          height: 100%;
          line-height: 1.875rem; }
        .ims-grid-container .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap .k-select .k-icon,
        .popup-actionbar .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap .k-select .k-icon,
        .tab-actionbar .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap .k-select .k-icon,
        .navbar .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap .k-select .k-icon {
          color: var(--bs-body-color);
          height: 1.875rem;
          line-height: 1.875rem; }
          .ims-grid-container .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap .k-select .k-icon:before,
          .popup-actionbar .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap .k-select .k-icon:before,
          .tab-actionbar .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap .k-select .k-icon:before,
          .navbar .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap .k-select .k-icon:before {
            content: "\e8b0";
            font-family: fontello;
            font-size: 0.625rem;
            color: var(--bs-primary); }
    .ims-grid-container .toolArea .ims-dropdown-menu .disabled,
    .popup-actionbar .toolArea .ims-dropdown-menu .disabled,
    .tab-actionbar .toolArea .ims-dropdown-menu .disabled,
    .navbar .toolArea .ims-dropdown-menu .disabled {
      opacity: 0.4; }

.ims-grid-container .toolArea .toolArea-btn .ims-icon, .ims-grid-container .toolArea .toolArea-btn .ims-icon-arrow-left, .ims-grid-container .toolArea .toolArea-btn .ims-icon-arrow-right,
.ims-grid-container .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
.ims-grid-container .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
.ims-grid-container .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right,
.ims-grid-container .toolArea .navbar-toggler .ims-icon,
.ims-grid-container .toolArea .navbar-toggler .ims-icon-arrow-left,
.ims-grid-container .toolArea .navbar-toggler .ims-icon-arrow-right,
.ims-grid-container .ims-main-header .toolArea-btn .ims-icon,
.ims-grid-container .ims-main-header .toolArea-btn .ims-icon-arrow-left,
.ims-grid-container .ims-main-header .toolArea-btn .ims-icon-arrow-right,
.ims-grid-container .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
.ims-grid-container .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
.ims-grid-container .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right,
.ims-grid-container .ims-main-header .navbar-toggler .ims-icon,
.ims-grid-container .ims-main-header .navbar-toggler .ims-icon-arrow-left,
.ims-grid-container .ims-main-header .navbar-toggler .ims-icon-arrow-right,
.popup-actionbar .toolArea .toolArea-btn .ims-icon,
.popup-actionbar .toolArea .toolArea-btn .ims-icon-arrow-left,
.popup-actionbar .toolArea .toolArea-btn .ims-icon-arrow-right,
.popup-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
.popup-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
.popup-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right,
.popup-actionbar .toolArea .navbar-toggler .ims-icon,
.popup-actionbar .toolArea .navbar-toggler .ims-icon-arrow-left,
.popup-actionbar .toolArea .navbar-toggler .ims-icon-arrow-right,
.popup-actionbar .ims-main-header .toolArea-btn .ims-icon,
.popup-actionbar .ims-main-header .toolArea-btn .ims-icon-arrow-left,
.popup-actionbar .ims-main-header .toolArea-btn .ims-icon-arrow-right,
.popup-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
.popup-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
.popup-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right,
.popup-actionbar .ims-main-header .navbar-toggler .ims-icon,
.popup-actionbar .ims-main-header .navbar-toggler .ims-icon-arrow-left,
.popup-actionbar .ims-main-header .navbar-toggler .ims-icon-arrow-right,
.tab-actionbar .toolArea .toolArea-btn .ims-icon,
.tab-actionbar .toolArea .toolArea-btn .ims-icon-arrow-left,
.tab-actionbar .toolArea .toolArea-btn .ims-icon-arrow-right,
.tab-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
.tab-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
.tab-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right,
.tab-actionbar .toolArea .navbar-toggler .ims-icon,
.tab-actionbar .toolArea .navbar-toggler .ims-icon-arrow-left,
.tab-actionbar .toolArea .navbar-toggler .ims-icon-arrow-right,
.tab-actionbar .ims-main-header .toolArea-btn .ims-icon,
.tab-actionbar .ims-main-header .toolArea-btn .ims-icon-arrow-left,
.tab-actionbar .ims-main-header .toolArea-btn .ims-icon-arrow-right,
.tab-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
.tab-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
.tab-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right,
.tab-actionbar .ims-main-header .navbar-toggler .ims-icon,
.tab-actionbar .ims-main-header .navbar-toggler .ims-icon-arrow-left,
.tab-actionbar .ims-main-header .navbar-toggler .ims-icon-arrow-right,
.navbar .toolArea .toolArea-btn .ims-icon,
.navbar .toolArea .toolArea-btn .ims-icon-arrow-left,
.navbar .toolArea .toolArea-btn .ims-icon-arrow-right,
.navbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
.navbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
.navbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right,
.navbar .toolArea .navbar-toggler .ims-icon,
.navbar .toolArea .navbar-toggler .ims-icon-arrow-left,
.navbar .toolArea .navbar-toggler .ims-icon-arrow-right,
.navbar .ims-main-header .toolArea-btn .ims-icon,
.navbar .ims-main-header .toolArea-btn .ims-icon-arrow-left,
.navbar .ims-main-header .toolArea-btn .ims-icon-arrow-right,
.navbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
.navbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
.navbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right,
.navbar .ims-main-header .navbar-toggler .ims-icon,
.navbar .ims-main-header .navbar-toggler .ims-icon-arrow-left,
.navbar .ims-main-header .navbar-toggler .ims-icon-arrow-right {
  width: 100%;
  height: 100%;
  margin: 0;
  display: inline-block;
  cursor: pointer; }
  .ims-grid-container .toolArea .toolArea-btn .ims-icon::before, .ims-grid-container .toolArea .toolArea-btn .ims-icon-arrow-left::before, .ims-grid-container .toolArea .toolArea-btn .ims-icon-arrow-right::before,
  .ims-grid-container .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon::before,
  .ims-grid-container .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left::before,
  .ims-grid-container .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right::before,
  .ims-grid-container .toolArea .navbar-toggler .ims-icon::before,
  .ims-grid-container .toolArea .navbar-toggler .ims-icon-arrow-left::before,
  .ims-grid-container .toolArea .navbar-toggler .ims-icon-arrow-right::before,
  .ims-grid-container .ims-main-header .toolArea-btn .ims-icon::before,
  .ims-grid-container .ims-main-header .toolArea-btn .ims-icon-arrow-left::before,
  .ims-grid-container .ims-main-header .toolArea-btn .ims-icon-arrow-right::before,
  .ims-grid-container .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon::before,
  .ims-grid-container .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left::before,
  .ims-grid-container .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right::before,
  .ims-grid-container .ims-main-header .navbar-toggler .ims-icon::before,
  .ims-grid-container .ims-main-header .navbar-toggler .ims-icon-arrow-left::before,
  .ims-grid-container .ims-main-header .navbar-toggler .ims-icon-arrow-right::before,
  .popup-actionbar .toolArea .toolArea-btn .ims-icon::before,
  .popup-actionbar .toolArea .toolArea-btn .ims-icon-arrow-left::before,
  .popup-actionbar .toolArea .toolArea-btn .ims-icon-arrow-right::before,
  .popup-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon::before,
  .popup-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left::before,
  .popup-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right::before,
  .popup-actionbar .toolArea .navbar-toggler .ims-icon::before,
  .popup-actionbar .toolArea .navbar-toggler .ims-icon-arrow-left::before,
  .popup-actionbar .toolArea .navbar-toggler .ims-icon-arrow-right::before,
  .popup-actionbar .ims-main-header .toolArea-btn .ims-icon::before,
  .popup-actionbar .ims-main-header .toolArea-btn .ims-icon-arrow-left::before,
  .popup-actionbar .ims-main-header .toolArea-btn .ims-icon-arrow-right::before,
  .popup-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon::before,
  .popup-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left::before,
  .popup-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right::before,
  .popup-actionbar .ims-main-header .navbar-toggler .ims-icon::before,
  .popup-actionbar .ims-main-header .navbar-toggler .ims-icon-arrow-left::before,
  .popup-actionbar .ims-main-header .navbar-toggler .ims-icon-arrow-right::before,
  .tab-actionbar .toolArea .toolArea-btn .ims-icon::before,
  .tab-actionbar .toolArea .toolArea-btn .ims-icon-arrow-left::before,
  .tab-actionbar .toolArea .toolArea-btn .ims-icon-arrow-right::before,
  .tab-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon::before,
  .tab-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left::before,
  .tab-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right::before,
  .tab-actionbar .toolArea .navbar-toggler .ims-icon::before,
  .tab-actionbar .toolArea .navbar-toggler .ims-icon-arrow-left::before,
  .tab-actionbar .toolArea .navbar-toggler .ims-icon-arrow-right::before,
  .tab-actionbar .ims-main-header .toolArea-btn .ims-icon::before,
  .tab-actionbar .ims-main-header .toolArea-btn .ims-icon-arrow-left::before,
  .tab-actionbar .ims-main-header .toolArea-btn .ims-icon-arrow-right::before,
  .tab-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon::before,
  .tab-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left::before,
  .tab-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right::before,
  .tab-actionbar .ims-main-header .navbar-toggler .ims-icon::before,
  .tab-actionbar .ims-main-header .navbar-toggler .ims-icon-arrow-left::before,
  .tab-actionbar .ims-main-header .navbar-toggler .ims-icon-arrow-right::before,
  .navbar .toolArea .toolArea-btn .ims-icon::before,
  .navbar .toolArea .toolArea-btn .ims-icon-arrow-left::before,
  .navbar .toolArea .toolArea-btn .ims-icon-arrow-right::before,
  .navbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon::before,
  .navbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left::before,
  .navbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right::before,
  .navbar .toolArea .navbar-toggler .ims-icon::before,
  .navbar .toolArea .navbar-toggler .ims-icon-arrow-left::before,
  .navbar .toolArea .navbar-toggler .ims-icon-arrow-right::before,
  .navbar .ims-main-header .toolArea-btn .ims-icon::before,
  .navbar .ims-main-header .toolArea-btn .ims-icon-arrow-left::before,
  .navbar .ims-main-header .toolArea-btn .ims-icon-arrow-right::before,
  .navbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon::before,
  .navbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left::before,
  .navbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right::before,
  .navbar .ims-main-header .navbar-toggler .ims-icon::before,
  .navbar .ims-main-header .navbar-toggler .ims-icon-arrow-left::before,
  .navbar .ims-main-header .navbar-toggler .ims-icon-arrow-right::before {
    margin-top: calc(50% - 0.5625rem);
    margin-left: calc(50% - 0.5625rem);
    font-size: 1.125rem;
    line-height: 1.125rem; }

.ims-grid-container .toolArea .toolArea-btn .ims-icon-filtersearchreset,
.ims-grid-container .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset,
.ims-grid-container .toolArea .navbar-toggler .ims-icon-filtersearchreset,
.ims-grid-container .ims-main-header .toolArea-btn .ims-icon-filtersearchreset,
.ims-grid-container .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset,
.ims-grid-container .ims-main-header .navbar-toggler .ims-icon-filtersearchreset,
.popup-actionbar .toolArea .toolArea-btn .ims-icon-filtersearchreset,
.popup-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset,
.popup-actionbar .toolArea .navbar-toggler .ims-icon-filtersearchreset,
.popup-actionbar .ims-main-header .toolArea-btn .ims-icon-filtersearchreset,
.popup-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset,
.popup-actionbar .ims-main-header .navbar-toggler .ims-icon-filtersearchreset,
.tab-actionbar .toolArea .toolArea-btn .ims-icon-filtersearchreset,
.tab-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset,
.tab-actionbar .toolArea .navbar-toggler .ims-icon-filtersearchreset,
.tab-actionbar .ims-main-header .toolArea-btn .ims-icon-filtersearchreset,
.tab-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset,
.tab-actionbar .ims-main-header .navbar-toggler .ims-icon-filtersearchreset,
.navbar .toolArea .toolArea-btn .ims-icon-filtersearchreset,
.navbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset,
.navbar .toolArea .navbar-toggler .ims-icon-filtersearchreset,
.navbar .ims-main-header .toolArea-btn .ims-icon-filtersearchreset,
.navbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset,
.navbar .ims-main-header .navbar-toggler .ims-icon-filtersearchreset {
  cursor: pointer; }
  .ims-grid-container .toolArea .toolArea-btn .ims-icon-filtersearchreset::before,
  .ims-grid-container .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset::before,
  .ims-grid-container .toolArea .navbar-toggler .ims-icon-filtersearchreset::before,
  .ims-grid-container .ims-main-header .toolArea-btn .ims-icon-filtersearchreset::before,
  .ims-grid-container .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset::before,
  .ims-grid-container .ims-main-header .navbar-toggler .ims-icon-filtersearchreset::before,
  .popup-actionbar .toolArea .toolArea-btn .ims-icon-filtersearchreset::before,
  .popup-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset::before,
  .popup-actionbar .toolArea .navbar-toggler .ims-icon-filtersearchreset::before,
  .popup-actionbar .ims-main-header .toolArea-btn .ims-icon-filtersearchreset::before,
  .popup-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset::before,
  .popup-actionbar .ims-main-header .navbar-toggler .ims-icon-filtersearchreset::before,
  .tab-actionbar .toolArea .toolArea-btn .ims-icon-filtersearchreset::before,
  .tab-actionbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset::before,
  .tab-actionbar .toolArea .navbar-toggler .ims-icon-filtersearchreset::before,
  .tab-actionbar .ims-main-header .toolArea-btn .ims-icon-filtersearchreset::before,
  .tab-actionbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset::before,
  .tab-actionbar .ims-main-header .navbar-toggler .ims-icon-filtersearchreset::before,
  .navbar .toolArea .toolArea-btn .ims-icon-filtersearchreset::before,
  .navbar .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset::before,
  .navbar .toolArea .navbar-toggler .ims-icon-filtersearchreset::before,
  .navbar .ims-main-header .toolArea-btn .ims-icon-filtersearchreset::before,
  .navbar .ims-main-header .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-filtersearchreset::before,
  .navbar .ims-main-header .navbar-toggler .ims-icon-filtersearchreset::before {
    margin-top: calc(50% - 0.75rem);
    margin-left: calc(50% - 0.75rem);
    font-size: var(--ims-font__size--500);
    line-height: 1.5rem; }

.toolArea .ims-gridMenuEdit .menuSeparator .k-icon {
  display: block; }

.toolArea .ims-gridMenuEdit .menuSeparator .k-animation-container .k-icon {
  display: none !important; }

.toolAreaCustom {
  width: 100%;
  padding: 0.3125rem 0; }
  .toolAreaCustom .input-element,
  .toolAreaCustom .ims-gridMenuEdit {
    display: inline-block;
    height: 2.5rem;
    width: 2.5rem;
    margin-right: var(--ims-spacing--25);
    background-color: var(--bs-white); }
    .toolAreaCustom .input-element:hover,
    .toolAreaCustom .ims-gridMenuEdit:hover {
      color: var(--bs-primary-dark); }
    .toolAreaCustom .input-element .k-state-default,
    .toolAreaCustom .ims-gridMenuEdit .k-state-default {
      color: var(--bs-on-primary); }
    .toolAreaCustom .input-element .k-state-disabled,
    .toolAreaCustom .ims-gridMenuEdit .k-state-disabled {
      background-color: var(--bs-surface-highest);
      opacity: 1;
      cursor: default; }
      .toolAreaCustom .input-element .k-state-disabled .ims-icon, .toolAreaCustom .input-element .k-state-disabled .ims-icon-arrow-left, .toolAreaCustom .input-element .k-state-disabled .ims-icon-arrow-right,
      .toolAreaCustom .ims-gridMenuEdit .k-state-disabled .ims-icon,
      .toolAreaCustom .ims-gridMenuEdit .k-state-disabled .ims-icon-arrow-left,
      .toolAreaCustom .ims-gridMenuEdit .k-state-disabled .ims-icon-arrow-right {
        color: var(--bs-on-primary); }
  .toolAreaCustom .searchBoxArea {
    width: 17.5rem;
    background-color: transparent; }
    .toolAreaCustom .searchBoxArea .k-textbox.k-space-right {
      background-color: var(--bs-white);
      padding-right: 2.5rem;
      height: 100%;
      border: 1px solid var(--bs-white);
      border-radius: 2px 0 0 2px; }
      .toolAreaCustom .searchBoxArea .k-textbox.k-space-right:hover {
        box-shadow: none; }
      .toolAreaCustom .searchBoxArea .k-textbox.k-space-right .k-icon {
        right: 0;
        margin-top: -0.625rem; }
    .toolAreaCustom .searchBoxArea span.k-widget.k-header,
    .toolAreaCustom .searchBoxArea input.k-input {
      height: 100%;
      padding: 0;
      border: none; }
      .toolAreaCustom .searchBoxArea span.k-widget.k-header.k-state-focused, .toolAreaCustom .searchBoxArea span.k-widget.k-header.k-state-hover,
      .toolAreaCustom .searchBoxArea input.k-input.k-state-focused,
      .toolAreaCustom .searchBoxArea input.k-input.k-state-hover {
        box-shadow: none; }
    .toolAreaCustom .searchBoxArea a:not([href]):not([tabindex]) {
      width: 2.5rem;
      font-size: 1.125rem; }
      .toolAreaCustom .searchBoxArea a:not([href]):not([tabindex])::before {
        margin-left: auto;
        margin-right: auto;
        font-size: 1.125rem;
        line-height: 1.125rem; }
  .toolAreaCustom .ims-gridMenuEdit {
    background-color: var(--bs-primary); }
    .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu,
    .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item,
    .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      float: none;
      position: static; }
      .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-animation-container,
      .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-animation-container,
      .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link > .k-animation-container {
        margin-left: 1.875rem; }
      .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon, .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon-arrow-left, .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon-arrow-right,
      .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon,
      .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon-arrow-left,
      .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon-arrow-right,
      .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
      .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
      .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right {
        float: none; }
      .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu .k-icon,
      .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .k-icon,
      .toolAreaCustom .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .k-icon {
        display: none; }
  .toolAreaCustom .toolArea-btn,
  .toolAreaCustom .k-widget.k-reset.k-menu > .k-item > .k-link {
    padding: 0; }
    .toolAreaCustom .toolArea-btn .ims-icon, .toolAreaCustom .toolArea-btn .ims-icon-arrow-left, .toolAreaCustom .toolArea-btn .ims-icon-arrow-right,
    .toolAreaCustom .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
    .toolAreaCustom .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
    .toolAreaCustom .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right {
      width: 100%;
      height: 100%;
      margin: 0;
      float: none; }
      .toolAreaCustom .toolArea-btn .ims-icon::before, .toolAreaCustom .toolArea-btn .ims-icon-arrow-left::before, .toolAreaCustom .toolArea-btn .ims-icon-arrow-right::before,
      .toolAreaCustom .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon::before,
      .toolAreaCustom .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left::before,
      .toolAreaCustom .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right::before {
        margin-top: calc(50% - 0.5625rem);
        margin-left: calc(50% - 0.5625rem);
        font-size: 1.125rem;
        line-height: 1.125rem; }

.toolAreaCustomPopUp {
  width: 100%;
  padding: 0.3125rem 0; }
  .toolAreaCustomPopUp .input-element,
  .toolAreaCustomPopUp .ims-gridMenuEdit {
    display: inline-block;
    height: 2.5rem;
    width: 2.5rem;
    margin-right: var(--ims-spacing--25);
    background-color: var(--bs-primary); }
    .toolAreaCustomPopUp .input-element:hover,
    .toolAreaCustomPopUp .ims-gridMenuEdit:hover {
      color: var(--bs-primary-dark); }
    .toolAreaCustomPopUp .input-element .k-state-default,
    .toolAreaCustomPopUp .ims-gridMenuEdit .k-state-default {
      color: var(--bs-on-primary); }
    .toolAreaCustomPopUp .input-element .k-state-disabled,
    .toolAreaCustomPopUp .ims-gridMenuEdit .k-state-disabled {
      background-color: var(--bs-surface-highest);
      opacity: 1; }
      .toolAreaCustomPopUp .input-element .k-state-disabled .ims-icon, .toolAreaCustomPopUp .input-element .k-state-disabled .ims-icon-arrow-left, .toolAreaCustomPopUp .input-element .k-state-disabled .ims-icon-arrow-right,
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-state-disabled .ims-icon,
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-state-disabled .ims-icon-arrow-left,
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-state-disabled .ims-icon-arrow-right {
        color: var(--bs-on-primary); }
  .toolAreaCustomPopUp .searchBoxArea {
    width: 17.5rem;
    background-color: transparent; }
    .toolAreaCustomPopUp .searchBoxArea .k-textbox.k-space-right {
      background-color: var(--bs-primary);
      padding-right: 2.5rem;
      height: 100%;
      border: 1px solid var(--bs-primary);
      border-radius: 2px 0 0 2px; }
      .toolAreaCustomPopUp .searchBoxArea .k-textbox.k-space-right:hover {
        box-shadow: none; }
      .toolAreaCustomPopUp .searchBoxArea .k-textbox.k-space-right .k-icon {
        right: 0;
        margin-top: -0.625rem; }
    .toolAreaCustomPopUp .searchBoxArea span.k-widget.k-header,
    .toolAreaCustomPopUp .searchBoxArea input.k-input {
      height: 100%;
      padding: 0;
      border: none; }
      .toolAreaCustomPopUp .searchBoxArea span.k-widget.k-header.k-state-focused, .toolAreaCustomPopUp .searchBoxArea span.k-widget.k-header.k-state-hover,
      .toolAreaCustomPopUp .searchBoxArea input.k-input.k-state-focused,
      .toolAreaCustomPopUp .searchBoxArea input.k-input.k-state-hover {
        box-shadow: none; }
    .toolAreaCustomPopUp .searchBoxArea a:not([href]):not([tabindex]) {
      width: 2.5rem;
      font-size: 1.125rem; }
      .toolAreaCustomPopUp .searchBoxArea a:not([href]):not([tabindex])::before {
        margin-left: auto;
        margin-right: auto;
        font-size: 1.125rem;
        line-height: 1.125rem; }
  .toolAreaCustomPopUp .ims-gridMenuEdit {
    background-color: var(--bs-primary); }
    .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu,
    .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item,
    .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      float: none;
      position: static; }
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-animation-container,
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-animation-container,
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link > .k-animation-container {
        margin-left: var(--ims-spacing--75); }
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon, .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon-arrow-left, .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu .ims-icon-arrow-right,
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon,
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon-arrow-left,
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .ims-icon-arrow-right,
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right {
        float: none; }
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu .k-icon,
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item .k-icon,
      .toolAreaCustomPopUp .ims-gridMenuEdit .k-widget.k-reset.k-menu > .k-item > .k-link .k-icon {
        display: none; }
  .toolAreaCustomPopUp .toolArea-btn .ims-icon, .toolAreaCustomPopUp .toolArea-btn .ims-icon-arrow-left, .toolAreaCustomPopUp .toolArea-btn .ims-icon-arrow-right,
  .toolAreaCustomPopUp .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon,
  .toolAreaCustomPopUp .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left,
  .toolAreaCustomPopUp .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right {
    width: 100%;
    height: 100%;
    margin: 0;
    float: none; }
    .toolAreaCustomPopUp .toolArea-btn .ims-icon::before, .toolAreaCustomPopUp .toolArea-btn .ims-icon-arrow-left::before, .toolAreaCustomPopUp .toolArea-btn .ims-icon-arrow-right::before,
    .toolAreaCustomPopUp .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon::before,
    .toolAreaCustomPopUp .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left::before,
    .toolAreaCustomPopUp .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right::before {
      margin-top: calc(50% - 0.5625rem);
      margin-left: calc(50% - 0.5625rem);
      font-size: 1.125rem;
      line-height: 1.125rem; }

/*#endregion ims grid container styles*/
#leftPaneContent > div.contentArea > div.toolArea > div.searchBoxArea > span.k-textbox:hover {
  background-color: var(--bs-white);
  border-color: var(--bs-dark-border-subtle);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }

#leftPaneContent > div.contentArea > div.toolArea > div.searchBoxArea > span > span.k-autocomplete.k-state-hover {
  background-color: var(--bs-white);
  border-color: var(--bs-dark-border-subtle);
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2), 0 0px 0px rgba(0, 0, 0, 0.05); }

.k-header.k-grid-toolbar {
  border-color: var(--bs-dark-border-subtle);
  background-color: var(--bs-light-bg-subtle); }

.k-header > .k-header-column-menu {
  z-index: 0; }

.k-grid-content .warning-row {
  color: var(--bs-white);
  background: var(--bs-danger); }

.k-grid-content {
  overflow-y: auto; }

.k-window-content {
  overflow: auto; }

.k-animation-container > ul {
  max-width: 300px !important; }

.k-grid .k-grouping-header {
  background-color: var(--bs-primary-lightest);
  color: var(--bs-on-primary); }

#PagesTreeViewMenu,
#PagesAddMenu,
.ims-gridMenuEdit {
  position: relative; }

.applyMatrixSignatureContainer .k-widget.k-dropdown.k-header.applyMatrixSignature {
  width: 21.875rem;
  padding: 0;
  margin-left: var(--ims-spacing--10);
  margin-right: var(--ims-spacing--10); }
  .applyMatrixSignatureContainer .k-widget.k-dropdown.k-header.applyMatrixSignature .k-dropdown-wrap.k-state-default, .applyMatrixSignatureContainer .k-widget.k-dropdown.k-header.applyMatrixSignature .k-dropdown-wrap.k-state-hover, .applyMatrixSignatureContainer .k-widget.k-dropdown.k-header.applyMatrixSignature .k-dropdown-wrap.k-state-active {
    border: 0;
    padding-bottom: 0; }

.applyMatrixSignatureContainer .k-widget.k-dropdown.applyMatrixSignature {
  border: 1px solid var(--bs-border-color) !important; }

.applyMatrixSignatureContainer .k-button {
  text-transform: none;
  width: 12.5rem;
  height: 1.875rem;
  font-size: var(--ims-font__size--100);
  padding: 0;
  color: var(--bs-primary-lightest);
  border-radius: var(--ims-border__radius--0); }

/* UI- bugfix kendo animation with long texts */
#trainingItemFormUpdateTarget .objectDetailsContainer .k-menu .k-animation-container,
#trainingItemFormUpdateTarget .objectDetailsContainer .k-menu .k-animation-container ul {
  border: none;
  white-space: normal; }

#supervisorContent .UserSupervisorListItem {
  margin-top: 1.625rem; }

.userAddresses #dataList {
  margin-top: 2.75rem; }

#supervisorMenu .k-menu .k-item > .k-link > .k-i-arrow-60-down,
#listPlaceholder .k-menu .k-item > .k-link > .k-i-arrow-60-down,
#addUserForm .k-menu .k-item > .k-link > .k-i-arrow-60-down {
  display: none; }

.k-panel > li.k-item,
.k-panelbar > li.k-item,
.k-tiles {
  background-color: var(--bs-white); }

.k-editable-area,
.k-panel > li.k-item,
.k-panelbar > li.k-item,
.k-tiles {
  background-color: var(--bs-light-bg-subtle); }

.k-treeview .k-item .k-icon {
  margin-top: -0.3125rem; }

.treeview-placeholder {
  margin-left: 0rem; }
  .treeview-placeholder .k-in .treeViewItemButton {
    display: none;
    margin-left: var(--ims-spacing--10); }
  .treeview-placeholder .k-in.k-state-hover .treeViewItemButton {
    display: inline-block; }

.k-widget .k-window .k-content .k-panelbar-collapse.k-icon,
.k-panelbar-expand.k-icon {
  position: absolute;
  top: 50%;
  right: 0.125rem;
  margin-top: -8px; }

.k-window-titlebar {
  padding: var(--ims-spacing--35) var(--ims-spacing--60);
  background-color: var(--bs-light-bg-subtle);
  color: var(--bs-body-color); }
  .k-window-titlebar .k-window-actions {
    right: 1.4em;
    padding-top: 0.6rem; }
  .k-window-titlebar .k-window-title {
    font-size: var(--ims-font__size--200);
    line-height: 1.25rem;
    padding-left: 0rem; }

.k-widget .k-window .k-content,
.buttons-row {
  float: left;
  text-align: right;
  width: 100%;
  padding-right: 2.25rem; }

.k-widget .k-window .k-content,
.buttons-row .k-button {
  font-size: 1.2em;
  border-radius: var(--ims-border__radius--0);
  margin: 0 1px;
  height: 2.5rem;
  padding: var(--ims-spacing--25) var(--ims-spacing--25);
  box-shadow: none; }

.k-edge .k-editor-toolbar,
.k-ff .k-editor-toolbar,
.k-ie9 .k-editor-toolbar,
.k-webkit .k-editor-toolbar {
  padding: 0;
  background-color: rgba(var(--bs-surface-low-rgb), var(--bs-bg-opacity)) !important; }

.k-editor .k-editor-toolbar-wrap {
  padding-left: 10px; }

.k-editor .k-resize-handle {
  display: none; }

.k-tool-group .k-picker-wrap.k-state-default {
  border: none !important; }

.k-dropdown-wrap.k-state-default span {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  /* Internet Explorer/Edge */
  user-select: none; }

.k-dropdown.k-state-default {
  border-color: var(--bs-dark-border-subtle); }

.k-editor-toolbar-wrap .k-dropdown.k-state-default {
  border-color: transparent !important; }

.tab-actionbar {
  height: 2.125rem;
  color: var(--bs-on-primary); }
  .tab-actionbar .input-element.searchBoxArea {
    float: right;
    margin-right: 0;
    padding-top: 0rem;
    padding-right: 0.9375rem; }
  .tab-actionbar .toolArea {
    background-color: transparent; }

.k-timepicker .k-icon {
  position: static; }

.k-dialog.k-confirm .k-window-titlebar {
  background-color: var(--bs-primary-dark) !important; }
  .k-dialog.k-confirm .k-window-titlebar .k-dialog-title {
    color: var(--bs-on-primary) !important;
    padding-left: 0rem !important; }
  .k-dialog.k-confirm .k-window-titlebar .k-dialog-actions {
    color: var(--bs-on-primary) !important;
    top: 0rem !important; }

.k-dialog .k-content {
  font-weight: bold; }

.k-dialog-buttongroup {
  padding: var(--ims-spacing--20) var(--ims-spacing--60) !important;
  margin: 0 !important;
  border-top: 0.0625rem solid var(--bs-dark-border-subtle) !important;
  flex-direction: row-reverse; }

.k-dialog .k-dialog-buttongroup.k-dialog-button-layout-stretched .k-button {
  font-size: var(--ims-font__size--60) !important;
  line-height: 2.375rem !important;
  height: 2.375rem !important;
  padding: 0 1.25rem !important;
  margin: 0 !important;
  margin-left: 0rem !important;
  margin-left: 0.25rem !important;
  background-color: var(--bs-primary-lightest) !important; }
  .k-dialog .k-dialog-buttongroup.k-dialog-button-layout-stretched .k-button:last-child {
    background-color: var(--bs-border-color) !important;
    color: var(--bs-on-primary) !important; }

div.k-loading-mask .k-loading-image {
  background-image: none;
  background: none; }
  div.k-loading-mask .k-loading-image:before {
    margin: 0;
    font-family: "fontello";
    font-size: 1.875rem;
    content: "\e9dc";
    color: var(--bs-primary-dark);
    animation: spin 2s infinite linear;
    display: inline-block;
    position: absolute;
    left: calc(50% - 0.9375rem);
    top: calc(50% - 0.9375rem); }

.sideContentArea .objectTab .innerContent .tab-content .detailsContent #EmailPlainMenu #iconButton {
  display: inline-block; }
  .sideContentArea .objectTab .innerContent .tab-content .detailsContent #EmailPlainMenu #iconButton span {
    padding: 0rem 0rem 0rem 1.625rem; }

/* fix for kendo drop down overflow in trainings structure tab as long as the action menu isn't fixed*/
#trainingStructure .k-group.k-menu-group.k-popup.k-reset .k-link {
  overflow-x: hidden;
  text-overflow: ellipsis; }

.k-gantt {
  border: none; }
  .k-gantt .k-header.k-gantt-toolbar,
  .k-gantt .k-treelist .k-state-selected td,
  .k-gantt .k-treelist .k-alt.k-state-selected > td,
  .k-gantt .k-treelist .k-alt.k-state-selected:hover > td {
    background-color: var(--bs-primary-lightest); }
  .k-gantt .k-header.k-gantt-views,
  .k-gantt .k-gantt-toolbar .k-button,
  .k-gantt > .k-header .k-link,
  .k-gantt > .k-header li {
    border: none; }
  .k-gantt .k-gantt-toolbar .k-gantt-action-btn {
    left: 4%;
    border: 0.0625rem solid var(--bs-on-primary);
    border-radius: 0.3125rem; }
  .k-gantt .k-gantt-toolbar .k-gantt-action-btn:hover {
    border-color: var(--bs-on-primary); }
  .k-gantt .k-grid td {
    padding: 0; }
  .k-gantt .k-grid,
  .k-gantt .k-treelist .k-alt {
    background-color: transparent; }
  .k-gantt .k-task-wrap {
    padding: 0;
    margin: 0; }
  .k-gantt .k-state-selected {
    color: var(--bs-on-primary); }
    .k-gantt .k-state-selected.k-task-single {
      box-shadow: 0 0.3125rem 0.5rem -0.125rem rgba(0, 0, 0, 0.2); }
  .k-gantt .k-task-single {
    margin-top: 0;
    background-color: var(--bs-primary); }
  .k-gantt .k-task-single .unit-unassigned {
    background-color: var(--bs-orange);
    margin: -0.5rem -0.625rem;
    padding: var(--ims-spacing--20) var(--ims-spacing--25); }
  .k-gantt .k-grid-content tr {
    height: auto; }
  .k-gantt .k-task-template {
    padding: var(--ims-spacing--20) var(--ims-spacing--25); }
  .k-gantt .k-gantt-toolbar .k-button:hover,
  .k-gantt .k-gantt-toolbar .k-gantt-views li.k-state-hover,
  .k-gantt .k-gantt-toolbar .k-gantt-views li.k-state-hover .k-link {
    background-color: rgba(var(--bs-white-rgb), 0.1); }

.k-gantt-timeline .k-grid-header .k-header {
  padding: 0;
  padding-left: var(--ims-spacing--25); }

ul.ims-tab-menu {
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
  list-style: none; }
  ul.ims-tab-menu .ims-tab-item {
    padding: 0.8125rem var(--ims-spacing--50);
    border: 0.0625rem solid var(--bs-primary-lightest);
    min-width: 5rem;
    text-align: center;
    margin-right: -0.0625rem;
    cursor: pointer; }
    ul.ims-tab-menu .ims-tab-item.tab-active {
      background-color: var(--bs-primary-lightest);
      color: var(--bs-on-primary); }

.k-popup.k-list-container {
  padding: 0 0 !important; }

.toolButtonArea ul.k-widget.k-menu > li.k-item .k-icon {
  display: none; }

.k-list-container {
  white-space: normal !important; }
  .k-list-container .k-list .k-item {
    padding-right: var(--ims-spacing--25); }
  .k-list-container .k-list-filter input.k-textbox {
    padding-right: var(--ims-spacing--0);
    height: 2.175rem !important; }

.k-column-menu .k-list-scroller .k-item.k-state-hover {
  color: var(--bs-on-primary); }

.k-column-menu .k-primary {
  color: var(--bs-primary) !important; }
  .k-column-menu .k-primary:hover {
    color: var(--bs-on-primary) !important; }

.k-widget.k-listview {
  border: 0.0625rem solid var(--bs-border-color); }

.kendoCustomAutoCompleteSearch .k-icon.ims-icon-search {
  width: 18px; }

.kendoCustomAutoCompleteSearch .k-clear-value.k-i-close {
  margin-top: 0; }

.kendoCustomAutoCompleteSearch .k-input {
  height: 20px; }

.toolArea .k-animation-container {
  z-index: 2 !important; }

.toolArea .ims-gridMenuEdit .k-menu .k-popup .ims-icon, .toolArea .ims-gridMenuEdit .k-menu .k-popup .ims-icon-arrow-left, .toolArea .ims-gridMenuEdit .k-menu .k-popup .ims-icon-arrow-right {
  color: var(--bs-primary-lightest) !important; }

.toolArea .ims-gridMenuEdit .k-state-disabled {
  height: 2.5rem !important; }

.toolArea .k-dropdown {
  background-color: transparent !important; }

.contentArea .toolBarArea .k-autocomplete .k-input {
  line-height: 2.2rem !important; }

.k-window .toolBarArea .k-autocomplete .k-input,
.modal-content .toolBarArea .k-autocomplete .k-input {
  line-height: 1.8rem !important; }

.k-editor-toolbar-wrap .k-dropdown-wrap > .k-input {
  height: 1.86rem; }

.k-editor-toolbar-wrap .k-dropdown-wrap.k-state-default.k-state-hover .k-select {
  background-color: var(--bs-white) !important; }

.k-editor-toolbar-wrap .k-select {
  height: 1.86rem;
  line-height: 1.86rem; }

.k-editor-toolbar-wrap .k-picker-wrap.k-state-default {
  background-color: var(--bs-light-bg-subtle);
  height: 1.86rem;
  line-height: 1.86rem; }
  .k-editor-toolbar-wrap .k-picker-wrap.k-state-default .k-select {
    background-color: var(--bs-light-bg-subtle) !important; }
  .k-editor-toolbar-wrap .k-picker-wrap.k-state-default.k-state-hover .k-select {
    background-color: var(--bs-white) !important; }

.k-editor-toolbar-wrap .k-toolbar .k-button.k-tool {
  border: 1px solid var(--bs-border-color);
  color: var(--bs-body-color) !important; }

.k-editor-toolbar-wrap .k-toolbar .k-editor-dropdown {
  border: 1px solid var(--bs-border-color) !important; }

.k-editor-toolbar-wrap .k-button.k-state-active:hover,
.k-editor-toolbar-wrap .k-button.k-state-selected:hover,
.k-editor-toolbar-wrap .k-button.k-state-selected.k-state-hover {
  border-color: var(--bs-border-color);
  background-color: var(--bs-surface-high);
  color: var(--bs-body-color); }

.k-editor-toolbar-wrap .k-editor-dropdown.k-group-start.k-group-end {
  margin-left: 0.2rem; }

input.k-radio[type="radio"]:checked {
  content: "" !important;
  width: 16px !important;
  height: 16px !important;
  border-style: solid !important;
  background-color: var(--bs-primary) !important; }

.k-radio-label {
  font-weight: 400; }
  .k-radio-label::before {
    border-color: var(--bs-border-color) !important;
    border-radius: 50% !important;
    background-color: var(--bs-white) !important;
    border-width: 1px !important; }

.k-radio:checked + .k-radio-label::after {
  content: "" !important;
  width: 10px !important;
  height: 10px !important;
  position: absolute !important;
  top: 3px !important;
  left: 3px !important; }

.k-widget .k-radio:checked + .k-radio-label::after {
  content: "" !important;
  width: 10px !important;
  height: 10px !important;
  position: absolute !important;
  top: 3px !important;
  left: px !important;
  background-color: var(--bs-primary) !important;
  border-radius: 50% !important; }

.k-widget .k-radio-label {
  padding-left: 20px; }

.k-widget .k-radio-label::before {
  border-color: var(--bs-border-color) !important;
  border-radius: 50% !important;
  background-color: var(--bs-white) !important;
  border-width: 1px !important;
  border-style: solid !important; }

.k-widget .k-radio-label::before {
  content: "" !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 16px !important;
  height: 16px !important;
  border-style: solid !important; }

.k-checkbox:checked {
  background-color: var(--bs-primary) !important; }

.k-calendar .k-link,
.k-calendar .k-button,
.k-scheduler .k-link,
.k-scheduler .k-button {
  color: var(--bs-body-color); }

.dataColumn span.k-widget.k-textbox {
  width: 100%; }

.dataColumn span.k-combobox.k-combobox-clearable .k-dropdown-wrap.k-state-default {
  background-color: var(--bs-white) !important; }

.dataColumn span.k-combobox.k-combobox-clearable:hover .k-dropdown-wrap.k-state-default:hover {
  background-color: rgba(var(--bs-surface-low-rgb), 0.3) !important; }

.k-viewhtml-dialog .k-editor-textarea {
  width: calc(100% - 3.2em);
  padding: .24em .24em .24em .4em; }

.k-pager-wrap .k-link:focus {
  box-shadow: inset 0 0 0 0px var(--bs-dark-border-subtle); }

.k-menu .k-item.k-state-default.k-state-focused {
  box-shadow: inset 0 0 0 0px; }

.k-coloreditor-header .k-button.k-icon-button {
  background-color: var(--bs-surface-high) !important; }

.k-coloreditor-header .k-button-group {
  box-shadow: none !important; }

.k-coloreditor-header .k-button-text {
  margin-left: 0rem !important; }
  .k-coloreditor-header .k-button-text .k-icon {
    height: inherit; }

.k-coloreditor-views .k-icon {
  height: inherit; }

.k-edit-form-container .k-primary,
.k-popup .k-primary {
  color: var(--bs-primary); }
  .k-edit-form-container .k-primary:hover,
  .k-popup .k-primary:hover {
    color: var(--bs-on-primary); }

.k-filterable .k-filter-menu-container .k-widget.k-dropdown .k-dropdown-wrap.k-state-default {
  border: 1px solid var(--bs-dark-border-subtle) !important;
  border-radius: var(--ims-border__radius--50); }

.k-button-group {
  box-shadow: none !important; }

.k-switch-handle {
  background-color: var(--bs-light-bg-subtle);
  width: 2.5em !important;
  height: 2.5em !important; }

.k-switch-container {
  background-color: var(--bs-light-bg-subtle) !important;
  height: 1.2em !important; }

.k-switch-on .k-switch-handle {
  background-color: var(--bs-primary) !important;
  width: 2.5em !important;
  height: 2.5em !important; }

.modal-open .k-list-container .k-list-scroller li.k-item.k-state-focused:hover {
  color: var(--bs-body-color) !important; }

@media (max-width: 991px) {
  .modal-open .k-animation-container .k-list-scroller .k-list .k-item {
    padding-right: 0.625rem; } }

.k-state-hover,
.k-state-focused,
.k-state-focused > .k-multiselect-wrap,
.k-textbox:hover,
.k-textbox:focus,
.k-picker-wrap.k-state-hover,
.k-picker-wrap.k-state-focused,
.k-picker-wrap.k-state-border-up,
.k-autocomplete.k-state-hover,
.k-autocomplete.k-state-focused {
  box-shadow: none; }

.k-list > .k-state-focused:not(:hover) {
  background-color: transparent;
  color: inherit; }

.k-multiselect-wrap li {
  border-radius: var(--ims-border__radius--0); }

.k-multiselect-wrap input.k-input:focus {
  box-shadow: none !important; }

.field-validation-error {
  display: block;
  font-size: var(--ims-font__size--80);
  margin-top: 0.1875rem;
  color: var(--bs-danger); }

.k-button {
  box-shadow: none;
  border: 0.0625rem solid transparent;
  color: var(--bs-primary);
  text-transform: none;
  border: 0.0625rem solid transparent;
  color: var(--bs-primary);
  text-transform: none;
  padding: 6px 14px; }
  .k-button:hover, .k-button:focus, .k-button:focus:hover, .k-button:active, .k-button.k-state-active, .k-button.k-state-hover, .k-button.k.state-focused {
    box-shadow: none;
    background-color: var(--ims-btn-light__background--hovered); }

.k-button.k-button-icon,
.k-icon-button {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-max-content;
  height: max-content; }

.k-scheduler-navigation .k-button {
  background-color: var(--bs-white); }
  .k-scheduler-navigation .k-button .k-button-text {
    margin-left: 0.0rem; }

.k-animation-container,
.k-animation-container *,
.k-animation-container ::after,
.k-block .k-header,
.k-dialog .k-window-content,
.k-list-container,
.k-widget,
.k-widget *,
.k-widget ::before {
  box-sizing: inherit; }

.k-animation-container .k-list-container .k-list-scroller li.k-item.k-state-focused {
  background-color: var(--bs-gray-200-hover) !important; }

.k-animation-container .k-list-container .k-list-scroller li.k-item .ims-tags-small-rounded {
  margin-top: 0.4rem; }

.k-animation-container .k-list-container .k-list-scroller .k-list .k-item span:not(.k-state-default) {
  padding-left: var(--ims-spacing--10) !important;
  padding-right: var(--ims-spacing--10) !important; }

.k-animation-container .k-popup .k-list .k-item {
  min-height: 1.83em !important;
  line-height: 1.83em !important; }

.k-animation-container .k-list-filter input.k-textbox {
  padding-right: 0rem; }

.k-animation-container .k-popup .k-button {
  color: var(--bs-body-color);
  background-color: var(--bs-secondary-bg-subtle); }
  .k-animation-container .k-popup .k-button:hover {
    background-color: var(--bg-surface-low-hover);
    border-color: var(--bg-surface-low-hover); }
  .k-animation-container .k-popup .k-button.k-primary {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
    color: var(--bs-on-primary) !important; }
    .k-animation-container .k-popup .k-button.k-primary:hover {
      background-color: var(--bs-primary-dark);
      border-color: var(--bs-primary-dark); }

.full-width-calendar {
  width: 100%; }
  .full-width-calendar .k-calendar-view {
    width: 100%; }

.k-calendar {
  display: block; }
  .k-calendar .k-header,
  .k-calendar .k-calendar-view {
    z-index: 0; }
  .k-calendar .k-calendar-view {
    height: auto; }
  .k-calendar .k-content td {
    border-radius: var(--ims-border__radius--0); }
    .k-calendar .k-content td.k-state-hover, .k-calendar .k-content td.k-state-selected, .k-calendar .k-content td.k-state-focused, .k-calendar .k-content td.k-state-hover.k-state-selected, .k-calendar .k-content td.k-today {
      cursor: pointer;
      box-shadow: none; }
      .k-calendar .k-content td.k-state-hover .k-link, .k-calendar .k-content td.k-state-selected .k-link, .k-calendar .k-content td.k-state-focused .k-link, .k-calendar .k-content td.k-state-hover.k-state-selected .k-link, .k-calendar .k-content td.k-today .k-link {
        background-color: transparent;
        border: none;
        box-shadow: none; }
  .k-calendar table {
    border-collapse: initial; }

.k-calendar .k-header,
.k-scheduler .k-header {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem; }
  .k-calendar .k-header .k-link,
  .k-calendar .k-header .k-button,
  .k-scheduler .k-header .k-link,
  .k-scheduler .k-header .k-button {
    margin-top: 0.1875rem;
    color: var(--bs-body-color);
    background-color: var(--bs-white);
    border-color: var(--bs-white);
    color: var(--bs-body-color); }
    .k-calendar .k-header .k-link.k-nav-next, .k-calendar .k-header .k-link.k-nav-prev,
    .k-calendar .k-header .k-button.k-nav-next,
    .k-calendar .k-header .k-button.k-nav-prev,
    .k-scheduler .k-header .k-link.k-nav-next,
    .k-scheduler .k-header .k-link.k-nav-prev,
    .k-scheduler .k-header .k-button.k-nav-next,
    .k-scheduler .k-header .k-button.k-nav-prev {
      background-color: var(--bs-light-bg-subtle); }
  .k-calendar .k-header .k-link:not(.k-nav-fast),
  .k-scheduler .k-header .k-link:not(.k-nav-fast) {
    padding: 0.3125rem;
    margin-top: 0.3125rem; }
    .k-calendar .k-header .k-link:not(.k-nav-fast) .k-icon,
    .k-scheduler .k-header .k-link:not(.k-nav-fast) .k-icon {
      height: 1.25rem;
      width: 1.25rem;
      font-size: var(--ims-font__size--300); }
  .k-calendar .k-header .k-link.k-state-hover,
  .k-scheduler .k-header .k-link.k-state-hover {
    background-color: var(--bs-light-bg-subtle);
    color: var(--bs-body-color); }
  .k-calendar .k-header .k-link:not(.k-nav-fast),
  .k-scheduler .k-header .k-link:not(.k-nav-fast) {
    padding: 0.3125rem;
    margin-top: 0.3125rem; }
  .k-calendar .k-header li,
  .k-scheduler .k-header li {
    background-color: transparent;
    border-color: transparent; }
  .k-calendar .k-header .k-button,
  .k-scheduler .k-header .k-button {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary); }
    .k-calendar .k-header .k-button.k-state-hover,
    .k-scheduler .k-header .k-button.k-state-hover {
      background-color: var(--bs-light-bg-subtle);
      border-color: var(--bs-border-color); }
    .k-calendar .k-header .k-button:hover,
    .k-scheduler .k-header .k-button:hover {
      background-color: var(--bs-primary-lightest);
      border-color: transparent; }

.k-calendar .k-content td,
.k-scheduler .k-content td {
  border: 0.0625rem solid var(--bs-white);
  border-radius: 0.1875rem; }
  .k-calendar .k-content td.k-state-hover,
  .k-scheduler .k-content td.k-state-hover {
    background-color: var(--bs-secondary-bg); }
  .k-calendar .k-content td.k-state-selected,
  .k-scheduler .k-content td.k-state-selected {
    background-color: var(--bs-primary);
    color: var(--bs-white); }
    .k-calendar .k-content td.k-state-selected.k-today,
    .k-scheduler .k-content td.k-state-selected.k-today {
      box-shadow: none; }
  .k-calendar .k-content td.k-state-focused:not(.k-state-selected),
  .k-scheduler .k-content td.k-state-focused:not(.k-state-selected) {
    border-color: var(--bs-border-color); }
  .k-calendar .k-content td.k-today:not(.k-state-selected),
  .k-scheduler .k-content td.k-today:not(.k-state-selected) {
    background-color: var(--bs-primary); }
    .k-calendar .k-content td.k-today:not(.k-state-selected) a,
    .k-scheduler .k-content td.k-today:not(.k-state-selected) a {
      color: var(--bs-white);
      background-color: var(--bs-primary); }

.k-calendar .k-scheduler-layout td .k-state-selected,
.k-scheduler .k-scheduler-layout td .k-state-selected {
  background-color: var(--bs-primary);
  color: var(--bs-white); }

.k-calendar .k-scheduler-table .k-today,
.k-scheduler .k-scheduler-table .k-today {
  background-color: var(--bs-primary);
  background-color: transparent;
  border-width: 0.0625rem 0.0625rem 0.0625rem 0.0625rem;
  color: var(--bs-primary) !important; }

.k-calendar .k-scheduler-toolbar .k-state-hover,
.k-scheduler .k-scheduler-toolbar .k-state-hover {
  color: var(--ims-btn-secondary__font-color--hovered);
  background-color: var(--ims-btn-secondary__background--hovered);
  border-color: var(--ims-btn-secondary__border-color--hovered); }

.k-calendar .k-scheduler-toolbar .k-state-selected,
.k-scheduler .k-scheduler-toolbar .k-state-selected {
  color: var(--ims-btn-secondary__font-color--hovered);
  background-color: var(--ims-btn-secondary__background--hovered);
  border-color: var(--ims-btn-secondary__border-color--hovered); }

.k-calendar .k-scheduler-toolbar .k-button.k-state-selected:hover,
.k-scheduler .k-scheduler-toolbar .k-button.k-state-selected:hover {
  color: var(--ims-btn-secondary__font-color--hovered); }

.k-calendar .k-scheduler-toolbar .k-scheduler-views li.k-state-hover,
.k-scheduler .k-scheduler-toolbar .k-scheduler-views li.k-state-hover {
  background-color: rgba(var(--bs-white-rgb), 0.25);
  border-color: rgba(var(--bs-white-rgb), 0.25); }
  .k-calendar .k-scheduler-toolbar .k-scheduler-views li.k-state-hover .k-link,
  .k-scheduler .k-scheduler-toolbar .k-scheduler-views li.k-state-hover .k-link {
    background-color: rgba(var(--bs-white-rgb), 0.25); }

.k-calendar .k-scheduler-toolbar ul li.k-state-hover,
.k-scheduler .k-scheduler-toolbar ul li.k-state-hover {
  background-color: transparent;
  border-color: transparent; }

.k-calendar .ims-session,
.k-scheduler .ims-session {
  border: 0.0625rem solid var(--bs-primary) !important;
  border-radius: 0.1875rem; }

.k-calendar .k-month .k-link,
.k-scheduler .k-month .k-link {
  width: auto;
  height: 2.25rem;
  line-height: 2.125rem;
  padding: 0; }

.k-dropdown {
  max-width: inherit; }

.k-gantt .k-gantt-toolbar .k-gantt-views li.k-state-selected.k-state-hover,
.k-gantt-toolbar > .k-gantt-views > li.k-state-selected,
.k-scheduler .k-scheduler-toolbar .k-scheduler-views li.k-state-selected.k-state-hover,
.k-scheduler-toolbar > .k-scheduler-views > li.k-state-selected {
  border-bottom-color: var(--bs-primary); }

.k-calendar .k-footer .k-nav-today,
.k-calendar .k-footer .k-nav-today:hover {
  color: var(--bs-primary); }

.k-numerictextbox {
  width: 100%; }

input.k-textbox::-moz-placeholder, span.k-dropdown::-moz-placeholder, .k-multiselect::-moz-placeholder {
  font-style: normal; }

input.k-textbox::placeholder,
span.k-dropdown::placeholder,
.k-multiselect::placeholder {
  font-style: normal; }

input[type="text"].k-invalid,
input[type="password"].k-invalid {
  border-color: var(--bs-danger); }

.ims-input.k-textbox:hover {
  background-color: rgba(var(--bs-surface-low-rgb), 0.3); }

.k-button.k-upload-button {
  height: 2.1875rem;
  width: 6.25rem;
  min-width: 3.125rem;
  padding: 0;
  border: none; }

.k-upload-button input {
  font: 0.875rem "Open Sans" !important;
  opacity: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: inherit; }
  .k-upload-button input::after {
    font-family: "fontello";
    content: "\ea0c";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    padding-top: var(--ims-spacing--25);
    background-color: var(--bs-surface-high);
    color: var(--bs-body-color); }
  .k-upload-button input:hover {
    background-color: var(--bs-surface-highest); }

@-moz-document url-prefix() {
  .k-upload .k-button.k-upload-button {
    background-color: var(--bs-surface-high);
    color: var(--bs-body-color); }
    .k-upload .k-button.k-upload-button input[type="file"] {
      font-size: var(--ims-font__size--900);
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
      cursor: pointer; }
    .k-upload .k-button.k-upload-button .k-dropzone {
      border-style: solid;
      border-width: 0;
      padding: 0.8em;
      background-color: transparent; } }

.k-dropzone-hovered {
  background-color: var(--bs-primary); }
  .k-dropzone-hovered .k-dropzone-hint {
    color: var(--bs-on-primary); }

.k-progressbar .k-state-selected {
  background-color: var(--bs-success);
  border: 0; }

.k-widget.k-progressbar.k-progressbar-horizontal {
  border-radius: var(--ims-border__radius--0);
  width: 100%; }

.k-datepicker {
  width: 100%; }
  .k-datepicker .k-icon {
    position: initial; }

.k-numerictextbox .k-numeric-wrap {
  border: none; }
  .k-numerictextbox .k-numeric-wrap input.k-input {
    padding: 0; }
  .k-numerictextbox .k-numeric-wrap.k-state-focused .k-state-selected {
    background-color: var(--bs-surface-high); }

.ims-modal .k-combobox {
  width: 100%; }

.k-window .k-icon.k-i-close::before {
  font-family: fontello;
  content: "\ea05"; }

.k-window .window-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.1875rem;
  margin: 0;
  overflow: hidden;
  white-space: nowrap; }
  .k-window .window-footer .buttons-row .k-button {
    overflow: hidden;
    border-radius: 3.125rem;
    float: right;
    margin: 0.125rem;
    text-transform: inherit; }

.k-window-title {
  font-size: var(--ims-font__size--400); }

span.k-autocomplete {
  width: 100%; }

.k-scheduler-toolbar > ul li:first-child,
.k-scheduler-toolbar > ul li:first-child .k-link,
.k-scheduler-toolbar > ul.k-scheduler-views li:first-child + li,
.k-scheduler-toolbar > ul.k-scheduler-views li:first-child + li .k-link {
  border-bottom-left-radius: 0;
  border-left: 0; }

.k-widget .wizard .wizardStep {
  display: none; }

.k-widget .wizard .wizardStep.active {
  display: block; }

.k-widget .wizard .col-12 {
  position: initial; }

.k-editor-toolbar .k-button-group .k-tool {
  width: inherit;
  height: inherit;
  line-height: inherit;
  margin: 0; }

.k-editor-toolbar li.k-row-break {
  display: inline-block !important; }

.ims-scheduler-toolbar .k-widget.k-datepicker .k-picker-wrap.k-state-default input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  line-height: 2.3125rem; }
  .ims-scheduler-toolbar .k-widget.k-datepicker .k-picker-wrap.k-state-default input:focus {
    border-color: inherit !important; }

.ims-scheduler-toolbar .scheduler-datepicker [data-type="customDatePicker"] [data-role="datepicker"] {
  border-radius: 0 !important;
  margin-left: var(--ims-spacing--10) !important; }
  .ims-scheduler-toolbar .scheduler-datepicker [data-type="customDatePicker"] [data-role="datepicker"]:focus {
    box-shadow: none !important; }

.ims-grid-container .left-pane-actionbar .toolArea .inline-container .formulate-input-element--customSelect .k-dropdown .k-state-default {
  background-color: var(--bs-primary) !important; }
  .ims-grid-container .left-pane-actionbar .toolArea .inline-container .formulate-input-element--customSelect .k-dropdown .k-state-default:hover {
    background-color: inherit; }

.ims-grid-container .left-pane-actionbar .toolArea .ims-dropdown-menu .formulate-input-element--customSelect .k-dropdown .k-state-default {
  background-color: var(--bs-white) !important; }

.k-animation-container > .k-popup > .k-list-scroller {
  height: inherit; }

.k-select .k-icon,
.k-tool-icon {
  vertical-align: inherit; }

.formulate-input-element .k-widget {
  background-color: transparent !important; }

.k-multiselect.k-state-focused > .k-multiselect-wrap > .k-clear-value,
.k-multiselect.k-state-hover > .k-multiselect-wrap > .k-clear-value {
  margin-right: var(--ims-spacing--45); }

.k-flatcolorpicker .k-slider-track-wrap {
  height: inherit; }
  .k-flatcolorpicker .k-slider-track-wrap .k-slider-track {
    height: inherit; }

.k-flatcolorpicker .k-colorgradient:focus {
  box-shadow: none; }

.k-flatcolorpicker .k-colorgradient-inputs .k-numerictextbox {
  width: inherit;
  height: inherit; }

.k-flatcolorpicker .k-input {
  height: 2.3125rem;
  padding: 0;
  border: 0px solid var(--bs-border-color); }
  .k-flatcolorpicker .k-input .k-input-inner {
    padding: 0;
    text-indent: .8em;
    border: var(--bs-border-width) solid var(--bs-border-color);
    height: 2.3125rem;
    background-color: transparent;
    border-radius: var(--ims-form-input__border-radius); }

.k-flatcolorpicker .k-button.k-flat {
  border: 0.0625rem solid transparent;
  width: calc(1.4285714286em + 16px);
  height: calc(1.4285714286em + 16px);
  padding: 0; }
  .k-flatcolorpicker .k-button.k-flat .k-icon {
    height: 1.2rem; }

.k-coloreditor-footer .k-button {
  border: 0.0625rem solid transparent;
  width: -moz-max-content;
  width: max-content;
  padding: var(--ims-spacing--15) var(--ims-spacing--30) var(--ims-spacing--15) var(--ims-spacing--30) !important; }
  .k-coloreditor-footer .k-button:hover:not(.k-primary) {
    background-color: var(--bs-surface-high) !important;
    border-color: transparent !important; }

.k-button.k-button-icon,
.k-icon-button {
  width: inherit; }

.modal-body .k-pager-sm .k-pager-sizes,
.modal-body .k-pager-sm .k-pager-info,
.modal-body .k-pager-sm .k-pager-numbers {
  display: flex; }

.modal-body .k-pager-sm .k-pager-wrap .k-pager-numbers-wrap select.k-dropdown {
  display: none !important; }

.modal-body .k-pager-sm .k-pager-wrap.k-grid-pager .k-link {
  margin-left: 1.4375rem; }

.modal-body .k-pager-sm .k-pager-nav:nth-last-of-type(3n) {
  margin-left: 8rem !important; }

.modal-body .k-pager-sm .k-pager-numbers-wrap select.k-dropdown {
  display: none !important; }

.modal-body .k-pager-numbers-wrap {
  margin-left: 0rem !important; }

.k-callout-s,
.k-callout-e,
.k-callout-n,
.k-callout-w {
  height: 2px !important;
  width: 2px !important;
  bottom: 0rem !important; }

.mediaEditor .ims-comp-base-kendo-list-view {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 0px 0px 0.125rem 0.125rem;
  height: 84vh !important; }

.mediaEditor .k-widget.k-listview {
  width: 100%;
  height: 84vh !important; }
  .mediaEditor .k-widget.k-listview .k-listview-content {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto !important;
    height: auto;
    padding: var(--ims-spacing--10) var(--ims-spacing--10) var(--ims-spacing--20) var(--ims-spacing--10); }
    .mediaEditor .k-widget.k-listview .k-listview-content .k-state-selected > div, .mediaEditor .k-widget.k-listview .k-listview-content .k-state-selected:hover > div {
      background-color: var(--bs-primary); }

.mediaEditor .treeViewWrapperMediaEditor {
  height: 84vh !important; }
  .mediaEditor .treeViewWrapperMediaEditor .k-state-selected {
    font-weight: var(--ims-font__weight--bold); }

.mediaEditor .ims-assetspreview-content {
  width: 25% !important; }

.mediaEditor:has(.ims-drop-zone) .ims-comp-base-kendo-list-view {
  height: calc(77vh - 1.7rem) !important; }

.mediaEditor:has(.ims-drop-zone) .k-widget.k-listview {
  height: calc(77vh - 1.7rem) !important; }

.mediaEditor:has(.ims-drop-zone) .k-widget.k-listview {
  height: auto !important; }

.mediaEditor:has(.ims-drop-zone):has(.ims-comp-base-kendo-list-view) .ims-comp-base-kendo-list-view {
  background-color: aqua;
  margin-bottom: 1.5rem !important; }

.mediaEditor:has(.ims-drop-zone):has(.ims-comp-base-kendo-list-view) .treeViewWrapperMediaEditor {
  height: calc(81vh + 1.84rem) !important; }

.ims-comp-base-modal .mediaEditor .treeViewWrapperMediaEditor {
  max-height: calc(34vh + 2.125rem) !important; }

.ims-comp-base-modal .mediaEditor .ims-comp-base-kendo-list-view {
  max-height: 34vh !important; }
  .ims-comp-base-modal .mediaEditor .ims-comp-base-kendo-list-view .k-widget {
    width: 100% !important; }
  .ims-comp-base-modal .mediaEditor .ims-comp-base-kendo-list-view .k-listview-content {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    max-height: 34vh !important; }
    .ims-comp-base-modal .mediaEditor .ims-comp-base-kendo-list-view .k-listview-content .ims-assetspreview-content .ims-comp-base-header .flex-grow-1 {
      width: 100%; }

@media (max-width: -1) {
  .mediaEditor .ims-assetspreview-content {
    width: 100% !important; } }

@media (max-width: 575px) {
  .mobile-calendar-nav {
    display: block; }
    .mobile-calendar-nav .inline-container {
      margin: 0rem 0.9375rem 0rem 0.9375rem; }
  .full-width-calendar .k-widget.k-calendar {
    display: none; }
  .full-width-calendar .active.k-widget.k-calendar {
    display: block; }
    .full-width-calendar .active.k-widget.k-calendar.k-calendar,
    .full-width-calendar .active.k-widget.k-calendar .k-header {
      border-radius: var(--ims-border__radius--0); }
  .mediaEditor .ims-assetspreview-content {
    width: 50% !important; } }

@media (max-width: -1) {
  .mediaEditor .ims-assetspreview-content {
    width: 100% !important; } }

@media (max-width: 575px) {
  .mediaEditor .ims-assetspreview-content {
    width: 50% !important; } }

@media (min-width: 576px) {
  .mobile-calendar-nav {
    display: none; } }

@media (max-width: 767px) {
  .mediaEditor .ims-assetspreview-content {
    width: 50% !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .mediaEditor .ims-assetspreview-content {
    width: 33.33% !important; } }

@media (min-width: 992px) {
  .mediaEditor .ims-assetspreview-content {
    width: 20% !important; } }

@media (max-width: 1199px) {
  .mediaEditor .treeViewWrapperMediaEditor {
    height: 34vh !important; } }

.dropdown-item {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  color: var(--bs-primary-lightest); }
  .dropdown-item:hover, .dropdown-item.mobile-hover {
    color: var(--bs-white);
    background-color: var(--bs-primary); }
    .dropdown-item:hover > a, .dropdown-item.mobile-hover > a {
      color: var(--bs-white); }
    .dropdown-item:hover > .nav-item-splitter, .dropdown-item.mobile-hover > .nav-item-splitter {
      border-color: var(--bs-white); }
  .dropdown-item .ims-icon, .dropdown-item .ims-icon-arrow-left, .dropdown-item .ims-icon-arrow-right {
    margin-right: var(--ims-spacing--25); }
    .dropdown-item .ims-icon:before, .dropdown-item .ims-icon-arrow-left:before, .dropdown-item .ims-icon-arrow-right:before {
      font-size: var(--ims-font__size--80); }
  .dropdown-item > a {
    text-decoration: none;
    font-size: var(--ims-font__size--100);
    display: flex;
    align-items: center;
    margin: 0 var(--ims-spacing--25);
    flex-basis: 0; }
  .dropdown-item > .navigation-area {
    flex-grow: 3; }
  .dropdown-item > .add-btn {
    flex-grow: 1; }
  .dropdown-item:hover .nav-link {
    color: var(--bs-white); }

.nav-item .nav-link .ims-icon, .nav-item .nav-link .ims-icon-arrow-left, .nav-item .nav-link .ims-icon-arrow-right {
  margin-right: 0.4rem; }

.table {
  margin: 0; }
  .table th,
  .table td,
  .table tbody + tbody,
  .table thead th {
    border: none; }
  .table caption {
    text-align: center; }

.form-group {
  margin: 0; }

.form-control:focus {
  box-shadow: none; }

#ims-headerNav .dropdown-menu {
  max-height: 31.25rem;
  overflow: auto; }

.nav-link.dropdown-toggle:hover {
  cursor: pointer; }

.dropdown-toggle::after {
  display: none; }

.navbar {
  display: block; }

.ims-footer .dropdown .dropdown-menu {
  top: auto !important;
  bottom: var(--ims-spacing--75) !important;
  right: 0 !important;
  left: auto !important;
  transform: unset !important; }

.img-thumbnail {
  height: auto; }

.img-fluid {
  width: 100%; }

.input-group {
  align-items: normal !important;
  flex-wrap: nowrap; }

.toggle-button-row.btn-group .btn {
  font-size: var(--ims-font__size--100);
  z-index: 0; }

#selectAllCheckboxes {
  margin-left: var(--ims-spacing--45); }

hr {
  border-color: var(--bs-dark-border-subtle); }

.dropdown-item,
.nav-link {
  color: var(--bs-body-color); }

.dropdown-menu {
  box-shadow: 0 0.375rem 0.75rem -0.125rem rgba(var(--bs-body-color-rgb), 0.2);
  padding: var(--ims-dd-menu__spacing) !important; }
  .dropdown-menu .dropdown-item .nav-link {
    transition: color 0.1s; }
  .dropdown-menu.ims-dropdown-menu .dropdown-item {
    padding: var(--ims-dd-menu__dd-item__spacing) !important; }
    .dropdown-menu.ims-dropdown-menu .dropdown-item:hover {
      color: var(--ims-dd-menu__dd-item__font-color--hovered) !important; }

.table thead tr:first-child > td {
  border-top: none; }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive.embed-responsive-16by9 {
    padding-top: 56.25%; }
  .embed-responsive.embed-responsive-21by9 {
    padding-top: 42.857143%; }
  .embed-responsive.embed-responsive-4by3 {
    padding-top: 75%; }
  .embed-responsive.embed-responsive-1by1 {
    padding-top: 100%; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive .embed-responsive embed,
  .embed-responsive .embed-responsive iframe,
  .embed-responsive .embed-responsive object,
  .embed-responsive .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.ratio-2x1 {
  --bs-aspect-ratio: 50%; }

.mt-2rem {
  margin-top: var(--ims-spacing--80) !important; }

.mt-4rem {
  margin-top: var(--ims-spacing--160) !important; }

.mt-8rem {
  margin-top: var(--ims-spacing--320) !important; }

.mt-16rem {
  margin-top: var(--ims-spacing--640) !important; }

@media (min-width: 0) {
  .mt-xs-2rem {
    margin-top: var(--ims-spacing--80) !important; }
  .mt-xs-4rem {
    margin-top: var(--ims-spacing--160) !important; }
  .mt-xs-8rem {
    margin-top: var(--ims-spacing--320) !important; }
  .mt-xs-16rem {
    margin-top: var(--ims-spacing--640) !important; } }

@media (min-width: 576px) {
  .mt-sm-2rem {
    margin-top: var(--ims-spacing--80) !important; }
  .mt-sm-4rem {
    margin-top: var(--ims-spacing--160) !important; }
  .mt-sm-8rem {
    margin-top: var(--ims-spacing--320) !important; }
  .mt-sm-16rem {
    margin-top: var(--ims-spacing--640) !important; } }

@media (min-width: 768px) {
  .mt-md-2rem {
    margin-top: var(--ims-spacing--80) !important; }
  .mt-md-4rem {
    margin-top: var(--ims-spacing--160) !important; }
  .mt-md-8rem {
    margin-top: var(--ims-spacing--320) !important; }
  .mt-md-16rem {
    margin-top: var(--ims-spacing--640) !important; } }

@media (min-width: 992px) {
  .mt-lg-2rem {
    margin-top: var(--ims-spacing--80) !important; }
  .mt-lg-4rem {
    margin-top: var(--ims-spacing--160) !important; }
  .mt-lg-8rem {
    margin-top: var(--ims-spacing--320) !important; }
  .mt-lg-16rem {
    margin-top: var(--ims-spacing--640) !important; } }

@media (min-width: 1200px) {
  .mt-xl-2rem {
    margin-top: var(--ims-spacing--80) !important; }
  .mt-xl-4rem {
    margin-top: var(--ims-spacing--160) !important; }
  .mt-xl-8rem {
    margin-top: var(--ims-spacing--320) !important; }
  .mt-xl-16rem {
    margin-top: var(--ims-spacing--640) !important; } }

.pt-2rem {
  margin-top: var(--ims-spacing--80) !important; }

.pt-4rem {
  margin-top: var(--ims-spacing--160) !important; }

.pt-8rem {
  margin-top: var(--ims-spacing--320) !important; }

.pt-16rem {
  margin-top: var(--ims-spacing--640) !important; }

@media (min-width: 0) {
  .pt-xs-2rem {
    margin-top: var(--ims-spacing--80) !important; }
  .pt-xs-4rem {
    margin-top: var(--ims-spacing--160) !important; }
  .pt-xs-8rem {
    margin-top: var(--ims-spacing--320) !important; }
  .pt-xs-16rem {
    margin-top: var(--ims-spacing--640) !important; } }

@media (min-width: 576px) {
  .pt-sm-2rem {
    margin-top: var(--ims-spacing--80) !important; }
  .pt-sm-4rem {
    margin-top: var(--ims-spacing--160) !important; }
  .pt-sm-8rem {
    margin-top: var(--ims-spacing--320) !important; }
  .pt-sm-16rem {
    margin-top: var(--ims-spacing--640) !important; } }

@media (min-width: 768px) {
  .pt-md-2rem {
    margin-top: var(--ims-spacing--80) !important; }
  .pt-md-4rem {
    margin-top: var(--ims-spacing--160) !important; }
  .pt-md-8rem {
    margin-top: var(--ims-spacing--320) !important; }
  .pt-md-16rem {
    margin-top: var(--ims-spacing--640) !important; } }

@media (min-width: 992px) {
  .pt-lg-2rem {
    margin-top: var(--ims-spacing--80) !important; }
  .pt-lg-4rem {
    margin-top: var(--ims-spacing--160) !important; }
  .pt-lg-8rem {
    margin-top: var(--ims-spacing--320) !important; }
  .pt-lg-16rem {
    margin-top: var(--ims-spacing--640) !important; } }

@media (min-width: 1200px) {
  .pt-xl-2rem {
    margin-top: var(--ims-spacing--80) !important; }
  .pt-xl-4rem {
    margin-top: var(--ims-spacing--160) !important; }
  .pt-xl-8rem {
    margin-top: var(--ims-spacing--320) !important; }
  .pt-xl-16rem {
    margin-top: var(--ims-spacing--640) !important; } }

.mb-2rem {
  margin-bottom: var(--ims-spacing--80) !important; }

.mb-4rem {
  margin-bottom: var(--ims-spacing--160) !important; }

.mb-8rem {
  margin-bottom: var(--ims-spacing--320) !important; }

.mb-16rem {
  margin-bottom: var(--ims-spacing--640) !important; }

@media (min-width: 0) {
  .mb-xs-2rem {
    margin-bottom: var(--ims-spacing--80) !important; }
  .mb-xs-4rem {
    margin-bottom: var(--ims-spacing--160) !important; }
  .mb-xs-8rem {
    margin-bottom: var(--ims-spacing--320) !important; }
  .mb-xs-16rem {
    margin-bottom: var(--ims-spacing--640) !important; } }

@media (min-width: 576px) {
  .mb-sm-2rem {
    margin-bottom: var(--ims-spacing--80) !important; }
  .mb-sm-4rem {
    margin-bottom: var(--ims-spacing--160) !important; }
  .mb-sm-8rem {
    margin-bottom: var(--ims-spacing--320) !important; }
  .mb-sm-16rem {
    margin-bottom: var(--ims-spacing--640) !important; } }

@media (min-width: 768px) {
  .mb-md-2rem {
    margin-bottom: var(--ims-spacing--80) !important; }
  .mb-md-4rem {
    margin-bottom: var(--ims-spacing--160) !important; }
  .mb-md-8rem {
    margin-bottom: var(--ims-spacing--320) !important; }
  .mb-md-16rem {
    margin-bottom: var(--ims-spacing--640) !important; } }

@media (min-width: 992px) {
  .mb-lg-2rem {
    margin-bottom: var(--ims-spacing--80) !important; }
  .mb-lg-4rem {
    margin-bottom: var(--ims-spacing--160) !important; }
  .mb-lg-8rem {
    margin-bottom: var(--ims-spacing--320) !important; }
  .mb-lg-16rem {
    margin-bottom: var(--ims-spacing--640) !important; } }

@media (min-width: 1200px) {
  .mb-xl-2rem {
    margin-bottom: var(--ims-spacing--80) !important; }
  .mb-xl-4rem {
    margin-bottom: var(--ims-spacing--160) !important; }
  .mb-xl-8rem {
    margin-bottom: var(--ims-spacing--320) !important; }
  .mb-xl-16rem {
    margin-bottom: var(--ims-spacing--640) !important; } }

.pb-2rem {
  margin-bottom: var(--ims-spacing--80) !important; }

.pb-4rem {
  margin-bottom: var(--ims-spacing--160) !important; }

.pb-8rem {
  margin-bottom: var(--ims-spacing--320) !important; }

.pb-16rem {
  margin-bottom: var(--ims-spacing--640) !important; }

@media (min-width: 0) {
  .pb-xs-2rem {
    margin-bottom: var(--ims-spacing--80) !important; }
  .pb-xs-4rem {
    margin-bottom: var(--ims-spacing--160) !important; }
  .pb-xs-8rem {
    margin-bottom: var(--ims-spacing--320) !important; }
  .pb-xs-16rem {
    margin-bottom: var(--ims-spacing--640) !important; } }

@media (min-width: 576px) {
  .pb-sm-2rem {
    margin-bottom: var(--ims-spacing--80) !important; }
  .pb-sm-4rem {
    margin-bottom: var(--ims-spacing--160) !important; }
  .pb-sm-8rem {
    margin-bottom: var(--ims-spacing--320) !important; }
  .pb-sm-16rem {
    margin-bottom: var(--ims-spacing--640) !important; } }

@media (min-width: 768px) {
  .pb-md-2rem {
    margin-bottom: var(--ims-spacing--80) !important; }
  .pb-md-4rem {
    margin-bottom: var(--ims-spacing--160) !important; }
  .pb-md-8rem {
    margin-bottom: var(--ims-spacing--320) !important; }
  .pb-md-16rem {
    margin-bottom: var(--ims-spacing--640) !important; } }

@media (min-width: 992px) {
  .pb-lg-2rem {
    margin-bottom: var(--ims-spacing--80) !important; }
  .pb-lg-4rem {
    margin-bottom: var(--ims-spacing--160) !important; }
  .pb-lg-8rem {
    margin-bottom: var(--ims-spacing--320) !important; }
  .pb-lg-16rem {
    margin-bottom: var(--ims-spacing--640) !important; } }

@media (min-width: 1200px) {
  .pb-xl-2rem {
    margin-bottom: var(--ims-spacing--80) !important; }
  .pb-xl-4rem {
    margin-bottom: var(--ims-spacing--160) !important; }
  .pb-xl-8rem {
    margin-bottom: var(--ims-spacing--320) !important; }
  .pb-xl-16rem {
    margin-bottom: var(--ims-spacing--640) !important; } }

@media (max-width: 1199px) {
  .ims-footer .dropdown-menu {
    width: 100%; } }

@media (min-width: 1200px) {
  .navbar.col-12 {
    padding: 0 0.9375rem; }
  .w-lg-50 {
    width: 50% !important; } }

@media (min-width: 576px) {
  .navbar-expand-md {
    margin-bottom: var(--ims-spacing--0); }
  .ims-section-navigation {
    margin-bottom: var(--ims-spacing--75); } }

@media (max-width: 575px) {
  .table td {
    position: relative; }
  #participantSelection {
    position: absolute;
    right: var(--ims-spacing--25);
    top: var(--ims-spacing--30); }
  .ims-cancellation-control {
    display: flex;
    justify-content: flex-end; }
  #selectAllCheckboxes {
    margin-right: var(--ims-spacing--50); }
  .navbar-expand-md.navbar.navbar-light {
    border-bottom: none; }
  .navbar-expand-md ~ .stick-to-navigation {
    margin-top: -1.5rem; }
  .w-sm-100 {
    width: 100% !important; }
  .ims-footer .dropdown-menu {
    position: static;
    width: 100%;
    bottom: 0;
    box-shadow: none; } }

#rightPaneTitle {
  margin-top: 0;
  transition-property: margin-top;
  transition-duration: .5s;
  transition-timing-function: ease;
  transition-delay: .5s;
  -webkit-transition-property: margin-top;
  -webkit-transition-duration: .5s;
  -webkit-transition-timing-function: ease;
  -webkit-transition-delay: .5s; }
  #rightPaneTitle.revealSaveAction {
    margin-top: -3.125rem; }

.grid-filter-list {
  display: none !important;
  opacity: 0;
  transition-property: opacity;
  transition-duration: .5s;
  transition-timing-function: ease;
  transition-delay: 0;
  -webkit-transition-property: opacity;
  -webkit-transition-duration: .5s;
  -webkit-transition-timing-function: ease;
  -webkit-transition-delay: 0; }
  .grid-filter-list.active {
    display: block !important;
    opacity: 1; }

@keyframes saveBtnAnim {
  from {
    background-color: var(--bs-primary-dark); }
  50% {
    background-color: var(--bs-primary-dark); }
  to {
    background-color: var(--bs-primary-dark); } }

.formulate-input[data-type="checkbox"]:not([data-classification="group"]) .formulate-input-wrapper,
.formulate-input[data-type="radio"]:not([data-classification="group"]) .formulate-input-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center; }
  .formulate-input[data-type="checkbox"]:not([data-classification="group"]) .formulate-input-wrapper .formulate-input-element-decorator,
  .formulate-input[data-type="radio"]:not([data-classification="group"]) .formulate-input-wrapper .formulate-input-element-decorator {
    position: absolute; }

.formulate-input.ims-inline .formulate-input-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center; }
  .formulate-input.ims-inline .formulate-input-group .formulate-input-group-item {
    margin-right: var(--ims-spacing--50); }

.ims-checkbox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center; }

.ims-checkbox-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  cursor: pointer; }
  .ims-checkbox-wrapper .ims-checkbox {
    margin-right: var(--ims-spacing--50); }

.ims-custom-control {
  cursor: pointer;
  line-height: 1.2;
  display: flex;
  align-items: center; }
  .ims-custom-control input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  .ims-custom-control .ims-custom-control-indicator {
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 0.15625rem;
    font-size: 0.59375rem;
    padding-top: 0.125rem;
    padding-left: 0.0625rem;
    margin-right: var(--ims-spacing--25);
    background-color: var(--bs-white);
    border: 0.0625rem solid var(--bs-dark-border-subtle);
    color: transparent; }
    .ims-custom-control .ims-custom-control-indicator[disabled] {
      cursor: default !important;
      background-color: rgba(var(--bs-white-rgb), 0.5);
      border: 0.0625rem solid rgba(var(--bs-border-color-subtle-rgb), 0.5); }
    .ims-custom-control .ims-custom-control-indicator:focus {
      -ms-box-shadow: var(--bs-primary);
      box-shadow: var(--bs-primary); }
  .ims-custom-control .ims-custom-control-input:checked ~ .ims-custom-control-indicator {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
    background-color: var(--bs-primary);
    border: 0.0625rem solid var(--bs-primary); }
  .ims-custom-control .ims-custom-control-description {
    vertical-align: middle; }

::before,
::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: var(--bs-primary);
  /* 2 */ }

input[type='checkbox'],
.vue-treeselect__checkbox,
input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0px;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: var(--bs-primary);
  background-color: var(--bs-white);
  border-color: var(--bs-dark-border-subtle);
  border-width: 1px;
  border-style: solid; }
  input[type='checkbox']:hover,
  .vue-treeselect__checkbox:hover,
  input[type='radio']:hover {
    background-color: var(--ims-form-input__hover); }

input[type='checkbox'],
.vue-treeselect__checkbox,
input[type='radio'] {
  margin-right: var(--ims-spacing--20); }

input[type='checkbox'],
.vue-treeselect__checkbox {
  border-radius: var(--ims-border__radius--25); }

input[type='radio'] {
  border-radius: 100%; }

input[type='checkbox']:focus,
input[type='radio']:focus {
  -ms-box-shadow: var(--bs-primary);
  box-shadow: var(--bs-primary); }

input[type='checkbox']:checked,
input[type='radio']:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat; }

input[type='checkbox']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e"); }

input[type='radio']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e"); }

input[type='checkbox']:checked:hover,
input[type='checkbox']:checked:focus,
input[type='radio']:checked:hover,
input[type='radio']:checked:focus {
  border-color: transparent;
  background-color: currentColor; }

input[type='checkbox']:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat; }

.vue-treeselect__check-mark {
  height: 1.2rem;
  width: 1.2rem;
  align-items: center; }

.vue-treeselect__minus-mark {
  left: 0.188rem;
  top: 0.188rem;
  width: 0.625rem;
  height: 0.5rem; }

.vue-treeselect__checkbox--checked {
  border-color: var(--bs-primary);
  background-color: var(--bs-primary); }
  .vue-treeselect__checkbox--checked:hover {
    border-color: var(--bs-primary);
    background-color: var(--bs-primary); }
  .vue-treeselect__checkbox--checked.vue-treeselect__option--highlight:hover {
    border-color: var(--bs-primary);
    background-color: var(--bs-primary); }

.vue-treeselect__check-mark {
  background-size: 18px 18px;
  align-items: center;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 22 22' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e"); }

.vue-treeselect__checkbox--disabled .vue-treeselect__check-mark {
  background-size: 18px 18px;
  align-items: center;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 22 22' fill='red' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e"); }

.vue-treeselect__checkbox--disabled .vue-treeselect__check-mark {
  border-color: var(--bs-dark-border-subtle);
  background: var(--bs-light-bg-subtle);
  margin-left: -0.2rem;
  margin-top: -0.2rem; }

.vue-treeselect__checkbox--checked, .vue-treeselect__checkbox--indeterminate, .vue-treeselect__label-container:hover .vue-treeselect__checkbox--checked, .vue-treeselect__label-container:hover .vue-treeselect__checkbox--indeterminate {
  border-color: var(--bs-primary);
  background: var(--bs-primary); }

input[type='checkbox']:indeterminate:hover,
input[type='checkbox']:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor; }

.formulate-input[data-classification="box"] .formulate-input-element {
  overflow: hidden;
  display: contents;
  align-items: center; }

.formulate-input[data-type="checkbox"] .formulate-input-wrapper .formulate-input-label--before {
  margin-right: var(--ims-spacing--20); }

.ims-checkbox {
  display: flex;
  align-items: initial; }

.k-checkbox-wrapper {
  vertical-align: initial !important; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: var(--bs-dark-border-subtle);
  border-color: var(--bs-primary);
  background-color: var(--bs-primary); }

.ims-custom-checkbox {
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center; }
  .ims-custom-checkbox input[type="checkbox"] {
    display: none; }
  .ims-custom-checkbox .custom-checkbox-indicator {
    min-height: 1rem;
    min-width: 1rem;
    border-radius: 0.1875rem;
    position: relative;
    margin-right: var(--ims-spacing--15);
    background-color: var(--bs-white);
    border: 0.0625rem solid var(--bs-border-color); }
    .ims-custom-checkbox .custom-checkbox-indicator .ims-icon, .ims-custom-checkbox .custom-checkbox-indicator .ims-icon-arrow-left, .ims-custom-checkbox .custom-checkbox-indicator .ims-icon-arrow-right {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      height: inherit;
      width: inherit;
      line-height: normal;
      color: var(--bs-primary); }
      .ims-custom-checkbox .custom-checkbox-indicator .ims-icon:before, .ims-custom-checkbox .custom-checkbox-indicator .ims-icon-arrow-left:before, .ims-custom-checkbox .custom-checkbox-indicator .ims-icon-arrow-right:before {
        margin: 0;
        position: absolute; }
  .ims-custom-checkbox input:checked ~ .custom-checkbox-indicator .ims-icon, .ims-custom-checkbox input:checked ~ .custom-checkbox-indicator .ims-icon-arrow-left, .ims-custom-checkbox input:checked ~ .custom-checkbox-indicator .ims-icon-arrow-right {
    display: block; }

.form-check {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center; }

.custom-toggle-button {
  cursor: pointer; }
  .custom-toggle-button input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0); }

.show > .btn-secondary.dropdown-toggle {
  background-color: var(--bs-light-bg-subtle);
  color: var(--bs-body-color); }

.k-primary[disabled] {
  background-color: var(--bs-light-bg-subtle);
  color: var(--bs-body-color);
  border-color: var(--bs-dark-border-subtle); }
  .k-primary[disabled]:hover {
    background-color: var(--bs-light-bg-subtle);
    color: var(--bs-body-color);
    border-color: var(--bs-dark-border-subtle); }

.k-button.k-state-active.k-state-focused {
  background-color: var(--bs-primary-light) !important; }

.ims-grid-container .toolArea .searchBoxArea div {
  display: flex; }
  .ims-grid-container .toolArea .searchBoxArea div span.k-widget.k-autocomplete {
    height: inherit; }
  .ims-grid-container .toolArea .searchBoxArea div button.btn {
    width: 2.5rem;
    height: 2.5rem;
    box-shadow: none;
    background-color: var(--bs-primary);
    border: 1px solid var(--bs-primary) !important;
    padding: var(--ims-spacing--15) var(--ims-spacing--30); }
    .ims-grid-container .toolArea .searchBoxArea div button.btn span.ims-icon, .ims-grid-container .toolArea .searchBoxArea div button.btn span.ims-icon-arrow-left, .ims-grid-container .toolArea .searchBoxArea div button.btn span.ims-icon-arrow-right {
      padding-right: 0rem; }
      .ims-grid-container .toolArea .searchBoxArea div button.btn span.ims-icon::before, .ims-grid-container .toolArea .searchBoxArea div button.btn span.ims-icon-arrow-left::before, .ims-grid-container .toolArea .searchBoxArea div button.btn span.ims-icon-arrow-right::before {
        font-size: var(--ims-font__size--300);
        margin-left: -0.1rem;
        margin-top: 0.2rem;
        color: var(--bs-on-primary); }
    .ims-grid-container .toolArea .searchBoxArea div button.btn:hover span.ims-icon::before, .ims-grid-container .toolArea .searchBoxArea div button.btn:hover span.ims-icon-arrow-left::before, .ims-grid-container .toolArea .searchBoxArea div button.btn:hover span.ims-icon-arrow-right::before {
      color: var(--bs-primary-lightest); }
  .ims-grid-container .toolArea .searchBoxArea div input.k-input {
    border: 1px solid var(--bs-primary) !important; }
  .ims-grid-container .toolArea .searchBoxArea div .k-widget.k-autocomplete .k-i-close {
    color: var(--bs-body-color) !important; }

.ims-grid-container .toolArea .ddMenu {
  z-index: 1; }
  .ims-grid-container .toolArea .ddMenu span.k-link.k-menu-link {
    background-color: transparent !important; }
  .ims-grid-container .toolArea .ddMenu ul.k-widget.k-menu > li.k-item {
    box-shadow: none !important;
    background-color: var(--bs-primary) !important;
    width: 2.5rem;
    height: 2.5rem; }
    .ims-grid-container .toolArea .ddMenu ul.k-widget.k-menu > li.k-item span.ims-icon::before, .ims-grid-container .toolArea .ddMenu ul.k-widget.k-menu > li.k-item span.ims-icon-arrow-left::before, .ims-grid-container .toolArea .ddMenu ul.k-widget.k-menu > li.k-item span.ims-icon-arrow-right::before {
      color: var(--bs-on-primary);
      margin-top: 0.1rem !important; }
    .ims-grid-container .toolArea .ddMenu ul.k-widget.k-menu > li.k-item .k-animation-container span.ims-icon::before, .ims-grid-container .toolArea .ddMenu ul.k-widget.k-menu > li.k-item .k-animation-container span.ims-icon-arrow-left::before, .ims-grid-container .toolArea .ddMenu ul.k-widget.k-menu > li.k-item .k-animation-container span.ims-icon-arrow-right::before {
      color: var(--bs-primary-lightest); }
    .ims-grid-container .toolArea .ddMenu ul.k-widget.k-menu > li.k-item .k-animation-container span.ims-icon.highlighted::before, .ims-grid-container .toolArea .ddMenu ul.k-widget.k-menu > li.k-item .k-animation-container span.highlighted.ims-icon-arrow-left::before, .ims-grid-container .toolArea .ddMenu ul.k-widget.k-menu > li.k-item .k-animation-container span.highlighted.ims-icon-arrow-right::before {
      color: var(--bs-yellow) !important; }
    .ims-grid-container .toolArea .ddMenu ul.k-widget.k-menu > li.k-item:last-child {
      margin-right: 0rem; }

.toolButtonArea .k-button.highlighted,
.saveSettingsIconContainer.highlighted,
.toolButtonArea .saveButton.highlighted,
.approveButton.highlighted {
  background: var(--bs-primary);
  color: var(--bs-on-primary);
  transition: all 0.1s ease;
  animation: saveBtnAnim 2s infinite ease-in;
  -webkit-animation: saveBtnAnim 2s infinite ease-in; }

/*** start single row input with button ***/
.ims-inputWithAddBtn {
  flex-wrap: inherit; }
  .ims-inputWithAddBtn > *:first-child > input {
    box-sizing: inherit;
    border-bottom-right-radius: var(--ims-border__radius--0) !important;
    border-top-right-radius: var(--ims-border__radius--0) !important; }
  .ims-inputWithAddBtn .k-widget.k-multiselect .k-multiselect-wrap.k-floatwrap {
    border-bottom-right-radius: var(--ims-border__radius--0) !important;
    border-top-right-radius: var(--ims-border__radius--0) !important; }
  .ims-inputWithAddBtn .ims-add-btn {
    width: 2.64em;
    min-height: 2.36rem;
    background-color: var(--bs-surface-high) !important;
    color: var(--bs-body-color) !important;
    border: none;
    padding: initial;
    border-bottom-right-radius: var(--ims-border__radius--50) !important;
    border-top-right-radius: var(--ims-border__radius--50) !important; }
    .ims-inputWithAddBtn .ims-add-btn:hover {
      cursor: pointer;
      background-color: var(--bs-surface-highest); }
  .ims-inputWithAddBtn .k-widget {
    width: 100%; }
  .ims-inputWithAddBtn.input-group .ims-add-btn {
    border-bottom-right-radius: var(--ims-form-input__border-radius) !important;
    border-top-right-radius: var(--ims-form-input__border-radius) !important; }

.input-group.ims-inputWithAddBtn span.k-autocomplete {
  border-width: 0 !important; }
  .input-group.ims-inputWithAddBtn span.k-autocomplete input.tagsInput {
    padding-left: var(--ims-spacing--0) !important;
    border: 0.0625rem solid var(--bs-dark-border-subtle) !important;
    margin-right: 0.2rem !important;
    line-height: 2.07142857rem !important; }
    .input-group.ims-inputWithAddBtn span.k-autocomplete input.tagsInput:hover {
      box-shadow: none; }

.stretched-button button,
.stretched-button input[type="submit"] {
  width: 100%; }

footer a.ims-icon-button {
  padding: 0.1875rem; }

.toggle-button-row .custom-toggle-button {
  border-radius: var(--ims-border__radius--0); }
  .toggle-button-row .custom-toggle-button:first-child {
    border-bottom-left-radius: var(--ims-form-input__border-radius);
    border-top-left-radius: var(--ims-form-input__border-radius);
    margin-right: var(--ims-spacing--0); }
  .toggle-button-row .custom-toggle-button:last-child {
    border-bottom-right-radius: var(--ims-form-input__border-radius);
    border-top-right-radius: var(--ims-form-input__border-radius);
    margin-left: var(--ims-spacing--0); }
  .toggle-button-row .custom-toggle-button:hover {
    color: var(--bs-white) !important; }
  .toggle-button-row .custom-toggle-button.active {
    color: var(--bs-white) !important; }
  .toggle-button-row .custom-toggle-button:focus {
    box-shadow: none !important; }

.toggleEditMode {
  border: 1px solid var(--bs-dark-border-subtle);
  padding: var(--ims-spacing--20) var(--ims-spacing--20);
  border-bottom: none; }
  .toggleEditMode button span {
    margin-right: 0 !important; }

.big-important-btn {
  height: 3.125rem;
  font-size: var(--ims-font__size--400); }

.ims-file-upload {
  display: flex;
  align-items: center; }
  .ims-file-upload .file-name {
    margin-left: 0.9375rem; }

.ims-upload-button {
  position: relative;
  overflow: hidden; }
  .ims-upload-button::after {
    content: none; }
  .ims-upload-button .file-upload {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    opacity: 0;
    cursor: pointer; }

.ims-booking-btn,
a.ims-booking-btn {
  padding: 0.3125rem 0.9375rem; }

.ims-mobile-sticky-button[disabled], .ims-mobile-sticky-button .disabled {
  cursor: default; }

footer a.ims-icon-button:not([disabled]) {
  background-color: transparent; }
  footer a.ims-icon-button:not([disabled]) .ims-icon, footer a.ims-icon-button:not([disabled]) .ims-icon-arrow-left, footer a.ims-icon-button:not([disabled]) .ims-icon-arrow-right {
    color: var(--bs-border-color); }
  footer a.ims-icon-button:not([disabled]):hover {
    background-color: transparent; }
    footer a.ims-icon-button:not([disabled]):hover .ims-icon, footer a.ims-icon-button:not([disabled]):hover .ims-icon-arrow-left, footer a.ims-icon-button:not([disabled]):hover .ims-icon-arrow-right {
      color: var(--bs-primary); }

footer a.ims-icon-button[disabled], footer a.ims-icon-button[disabled]:hover {
  background-color: transparent; }
  footer a.ims-icon-button[disabled] .ims-icon, footer a.ims-icon-button[disabled] .ims-icon-arrow-left, footer a.ims-icon-button[disabled] .ims-icon-arrow-right, footer a.ims-icon-button[disabled]:hover .ims-icon, footer a.ims-icon-button[disabled]:hover .ims-icon-arrow-left, footer a.ims-icon-button[disabled]:hover .ims-icon-arrow-right {
    color: var(--bs-border-color); }

.favorite-btn-container input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute; }

.favorite-btn-container .favorite-btn {
  display: block; }
  .favorite-btn-container .favorite-btn:hover {
    cursor: pointer; }

.favorite-btn-container .favorite-btn:not([disabled]):hover,
.favorite-btn-container input:checked ~ .favorite-btn,
.favorite-btn-container .favorite-btn.remove {
  background-color: rgba(var(--bs-primary-rgb), 0.8); }

.favorite-btn-container .favorite-btn.remove .ims-icon:before, .favorite-btn-container .favorite-btn.remove .ims-icon-arrow-left:before, .favorite-btn-container .favorite-btn.remove .ims-icon-arrow-right:before {
  color: var(--bs-primary); }

.favorite-btn-container input:checked ~ .favorite-btn .ims-icon:before, .favorite-btn-container input:checked ~ .favorite-btn .ims-icon-arrow-left:before, .favorite-btn-container input:checked ~ .favorite-btn .ims-icon-arrow-right:before {
  color: var(--bs-white) !important; }

.favorite-btn-container .favorite-btn:hover .ims-icon:before, .favorite-btn-container .favorite-btn:hover .ims-icon-arrow-left:before, .favorite-btn-container .favorite-btn:hover .ims-icon-arrow-right:before {
  color: var(--bs-white); }

.ims-file-upload .first-name .ims-icon, .ims-file-upload .first-name .ims-icon-arrow-left, .ims-file-upload .first-name .ims-icon-arrow-right {
  color: var(--bs-success); }

.catalogue-search-container {
  max-width: 100%;
  position: relative;
  border-radius: 0.3125rem; }
  .catalogue-search-container input[type="text"] {
    width: 100%; }
  .catalogue-search-container.catalogue-search-container-dynamicHeight input[type="text"] {
    height: auto; }
  .catalogue-search-container.catalogue-search-container-dynamicHeight .ims-primary-button {
    background-color: transparent;
    border-color: transparent; }
    .catalogue-search-container.catalogue-search-container-dynamicHeight .ims-primary-button .ims-icon, .catalogue-search-container.catalogue-search-container-dynamicHeight .ims-primary-button .ims-icon-arrow-left, .catalogue-search-container.catalogue-search-container-dynamicHeight .ims-primary-button .ims-icon-arrow-right {
      color: var(--bs-border-color); }
  .catalogue-search-container button {
    position: absolute;
    top: 0;
    right: -0.0625rem;
    bottom: 0;
    border-radius: 0 var(--ims-buttonBorderRadius-custom) var(--ims-buttonBorderRadius-custom) 0;
    box-shadow: none;
    width: 2.1875rem;
    padding-left: var(--ims-spacing--15);
    padding-right: var(--ims-spacing--15); }
    .catalogue-search-container button::after {
      border-radius: 0 var(--ims-buttonBorderRadius-custom) var(--ims-buttonBorderRadius-custom) 0; }
    .catalogue-search-container button.transparent {
      background-color: transparent !important;
      border: transparent !important; }
      .catalogue-search-container button.transparent span {
        color: var(--bs-body-color) !important; }
  .catalogue-search-container button.cancel {
    right: 2.125rem;
    border-radius: var(--ims-border__radius--0);
    border-right: 0.0625rem solid var(--bs-white); }
  .catalogue-search-container button:last-child {
    border-top-left-radius: var(--ims-border__radius--0) !important;
    border-bottom-left-radius: var(--ims-border__radius--0) !important; }

.catalogue-searchbar a.btn.btn-secondary {
  color: var(--bs-on-primary) !important; }

.ims-tags {
  padding: 0.3125rem;
  cursor: pointer;
  border: 0.0625rem solid var(--bs-border-color);
  background-color: var(--bs-light-bg-subtle);
  display: inline-block; }
  .ims-tags-small-rounded {
    padding: var(--ims-spacing--0) var(--ims-spacing--20);
    border-radius: var(--ims-border__radius--250);
    border: 0.0625rem solid var(--bs-border-color);
    background-color: var(--bs-light-bg-subtle);
    display: inline-block; }
    .ims-tags-small-rounded .k-icon::before {
      line-height: 0.84 !important; }

.ims-tags-exists {
  color: var(--bs-primary) !important;
  float: right; }

.ims-info-stamps {
  padding: var(--ims-spacing--0) var(--ims-spacing--35) !important;
  border-radius: var(--ims-border__radius--250);
  border: 0rem solid var(--bs-border-color);
  background-color: var(--bs-body-color);
  display: inline-block;
  color: var(--bs-white); }

.formulate-form .form-element-container .input-group input::-ms-reveal {
  display: none; }

.btn {
  box-shadow: none; }

.ims-floating-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.75rem;
  width: 3.75rem;
  border-radius: 50rem !important;
  border: 0.1rem solid var(--bs-border-color) !important;
  background-color: var(--bs-primary); }
  .ims-floating-btn .ims-icon, .ims-floating-btn .ims-icon-arrow-left, .ims-floating-btn .ims-icon-arrow-right {
    color: var(--bs-dark-border-subtle);
    font-size: var(--ims-font__size--500); }

.ims-stream-nav--floating .ims-floating-btn, .ims-stream-nav--floating .ims-profile-img img {
  box-shadow: var(--ims-elevation__dp--20); }

.ims-primary-button,
a.ims-button.ims-primary-button,
.formulate-input-element--submit button {
  font-size: var(--ims-font__size--100);
  line-height: var(--ims-font__line-height--reset);
  padding: var(--ims-buttonPaddingY) var(--ims-buttonPaddingX);
  text-align: center;
  position: relative;
  white-space: nowrap;
  display: inline-block;
  border-width: var(--ims-buttonBorderWidth);
  border-radius: var(--ims-buttonBorderRadius);
  border-style: solid;
  color: var(--bs-white);
  background-color: var(--ims-btn-primary__background);
  border-color: var(--ims-btn-primary__border-color); }
  .ims-primary-button:hover,
  a.ims-button.ims-primary-button:hover,
  .formulate-input-element--submit button:hover {
    color: var(--bs-white) !important; }

.ims-primary-button:hover:not([disabled]),
a.ims-button.ims-primary-button:hover:not([disabled]),
.formulate-input-element--submit button:hover:not([disabled]) {
  color: var(--ims-colButtonPrimaryTextHover);
  background-color: var(--ims-btn-primary__background--hovered); }

button.search-bar-expand {
  background-color: transparent;
  border-radius: var(--ims-border__radius--625) !important; }

.trainingBookingWidget .ims-multibook-selection .ims-participant-selection a.ims-accordion .custom-accordion-plus::before, .trainingBookingWidget .ims-multibook-selection .ims-participant-selection a.ims-accordion .custom-accordion-plus::after {
  border-top: 0.125rem solid var(--bs-on-primary); }

.trainingBookingWidget .ims-multibook-selection .ims-participant-selection button.ims-accordion .custom-accordion-plus::before, .trainingBookingWidget .ims-multibook-selection .ims-participant-selection button.ims-accordion .custom-accordion-plus::after {
  border-top: 0.125rem solid var(--bs-on-primary); }

.trainingBookingWidget .ims-multibook-selection .ims-participant-selection a.ims-accordion:not(.collapsed) .custom-accordion-plus::after {
  border-color: var(--bs-on-primary); }

.trainingBookingWidget .ims-multibook-selection .ims-participant-selection button.ims-accordion:not(.collapsed) .custom-accordion-plus::after {
  border-color: var(--bs-on-primary); }

.trainingBookingWidget .ims-multibook-selection .ims-participant-selection .ims-button {
  background-color: var(--ims-btn-menu__background) !important;
  color: var(--bs-on-primary) !important;
  padding: var(--ims-spacing--60) var(--ims-spacing--60) !important;
  border-color: transparent !important; }

button.ims-accordion .custom-accordion-plus::before,
button.ims-accordion .custom-accordion-plus::after,
button.ims-accordion-btn .custom-accordion-plus::before,
button.ims-accordion-btn .custom-accordion-plus::after {
  content: '';
  display: block;
  position: absolute;
  width: 0.875rem;
  transform: translate(-50%, -50%) rotate(0);
  transition: all 300ms ease; }

button.ims-accordion.collapsed .custom-accordion-plus::after,
button.ims-accordion-btn.collapsed .custom-accordion-plus::after {
  transform: translate(-50%, -50%) rotate(90deg); }

#rightPaneSaveAction .toolButtonArea .btn {
  margin: var(--ims-spacing--0) var(--ims-spacing--10); }

#rightPaneSaveAction .toolButtonArea a.btn-outline-secondary {
  background-color: var(--bs-light-bg-subtle);
  border-color: var(--bs-dark-border-subtle); }

.toolButtonArea #reloadButton {
  width: 2.625rem !important; }

.k-gantt-header .k-button {
  color: var(--bs-body-color);
  background-color: var(--bs-surface-lowest); }
  .k-gantt-header .k-button.k-state-selected {
    color: var(--bs-white) !important;
    background-color: var(--bs-surface-lowest-hover); }

.k-master-row .gridcell .btn {
  font-size: var(--bs-btn-font-size); }

.ims-multiple-items-spacing .btn:not(:last-child) {
  margin-right: var(--ims-spacing--5); }

.bigPictureWidget .ims-btn__pulsating-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 50px;
  height: 50px;
  cursor: pointer; }
  .bigPictureWidget .ims-btn__pulsating-circle:before {
    content: '';
    position: relative;
    display: block;
    width: 280%;
    height: 280%;
    box-sizing: border-box;
    margin-left: -96%;
    margin-top: -98%;
    border-radius: 80px;
    background-color: var(--ims-btn__pulsating-circle-color-before);
    animation: ims-pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    pointer-events: none; }
  .bigPictureWidget .ims-btn__pulsating-circle:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 92%;
    height: 92%;
    background-color: var(--ims-btn__pulsating-circle-color-after);
    border-radius: 30px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: ims-pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite; }
  .bigPictureWidget .ims-btn__pulsating-circle div.ims-icon, .bigPictureWidget .ims-btn__pulsating-circle div.ims-icon-arrow-left, .bigPictureWidget .ims-btn__pulsating-circle div.ims-icon-arrow-right {
    position: absolute;
    margin-top: -4rem;
    margin-left: 2.1rem;
    z-index: 1;
    background-color: var(--bs-white);
    box-shadow: var(--ims-elevation__dp--20);
    border-radius: 50%;
    height: 1.22rem; }
    .bigPictureWidget .ims-btn__pulsating-circle div.ims-icon::before, .bigPictureWidget .ims-btn__pulsating-circle div.ims-icon-arrow-left::before, .bigPictureWidget .ims-btn__pulsating-circle div.ims-icon-arrow-right::before {
      box-shadow: var(--ims-elevation__dp--20);
      font-size: 100% !important;
      border-radius: 50%; }
  .bigPictureWidget .ims-btn__pulsating-circle .ims-btn__pulsating-circle--link {
    position: absolute;
    z-index: 1;
    margin-top: -5.2rem;
    text-align: center !important;
    font-size: 1.2rem;
    width: 47px;
    color: var(--bs-on-primary); }

.bigPictureWidget .ims-btn__pulsating-circle--sm {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 50px;
  height: 50px;
  cursor: pointer; }
  .bigPictureWidget .ims-btn__pulsating-circle--sm:before {
    content: '';
    position: relative;
    display: block;
    width: 280%;
    height: 280%;
    box-sizing: border-box;
    margin-left: -96%;
    margin-top: -98%;
    border-radius: 80px;
    background-color: var(--ims-btn__pulsating-circle-color-before);
    animation: ims-pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    pointer-events: none; }
  .bigPictureWidget .ims-btn__pulsating-circle--sm:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 92%;
    height: 92%;
    background-color: var(--ims-btn__pulsating-circle-color-after);
    border-radius: 30px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: ims-pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite; }
  .bigPictureWidget .ims-btn__pulsating-circle--sm div.ims-icon, .bigPictureWidget .ims-btn__pulsating-circle--sm div.ims-icon-arrow-left, .bigPictureWidget .ims-btn__pulsating-circle--sm div.ims-icon-arrow-right {
    position: absolute;
    margin-top: -4rem;
    margin-left: 2.1rem;
    z-index: 1;
    background-color: var(--bs-white);
    box-shadow: var(--ims-elevation__dp--20);
    border-radius: 50%;
    height: 1.22rem; }
    .bigPictureWidget .ims-btn__pulsating-circle--sm div.ims-icon::before, .bigPictureWidget .ims-btn__pulsating-circle--sm div.ims-icon-arrow-left::before, .bigPictureWidget .ims-btn__pulsating-circle--sm div.ims-icon-arrow-right::before {
      box-shadow: var(--ims-elevation__dp--20);
      font-size: 100% !important;
      border-radius: 50%; }
  .bigPictureWidget .ims-btn__pulsating-circle--sm .ims-btn__pulsating-circle--link {
    position: absolute;
    z-index: 1;
    margin-top: -5.2rem;
    text-align: center !important;
    font-size: 1.2rem;
    width: 47px;
    color: var(--bs-on-primary); }
  .bigPictureWidget .ims-btn__pulsating-circle--sm:before {
    width: 200%;
    height: 200%;
    margin-left: -75%;
    margin-top: -72%; }
  .bigPictureWidget .ims-btn__pulsating-circle--sm:after {
    width: 50%;
    height: 50%; }
  .bigPictureWidget .ims-btn__pulsating-circle--sm div.ims-icon, .bigPictureWidget .ims-btn__pulsating-circle--sm div.ims-icon-arrow-left, .bigPictureWidget .ims-btn__pulsating-circle--sm div.ims-icon-arrow-right {
    margin-top: -3.5rem;
    margin-left: 1.3rem; }
  .bigPictureWidget .ims-btn__pulsating-circle--sm .ims-btn__pulsating-circle--link {
    margin-top: -3.9rem;
    width: 50%;
    text-align: center !important;
    font-size: 0.875rem; }

.bigPictureWidget .ims-btn__pulsating-circle--lg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 50px;
  height: 50px;
  cursor: pointer; }
  .bigPictureWidget .ims-btn__pulsating-circle--lg:before {
    content: '';
    position: relative;
    display: block;
    width: 280%;
    height: 280%;
    box-sizing: border-box;
    margin-left: -96%;
    margin-top: -98%;
    border-radius: 80px;
    background-color: var(--ims-btn__pulsating-circle-color-before);
    animation: ims-pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    pointer-events: none; }
  .bigPictureWidget .ims-btn__pulsating-circle--lg:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 92%;
    height: 92%;
    background-color: var(--ims-btn__pulsating-circle-color-after);
    border-radius: 30px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: ims-pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite; }
  .bigPictureWidget .ims-btn__pulsating-circle--lg div.ims-icon, .bigPictureWidget .ims-btn__pulsating-circle--lg div.ims-icon-arrow-left, .bigPictureWidget .ims-btn__pulsating-circle--lg div.ims-icon-arrow-right {
    position: absolute;
    margin-top: -4rem;
    margin-left: 2.1rem;
    z-index: 1;
    background-color: var(--bs-white);
    box-shadow: var(--ims-elevation__dp--20);
    border-radius: 50%;
    height: 1.22rem; }
    .bigPictureWidget .ims-btn__pulsating-circle--lg div.ims-icon::before, .bigPictureWidget .ims-btn__pulsating-circle--lg div.ims-icon-arrow-left::before, .bigPictureWidget .ims-btn__pulsating-circle--lg div.ims-icon-arrow-right::before {
      box-shadow: var(--ims-elevation__dp--20);
      font-size: 100% !important;
      border-radius: 50%; }
  .bigPictureWidget .ims-btn__pulsating-circle--lg .ims-btn__pulsating-circle--link {
    position: absolute;
    z-index: 1;
    margin-top: -5.2rem;
    text-align: center !important;
    font-size: 1.2rem;
    width: 47px;
    color: var(--bs-on-primary); }
  .bigPictureWidget .ims-btn__pulsating-circle--lg:before {
    width: 320%;
    height: 320%;
    margin-left: -100%;
    margin-top: -100%; }
  .bigPictureWidget .ims-btn__pulsating-circle--lg:after {
    width: 120%;
    height: 120%; }
  .bigPictureWidget .ims-btn__pulsating-circle--lg div.ims-icon, .bigPictureWidget .ims-btn__pulsating-circle--lg div.ims-icon-arrow-left, .bigPictureWidget .ims-btn__pulsating-circle--lg div.ims-icon-arrow-right {
    margin-top: -4.5rem;
    margin-left: 2.5rem; }
  .bigPictureWidget .ims-btn__pulsating-circle--lg .ims-btn__pulsating-circle--link {
    position: absolute;
    z-index: 1;
    margin-top: -6.18rem;
    width: 120%;
    font-size: 1.5rem;
    text-align: center !important;
    color: var(--bs-on-primary); }

.bigPictureWidget .popover {
  position: absolute;
  margin-left: 2.4rem;
  min-width: 14rem !important;
  line-height: 1.4rem !important; }

.ims-btn__pulsating-circle.ims-pulse-disabled:before,
.ims-btn__pulsating-circle--sm.ims-pulse-disabled:before,
.ims-btn__pulsating-circle--lg.ims-pulse-disabled:before {
  width: 0;
  animation: ims-pulse-dot 0s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite; }

.ims-btn__pulsating-circle.ims-pulse-disabled:after,
.ims-btn__pulsating-circle--sm.ims-pulse-disabled:after,
.ims-btn__pulsating-circle--lg.ims-pulse-disabled:after {
  animation: ims-pulse-dot 0s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite; }

.imagePreview .btn {
  background-color: transparent;
  border-color: transparent; }

@keyframes ims-pulse-ring {
  0% {
    transform: scale(0.33); }
  80%, 100% {
    opacity: 0; } }

@keyframes ims-pulse-dot {
  0% {
    transform: scale(0.8); }
  50% {
    transform: scale(1); }
  100% {
    transform: scale(0.8); } }

@media (max-width: 575px) {
  .bigPictureWidget .popover {
    position: absolute;
    margin-left: -5rem;
    min-width: 10rem !important;
    margin-top: 2rem; }
  .ims-mobile-sticky-button {
    position: fixed;
    z-index: 1;
    bottom: var(--ims-spacing--0);
    left: var(--ims-spacing--45);
    right: var(--ims-spacing--0);
    padding-top: var(--ims-spacing--45);
    padding-bottom: var(--ims-spacing--45);
    text-align: center; }
    .ims-mobile-sticky-button[disabled], .ims-mobile-sticky-button .disabled {
      display: none; }
  .ims-mobile-sticky-button:not(disabled),
  .ims-mobile-sticky-button:not(.disabled) {
    background-color: var(--bs-primary);
    color: var(--bs-on-primary); } }

.ims-widget-big-picture .popover {
  position: absolute;
  margin-left: -5rem !important;
  min-width: 10rem !important;
  margin-top: 1.65rem !important; }

input::-moz-placeholder {
  color: var(--bs-secondary-color); }

input::placeholder {
  color: var(--bs-secondary-color); }

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="time"],
input[type="search"],
input.k-input,
span.k-input {
  height: 2.3125rem;
  color: var(--bs-body-color);
  padding: var(--ims-spacing--25) var(--ims-spacing--20);
  width: 100%;
  border-width: var(--ims-inputBorderWidth);
  border-style: solid;
  border-radius: var(--ims-form-input__border-radius);
  border: var(--bs-border-width) solid var(--bs-border-color);
  background-color: var(--bs-white); }
  input[type="text"].not-resizable,
  input[type="password"].not-resizable,
  input[type="email"].not-resizable,
  input[type="number"].not-resizable,
  input[type="date"].not-resizable,
  input[type="time"].not-resizable,
  input[type="search"].not-resizable,
  input.k-input.not-resizable,
  span.k-input.not-resizable {
    resize: none; }
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  input[type="number"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="search"]:focus,
  input.k-input:focus,
  span.k-input:focus {
    border-color: var(--ims-colInputFocus) !important;
    box-shadow: 0px 0px 0px 0.125rem rgba(var(--ims-colInputFocus-rgb), 0.5) !important; }
  input[type="text"]:hover,
  input[type="password"]:hover,
  input[type="email"]:hover,
  input[type="number"]:hover,
  input[type="date"]:hover,
  input[type="time"]:hover,
  input[type="search"]:hover,
  input.k-input:hover,
  span.k-input:hover {
    background-color: var(--ims-form-input__hover); }

input#globalSearchInput:focus {
  box-shadow: none; }

input#globalSearchInput:hover {
  background-color: transparent; }

textarea,
textarea.k-textbox {
  color: var(--bs-body-color);
  padding: var(--ims-spacing--25) var(--ims-spacing--20);
  width: 100%;
  border-width: var(--ims-inputBorderWidth);
  border-style: solid;
  border-radius: var(--ims-form-input__border-radius);
  border: var(--bs-border-width) solid var(--bs-border-color);
  background-color: var(--bs-white); }
  textarea.not-resizable,
  textarea.k-textbox.not-resizable {
    resize: none; }
  textarea:focus,
  textarea.k-textbox:focus {
    border-color: var(--ims-colInputFocus) !important;
    box-shadow: 0px 0px 0px 0.125rem rgba(var(--ims-colInputFocus-rgb), 0.5) !important; }
  textarea:hover,
  textarea.k-textbox:hover {
    background-color: var(--ims-form-input__hover); }
  textarea.not-resizable,
  textarea.k-textbox.not-resizable {
    resize: none; }

.ims-textarea-autogrow {
  resize: none;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 2.3125rem; }

input[type="search"].k-textbox,
input[type="text"].k-textbox {
  text-indent: 0em; }

.formulate-input-element--customHtmlEditor .k-editor-widget {
  border-radius: var(--ims-form-input__border-radius) !important; }
  .formulate-input-element--customHtmlEditor .k-editor-widget .k-editor-toolbar-wrap {
    border-top-left-radius: var(--ims-form-input__border-radius) !important;
    border-top-right-radius: var(--ims-form-input__border-radius) !important; }

.formulate-input-element--customHtmlEditor .k-editor .k-editor-toolbar-wrap {
  padding-left: var(--ims-spacing--25);
  padding-right: var(--ims-spacing--25); }

.form-element-container,
.formulate-form-errors {
  margin-bottom: var(--ims-spacing--60); }

.ims-input,
.form-element-container .k-dropdown {
  width: 100%; }

::-webkit-input-placeholder {
  font-style: italic;
  opacity: 0.6;
  overflow: hidden; }

:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  opacity: 0.6;
  overflow: hidden; }

::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  opacity: 0.6;
  overflow: hidden; }

:-ms-input-placeholder {
  font-style: italic;
  opacity: 0.6;
  overflow: hidden; }

.ims-custom-control.ims-custom-radio input[type="radio"] {
  position: absolute;
  opacity: 1; }

.input-group .formulate-input {
  width: 100%; }

.input-group input[type="text"]:first-child,
.input-group input[type="password"]:first-child {
  border-right: 1;
  border-bottom-right-radius: var(--ims-border__radius--0);
  border-top-right-radius: var(--ims-border__radius--0); }

.input-group input[type="text"]:last-child:not(:first-child),
.input-group input[type="password"]:last-child:not(:fist-child) {
  border-left: 1;
  border-right: 1;
  border-bottom-left-radius: var(--ims-border__radius--0);
  border-top-left-radius: var(--ims-border__radius--0); }

.input-group [type="button"],
.input-group button {
  border-radius: 0rem; }

.input-group [type="button"]:last-child,
.input-group button:last-child {
  border-bottom-left-radius: var(--ims-border__radius--0);
  border-top-left-radius: var(--ims-border__radius--0); }

.input-group [type="button"]:first-child,
.input-group button:first-child {
  border-bottom-right-radius: var(--ims-form-input__border-radius);
  border-top-right-radius: var(--ims-form-input__border-radius); }

.input-group .input-group-prepend [type="button"]:first-child {
  border-bottom-left-radius: var(--ims-form-input__border-radius);
  border-top-left-radius: var(--ims-form-input__border-radius);
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  height: 2.4rem !important;
  border-left-color: var(--bs-border-color) !important;
  border-right-color: transparent !important; }

.input-group .input-group-prepend .formulate-input-element--customSelect .k-dropdown-wrap.k-state-default {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.input-group .input-group-append [type="button"]:last-child {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: var(--ims-form-input__border-radius);
  border-top-right-radius: var(--ims-form-input__border-radius);
  height: 2.33rem !important; }

.input-group .form-select {
  border-bottom-left-radius: var(--ims-form-input__border-radius);
  border-top-left-radius: var(--ims-form-input__border-radius); }

.input-group button {
  border-top-right-radius: var(--ims-form-input__border-radius) !important;
  border-bottom-right-radius: var(--ims-form-input__border-radius) !important; }

select {
  height: 2.3125rem;
  color: var(--bs-body-color);
  padding: var(--ims-spacing--25) var(--ims-spacing--20);
  width: 100%;
  border-width: var(--ims-inputBorderWidth);
  border-style: solid;
  border-radius: var(--ims-form-input__border-radius);
  border: var(--bs-border-width) solid var(--bs-border-color);
  background-color: var(--bs-white); }
  select.not-resizable {
    resize: none; }
  select:focus {
    border-color: var(--ims-colInputFocus) !important;
    box-shadow: 0px 0px 0px 0.125rem rgba(var(--ims-colInputFocus-rgb), 0.5) !important; }
  select:hover {
    background-color: var(--ims-form-input__hover); }

.form-element-container select {
  width: 100%; }

.global-search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3.125rem;
  position: relative;
  height: 1.874rem; }
  .global-search-container .search-input {
    padding: 0;
    font-size: var(--ims-font__size--100);
    border: none;
    border-radius: var(--ims-border__radius--0);
    background-color: transparent; }
  .global-search-container .ims-icon, .global-search-container .ims-icon-arrow-left, .global-search-container .ims-icon-arrow-right {
    font-size: var(--ims-font__size--200);
    cursor: pointer;
    padding: var(--ims-spacing--0) var(--ims-spacing--25);
    line-height: 2rem;
    border-radius: 2rem !important; }
  .global-search-container .search-bar-expand {
    position: absolute;
    right: 0;
    box-shadow: none;
    border: none; }

.ims-custom-searchbar {
  width: calc(100% - 1.875rem);
  display: inline-block; }

.global-search-container.expandable {
  transition: width 0.5s;
  width: 2.625rem; }
  .global-search-container.expandable a,
  .global-search-container.expandable .search-input {
    display: none; }
  .global-search-container.expandable .btn.btn-minimal-secondary {
    display: none !important;
    transition: all 1s; }
  .global-search-container.expandable .btn.btn-secondary {
    display: block !important; }

.global-search-container.expandable.expanded {
  width: 17.5rem; }
  .global-search-container.expandable.expanded a,
  .global-search-container.expandable.expanded .search-input {
    display: block; }
  .global-search-container.expandable.expanded .btn.btn-minimal-secondary {
    display: block !important;
    transition: all 1s; }

.global-search-container {
  background-color: var(--bs-white); }
  .global-search-container .search-input::-moz-placeholder {
    color: var(--bs-border-color);
    -moz-transition: color 0.5s;
    transition: color 0.5s; }
  .global-search-container .search-input::placeholder {
    color: var(--bs-border-color);
    transition: color 0.5s; }
  .global-search-container .search-input:hover, .global-search-container .search-input:focus {
    border-color: var(--bs-primary-light); }
    .global-search-container .search-input:hover::-moz-placeholder, .global-search-container .search-input:focus::-moz-placeholder {
      color: var(--bs-border-color); }
    .global-search-container .search-input:hover::placeholder, .global-search-container .search-input:focus::placeholder {
      color: var(--bs-border-color); }
  .global-search-container a.ims-icon, .global-search-container a.ims-icon-arrow-left, .global-search-container a.ims-icon-arrow-right,
  .global-search-container .search-bar-expand.ims-icon,
  .global-search-container .search-bar-expand.ims-icon-arrow-left,
  .global-search-container .search-bar-expand.ims-icon-arrow-right {
    transition: color 0.5s; }
  .global-search-container.expandable.expanded .search-bar-expand.ims-icon, .global-search-container.expandable.expanded .search-bar-expand.ims-icon-arrow-left, .global-search-container.expandable.expanded .search-bar-expand.ims-icon-arrow-right {
    transition: color 0.5s; }
  .global-search-container .search-bar-expand {
    background-color: var(--bs-white); }
    .global-search-container .search-bar-expand:hover {
      background-color: transparent; }
  .global-search-container .btn.btn-secondary,
  .global-search-container .btn-secondary {
    color: var(--bs-border-color); }
    .global-search-container .btn.btn-secondary:hover,
    .global-search-container .btn-secondary:hover {
      background-color: var(--bs-light-bg-subtle) !important; }

.formulate-input[data-is-showing-errors][data-has-errors] input {
  border-color: var(--bs-danger);
  color: var(--bs-danger); }

.form-check-input {
  margin-top: 0.2rem; }

.ims-listBox-fullWidth {
  width: inherit !important; }

.form-element-container,
.formulate-form-errors {
  margin-bottom: var(--ims-spacing--60); }

.ddMenu .formulate-input[data-classification="select"] .formulate-input-element::before {
  right: var(--ims-spacing--20); }

input.k-textbox {
  width: 100%; }

input.ims-input.k-textbox {
  border: 0.0625rem solid var(--bs-dark-border-subtle); }

.k-grid-edit-row .text-box {
  border: 0.0625rem solid var(--bs-dark-border-subtle) !important;
  padding-left: 0.6rem !important;
  height: 2.07142857rem; }

.k-multiselect-wrap input.k-input:focus {
  box-shadow: none !important; }

.k-fieldselector .k-list .k-item,
.k-popup .k-list .k-item,
.k-popup > .k-group-header {
  line-height: 1.2rem;
  min-height: 1.2rem; }

.k-dropdown-wrap.k-state-active.k-state-border-up,
.k-numeric-wrap.k-state-focused,
.k-picker-wrap.k-state-focused {
  box-shadow: 0px 0px 0px 0.125rem var(--bs-primary-light) !important; }

.dataColumn .k-dropdown-wrap .k-input::before {
  padding-top: var(--ims-spacing--40); }

.k-widget.k-numerictextbox {
  border: 0.0625rem solid var(--bs-border-color) !important; }
  .k-widget.k-numerictextbox input.k-input:focus {
    box-shadow: none !important; }
  .k-widget.k-numerictextbox .k-numeric-wrap.k-state-focused {
    background-color: transparent !important; }

.k-treeview .k-in {
  margin-left: var(--ims-spacing--0) !important; }

.k-grid-content td[role="gridcell"] .k-dropdown .k-state-default {
  border: 1px solid var(--bs-border-color) !important; }

.grid-filter-list {
  margin-top: 2.8rem !important;
  min-height: auto !important; }
  .grid-filter-list .formulate-input[data-classification="select"] .formulate-input-element::before {
    right: 1.6rem !important; }

.matrixLayerItem label {
  margin-bottom: var(--ims-spacing--0) !important; }

.detailsRow.ims-checkbox-container label {
  color: inherit;
  width: initial !important;
  float: none !important;
  line-height: 1.25rem;
  margin-bottom: var(--ims-spacing--0); }

.approveButton,
.enableButton {
  width: auto !important; }
  .approveButton input[type="checkbox"],
  .enableButton input[type="checkbox"] {
    width: 1rem !important;
    height: 1rem !important; }

.tooltip-text-label {
  line-height: inherit !important; }
  .tooltip-text-label label {
    margin-bottom: 0 !important; }

label.ims-label-resetMargin {
  margin-bottom: 0 !important; }

.detailsColumn .ims-infoElement-middle {
  display: flex !important;
  justify-content: center !important; }
  .detailsColumn .ims-infoElement-middle label.tooltip-text-label {
    padding-right: var(--ims-spacing--40); }
  .detailsColumn .ims-infoElement-middle div.dataColumn {
    padding-left: var(--ims-spacing--40); }

.tagsDisplayWrapper .tagsDisplayArea .selectedTag .k-button {
  box-shadow: none !important;
  padding: 0.25rem 0.4rem !important; }
  .tagsDisplayWrapper .tagsDisplayArea .selectedTag .k-button span.tagValue {
    text-transform: initial !important; }
  .tagsDisplayWrapper .tagsDisplayArea .selectedTag .k-button .ims-icon-cancel-1.k-delete {
    color: var(--bs-primary-lightest) !important;
    opacity: 0.6 !important;
    font-size: 85% !important;
    margin-left: 0.4rem; }

.custom-select {
  font-size: 100% !important;
  color: var(--bs-border-color) !important;
  padding: var(--ims-spacing--15) 1.75rem var(--ims-spacing--15) var(--ims-spacing--20) !important;
  background-color: var(--bs-light-bg-subtle); }
  .custom-select:hover {
    box-shadow: var(--ims-elevation__dp--10); }

.uploadFilesControl .k-upload .k-upload-button {
  box-shadow: none; }

.k-upload {
  background-color: var(--bs-white); }

.k-multiselect-wrap > .k-i-close {
  color: var(--bs-primary-lightest) !important; }

.ims-grid-container .toolArea .ims-dropdown-menu .k-widget.k-dropdown .k-dropdown-wrap .k-input {
  color: var(--bs-border-color) !important;
  border-radius: 0 !important; }

.k-datetimepicker {
  border: 0rem !important; }

.ims-label-left .formulate-input-wrapper .formulate-input-label--before {
  margin-bottom: 0 !important; }

/* Change Autocomplete styles in Chrome*/
.form-group.global-search-container input:-webkit-autofill,
.form-group.global-search-container input:-webkit-autofill:hover,
.form-group.global-search-container input:-webkit-autofill:focus,
.form-group.global-search-container textarea:-webkit-autofill,
.form-group.global-search-container textarea:-webkit-autofill:hover,
.form-group.global-search-container textarea:-webkit-autofill:focus,
.form-group.global-search-container select:-webkit-autofill,
.form-group.global-search-container select:-webkit-autofill:hover,
.form-group.global-search-container select:-webkit-autofill:focus {
  border: 0 !important;
  -webkit-text-fill-color: var(--bs-body-color);
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  height: 1.8rem; }

.k-widget.k-timepicker,
.k-widget.k-datetimepicker,
.k-widget.k-combobox,
.k-datetimepicker {
  width: 100% !important; }

.k-textbox,
.k-multiselect-wrap,
.k-picker-wrap.k-state-default {
  border-color: var(--bs-border-color) !important; }

.k-datetimepicker {
  width: 100%; }

.k-datepicker .k-input,
.k-datetimepicker .k-input {
  background-color: var(--bs-white); }

input.vue-treeselect__input:focus {
  border-color: none !important; }

.formulate-input-element--select select {
  color: var(--bs-body-color);
  padding: 0.275rem 1rem 0.275rem 0.5rem;
  border: 1px solid var(--bs-border-color) !important;
  border-radius: var(--ims-border__radius--0);
  background-color: var(--bs-white);
  text-overflow: ellipsis; }
  .formulate-input-element--select select option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--bs-body-color); }

.formulate-input-element--customSelect .k-widget.k-dropdown {
  width: 100% !important;
  white-space: normal; }

.formulate-input-element--customSelect .k-dropdown-wrap.k-state-default {
  border: 1px solid var(--bs-border-color) !important;
  background-color: var(--bs-white) !important;
  border-radius: var(--ims-form-input__border-radius); }
  .formulate-input-element--customSelect .k-dropdown-wrap.k-state-default.k-state-hover .k-select {
    background-color: var(--ims-form-input__hover) !important; }

.formulate-input-element--customSelect .k-dropdown-wrap > .k-input {
  line-height: 2rem; }

.formulate-input-element--customSelect .k-dropdown .k-state-default {
  background-color: var(--bs-white) !important; }

.formulate-input-element--customSelect .k-widget {
  border-radius: var(--ims-form-input__border-radius); }

.formulate-input-element--customComboBox .k-combobox {
  background-color: var(--bs-white) !important; }
  .formulate-input-element--customComboBox .k-combobox .k-dropdown-wrap {
    border-radius: var(--ims-form-input__border-radius);
    border-color: var(--bs-border-color);
    padding-left: 0.063rem; }
    .formulate-input-element--customComboBox .k-combobox .k-dropdown-wrap.k-state-default {
      background-color: var(--bs-white) !important;
      border-radius: var(--ims-form-input__border-radius); }
      .formulate-input-element--customComboBox .k-combobox .k-dropdown-wrap.k-state-default.k-state-hover {
        background-color: var(--ims-form-input__hover) !important; }
        .formulate-input-element--customComboBox .k-combobox .k-dropdown-wrap.k-state-default.k-state-hover input {
          background-color: var(--ims-form-input__hover) !important; }
        .formulate-input-element--customComboBox .k-combobox .k-dropdown-wrap.k-state-default.k-state-hover .k-select {
          background-color: var(--ims-form-input__hover) !important; }
  .formulate-input-element--customComboBox .k-combobox .k-numeric-wrap,
  .formulate-input-element--customComboBox .k-combobox .k-picker-wrap {
    border-top-left-radius: var(--ims-form-input__border-radius);
    border-bottom-left-radius: var(--ims-form-input__border-radius); }

.formulate-input-element--customComboBox .k-input {
  background-color: var(--bs-white) !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-shadow: none; }

.formulate-input-element--customComboBox .k-dropdown-wrap.k-state-focused {
  box-shadow: none; }

.formulate-input {
  position: relative; }

.formulate-input[data-classification="select"] .formulate-input-element::before {
  content: "";
  width: 0;
  height: 0;
  border-color: var(--bs-body-color) transparent transparent;
  border-style: solid;
  border-width: 0.4rem 0.3rem 0;
  margin-top: 1rem;
  right: 0.90rem;
  position: absolute; }

.formulate-input[data-classification="select"] select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: var(--ims-border__radius--0);
  border: 1px solid var(--bs-border-color);
  box-sizing: border-box;
  background-color: var(--bs-white) !important;
  display: block;
  width: 100%;
  line-height: 1.2rem;
  margin: 0; }
  .formulate-input[data-classification="select"] select:hover {
    background-color: var(--bs-white) !important; }

.formulate-input[data-classification="select"] select[data-placeholder-selected] {
  color: var(--bs-border-color); }

.formulate-input-element--customNumber .k-widget.k-numerictextbox {
  border: solid 1px var(--bs-dark-border-subtle); }

.k-datetimepicker .k-input {
  background-color: var(--bs-white); }

.formulate-input-element--customDateTimePicker .k-datetimepicker {
  width: 100%; }

.formulate-input-element--customDatePicker .k-datepicker .k-picker-wrap,
.formulate-input-element--customDatePicker .k-datepicker .k-numeric-wrap,
.formulate-input-element--customDatePicker .k-datetimepicker .k-picker-wrap,
.formulate-input-element--customDatePicker .k-datetimepicker .k-numeric-wrap,
.formulate-input-element--customDatePicker .k-numerictextbox .k-picker-wrap,
.formulate-input-element--customDatePicker .k-numerictextbox .k-numeric-wrap,
.formulate-input-element--customDateTimePicker .k-datepicker .k-picker-wrap,
.formulate-input-element--customDateTimePicker .k-datepicker .k-numeric-wrap,
.formulate-input-element--customDateTimePicker .k-datetimepicker .k-picker-wrap,
.formulate-input-element--customDateTimePicker .k-datetimepicker .k-numeric-wrap,
.formulate-input-element--customDateTimePicker .k-numerictextbox .k-picker-wrap,
.formulate-input-element--customDateTimePicker .k-numerictextbox .k-numeric-wrap,
.formulate-input-element--customNumber .k-datepicker .k-picker-wrap,
.formulate-input-element--customNumber .k-datepicker .k-numeric-wrap,
.formulate-input-element--customNumber .k-datetimepicker .k-picker-wrap,
.formulate-input-element--customNumber .k-datetimepicker .k-numeric-wrap,
.formulate-input-element--customNumber .k-numerictextbox .k-picker-wrap,
.formulate-input-element--customNumber .k-numerictextbox .k-numeric-wrap {
  border-radius: var(--ims-form-input__border-radius) !important; }
  .formulate-input-element--customDatePicker .k-datepicker .k-picker-wrap:hover,
  .formulate-input-element--customDatePicker .k-datepicker .k-numeric-wrap:hover,
  .formulate-input-element--customDatePicker .k-datetimepicker .k-picker-wrap:hover,
  .formulate-input-element--customDatePicker .k-datetimepicker .k-numeric-wrap:hover,
  .formulate-input-element--customDatePicker .k-numerictextbox .k-picker-wrap:hover,
  .formulate-input-element--customDatePicker .k-numerictextbox .k-numeric-wrap:hover,
  .formulate-input-element--customDateTimePicker .k-datepicker .k-picker-wrap:hover,
  .formulate-input-element--customDateTimePicker .k-datepicker .k-numeric-wrap:hover,
  .formulate-input-element--customDateTimePicker .k-datetimepicker .k-picker-wrap:hover,
  .formulate-input-element--customDateTimePicker .k-datetimepicker .k-numeric-wrap:hover,
  .formulate-input-element--customDateTimePicker .k-numerictextbox .k-picker-wrap:hover,
  .formulate-input-element--customDateTimePicker .k-numerictextbox .k-numeric-wrap:hover,
  .formulate-input-element--customNumber .k-datepicker .k-picker-wrap:hover,
  .formulate-input-element--customNumber .k-datepicker .k-numeric-wrap:hover,
  .formulate-input-element--customNumber .k-datetimepicker .k-picker-wrap:hover,
  .formulate-input-element--customNumber .k-datetimepicker .k-numeric-wrap:hover,
  .formulate-input-element--customNumber .k-numerictextbox .k-picker-wrap:hover,
  .formulate-input-element--customNumber .k-numerictextbox .k-numeric-wrap:hover {
    background-color: var(--ims-form-input__hover); }
    .formulate-input-element--customDatePicker .k-datepicker .k-picker-wrap:hover .k-select,
    .formulate-input-element--customDatePicker .k-datepicker .k-numeric-wrap:hover .k-select,
    .formulate-input-element--customDatePicker .k-datetimepicker .k-picker-wrap:hover .k-select,
    .formulate-input-element--customDatePicker .k-datetimepicker .k-numeric-wrap:hover .k-select,
    .formulate-input-element--customDatePicker .k-numerictextbox .k-picker-wrap:hover .k-select,
    .formulate-input-element--customDatePicker .k-numerictextbox .k-numeric-wrap:hover .k-select,
    .formulate-input-element--customDateTimePicker .k-datepicker .k-picker-wrap:hover .k-select,
    .formulate-input-element--customDateTimePicker .k-datepicker .k-numeric-wrap:hover .k-select,
    .formulate-input-element--customDateTimePicker .k-datetimepicker .k-picker-wrap:hover .k-select,
    .formulate-input-element--customDateTimePicker .k-datetimepicker .k-numeric-wrap:hover .k-select,
    .formulate-input-element--customDateTimePicker .k-numerictextbox .k-picker-wrap:hover .k-select,
    .formulate-input-element--customDateTimePicker .k-numerictextbox .k-numeric-wrap:hover .k-select,
    .formulate-input-element--customNumber .k-datepicker .k-picker-wrap:hover .k-select,
    .formulate-input-element--customNumber .k-datepicker .k-numeric-wrap:hover .k-select,
    .formulate-input-element--customNumber .k-datetimepicker .k-picker-wrap:hover .k-select,
    .formulate-input-element--customNumber .k-datetimepicker .k-numeric-wrap:hover .k-select,
    .formulate-input-element--customNumber .k-numerictextbox .k-picker-wrap:hover .k-select,
    .formulate-input-element--customNumber .k-numerictextbox .k-numeric-wrap:hover .k-select {
      background-color: var(--ims-form-input__hover); }
  .formulate-input-element--customDatePicker .k-datepicker .k-picker-wrap .k-select,
  .formulate-input-element--customDatePicker .k-datepicker .k-numeric-wrap .k-select,
  .formulate-input-element--customDatePicker .k-datetimepicker .k-picker-wrap .k-select,
  .formulate-input-element--customDatePicker .k-datetimepicker .k-numeric-wrap .k-select,
  .formulate-input-element--customDatePicker .k-numerictextbox .k-picker-wrap .k-select,
  .formulate-input-element--customDatePicker .k-numerictextbox .k-numeric-wrap .k-select,
  .formulate-input-element--customDateTimePicker .k-datepicker .k-picker-wrap .k-select,
  .formulate-input-element--customDateTimePicker .k-datepicker .k-numeric-wrap .k-select,
  .formulate-input-element--customDateTimePicker .k-datetimepicker .k-picker-wrap .k-select,
  .formulate-input-element--customDateTimePicker .k-datetimepicker .k-numeric-wrap .k-select,
  .formulate-input-element--customDateTimePicker .k-numerictextbox .k-picker-wrap .k-select,
  .formulate-input-element--customDateTimePicker .k-numerictextbox .k-numeric-wrap .k-select,
  .formulate-input-element--customNumber .k-datepicker .k-picker-wrap .k-select,
  .formulate-input-element--customNumber .k-datepicker .k-numeric-wrap .k-select,
  .formulate-input-element--customNumber .k-datetimepicker .k-picker-wrap .k-select,
  .formulate-input-element--customNumber .k-datetimepicker .k-numeric-wrap .k-select,
  .formulate-input-element--customNumber .k-numerictextbox .k-picker-wrap .k-select,
  .formulate-input-element--customNumber .k-numerictextbox .k-numeric-wrap .k-select {
    border-top-right-radius: var(--ims-form-input__border-radius) !important;
    border-bottom-right-radius: var(--ims-form-input__border-radius) !important; }
  .formulate-input-element--customDatePicker .k-datepicker .k-picker-wrap.k-state-hover input,
  .formulate-input-element--customDatePicker .k-datepicker .k-numeric-wrap.k-state-hover input,
  .formulate-input-element--customDatePicker .k-datetimepicker .k-picker-wrap.k-state-hover input,
  .formulate-input-element--customDatePicker .k-datetimepicker .k-numeric-wrap.k-state-hover input,
  .formulate-input-element--customDatePicker .k-numerictextbox .k-picker-wrap.k-state-hover input,
  .formulate-input-element--customDatePicker .k-numerictextbox .k-numeric-wrap.k-state-hover input,
  .formulate-input-element--customDateTimePicker .k-datepicker .k-picker-wrap.k-state-hover input,
  .formulate-input-element--customDateTimePicker .k-datepicker .k-numeric-wrap.k-state-hover input,
  .formulate-input-element--customDateTimePicker .k-datetimepicker .k-picker-wrap.k-state-hover input,
  .formulate-input-element--customDateTimePicker .k-datetimepicker .k-numeric-wrap.k-state-hover input,
  .formulate-input-element--customDateTimePicker .k-numerictextbox .k-picker-wrap.k-state-hover input,
  .formulate-input-element--customDateTimePicker .k-numerictextbox .k-numeric-wrap.k-state-hover input,
  .formulate-input-element--customNumber .k-datepicker .k-picker-wrap.k-state-hover input,
  .formulate-input-element--customNumber .k-datepicker .k-numeric-wrap.k-state-hover input,
  .formulate-input-element--customNumber .k-datetimepicker .k-picker-wrap.k-state-hover input,
  .formulate-input-element--customNumber .k-datetimepicker .k-numeric-wrap.k-state-hover input,
  .formulate-input-element--customNumber .k-numerictextbox .k-picker-wrap.k-state-hover input,
  .formulate-input-element--customNumber .k-numerictextbox .k-numeric-wrap.k-state-hover input {
    background-color: var(--ims-form-input__hover); }

.formulate-input-element--customDatePicker .k-numerictextbox,
.formulate-input-element--customDateTimePicker .k-numerictextbox,
.formulate-input-element--customNumber .k-numerictextbox {
  border-top-left-radius: var(--ims-form-input__border-radius) !important;
  border-bottom-left-radius: var(--ims-form-input__border-radius) !important;
  border-top-right-radius: var(--ims-form-input__border-radius) !important;
  border-bottom-right-radius: var(--ims-form-input__border-radius) !important; }

.formulate-input-group-item {
  margin-bottom: var(--ims-spacing--20) !important; }

.formulate-input-element--customColorPicker .k-colorpicker .k-picker-wrap {
  border-color: var(--bs-border-color);
  border-radius: var(--ims-form-input__border-radius) !important; }

.formulate-input[data-is-showing-errors][data-has-errors] input {
  border-color: var(--bs-border-color);
  color: var(--bs-danger); }

ul.formulate-input-errors,
ul.formulate-files {
  padding: 0 0 0 0;
  margin-top: var(--ims-spacing--10); }
  ul.formulate-input-errors li,
  ul.formulate-files li {
    list-style: none !important;
    line-height: normal; }

.formulate-input[data-classification="file"] .formulate-input-upload-area input {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 0;
  position: absolute;
  left: var(--ims-spacing--0);
  right: var(--ims-spacing--0);
  bottom: var(--ims-spacing--0);
  top: var(--ims-spacing--0);
  width: 100%;
  height: 100%;
  z-index: 1; }

.formulate-input[data-classification="file"] .formulate-input-upload-area-mask::before {
  position: absolute;
  left: var(--ims-spacing--40);
  font-family: fontello;
  content: "\ea0c";
  padding: var(--ims-spacing--20) var(--ims-spacing--40) var(--ims-spacing--20) var(--ims-spacing--40);
  background-color: var(--bs-surface-high);
  color: var(--bs-body-color);
  border-radius: var(--ims-form-input__border-radius); }

.formulate-input[data-classification="file"] .formulate-files .formulate-file {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: var(--ims-border__radius--0);
  border: 0.1rem solid var(--bs-border-color);
  box-sizing: border-box;
  background-color: var(--bs-primary);
  font-size: 0.9rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: var(--ims-spacing--30) var(--ims-spacing--30) var(--ims-spacing--30) var(--ims-spacing--30);
  display: block;
  font-weight: 400;
  line-height: 1.2rem;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative; }
  .formulate-input[data-classification="file"] .formulate-files .formulate-file .formulate-file-name {
    padding-left: var(--ims-spacing--20);
    padding-right: 4em;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: auto; }
    .formulate-input[data-classification="file"] .formulate-files .formulate-file .formulate-file-name::before {
      left: 0.7rem;
      margin-top: -0.7rem;
      margin-top: var(--ims-spacing--0);
      background-color: var(--bs-border-color);
      content: "";
      -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.06 83.59"><path d="M55.94,83.59a8.16,8.16,0,0,0,8.12-8.16V19.12a1.77,1.77,0,0,0-.52-1.25L46.21.59A1.69,1.69,0,0,0,45.14.08L44.69,0l-.18,0H8.13A8.18,8.18,0,0,0,0,8.16V75.41a8.16,8.16,0,0,0,8.13,8.16H55.94ZM46.68,6,58.11,17.38H46.68ZM3.52,75.43V8.16A4.64,4.64,0,0,1,8.13,3.52h35V19.16a1.75,1.75,0,0,0,1.76,1.74H60.55V75.43a4.65,4.65,0,0,1-4.61,4.65H8.13A4.65,4.65,0,0,1,3.52,75.43Z"/></svg>');
      mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.06 83.59"><path d="M55.94,83.59a8.16,8.16,0,0,0,8.12-8.16V19.12a1.77,1.77,0,0,0-.52-1.25L46.21.59A1.69,1.69,0,0,0,45.14.08L44.69,0l-.18,0H8.13A8.18,8.18,0,0,0,0,8.16V75.41a8.16,8.16,0,0,0,8.13,8.16H55.94ZM46.68,6,58.11,17.38H46.68ZM3.52,75.43V8.16A4.64,4.64,0,0,1,8.13,3.52h35V19.16a1.75,1.75,0,0,0,1.76,1.74H60.55V75.43a4.65,4.65,0,0,1-4.61,4.65H8.13A4.65,4.65,0,0,1,3.52,75.43Z"/></svg>');
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: contain;
      mask-size: contain;
      width: 1.5rem;
      height: 1.5rem;
      display: inline-block; }
  .formulate-input[data-classification="file"] .formulate-files .formulate-file .formulate-file-progress {
    background-color: var(--bs-light-bg-subtle);
    height: 0.3rem;
    border-radius: var(--ims-border__radius--250);
    width: 5rem;
    overflow: hidden;
    position: absolute;
    right: var(--ims-spacing--30);
    transition: height 0.25s, width 0.25s, flex-basis 0.25s;
    z-index: 2; }
    .formulate-input[data-classification="file"] .formulate-files .formulate-file .formulate-file-progress .formulate-file-progress-inner {
      background-color: var(--bs-success);
      width: 1%;
      position: absolute;
      left: var(--ims-spacing--0);
      bottom: var(--ims-spacing--0);
      top: var(--ims-spacing--0);
      z-index: 2; }
    .formulate-input[data-classification="file"] .formulate-files .formulate-file .formulate-file-progress::before {
      content: "";
      position: absolute;
      top: var(--ims-spacing--0);
      left: var(--ims-spacing--0);
      right: var(--ims-spacing--0);
      bottom: var(--ims-spacing--0);
      display: block;
      opacity: 0;
      transform: scale(0.8);
      background-color: var(--bs-primary);
      -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>');
      mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>');
      -webkit-mask-size: 77%;
      mask-size: 77%;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-position: center;
      mask-position: center;
      z-index: 3; }
    .formulate-input[data-classification="file"] .formulate-files .formulate-file .formulate-file-progress[data-is-finished]::before {
      transition: opacity 0.1s;
      opacity: 1; }
    .formulate-input[data-classification="file"] .formulate-files .formulate-file .formulate-file-progress[data-is-finished] {
      transition: height 0.25s, width 0.25s, left 0.25s, top, 0.25s, border-radius 0.25s;
      width: 1rem;
      height: 1rem;
      right: 3rem; }

.formulate-input[data-classification="file"] .formulate-files .formulate-file-add {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  min-width: 75px;
  border: 0.1rem solid var(--bs-primary-dark);
  color: var(--bs-primary-dark);
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  position: relative;
  font-size: var(--ims-font__size--80);
  background-color: transparent;
  cursor: pointer;
  margin: var(--ims-spacing--20) var(--ims-spacing--0); }

.formulate-input[data-classification="file"] .formulate-files .formulate-file-remove {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: var(--ims-border__radius--200);
  border: 0.1rem solid var(--bs-border-color);
  background-color: var(--bs-primary-lightest);
  -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.1 59.2"><path d="M1.6,57.7a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5l21-21,21,21a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5,5,5,0,0,0,0-7.1l-21-21,21-21a5,5,0,0,0,0-7.1,5,5,0,0,0-7.1,0l-21,21L8.6,1.7a5,5,0,0,0-7.1,0,5,5,0,0,0,0,7.1l21,21L1.6,50.7A4.83,4.83,0,0,0,1.6,57.7Z"/></svg>');
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.1 59.2"><path d="M1.6,57.7a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5l21-21,21,21a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5,5,5,0,0,0,0-7.1l-21-21,21-21a5,5,0,0,0,0-7.1,5,5,0,0,0-7.1,0l-21,21L8.6,1.7a5,5,0,0,0-7.1,0,5,5,0,0,0,0,7.1l21,21L1.6,50.7A4.83,4.83,0,0,0,1.6,57.7Z"/></svg>');
  -webkit-mask-size: 0.6rem;
  mask-size: 0.6rem;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  cursor: pointer;
  position: absolute;
  right: var(--ims-spacing--30);
  z-index: 1 !important;
  transition: transform 0.25s; }
  .formulate-input[data-classification="file"] .formulate-files .formulate-file-remove::-moz-selection {
    background: var(--bs-success);
    color: var(--bs-primary); }
  .formulate-input[data-classification="file"] .formulate-files .formulate-file-remove::selection {
    background: var(--bs-success);
    color: var(--bs-primary); }

.formulate-input[data-classification="file"] .formulate-input-upload-area {
  width: 100%;
  position: relative;
  padding: var(--ims-spacing--80) var(--ims-spacing--0);
  background-color: var(--bs-white);
  border-radius: var(--ims-form-input__border-radius); }
  .formulate-input[data-classification="file"] .formulate-input-upload-area[data-has-files="true"] {
    padding: var(--ims-spacing--0) var(--ims-spacing--0); }
  .formulate-input[data-classification="file"] .formulate-input-upload-area ul.formulate-files {
    margin-bottom: 0.3rem; }
    .formulate-input[data-classification="file"] .formulate-input-upload-area ul.formulate-files li[data-has-error="true"] .formulate-file-progress {
      display: none !important; }

.formulate-input[data-classification="file"] .formulate-input-upload-area-mask {
  border-radius: var(--ims-form-input__border-radius);
  position: absolute;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  left: var(--ims-spacing--0);
  right: var(--ims-spacing--0);
  top: var(--ims-spacing--0);
  bottom: var(--ims-spacing--0);
  border: 1px solid var(--bs-border-color);
  z-index: 0;
  background-color: inherit; }

.requiredField:after {
  content: "*";
  color: var(--bs-danger);
  font-weight: bold; }

.formulate-input-help {
  line-height: normal;
  color: var(--bs-secondary-color);
  margin-top: var(--ims-spacing--10); }

.dataColumn input:not([type="radio"]):not([type="checkbox"]):not([type="file"]),
.dataColumn textarea,
.dataColumn .k-dropdown .k-state-default {
  background-color: var(--bs-white);
  border-radius: var(--ims-form-input__border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; }
  .dataColumn input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):hover,
  .dataColumn textarea:hover,
  .dataColumn .k-dropdown .k-state-default:hover {
    background-color: var(--bs-white); }

.k-dropdown-wrap.k-state-hover,
.k-numeric-wrap.k-state-hover {
  box-shadow: none !important; }

.ims-2-items-with-hypen-container {
  display: flex;
  gap: 0.4rem 0.4rem; }
  .ims-2-items-with-hypen-container div:first-child {
    flex-grow: 3; }
  .ims-2-items-with-hypen-container div:nth-child(2) {
    flex-grow: 0;
    align-self: baseline;
    margin-top: 0.5rem; }
  .ims-2-items-with-hypen-container div:last-child {
    flex-grow: 3; }

.ims-input-group-container {
  display: flex;
  gap: 0.5rem 0.5rem;
  justify-content: space-between;
  align-items: self-end; }
  .ims-input-group-container > div {
    flex: 1 1; }

.ims-formulate-input-spacing .formulate-input {
  margin-bottom: var(--ims-spacing--60); }

.ims-input-branded-object {
  display: inline-block;
  width: 0.875rem;
  height: 0.875rem; }

.formulate-input-element--customSelect .k-dropdown-wrap .k-input::before {
  content: "";
  display: contents;
  width: 0; }

.input-group .formulate-input-element--customSelect:last-child .k-dropdown-wrap.k-state-default {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.nav-item.widget-search .global-search-container input {
  box-shadow: none !important; }

.formulate-input[data-type="customImageUpload"] ul.formulate-input-errors,
.formulate-input[data-type="customImageUpload"] ul.formulate-files {
  padding: 0;
  position: relative; }
  .formulate-input[data-type="customImageUpload"] ul.formulate-input-errors li,
  .formulate-input[data-type="customImageUpload"] ul.formulate-files li {
    list-style: none !important; }
    .formulate-input[data-type="customImageUpload"] ul.formulate-input-errors li .formulate-file-image-preview .formulate-file-image-preview-image,
    .formulate-input[data-type="customImageUpload"] ul.formulate-files li .formulate-file-image-preview .formulate-file-image-preview-image {
      width: auto !important;
      max-height: 60px !important;
      padding: var(--ims-spacing--20) var(--ims-spacing--20) !important; }

.formulate-input[data-type="customUpload"] .k-button.k-upload-button > span:before {
  display: none !important; }

.formulate-input[data-type="customUpload"] .k-button.k-upload-button span {
  top: var(--ims-spacing--0);
  font-size: var(--ims-font__size--600); }

.formulate-input[data-type="customUpload"] .k-button.k-upload-button:hover {
  color: var(--bs-body-color) !important;
  background-color: var(--bs-surface-highest); }

table.k-editor {
  border: 0.0625rem solid var(--bs-border-color) !important; }
  table.k-editor .k-editable-area {
    background-color: var(--bs-white);
    border-bottom-left-radius: var(--ims-form-input__border-radius);
    border-bottom-right-radius: var(--ims-form-input__border-radius); }

.formulate-input-element--customTimePicker .k-datepicker .k-select,
.formulate-input-element--customTimePicker .k-datetimepicker .k-select,
.formulate-input-element--customTimePicker .k-numerictextbox .k-select,
.formulate-input-element--customTimePicker .k-widget.k-timepicker .k-select,
.formulate-input-element--customTimePicker .k-picker-wrap.k-state-default .k-select {
  border-top-right-radius: var(--ims-form-input__border-radius);
  border-bottom-right-radius: var(--ims-form-input__border-radius); }
  .formulate-input-element--customTimePicker .k-datepicker .k-select.k-state-hover,
  .formulate-input-element--customTimePicker .k-datetimepicker .k-select.k-state-hover,
  .formulate-input-element--customTimePicker .k-numerictextbox .k-select.k-state-hover,
  .formulate-input-element--customTimePicker .k-widget.k-timepicker .k-select.k-state-hover,
  .formulate-input-element--customTimePicker .k-picker-wrap.k-state-default .k-select.k-state-hover {
    background-color: var(--ims-form-input__hover); }
    .formulate-input-element--customTimePicker .k-datepicker .k-select.k-state-hover .k.select,
    .formulate-input-element--customTimePicker .k-datetimepicker .k-select.k-state-hover .k.select,
    .formulate-input-element--customTimePicker .k-numerictextbox .k-select.k-state-hover .k.select,
    .formulate-input-element--customTimePicker .k-widget.k-timepicker .k-select.k-state-hover .k.select,
    .formulate-input-element--customTimePicker .k-picker-wrap.k-state-default .k-select.k-state-hover .k.select {
      background-color: var(--ims-form-input__hover); }

.formulate-input-element--customTimePicker .k-combobox,
.formulate-input-element--customTimePicker .k-combobox .k-input,
.formulate-input-element--customTimePicker .k-datepicker,
.formulate-input-element--customTimePicker .k-datepicker .k-input,
.formulate-input-element--customTimePicker .k-datetimepicker,
.formulate-input-element--customTimePicker .k-datetimepicker .k-input,
.formulate-input-element--customTimePicker .k-timepicker,
.formulate-input-element--customTimePicker .k-timepicker .k-input {
  border-top-left-radius: var(--ims-form-input__border-radius) !important;
  border-bottom-left-radius: var(--ims-form-input__border-radius) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.formulate-input-wrapper .k-dropdown {
  border-radius: var(--ims-form-input__border-radius) !important; }

.k-combobox,
.k-combobox .k-input,
.k-datepicker,
.k-datepicker .k-input,
.k-datetimepicker,
.k-datetimepicker .k-input,
.k-timepicker,
.k-timepicker .k-input {
  border-top-left-radius: var(--ims-form-input__border-radius) !important;
  border-bottom-left-radius: var(--ims-form-input__border-radius) !important;
  background-color: var(--bs-white); }

.k-dropdown-wrap .k-input,
.k-numeric-wrap .k-input,
.k-picker-wrap .k-input {
  border-top-left-radius: var(--ims-form-input__border-radius) !important;
  border-bottom-left-radius: var(--ims-form-input__border-radius) !important; }

.formulate-input-element--customMultiselect::before {
  right: var(--ims-spacing--30) !important; }

.formulate-input-element--customMultiselect .k-widget.k-multiselect.k-state-disabled {
  border-color: var(--bs-border-color) !important;
  opacity: 0.98; }
  .formulate-input-element--customMultiselect .k-widget.k-multiselect.k-state-disabled .k-button {
    color: var(--bs-black) !important;
    border-color: var(--bs-border-color) !important; }
  .formulate-input-element--customMultiselect .k-widget.k-multiselect.k-state-disabled .k-multiselect-wrap.k-floatwrap {
    background-color: var(--bs-white); }

.vue-treeselect__placeholder {
  text-indent: 0.3rem; }

.is-required label {
  margin-bottom: var(--ims-spacing--10); }

.is-required label::after {
  content: "*";
  color: var(--bs-danger);
  font-weight: bold; }

.formulate-input-element--textarea [disabled="disabled"] {
  opacity: .3;
  pointer-events: none; }

.k-animation-container .k-list-container {
  overflow-y: auto !important;
  height: 12.74rem !important; }

@media (max-width: 575px) {
  .ims-input-group-container {
    flex-direction: column !important;
    align-items: normal;
    gap: 2.5rem 0.5rem; }
    .ims-input-group-container > div {
      align-items: self-start !important; }
  .ims-2-items-with-hypen-container {
    flex-direction: column !important;
    align-items: normal;
    gap: 0.5rem 0.5rem; }
    .ims-2-items-with-hypen-container div:nth-child(2) {
      align-self: center !important; } }

.ims-pagelayout-small {
  max-width: 28.125rem;
  margin-left: auto;
  margin-right: auto; }

.ims-pagelayout-medium {
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto; }

.ims-pagelayout-full {
  margin-left: 15px;
  margin-right: 15px; }

.ims-tile {
  border: 1px solid rgba(0, 0, 0, 0.125); }

.card .embed-responsive img {
  filter: contrast(85%);
  transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91); }

.card--fixwidth {
  background-color: transparent;
  width: 10.8rem;
  height: auto; }

.card:hover .embed-responsive img {
  filter: contrast(100%); }

.icon-40px {
  font-size: 40px;
  height: 40px;
  width: 40px; }

.icon-50px {
  font-size: 50px;
  height: 50px;
  width: 50px; }

.icon-80px {
  font-size: 80px;
  height: 80px;
  width: 80px; }

.ims-full-width-container {
  margin-left: -10%;
  margin-right: -10%; }
  .ims-full-width-container .ims-full-width-container-inner {
    width: 83.4%;
    margin-left: auto;
    margin-right: auto; }

.borderTopBrandingStyleObject {
  border-top: 0.3rem solid; }

.ims-borderLeft-branding-collapse {
  border-left: var(--ims-spacing--20) solid var(--bs-dark-border-subtle) !important; }
  .ims-borderLeft-branding-collapse.slim-fit-border {
    background-color: var(--bs-light-bg-subtle); }

.ims-borderLeft-branding-collapse-1rem {
  border-left: var(--ims-spacing--40) solid var(--bs-dark-border-subtle) !important; }
  .ims-borderLeft-branding-collapse-1rem.slim-fit-border {
    background-color: var(--bs-light-bg-subtle); }

.slim-fit-border {
  border: 0.0625rem solid var(--bs-dark-border-subtle); }
  .slim-fit-border.reset-padding {
    padding: var(--ims-spacing--0) var(--ims-spacing--0) !important; }
  .slim-fit-border h3 {
    max-width: 38rem; }
  .slim-fit-border .ims-icons-container .ims-icon, .slim-fit-border .ims-icons-container .ims-icon-arrow-left, .slim-fit-border .ims-icons-container .ims-icon-arrow-right {
    margin-left: var(--ims-spacing--40);
    margin-right: var(--ims-spacing--40);
    margin-top: auto;
    margin-bottom: auto; }
    .slim-fit-border .ims-icons-container .ims-icon.ims-custom-icon, .slim-fit-border .ims-icons-container .ims-custom-icon.ims-icon-arrow-left, .slim-fit-border .ims-icons-container .ims-custom-icon.ims-icon-arrow-right {
      font-size: var(--ims-font__size--700);
      margin-left: 0.2rem; }

.ims-contentInfoElement-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 4rem;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis; }
  .ims-contentInfoElement-centered a,
  .ims-contentInfoElement-centered label {
    overflow: hidden;
    text-overflow: ellipsis; }
  .ims-contentInfoElement-centered span {
    overflow: hidden;
    text-overflow: ellipsis; }
  .ims-contentInfoElement-centered.ims-white-space-normal h3 {
    white-space: normal !important; }
  .ims-contentInfoElement-centered-small {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 2rem; }

.ims-info-element {
  margin-bottom: var(--ims-spacing--35); }

.ims-display-ul-ol ul,
.ims-display-ul-ol ol {
  padding-inline-start: var(--ims-spacing--50);
  padding-inline-start: 1.5625rem;
  margin-bottom: var(--ims-spacing--20); }
  .ims-display-ul-ol ul li,
  .ims-display-ul-ol ol li {
    list-style: inherit !important; }

.inline-date-display {
  background-color: var(--bs-light-bg-subtle);
  font-size: var(--ims-font__size--80);
  padding: 0.125rem var(--ims-spacing--25); }

.session-container {
  margin-bottom: var(--ims-spacing--40);
  overflow-wrap: anywhere; }
  .session-container.session-header {
    margin-bottom: var(--ims-spacing--20); }

.ims-sub-item {
  margin-top: -1.875rem;
  margin-bottom: var(--ims-spacing--75); }

.ims-tile {
  padding: var(--ims-spacing--25);
  background-color: var(--bs-surface); }
  .ims-tile.ims-table-container {
    padding-left: var(--ims-spacing--0);
    padding-right: var(--ims-spacing--0); }

.ims-tile.slim-fit {
  padding: var(--ims-spacing--0) var(--ims-spacing--0); }

.ims-fixed-tiles-container {
  margin-right: -1.4375rem; }

.ims-fixed-tile-frame {
  padding: 0 1.4375rem 1.4375rem 0; }
  .ims-fixed-tile-frame .ims-tile-body {
    width: 18.75rem; }
  .ims-fixed-tile-frame .ims-tile-thumbnail-frame {
    margin-left: var(--ims-spacing--25);
    margin-right: var(--ims-spacing--25);
    margin-top: var(--ims-spacing--25); }

.slim-panel-item {
  width: auto;
  max-width: 50rem; }

.inner-container-center-fit {
  margin-left: auto;
  margin-right: auto; }

.ims-inner-collapse-block {
  padding-top: var(--ims-spacing--80);
  padding-bottom: var(--ims-spacing--80); }
  .ims-inner-collapse-block:not(:last-of-type) {
    border-bottom: 0.0625rem solid var(--bs-dark-border-subtle); }
  .ims-inner-collapse-block:first-of-type {
    padding-top: 0.6rem; }
  .ims-inner-collapse-block:last-of-type {
    padding-bottom: 0.6rem; }
  .ims-inner-collapse-block h3 {
    font-size: large !important; }

.inline-choice-container,
.checkbox-list,
.ims-file-upload {
  margin-top: 0.1875rem; }

.ims-meta-infobox {
  padding: var(--ims-spacing--80) 2 var(--ims-spacing--40);
  margin-bottom: 0.9375rem;
  background-color: var(--bs-surface-high); }

.ims-pageTitleSection {
  margin-top: var(--ims-spacing--40);
  margin-bottom: var(--ims-spacing--75); }

.ims-pageTitleSection {
  margin-bottom: var(--ims-spacing--80); }

.ims-widgetHeader {
  margin-top: var(--ims-spacing--40);
  margin-bottom: var(--ims-spacing--40); }

.carouselWidget .ims-widgetHeader {
  margin-top: var(--ims-spacing--40);
  margin-bottom: var(--ims-spacing--10); }

.ims-booking-btn-container {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1; }

.ims-profile-photo-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row; }
  .ims-profile-photo-container .ims-profile-photo-frame {
    margin-right: 0.9375rem; }
  .ims-profile-photo-container .ims-profile-photo-frame-m1 {
    margin: 0.9375rem 0.9375rem 0rem 0.9375rem; }

.ims-infoIcon-container {
  display: flex; }
  .ims-infoIcon-container .ims-icon-frame {
    margin-right: 0.9375rem; }
  .ims-infoIcon-container .ims-info-frame {
    margin: 0.9375rem 0.9375rem 0rem 0.9375rem; }

.ims-icon-bg-border {
  background-color: var(--bs-white);
  border: 0.1rem solid var(--bs-white); }

.ims-profile-header .ims-user-detail {
  position: absolute;
  left: var(--ims-spacing--40);
  top: auto;
  bottom: -20%; }

.ims-profile-header .ims-comp-base-image span {
  border-top-right-radius: var(--ims-panel__border-radius);
  border-top-left-radius: var(--ims-panel__border-radius); }

.ims-avatar-xl {
  height: 8.125rem;
  width: 8.125rem; }

.ims-avatar-l {
  height: 3.75rem;
  width: 3.75rem; }

.ims-avatar-m {
  height: 2.5rem;
  width: 2.5rem; }

.ims-avatar-sm {
  height: 1.875rem;
  width: 1.875rem; }

.ims-feed-header--fixed {
  position: relative;
  margin-top: 0.48rem; }
  .ims-feed-header--fixed .ims-feed-subtitle {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    width: 37.5rem; }
    .ims-feed-header--fixed .ims-feed-subtitle a.ims-button__icon {
      background-color: var(--bs-light-bg-subtle) !important;
      border-radius: var(--ims-border__radius--400);
      margin-right: var(--ims-spacing--40);
      padding: var(--ims-spacing--20) var(--ims-spacing--20); }
    .ims-feed-header--fixed .ims-feed-subtitle .ims-subtitle {
      align-self: center; }

.ims-profile-img {
  z-index: 2;
  position: relative; }
  .ims-profile-img img {
    border-radius: 50rem !important;
    border: 0.2rem solid var(--bs-border-color) !important; }

.ims-parent-feed-container,
.ims-parent-feed-container {
  border: 0.1rem solid var(--bs-border-color) !important;
  max-width: 37.5rem; }
  .ims-parent-feed-container--no-border,
  .ims-parent-feed-container--no-border {
    border: none;
    max-width: 37.5rem; }

.ims-child-feed-container {
  border-bottom: 0.1rem solid var(--bs-border-color) !important; }
  .ims-child-feed-container--header {
    border-bottom: 0.1rem solid var(--bs-border-color) !important; }
  .ims-child-feed-container:last-child {
    border-bottom: none !important; }
  .ims-child-feed-container .ims-feedback a {
    padding: 4px 8px; }
    .ims-child-feed-container .ims-feedback a:hover {
      background-color: var(--bs-light-bg-subtle) !important; }

.ims-card-feed {
  border: 0.1rem solid var(--bs-border-color) !important;
  border-radius: var(--ims-border__radius--175) !important;
  position: relative; }
  .ims-card-feed .embed-responsive {
    border-bottom-left-radius: var(--ims-border__radius--175) !important;
    border-bottom-right-radius: var(--ims-border__radius--175) !important; }
    .ims-card-feed .embed-responsive .embed-responsive-item {
      -o-object-fit: cover;
         object-fit: cover; }
  .ims-card-feed .img-fluid {
    border-bottom-left-radius: var(--ims-border__radius--175) !important;
    border-bottom-right-radius: var(--ims-border__radius--175) !important; }
  .ims-card-feed.round-image .img-fluid {
    border-top-left-radius: var(--ims-border__radius--175) !important;
    border-top-right-radius: var(--ims-border__radius--175) !important; }
  .ims-card-feed .ims-card-img-default .card-img-top {
    border-radius: var(--ims-border__radius--200) !important; }
  .ims-card-feed--no-text-content {
    position: relative;
    border: 0.1rem solid var(--bs-dark-border-subtle) !important;
    border-radius: var(--ims-border__radius--175) !important; }
    .ims-card-feed--no-text-content .embed-responsive {
      border-radius: var(--ims-border__radius--175) !important;
      width: 100%;
      height: auto; }
      .ims-card-feed--no-text-content .embed-responsive .embed-responsive-item {
        -o-object-fit: cover;
           object-fit: cover; }
    .ims-card-feed--no-text-content .ims-card-img-default .card-img-top {
      border-radius: var(--ims-border__radius--200) !important; }

.ims-cell-inner-div {
  border-bottom-color: 0.1rem solid #eff3f4; }

.ims-feed-line::after {
  content: '';
  position: absolute;
  width: 1px;
  background-color: var(--bs-light-bg-subtle);
  top: 0;
  bottom: 0;
  left: var(--ims-spacing--80);
  margin-left: -3px;
  z-index: -1; }

.ims-feed-line:hover::after {
  z-index: 1; }

.ims-feed-line {
  position: relative;
  max-width: 1200px;
  margin: 0 auto; }

.ims-feed-threat {
  margin-top: -1.8rem;
  margin-left: var(--ims-spacing--35); }

.ims-feed-text-content p {
  margin-bottom: var(--ims-spacing--20); }

.ims-feed-text-content ul {
  margin-left: var(--ims-spacing--60); }
  .ims-feed-text-content ul li {
    list-style: inherit !important; }

.ims-feed-text-content--parent {
  font-size: var(--ims-font__size--300) !important;
  line-height: var(--ims-font__line-height--body) !important;
  color: var(--bs-body-color) !important;
  overflow-wrap: break-word; }
  .ims-feed-text-content--parent p {
    margin-bottom: var(--ims-spacing--20);
    max-width: calc(90vw - 50px); }
  .ims-feed-text-content--parent ul {
    margin-left: var(--ims-spacing--60); }
    .ims-feed-text-content--parent ul li {
      list-style: inherit !important; }

.ims-dynamic-gap-box {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 8px; }
  .ims-dynamic-gap-box > div {
    flex: 1 1;
    width: 100%; }
  .ims-dynamic-gap-box.ims-full-width {
    display: flex;
    flex-direction: column;
    gap: 0px 0px; }

.ims-user-choice-selection-container {
  padding: var(--ims-spacing--40) var(--ims-spacing--40);
  border-radius: var(--ims-border__radius--50);
  border: 1px solid var(--bs-border-color); }
  .ims-user-choice-selection-container .ims-custom-collapse span.ims-icon, .ims-user-choice-selection-container .ims-custom-collapse span.ims-icon-arrow-left, .ims-user-choice-selection-container .ims-custom-collapse span.ims-icon-arrow-right {
    color: var(--bs-body-color); }

.ims-user-choice-selection--default {
  padding: var(--ims-spacing--40) var(--ims-spacing--40);
  border-radius: var(--ims-border__radius--50);
  border: 1px solid var(--bs-border-color);
  cursor: pointer; }
  .ims-user-choice-selection--default:hover {
    background-color: var(ims-color__neutral--80); }

.ims-user-choice-selection--selected {
  padding: var(--ims-spacing--40) var(--ims-spacing--40);
  border-radius: var(--ims-border__radius--50);
  border: 1px solid var(--bs-border-color);
  border: 1px solid var(--bs-border-color);
  background-color: var(--bs-light-bg-subtle);
  cursor: pointer; }

.ims-user-choice-selection--deactivated {
  padding: var(--ims-spacing--40) var(--ims-spacing--40);
  border-radius: var(--ims-border__radius--50);
  border: 1px solid var(--bs-border-color);
  color: var(--bs-dark-border-subtle);
  border: 1px solid var(--bs-dark-border-subtle); }

#ims-mainContentArea {
  margin-top: 2rem; }

@media (max-width: 1199px) {
  .ims-full-width-container {
    margin-left: -24px;
    margin-right: -24px; }
    .ims-full-width-container .ims-full-width-container-inner {
      width: auto;
      margin-left: 24px;
      margin-right: 24px; }
  .inner-container-center-fit {
    margin-left: 30px;
    margin-right: 30px; }
    .inner-container-center-fit .inner-container-center-fit {
      margin-left: auto;
      margin-right: auto; }
  .slim-panel-item {
    max-width: initial; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .inner-container-center-fit {
    margin-left: 30px;
    margin-right: 30px; }
    .inner-container-center-fit .inner-container-center-fit {
      margin-left: auto;
      margin-right: auto; }
  .slim-panel-item {
    max-width: initial; } }

@media (max-width: 991px) {
  .slim-fit-border h3 {
    max-width: 34rem; }
  .inner-container-center-fit {
    margin-left: 0px;
    margin-right: 0px; }
  .medium-panel-item {
    width: auto;
    margin-left: 0rem;
    margin-right: 0rem; }
  .ims-inner-collapse-block .course-type {
    position: initial !important; } }

@media (max-width: 575px) {
  .slim-fit-border h3 {
    max-width: 20rem; }
  .ims-dynamic-gap-box {
    display: flex;
    flex-direction: row;
    gap: 0.25rem 0px; }
  .inner-container-center-fit {
    margin-left: 0;
    margin-right: 0; }
  .navbar-expand-md {
    margin-top: 0.9375rem; }
  .medium-panel-item {
    width: auto;
    margin-left: 0rem;
    margin-right: 0rem; }
  .ims-full-width-card-container .ims-full-width-inner-card-container .ims-course-progress {
    margin-bottom: var(--ims-spacing--40); }
  .ims-feed-header--fixed .ims-feed-subtitle {
    width: 27.5rem; }
  .ims-user-detail .ims-profile-img img.ims-avatar-xl {
    height: 5.125rem !important;
    width: 5.125rem !important; }
  .ims-profile-header .ims-user-detail {
    bottom: -15%; }
  .ims-feed-header--fixed .ims-feed-subtitle {
    width: 21.4rem; }
  .ims-user-detail .ims-profile-img img.ims-avatar-xl {
    height: 4.125rem !important;
    width: 4.125rem !important; }
  .ims-pagelayout-small {
    padding-left: 1.5rem;
    padding-right: 1.5rem; } }

@media screen and (min-width: 414px) and (max-width: 476px) {
  .ims-parent-feed-container,
  .ims-parent-feed-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
    .ims-parent-feed-container--no-border,
    .ims-parent-feed-container--no-border {
      border: none;
      width: 23.4rem; }
  .ims-feed-header--fixed .ims-feed-subtitle {
    width: 23.4rem; } }

@media screen and (min-width: 420px) and (max-width: 476px) {
  .ims-parent-feed-container,
  .ims-parent-feed-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
    .ims-parent-feed-container--no-border,
    .ims-parent-feed-container--no-border {
      border: none;
      width: 23.4rem; }
  .ims-feed-header--fixed .ims-feed-subtitle {
    width: 23.4rem; } }

@media screen and (min-width: 369px) and (max-width: 399px) {
  .ims-parent-feed-container,
  .ims-parent-feed-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
    .ims-parent-feed-container--no-border,
    .ims-parent-feed-container--no-border {
      border: none;
      width: 21.2rem; }
  .ims-feed-header--fixed .ims-feed-subtitle {
    width: 21.2rem; } }

.ims-lng {
  display: none; }

[lang="da-DK"] .ims-lng--da-DK,
[lang="nl-BE"] .ims-lng--nl-BE,
[lang="nl-NL"] .ims-lng--nl-NL,
[lang="en-AU"] .ims-lng--en-AU,
[lang="en-CA"] .ims-lng--en-CA,
[lang="en-GB"] .ims-lng--en-GB,
[lang="en-US"] .ims-lng--en-US,
[lang="fi-FI"] .ims-lng--fi-FI,
[lang="fr-BE"] .ims-lng--fr-BE,
[lang="fr-CA"] .ims-lng--fr-CA,
[lang="fr-FR"] .ims-lng--fr-FR,
[lang="fr-CH"] .ims-lng--fr-CH,
[lang="de-DE"] .ims-lng--de-DE,
[lang="de-CH"] .ims-lng--de-CH,
[lang="it-IT"] .ims-lng--it-IT,
[lang="nb-NO"] .ims-lng--nb-NO,
[lang="pt-BR"] .ims-lng--pt-BR,
[lang="es-CO"] .ims-lng--es-CO,
[lang="es-ES"] .ims-lng--es-ES,
[lang="sv-SE"] .ims-lng--sv-SE,
[lang="bg-BG"] .ims-lng--bg-BG,
[lang="zh-CN"] .ims-lng--zh-CN,
[lang="cs-CZ"] .ims-lng--cs-CZ,
[lang="en-SG"] .ims-lng--en-SG,
[lang="en-ZA"] .ims-lng--en-ZA,
[lang="hu-HU"] .ims-lng--hu-HU,
[lang="it-CH"] .ims-lng--it-CH,
[lang="pl-PL"] .ims-lng--pl-PL,
[lang="pt-PT"] .ims-lng--pt-PT,
[lang="ro-RO"] .ims-lng--ro-RO,
[lang="tr-TR"] .ims-lng--tr-TR,
[lang="ar-SA"] .ims-lng--ar-SA,
[lang="hr-HR"] .ims-lng--hr-HR,
[lang="en-029"] .ims-lng--en-029,
[lang="en-NZ"] .ims-lng--en-NZ,
[lang="de-AT"] .ims-lng--de-AT,
[lang="el-GR"] .ims-lng--el-GR,
[lang="kk-KZ"] .ims-lng--kk-KZ,
[lang="mt-MT"] .ims-lng--mt-MT,
[lang="fa-IR"] .ims-lng--fa-IR,
[lang="ru-RU"] .ims-lng--ru-RU,
[lang="sr-Latn-RS"] .ims-lng--sr-Latn-RS,
[lang="sk-SK"] .ims-lng--sk-SK,
[lang="sl-SI"] .ims-lng--sl-SI,
[lang="uk-UA"] .ims-lng--uk-UA {
  display: block; }

/* USE AS FOLLOWS

<html lang="de-DE">
<body>

<div class="ims-lng ims-lng--de-DE">
block 1 de
</div>
<div class="ims-lng ims-lng--es-ES">
block 2 es
</div>
<div class="ims-lng ims-lng--en-GB">
block 3 en
</div>


</body>
</html>

*/
.ims-metadata .user-portrait img {
  width: 7.5rem;
  height: auto; }

.thumbnail-image {
  height: inherit;
  width: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  display: block; }

.ims-tile-thumbnail-frame {
  width: 18.75rem;
  height: calc(18.75rem / 16 * 9);
  background-color: var(--bs-border-color);
  position: relative; }
  .ims-tile-thumbnail-frame .ims-tile-thumbnail {
    width: 100%;
    height: 100%;
    background-position: center;
    display: block; }
  .ims-tile-thumbnail-frame .ims-duration-indicator {
    position: absolute;
    bottom: var(--ims-spacing--15);
    left: var(--ims-spacing--15);
    background-color: rgba(var(--bs-body-color-rgb), 0.6);
    border-radius: 0.125rem;
    padding-left: var(--ims-spacing--15);
    padding-right: var(--ims-spacing--15);
    color: var(--bs-on-primary);
    font-size: var(--ims-font__size--60); }

.imagePreview img {
  width: 16.25rem;
  display: block;
  margin-bottom: var(--ims-spacing--60);
  margin-left: auto;
  margin-right: auto;
  box-shadow: var(--ims-spacing--25); }

.ims-profile-photo-frame, .ims-profile-photo-frame-m1 {
  width: 4.0625rem;
  height: 4.0625rem;
  display: block; }

.ims-profile-photo {
  width: inherit;
  height: inherit;
  display: block;
  background: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3.125rem; }

.ims-thumbnail-frame-sm, .catalogue-item-thumbnail.ims-thumbnail-frame-sm {
  width: 8.125rem;
  height: calc(8.125rem / 16*9);
  margin-right: var(--ims-spacing--25); }

.ims-thumbnail-frame-md, .catalogue-item-thumbnail.ims-thumbnail-frame-md {
  width: 12.5rem;
  height: calc(12.5rem / 16*9);
  margin-right: var(--ims-spacing--25); }

.ims-image-overlay {
  position: absolute;
  background-color: rgba(var(--bs-body-color-rgb), 0.7);
  color: var(--bs-on-primary);
  bottom: 0;
  padding: var(--ims-spacing--25) var(--ims-spacing--25);
  width: 100%; }

.video-js, .wbt-player, .resource-type-image {
  display: block; }

.ims-video-wrapper {
  width: 100%;
  height: 37.5rem; }

.resource-window {
  margin-bottom: 1.625rem;
  margin-top: 1.625rem; }

.no-shadow {
  box-shadow: none; }

.ims-widget-video .ims-video-overlay .ims-icon-play {
  padding: 0px 10px 10px 15px;
  position: absolute;
  background-color: rgba(var(---bs-body-color-rgb), 0.1);
  border-radius: 50%;
  font-size: 5rem !important;
  top: 40%;
  left: 45%;
  width: 130px;
  height: 130px;
  cursor: pointer;
  opacity: .8;
  color: var(--bs-on-primary);
  transition: all 0.4s ease; }

.ims-widget-video .ims-video-overlay:hover {
  background-color: rgba(var(--bs-body-color-rgb), 0.1); }
  .ims-widget-video .ims-video-overlay:hover .ims-icon-play {
    opacity: 1;
    transition: all 0.4s ease; }

@media (max-width: 575px) {
  .ims-thumbnail-frame-md, .catalogue-item-thumbnail.ims-thumbnail-frame-md {
    overflow: visible !important; }
    .ims-thumbnail-frame-md .ims-overlay-icon-button, .catalogue-item-thumbnail.ims-thumbnail-frame-md .ims-overlay-icon-button {
      bottom: -0.96875rem;
      position: absolute;
      right: var(--ims-spacing--20); }
  .ims-widget-video .ims-video-overlay .ims-icon-play {
    top: 24% !important;
    left: 38% !important; } }

@media print {
  body:has(#section-to-print) {
    visibility: hidden; }
  * {
    float: none !important;
    overflow: visible !important; }
  .vue-portal-target > * {
    position: absolute;
    left: 0;
    top: 0; }
  #section-to-print {
    padding: 2rem;
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0; }
    #section-to-print table {
      -moz-column-break-after: auto;
           break-after: auto; }
    #section-to-print tr {
      -moz-column-break-inside: avoid;
           break-inside: avoid;
      -moz-column-break-after: auto;
           break-after: auto; }
    #section-to-print td {
      -moz-column-break-inside: avoid;
           break-inside: avoid;
      -moz-column-break-after: auto;
           break-after: auto; }
    #section-to-print thead {
      display: table-header-group;
      -moz-column-break-after: auto;
           break-after: auto; }
    #section-to-print tfoot {
      display: table-footer-group;
      -moz-column-break-after: auto;
           break-after: auto; }
    #section-to-print div {
      -moz-column-break-inside: avoid;
           break-inside: avoid;
      -moz-column-break-after: auto;
           break-after: auto; }
    #section-to-print table, #section-to-print tr, #section-to-print td, #section-to-print th {
      border: 1px solid; }
    #section-to-print table {
      border-collapse: collapse; } }

.custom-switch-field {
  display: flex; }
  .custom-switch-field input {
    position: absolute;
    height: 0px;
    width: 0px;
    opacity: 0;
    margin: 0; }
  .custom-switch-field label {
    background-color: var(--bs-white);
    color: var(--bs-primary);
    border: 0.0625rem solid var(--bs-primary);
    padding: var(--ims-spacing--30) var(--ims-spacing--25);
    line-height: 0.725rem;
    margin: 0; }
    .custom-switch-field label:first-of-type {
      border-top-left-radius: var(--ims-toggler__border-radius);
      border-bottom-left-radius: var(--ims-toggler__border-radius); }
    .custom-switch-field label:last-of-type {
      border-top-right-radius: var(--ims-toggler__border-radius);
      border-bottom-right-radius: var(--ims-toggler__border-radius); }
    .custom-switch-field label:hover {
      cursor: pointer; }
  .custom-switch-field input:checked + label {
    color: var(--bs-white);
    background-color: var(--bs-primary); }

.ims-material-switch {
  margin-bottom: var(--ims-spacing--15);
  display: flex;
  justify-content: flex-end;
  padding: var(--ims-spacing--25) var(--ims-spacing--25) var(--ims-spacing--30) var(--ims-spacing--0);
  box-shadow: none; }
  .ims-material-switch > input[type="checkbox"] {
    display: none; }
    .ims-material-switch > input[type="checkbox"]:checked + label::before {
      background: var(--bs-primary-dark);
      opacity: .5; }
    .ims-material-switch > input[type="checkbox"]:checked + label::after {
      background: inherit;
      left: var(--ims-spacing--50); }
  .ims-material-switch > label {
    cursor: pointer;
    height: 0;
    position: relative;
    width: 2.5rem; }
    .ims-material-switch > label::before {
      border-radius: 0.5rem;
      content: '';
      height: 1rem;
      margin-top: -0.5rem;
      position: absolute;
      opacity: .3;
      transition: all .3s ease-in-out;
      width: 2.5rem;
      background: var(--bs-border-color); }
    .ims-material-switch > label::after {
      border-radius: 1rem;
      content: '';
      height: 1.5rem;
      left: -0.25rem;
      margin-top: -0.5rem;
      position: absolute;
      top: -0.25rem;
      transition: all .2s ease-in-out;
      width: 1.5rem;
      background: var(--bs-dark-border-subtle);
      box-shadow: none; }
    .ims-material-switch > label.label-disabled {
      cursor: context-menu; }
      .ims-material-switch > label.label-disabled::before {
        background: var(--bs-white); }
  .ims-material-switch .label-success {
    background-color: var(--bs-primary); }

.ims-custom-dropdown {
  display: flex;
  justify-content: space-between;
  border-radius: 0.3125rem; }

.ims-custom-dropdown,
.ims-custom-searchbar {
  overflow-x: hidden;
  padding: var(--ims-spacing--20) var(--ims-spacing--25); }
  .ims-custom-dropdown .dropdown-selection,
  .ims-custom-searchbar .dropdown-selection {
    text-overflow: ellipsis;
    overflow-x: hidden; }

.underlined {
  border: none;
  border-bottom: 0.0625rem solid var(--bs-border-color);
  border-radius: 0; }

.vertical-divide {
  height: 1.125rem;
  margin-left: var(--ims-spacing--30);
  margin-right: var(--ims-spacing--30);
  border-left: 0.0625rem solid var(--bs-dark-border-subtle); }

.ims-radio-switch {
  border-radius: 0.3125rem;
  position: relative;
  background-color: var(--bs-white);
  border: 0.0625rem solid var(--bs-primary); }
  .ims-radio-switch label.ims-radio-pill {
    cursor: pointer;
    margin: 0;
    text-align: center;
    z-index: 1;
    padding: var(--ims-spacing--20) var(--ims-spacing--20);
    color: var(--bs-primary); }
  .ims-radio-switch input[type="radio"] {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0; }
  .ims-radio-switch input:checked + label {
    color: var(--bs-on-primary); }
  .ims-radio-switch .ims-switch-selection {
    border-radius: 0.3125rem;
    width: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -0.0625rem;
    margin-left: 0;
    transition: margin-left .15s ease-out;
    background-color: var(--bs-primary);
    border: 0.0625rem solid var(--bs-primary); }
  .ims-radio-switch input.switch-off:checked ~ .ims-switch-selection {
    margin-left: 51%; }

.ims-filter-count {
  display: inline-block;
  font-size: 0.5rem;
  border-radius: 50%;
  width: 1.125rem;
  height: 1.125rem;
  line-height: 1.125rem;
  font-weight: bold;
  text-align: center;
  margin-left: var(--ims-spacing--15);
  color: var(--bs-on-primary);
  text-indent: -0.0625rem;
  background-color: var(--bs-primary); }

.ims-custom-dropdown {
  background-color: var(--bs-white);
  border: 0.0625rem solid var(--bs-dark-border-subtle); }
  .ims-custom-dropdown .dropdown-selection {
    color: var(--bs-body-color); }
  .ims-custom-dropdown .ims-icon, .ims-custom-dropdown .ims-icon-arrow-left, .ims-custom-dropdown .ims-icon-arrow-right {
    color: var(--bs-body-color); }

.form-control {
  font-size: inherit; }

.ims-progress-bar .progress .progress-bar {
  background-color: var(--bs-white); }

.ims-progress-bar .progress .progress-bar-error,
.ims-progress-bar .progress .progress-bar-failed {
  background-color: var(--bs-danger); }

.ims-progress-bar .progress .progress-bar-warning,
.ims-progress-bar .progress .progress-bar-incomplete {
  background-color: var(--bs-orange); }

.ims-progress-bar .progress .progress-bar-success,
.ims-progress-bar .progress .progress-bar-completed,
.ims-progress-bar .progress .progress-bar-passed {
  background-color: var(--bs-success); }

.ims-progress-bar .ims-icon.error, .ims-progress-bar .error.ims-icon-arrow-left, .ims-progress-bar .error.ims-icon-arrow-right,
.ims-progress-bar .ims-icon.failed,
.ims-progress-bar .failed.ims-icon-arrow-left,
.ims-progress-bar .failed.ims-icon-arrow-right {
  color: var(--bs-danger); }

.ims-progress-bar .ims-icon.incomplete, .ims-progress-bar .incomplete.ims-icon-arrow-left, .ims-progress-bar .incomplete.ims-icon-arrow-right {
  color: var(--bs-orange); }

.ims-progress-bar i.ims-icon.completed, .ims-progress-bar i.completed.ims-icon-arrow-left, .ims-progress-bar i.completed.ims-icon-arrow-right,
.ims-progress-bar i.ims-icon.passed,
.ims-progress-bar i.passed.ims-icon-arrow-left,
.ims-progress-bar i.passed.ims-icon-arrow-right {
  color: var(--bs-success); }

a.ims-accordion .custom-accordion-plus:before, a.ims-accordion .custom-accordion-plus:after {
  border-top-color: var(--bs-primary); }

a.ims-accordion:hover .custom-accordion-plus:before, a.ims-accordion:hover .custom-accordion-plus:after {
  border-top-color: var(--bs-on-primary); }

select {
  border-radius: --ims-form-input__border-radius !important; }

/* Firefox */
html {
  scrollbar-color: var(--bs-body-color); }

.sticky-header #ims-logo {
  transition: opacity 0.5s; }

.ribbon-container-topBar .ims-page-logo img {
  height: 3.75rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem; }

#ims-navigation-logo-bottom-right .img-responsive {
  height: 3rem;
  margin: var(--ims-spacing--25) var(--ims-spacing--0); }

@keyframes navTransition {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes navTransitionBack {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

#pageHeaderMobile {
  display: none; }

.sticky-header .ribbon-container-topBar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  align-items: center !important;
  display: flex; }

.sticky-header .ribbon-container-mainNav {
  margin-top: 3.75rem; }

.sticky-header .headerTopBar.header-is-sticky-has-cs {
  margin-top: var(--ims-spacing--200); }

.sticky-header.sticky-navigation.sticked {
  margin-bottom: 5rem; }
  .sticky-header.sticky-navigation.sticked #ims-logo {
    display: block; }
  .sticky-header.sticky-navigation.sticked #ims-headerMenuBar {
    position: fixed;
    z-index: 1;
    margin-top: -4.375rem;
    animation: navTransition 1s;
    width: 60% !important; }
  .sticky-header.sticky-navigation.sticked .d-flex.align-items-center.justify-content-between #ims-headerMenuBar {
    margin-top: 0rem;
    animation: navTransition 1s; }
  .sticky-header.sticky-navigation.sticked .d-flex.align-items-center.justify-content-between .navbar {
    padding: var(--ims-spacing--20) var(--ims-spacing--0) !important; }

.sticky-header.sticky-navigation:not(.sticked) #ims-headerMenuBar {
  animation: navTransitionBack 0.5s; }

#ims-headerNav .nav > .dropdown-item .ims-icon, #ims-headerNav .nav > .dropdown-item .ims-icon-arrow-left, #ims-headerNav .nav > .dropdown-item .ims-icon-arrow-right,
#ims-headerNav .nav-link .ims-icon,
#ims-headerNav .nav-link .ims-icon-arrow-left,
#ims-headerNav .nav-link .ims-icon-arrow-right {
  color: var(--bs-primary); }

#ims-headerNav a.nav-link.dropdown-toggle .ims-icon, #ims-headerNav a.nav-link.dropdown-toggle .ims-icon-arrow-left, #ims-headerNav a.nav-link.dropdown-toggle .ims-icon-arrow-right {
  color: var(--bs-primary); }

.nav-item.widget-search .global-search-container input {
  box-shadow: none !important; }

#pageHeaderDesktop #ims-headerTopBar #ims-headerNav .nav-item.dropdown {
  max-width: 18.8rem !important; }
  #pageHeaderDesktop #ims-headerTopBar #ims-headerNav .nav-item.dropdown .dropdown-toggle {
    white-space: nowrap !important; }

#ims-header {
  position: relative;
  z-index: 3; }

.ims-cs-cookie {
  display: flex;
  width: 100%;
  z-index: 4;
  background-color: var(--ims-cs__main__bg-color);
  position: relative;
  height: 5rem; }
  .ims-cs-cookie .ims-cs-content {
    display: flex;
    box-sizing: border-box;
    align-items: center; }
    .ims-cs-cookie .ims-cs-content .ims-cs-txt {
      display: inline-block;
      flex: 1;
      font-size: var(--ims-font__size--100); }
    .ims-cs-cookie .ims-cs-content .ims-cs-actions {
      gap: 1rem;
      padding-inline-start: 32px;
      box-sizing: border-box;
      display: flex;
      flex: 1;
      justify-content: flex-end; }
      .ims-cs-cookie .ims-cs-content .ims-cs-actions .formulate-input {
        min-width: 14rem; }
      .ims-cs-cookie .ims-cs-content .ims-cs-actions .formulate-input-element--customSelect .k-dropdown-wrap.k-state-default.k-state-hover {
        background-color: var(--ims-btn-secondary__background--hovered) !important; }
        .ims-cs-cookie .ims-cs-content .ims-cs-actions .formulate-input-element--customSelect .k-dropdown-wrap.k-state-default.k-state-hover .k-select {
          background-color: var(--ims-btn-secondary__background--hovered) !important; }
  .ims-cs-cookie.ims-cs-sticky-navigation {
    position: fixed;
    top: 0; }
  .ims-cs-cookie.ims-cs-sticked {
    position: fixed;
    top: 0; }

.sticky-header.sticky-navigation.is-sticky .ribbon-container-topBar {
  margin-top: 5rem; }

.sticky-header.sticky-navigation.is-sticky .headerTopBar {
  margin-top: var(--ims-spacing--200) !important; }

.sticky-header.is-sticky .ribbon-container-mainNav {
  margin-top: var(--ims-sticky-header-ribbon-top-spacing); }

#ims-mainContentArea.header-is-sticky-has-cs {
  padding-top: var(--ims-spacing--50); }

@media (min-width: 1200px) {
  .ribbon-container-topBar {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2; }
  #pageHeaderDesktop {
    display: block; }
    #pageHeaderDesktop .navbar {
      padding: var(--ims-spacing--20) var(--ims-spacing--0); }
    #pageHeaderDesktop .d-flex.align-items-center.justify-content-between .navbar {
      padding: var(--ims-spacing--20) var(--ims-header__menu-padding-l); }
  .body-noscroll {
    overflow: auto !important;
    position: relative !important; } }

@media (max-width: 1199px) {
  #ims-mainContentArea {
    margin-top: 3.75rem; } }

@media (max-width: 991px) {
  header.sticky-header.is-sticky ~ #ims-mainContentArea {
    margin-top: var(--ims-spacing--400); } }

.body-noscroll {
  overflow: hidden;
  position: fixed;
  width: 100%; }

@media (max-width: 991px) {
  #pageHeaderMobile {
    display: block;
    position: relative;
    z-index: 3; }
    #pageHeaderMobile .navbar {
      padding: var(--ims-spacing--20) var(--ims-spacing--0); }
    #pageHeaderMobile #ims-mainNav {
      padding: var(--ims-spacing--60) var(--ims-spacing--0); }
  #pageHeaderDesktop {
    display: none; }
  .ims-cs-cookie {
    position: relative;
    top: 0; }
    .ims-cs-cookie .ims-cs-content .ims-cs-actions {
      flex-wrap: nowrap; }
    .ims-cs-cookie .ims-cs-content.ims-default-container {
      width: 100% !important;
      margin-left: 0;
      margin-right: 0; }
  .ims-cs-content .ims-cs-actions {
    padding-inline-start: 0px !important; }
  .ims-cs-mobile .ribbon-container-topBar {
    position: relative; }
  #pageHeaderMobile .navbar .ims-icon.ims-icon-menu, #pageHeaderMobile .navbar .ims-icon-menu.ims-icon-arrow-left, #pageHeaderMobile .navbar .ims-icon-menu.ims-icon-arrow-right {
    margin-right: 0 !important; }
  #mobile-header-collapse-menu-user .grid {
    padding: var(--ims-spacing--60) var(--ims-spacing--0); } }

@media (max-width: 575px) {
  .ims-cs-cookie .ims-cs-content .ims-cs-txt {
    display: none !important; }
  .ims-cs-cookie .ims-cs-content .ims-cs-actions {
    gap: 0.2rem;
    justify-content: left; }
    .ims-cs-cookie .ims-cs-content .ims-cs-actions .formulate-input {
      min-width: 12rem; } }

@media only screen and (min-width: 2500px) {
  .sticky-header.sticky-navigation.sticked #ims-headerMenuBar {
    width: 48% !important; } }

/* this is used for resizing the grids in the administration area, not required for tutoring area */
.administrationArea .leftPane .k-grid .k-grid-pager {
  float: left;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }

/*#region Kendo Grid overrides */
.splitter > .leftPane .k-pager-wrap {
  padding-left: var(--ims-spacing--75); }

.k-column-menu .k-menu,
.training-content-actions-menu .k-menu,
.splitter .toolButtonArea .k-menu {
  color: var(--bs-body-color) !important; }

.ims-navigation-item a, .ims-navigation-item a:not([href]):not([tabindex]), .ims-alltabs-save a, .ims-alltabs-save a:not([href]):not([tabindex]) {
  color: var(--bs-dark-border-subtle) !important; }

.ims-navigation-item .dropdown-item {
  padding: 0 !important; }

.ims-grid-view .ims-navigation {
  position: fixed; }

.ims-navigation-list {
  width: 100%;
  margin-bottom: 0; }

.ims-navigation-list > .ims-navigation-item {
  padding: var(--ims-spacing--25) var(--ims-spacing--15);
  position: relative; }
  .ims-navigation-list > .ims-navigation-item > a .ims-icon, .ims-navigation-list > .ims-navigation-item > a .ims-icon-arrow-left, .ims-navigation-list > .ims-navigation-item > a .ims-icon-arrow-right {
    display: block;
    margin-bottom: 0.125rem; }
    .ims-navigation-list > .ims-navigation-item > a .ims-icon:before, .ims-navigation-list > .ims-navigation-item > a .ims-icon-arrow-left:before, .ims-navigation-list > .ims-navigation-item > a .ims-icon-arrow-right:before {
      display: block;
      margin-left: auto;
      margin-right: auto;
      font-size: 1.40625rem;
      color: var(--bs-on-primary); }
  .ims-navigation-list > .ims-navigation-item > a .ims-nav-item-title {
    display: block;
    color: var(--bs-on-primary);
    text-align: center;
    font-size: var(--ims-font__size--60); }

.ims-navigation-item:hover,
.ims-navbar-toggler:hover {
  background-color: var(--ims-sidebar__navitem-bg); }

#sidebar .ims-navigation-item .dropdown-menu .ims-navigation-item:hover {
  background-color: var(--ims-sidebar__navitem-bg--hover) !important; }

.ims-menu-splitter {
  border-color: var(--bs-primary-lightest);
  margin-top: var(--ims-spacing--10);
  margin-bottom: var(--ims-spacing--10);
  width: inherit; }

.ims-collapse-icon-bar {
  height: 0.25rem;
  margin-top: var(--ims-spacing--15);
  margin-bottom: var(--ims-spacing--15);
  background-color: var(--bs-white); }

.nav-element:hover {
  cursor: pointer;
  text-decoration: none; }

.nav-item-splitter {
  position: absolute;
  right: 35%;
  border-left: 1px solid var(--bs-primary-lightest);
  padding: var(--ims-spacing--30) var(--ims-spacing--0); }

.navbar-toggler:focus {
  box-shadow: 0 0 0 0.14rem !important; }

.navbar-toggler.btn {
  border-radius: var(--ims-header-nav-bar-toggler__border-radius) !important; }

.navbar-collapse.ims-navigation .navbar-toggler {
  background-color: transparent;
  border-bottom: 0.0625rem solid var(--bs-primary-light);
  width: inherit;
  height: 4.6875rem;
  margin: 0;
  padding-left: var(--ims-spacing--100);
  align-items: center; }
  .navbar-collapse.ims-navigation .navbar-toggler .ims-icon::before, .navbar-collapse.ims-navigation .navbar-toggler .ims-icon-arrow-left::before, .navbar-collapse.ims-navigation .navbar-toggler .ims-icon-arrow-right::before {
    line-height: 0.9375rem;
    font-size: 0.9375rem; }

.navbar-collapse.ims-navigation .nav-item.active {
  background-color: var(--bs-primary); }

/*#region Kendo Menu overrides */
.ddMenu ul.k-widget.k-reset.k-header.k-menu.k-menu-horizontal {
  float: right \9; }

.ddMenu .k-animation-container,
.ddMenu .k-animation-container ul {
  margin-left: var(--ims-spacing--0) !important;
  margin-right: var(--ims-spacing--0) !important;
  padding-left: var(--ims-spacing--0) !important;
  padding-right: var(--ims-spacing--0) !important; }

.ddMenu .k-menu .k-item > .k-link > .k-i-arrow-e {
  right: 0; }

.ddMenu ul .icon {
  margin-left: var(--ims-spacing--0);
  margin-top: var(--ims-spacing--0) !important; }

.ddMenu ul li .ims-icon.left, .ddMenu ul li .left.ims-icon-arrow-left, .ddMenu ul li .left.ims-icon-arrow-right {
  float: none; }

.ddMenu .k-menu .k-item .k-item,
.ddMenu ul.k-menu-vertical > .k-separator {
  padding-right: var(--ims-spacing--0);
  padding-bottom: var(--ims-spacing--0);
  padding-top: var(--ims-spacing--0); }

.ddMenu .k-menu {
  margin: 0;
  padding: 0;
  height: 100%; }
  .ddMenu .k-menu > li.k-item.k-state-hover:hover {
    background-color: rgba(var(--bs-body-color-rgb), 0.1);
    height: 100%; }

.k-menu.k-header,
.k-menu .k-item,
.k-widget.k-menu-horizontal > .k-item {
  border: none; }

.k-menu .k-item > .k-state-active,
.k-menu .k-item > .k-link:hover,
.k-menu .k-item.k-state-hover,
.k-menu .k-item.k-state-hover:hover {
  background-color: transparent; }

.k-menu {
  background-color: transparent !important; }
  .k-menu .k-menu-group .k-item > .k-link {
    color: var(--bs-body-color); }
  .k-menu .k-animation-container {
    border: none; }
    .k-menu .k-animation-container ul {
      border: none; }
    .k-menu .k-animation-container .k-menu-group {
      padding: 0; }
  .k-menu .k-item > a {
    color: var(--bs-on-primary); }
  .k-menu .k-item > .k-link > .k-icon {
    margin-left: 0; }
  .k-menu > .k-item > .k-link:hover {
    opacity: 0.8; }

.toolBarArea .ims-dropdown-menu .formulate-input {
  width: 14.2rem !important; }

.toolBarArea .ims-dropdown-menu .k-dropdown-wrap .k-input {
  height: 2.1rem; }

.toolBarArea input {
  height: auto; }

.toolBarArea .input-element.searchBoxArea {
  display: inline-flex; }
  .toolBarArea .input-element.searchBoxArea button {
    box-shadow: none; }
    .toolBarArea .input-element.searchBoxArea button .ims-icon, .toolBarArea .input-element.searchBoxArea button .ims-icon-arrow-left, .toolBarArea .input-element.searchBoxArea button .ims-icon-arrow-right {
      color: var(--bs-primary); }
  .toolBarArea .input-element.searchBoxArea .k-input {
    border-radius: var(--ims-border__radius--0) !important;
    height: 2.25rem; }

.toolBarArea .formulate-input-element--customSelect .k-dropdown-wrap > .k-input {
  line-height: 2rem; }

.toolBarArea .k-dropdown-wrap .k-select {
  line-height: 2rem; }

.toolBarArea .ddMenu .toolBarArea {
  border: none;
  padding: 0.2rem; }

.ddMenu .toolBarArea {
  border: none;
  padding: 0.2rem; }

.k-editor-toolbar-wrap .k-dropdown-wrap > .k-input {
  line-height: 1.4rem;
  height: var(--ims-spacing--80); }

.toolArea .k-input {
  border-radius: var(--ims-border__radius--0) !important; }

.toolArea .k-dropdown-wrap .k-input {
  line-height: 2rem; }

.toolArea .ims-dropdown-menu .k-icon::before {
  top: -0.5rem; }

/*#endregion */
.toolBarArea {
  border: 0.0625rem solid var(--bs-dark-border-subtle);
  padding: var(--ims-spacing--20) var(--ims-spacing--20); }
  .toolBarArea .k-autocomplete {
    border: 0.1rem solid var(--bs-border-color);
    border-right: 0px; }
    .toolBarArea .k-autocomplete .k-input {
      padding: 0.225rem 0 !important;
      line-height: 1.8rem !important; }
      .toolBarArea .k-autocomplete .k-input:hover {
        box-shadow: none; }
  .toolBarArea > div {
    display: inline-flex; }
  .toolBarArea .btn {
    padding: 0.239rem var(--ims-spacing--20) !important;
    background-color: var(--bs-light-bg-subtle);
    border: 0.1rem solid var(--bs-dark-border-subtle) !important;
    box-shadow: none; }
    .toolBarArea .btn .ims-icon, .toolBarArea .btn .ims-icon-arrow-left, .toolBarArea .btn .ims-icon-arrow-right {
      color: var(--bs-primary);
      padding-right: 0rem;
      font-size: var(--ims-font__size--200); }
    .toolBarArea .btn:hover {
      background-color: var(--bs-light-bg-subtle);
      background-color: var(--bs-light-bg-subtle); }

/* start tool area for grids in details-pane and pop ups */
.contentArea .toolBarArea, .k-window .toolBarArea {
  border: 0.0625rem solid var(--bs-dark-border-subtle);
  padding: var(--ims-spacing--20) var(--ims-spacing--20); }
  .contentArea .toolBarArea .k-autocomplete, .k-window .toolBarArea .k-autocomplete {
    border: 0.1rem solid var(--bs-border-color);
    border-right: 0px; }
    .contentArea .toolBarArea .k-autocomplete .k-input, .k-window .toolBarArea .k-autocomplete .k-input {
      padding: 0.225rem 0 !important;
      line-height: 1.8rem !important; }
      .contentArea .toolBarArea .k-autocomplete .k-input:hover, .k-window .toolBarArea .k-autocomplete .k-input:hover {
        box-shadow: none; }
  .contentArea .toolBarArea > div, .k-window .toolBarArea > div {
    display: inline-flex;
    vertical-align: middle;
    padding: 0; }
  .contentArea .toolBarArea .ddMenu, .k-window .toolBarArea .ddMenu {
    border: none;
    padding: 0; }
    .contentArea .toolBarArea .ddMenu ul.k-widget.k-menu > li.k-item.hotfix, .k-window .toolBarArea .ddMenu ul.k-widget.k-menu > li.k-item.hotfix {
      padding: 0;
      width: auto;
      height: auto; }
  .contentArea .toolBarArea .tab-actionbar .input-element.searchBoxArea, .contentArea .toolBarArea .popup-actionbar .input-element.searchBoxArea, .k-window .toolBarArea .tab-actionbar .input-element.searchBoxArea, .k-window .toolBarArea .popup-actionbar .input-element.searchBoxArea {
    padding: 0;
    height: inherit; }
    .contentArea .toolBarArea .tab-actionbar .input-element.searchBoxArea .k-textbox.k-space-right, .contentArea .toolBarArea .popup-actionbar .input-element.searchBoxArea .k-textbox.k-space-right, .k-window .toolBarArea .tab-actionbar .input-element.searchBoxArea .k-textbox.k-space-right, .k-window .toolBarArea .popup-actionbar .input-element.searchBoxArea .k-textbox.k-space-right {
      height: inherit;
      width: 18.75rem;
      padding-right: 2.25rem;
      color: var(--bs-primary-lightest);
      border-color: var(--bs-dark-border-subtle); }
      .contentArea .toolBarArea .tab-actionbar .input-element.searchBoxArea .k-textbox.k-space-right .k-icon, .contentArea .toolBarArea .popup-actionbar .input-element.searchBoxArea .k-textbox.k-space-right .k-icon, .k-window .toolBarArea .tab-actionbar .input-element.searchBoxArea .k-textbox.k-space-right .k-icon, .k-window .toolBarArea .popup-actionbar .input-element.searchBoxArea .k-textbox.k-space-right .k-icon {
        top: 50%;
        right: var(--ims-spacing--20); }
      .contentArea .toolBarArea .tab-actionbar .input-element.searchBoxArea .k-textbox.k-space-right .k-autocomplete, .contentArea .toolBarArea .popup-actionbar .input-element.searchBoxArea .k-textbox.k-space-right .k-autocomplete, .k-window .toolBarArea .tab-actionbar .input-element.searchBoxArea .k-textbox.k-space-right .k-autocomplete, .k-window .toolBarArea .popup-actionbar .input-element.searchBoxArea .k-textbox.k-space-right .k-autocomplete {
        height: 100%;
        border: none; }
      .contentArea .toolBarArea .tab-actionbar .input-element.searchBoxArea .k-textbox.k-space-right .k-input, .contentArea .toolBarArea .popup-actionbar .input-element.searchBoxArea .k-textbox.k-space-right .k-input, .k-window .toolBarArea .tab-actionbar .input-element.searchBoxArea .k-textbox.k-space-right .k-input, .k-window .toolBarArea .popup-actionbar .input-element.searchBoxArea .k-textbox.k-space-right .k-input {
        height: inherit;
        padding: 0;
        border-right: 0.0625rem solid var(--bs-dark-border-subtle); }
  .contentArea .toolBarArea .grid-filter-list, .k-window .toolBarArea .grid-filter-list {
    position: absolute;
    z-index: 2;
    left: 0.9375rem;
    right: 0.9375rem;
    padding: var(--ims-spacing--30) var(--ims-spacing--30);
    padding-top: 0.9375rem;
    margin-top: var(--ims-spacing--20);
    background-color: var(--bs-light-bg-subtle);
    box-shadow: 0 0.5rem 0.5rem -0.25rem rgba(0, 0, 0, 0.2);
    min-height: 11.25rem;
    height: auto; }

.ims-header-row .ims-admin-menu {
  display: flex;
  height: 100%; }
  .ims-header-row .ims-admin-menu:hover {
    background-color: rgba(193, 193, 193, 0.2); }
    .ims-header-row .ims-admin-menu:hover .dropdown-menu {
      display: block; }
  .ims-header-row .ims-admin-menu > button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 1.0625rem 0.8rem 1.0625rem 0.3125rem;
    color: var(--bs-body-color);
    border: none;
    background-color: transparent; }
    .ims-header-row .ims-admin-menu > button:focus {
      outline-width: 0; }

.ims-admin-user-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  height: 100%; }
  .ims-admin-user-item .dropdown-menu {
    top: 100%;
    display: none;
    border: none;
    border-radius: var(--ims-border__radius--0);
    background-color: var(--bs-surface-high);
    margin: 0;
    min-width: 100%;
    box-shadow: 0rem 0.0625rem 0.125rem 0.0625rem rgba(0, 0, 0, 0.08), -0.0625rem 0.25rem 0.375rem rgba(0, 0, 0, 0.08); }
    .ims-admin-user-item .dropdown-menu .dropdown-item a > span {
      color: var(--bs-body-color); }
    .ims-admin-user-item .dropdown-menu .dropdown-item:hover a > span {
      color: var(--bs-on-primary); }
  .ims-admin-user-item button {
    background-color: var(--bs-white) !important; }
    .ims-admin-user-item button:hover {
      background-color: var(--bs-secondary-color) !important; }

.ims-admin-user-info {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .ims-admin-user-info span {
    padding-left: 0.15625rem;
    padding-right: 0.15625rem; }

.ims-admin-icon {
  border-radius: 50%;
  height: 2.125rem;
  width: 2.125rem;
  margin-left: var(--ims-spacing--25);
  border: 1px solid var(--bs-body-color); }
  .ims-admin-icon .ims-icon, .ims-admin-icon .ims-icon-arrow-left, .ims-admin-icon .ims-icon-arrow-right {
    font-size: 1.5625rem; }
    .ims-admin-icon .ims-icon:before, .ims-admin-icon .ims-icon-arrow-left:before, .ims-admin-icon .ims-icon-arrow-right:before {
      font-size: 1.625rem;
      text-align: center;
      margin: 0;
      margin-top: var(--ims-spacing--20); }

/*detailsTab-naviigation-styling*/
.detailsTab-navi-pos {
  position: absolute;
  top: 0;
  left: 0;
  width: 14rem; }
  .detailsTab-navi-pos .ims-alltabs-save {
    list-style-type: none;
    padding: 0; }
    .detailsTab-navi-pos .ims-alltabs-save .k-item {
      padding: 0.4375rem 0.3125rem 0.4375rem 2rem;
      color: var(--bs-on-primary);
      font-size: var(--ims-font__size--80);
      cursor: pointer; }
      .detailsTab-navi-pos .ims-alltabs-save .k-item:hover {
        background-color: var(--bs-primary);
        color: var(--bs-on-primary); }
    .detailsTab-navi-pos .ims-alltabs-save .k-state-active {
      background-color: var(--bs-primary);
      color: var(--bs-on-primary); }

@media only screen and (max-width: 1600px) {
  .ims-navigation-list > .ims-navigation-item > a .ims-icon:before, .ims-navigation-list > .ims-navigation-item > a .ims-icon-arrow-left:before, .ims-navigation-list > .ims-navigation-item > a .ims-icon-arrow-right:before {
    font-size: var(--ims-font__size--300); } }

@media only screen and (max-width: 1365px), (max-height: 767px) {
  .ims-navigation {
    width: 16.875rem;
    background-color: var(--ims-sidebar__navitem-bg);
    box-shadow: 0.0625rem 0.0625rem 0.3125rem rgba(0, 0, 0, 0.3); }
  .ims-navigation-item:hover,
  .ims-navbar-toggler:hover {
    background-color: var(--ims-sidebar__navitem-bg--hover); }
  .ims-navigation-list > .ims-navigation-item {
    padding-left: var(--ims-spacing--100); }
    .ims-navigation-list > .ims-navigation-item > a.nav-element {
      display: flex;
      align-items: center;
      height: auto;
      min-height: 1.375rem;
      white-space: initial; }
      .ims-navigation-list > .ims-navigation-item > a.nav-element > .ims-icon, .ims-navigation-list > .ims-navigation-item > a.nav-element > .ims-icon-arrow-left, .ims-navigation-list > .ims-navigation-item > a.nav-element > .ims-icon-arrow-right {
        margin: 0; }
        .ims-navigation-list > .ims-navigation-item > a.nav-element > .ims-icon:before, .ims-navigation-list > .ims-navigation-item > a.nav-element > .ims-icon-arrow-left:before, .ims-navigation-list > .ims-navigation-item > a.nav-element > .ims-icon-arrow-right:before {
          margin: 0;
          margin-right: var(--ims-spacing--50); }
      .ims-navigation-list > .ims-navigation-item > a.nav-element .ims-nav-item-title {
        text-align: left;
        padding-right: var(--ims-spacing--50);
        font-size: var(--ims-font__size--100); } }

@media only screen and (max-height: 580px), (min-height: 768px) and (min-width: 1366px) {
  .ims-navigation {
    height: 100%;
    z-index: 1; } }

@media all and (-ms-high-contrast: none) and (max-width: 386px), (-ms-high-contrast: active) and (max-width: 386px) {
  .ims-dashboard-filter,
  .ims-admin-user-item {
    min-width: 100%; } }

@media only screen and (max-width: 540px) {
  .ims-admin-user-item {
    justify-content: center; } }

@media only screen and (max-width: 671px) {
  .ims-admin-icon {
    display: none; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ims-nav-item-splitter {
    margin-top: -0.75rem; } }

#pageHeaderDesktop #ims-headerTopBar #ie-stat-02 .dropdown-item .nav-link.dropdown-toggle {
  margin-left: 0.92rem !important; }

#pageHeaderMobile .dropdown-item .nav-link.dropdown-toggle {
  margin-left: 0.7rem !important; }

#pageHeaderMobile button.navbar-toggler .ims-icon, #pageHeaderMobile button.navbar-toggler .ims-icon-arrow-left, #pageHeaderMobile button.navbar-toggler .ims-icon-arrow-right {
  margin-right: var(--ims-spacing--0) !important; }

.ims-stream-nav {
  width: 17rem;
  margin-right: var(--ims-spacing--80); }
  .ims-stream-nav a.ims-stream-avatar-link {
    display: flex; }
  .ims-stream-nav .ims-profile-text {
    margin-left: var(--ims-spacing--30);
    font-weight: var(--ims-font__weight--bold);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 12rem;
    align-self: center; }
  .ims-stream-nav--floating {
    display: none; }
    .ims-stream-nav--floating .ims-floating-btn {
      margin-top: var(--ims-spacing--40); }
  .ims-stream-nav .nav {
    margin-top: var(--ims-spacing--40); }
    .ims-stream-nav .nav .nav-link {
      display: flex; }
      .ims-stream-nav .nav .nav-link .ims-stream-nav-link-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 12rem;
        align-self: center; }
    .ims-stream-nav .nav .nav-item {
      margin: var(--ims-spacing--20) 0; }
      .ims-stream-nav .nav .nav-item:hover {
        background: var(--bs-light-bg-subtle) !important;
        border-radius: var(--ims-border__radius--400); }
      .ims-stream-nav .nav .nav-item.active {
        background: var(--bs-light-bg-subtle) !important;
        border-radius: var(--ims-border__radius--400); }
      .ims-stream-nav .nav .nav-item.primary {
        background: var(--bs-primary) !important;
        border-radius: var(--ims-border__radius--400); }
        .ims-stream-nav .nav .nav-item.primary .nav-link {
          color: var(--bs-on-primary) !important; }
      .ims-stream-nav .nav .nav-item .ims-icon, .ims-stream-nav .nav .nav-item .ims-icon-arrow-left, .ims-stream-nav .nav .nav-item .ims-icon-arrow-right {
        margin-right: var(--ims-spacing--20);
        font-size: var(--ims-font__size--300); }
  .ims-stream-nav--tabnav {
    display: none; }
  .ims-stream-nav .ims-profile-img .ims-custom-select {
    flex-direction: row;
    border-radius: var(--ims-border__radius--200); }
  .ims-stream-nav .ims-profile-img.ims-profile-nav {
    padding-right: var(--ims-spacing--40); }
    .ims-stream-nav .ims-profile-img.ims-profile-nav:hover {
      background: var(--bs-light-bg-subtle) !important;
      border-radius: var(--ims-border__radius--400); }

#ims-header.sticky-header {
  position: relative;
  z-index: 3; }

@media (max-width: 991px) {
  .ims-stream-nav {
    width: 8rem; }
    .ims-stream-nav .ims-profile-text {
      display: none; }
    .ims-stream-nav .nav {
      margin-top: var(--ims-spacing--0); }
      .ims-stream-nav .nav .nav-item {
        width: 2.3rem; }
      .ims-stream-nav .nav .nav-link .ims-stream-nav-link-text {
        transition: all 0.5s;
        display: none; }
      .ims-stream-nav .nav .nav-link .ims-icon, .ims-stream-nav .nav .nav-link .ims-icon-arrow-left, .ims-stream-nav .nav .nav-link .ims-icon-arrow-right {
        margin-right: var(--ims-spacing--0) !important;
        margin-left: -0.24rem !important; }
    .ims-stream-nav .ims-profile-img {
      flex-direction: column; }
      .ims-stream-nav .ims-profile-img .ims-custom-select {
        margin-top: var(--ims-spacing--40);
        width: 4.4rem; }
    .ims-stream-nav .ims-profile-img.ims-profile-nav {
      padding-right: var(--ims-spacing--0); } }

@media (max-width: 575px) {
  .ims-stream-nav {
    transition: all 0.5s;
    display: none; }
    .ims-stream-nav--floating {
      transition: all 0.5s;
      display: block !important;
      position: fixed;
      z-index: 2;
      bottom: 5vh;
      right: 4vw; }
    .ims-stream-nav--tabnav {
      transition: all 0.5s;
      display: block;
      position: absolute;
      width: 96% !important; }
      .ims-stream-nav--tabnav .tabNavigation {
        background: transparent !important; }
    .ims-stream-nav .ims-profile-img.ims-profile-nav {
      margin-right: var(--ims-spacing--0); }
  .ims-feed-header--fixed {
    margin-top: 4.4rem !important; }
  .ims-stream-nav--floating {
    transition: all 0.5s;
    bottom: 10vh;
    right: 10vw; } }

.pageEditor .containerBar a.buttonType {
  color: var(--bs-on-primary) !important; }

.alert-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  background-color: var(--bs-orange);
  color: var(--bs-body-color);
  z-index: 3; }
  .alert-bar button {
    color: inherit;
    border: 0; }
    .alert-bar button:active, .alert-bar button:focus {
      outline: none; }
  .alert-bar button.info-bar {
    background-color: transparent;
    border: 0;
    font-size: var(--ims-font__size--100);
    line-height: 0.875rem;
    font-weight: 500;
    color: inherit;
    height: 100%;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .alert-bar button.info-bar .ims-icon:before, .alert-bar button.info-bar .ims-icon-arrow-left:before, .alert-bar button.info-bar .ims-icon-arrow-right:before {
      border: 0.0625rem solid var(--bs-body-color);
      border-radius: 50%;
      width: 1.5625rem;
      height: 1.5625rem;
      line-height: 1.5625rem; }
  .alert-bar .ims-icon.ims-icon-info, .alert-bar .ims-icon-info.ims-icon-arrow-left, .alert-bar .ims-icon-info.ims-icon-arrow-right {
    border: 0.0625rem solid var(--bs-body-color);
    border-radius: 0.9375rem;
    color: var(--bs-body-color); }
  .alert-bar button.info {
    background-color: transparent;
    height: 1.875rem;
    white-space: nowrap;
    border: 0.0625rem solid var(--bs-on-primary) !important;
    border-radius: 0.3125rem; }
  .alert-bar button.close {
    background-color: transparent;
    box-sizing: content-box;
    border-radius: 5px;
    width: 1.6875rem;
    height: 1.6875rem;
    opacity: 1;
    text-shadow: none;
    font-size: 1rem !important; }
    .alert-bar button.close .ims-icon:before, .alert-bar button.close .ims-icon-arrow-left:before, .alert-bar button.close .ims-icon-arrow-right:before {
      line-height: 1.6875rem;
      width: 1.6875rem;
      margin: 0; }
    .alert-bar button.close:hover {
      color: var(--bs-body-color);
      background-color: transparent; }

.alert .btn-close {
  filter: var(--bs-btn-close-black-filter); }

.toast-container {
  position: fixed; }
  .toast-container .btn-close-white {
    filter: var(--bs-btn-close-black-filter); }
  .toast-container .btn:hover {
    background-color: transparent;
    border-color: transparent; }

.ims-alert-message {
  padding: var(--ims-spacing--30) var(--ims-spacing--0);
  background-color: none; }
  .ims-alert-message--attention span {
    color: var(--bs-warning) !important; }
  .ims-alert-message--success span {
    color: var(--bs-success) !important; }
  .ims-alert-message--info span {
    color: var(--bs-info) !important; }

.alert-danger {
  background-color: transparent;
  border-color: var(--bs-danger);
  color: var(--bs-danger);
  border-radius: 0.3125rem; }

.alert-secondary {
  background-color: transparent;
  border-color: var(--bs-danger);
  color: var(--bs-secondary);
  border-radius: 0.3125rem; }
  .alert-secondary span {
    padding: var(--ims-spacing--35) var(--ims-spacing--35); }

.alert-success {
  background-color: var(--bs-white);
  border-color: var(--bs-success);
  color: var(--bs-success);
  border-radius: 0.3125rem; }

.label-success {
  background-color: var(--bs-success); }

/*refactoring required*/
.detailsContent {
  margin-bottom: var(--ims-spacing--75); }
  .detailsContent .detailsColumn .detailsRow {
    padding-bottom: var(--ims-spacing--60); }
    .detailsContent .detailsColumn .detailsRow label {
      margin-right: 0.1875rem; }
    .detailsContent .detailsColumn .detailsRow label:not(:first-of-type) {
      margin-left: var(--ims-spacing--25); }
  .detailsContent .detailsColumn .ims-checkbox-container label {
    color: var(--bs-body-color);
    margin-right: 0.1875rem;
    font-size: var(--ims-font__size--80); }
  .detailsContent.detailsContentInfo > .detailsColumn > .row {
    align-items: center; }
  .detailsContent .ims-sub-container {
    border-radius: 2px;
    background-color: var(--bs-white);
    padding: var(--ims-spacing--25) var(--ims-spacing--0); }

.detailsRow.ims-checkbox-container label {
  color: inherit;
  width: calc(100% - 1.3125rem);
  float: right;
  line-height: 1.25rem;
  margin-bottom: 0; }

.detailsRow.ims-checkbox-container.ims-sub-container-control {
  margin-top: var(--ims-spacing--100);
  padding-bottom: var(--ims-spacing--0); }

.ims-right-pane-container {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 3rem;
  width: 70%;
  overflow: auto;
  background-color: var(--bs-primary-dark); }
  .ims-right-pane-container.ims-pane-active {
    display: flex; }

#rightPaneContent .paneTitle {
  padding-left: var(--ims-spacing--60);
  padding-right: var(--ims-spacing--15);
  height: 3.125rem; }

#rightPaneContent div.paneTitle > span.titleText {
  font-size: var(--ims-font__size--400);
  vertical-align: middle;
  font-weight: 600;
  margin-top: var(--ims-spacing--25); }

.objectDetailsContainer {
  width: 100%;
  margin-top: 3.125rem;
  padding: var(--ims-spacing--75) var(--ims-spacing--60) 0;
  overflow-y: auto; }
  .objectDetailsContainer div.detailsRow > div.dataColumn > input:not([type="radio"]):not([type="checkbox"]).withIcon,
  .objectDetailsContainer div.detailsRow > div.dataColumn > textarea.withIcon {
    width: inherit; }

.fullSBtn {
  color: var(--bs-dark-border-subtle) !important; }
  .fullSBtn a,
  .fullSBtn a:not([href]):not([tabindex]) {
    color: var(--bs-on-primary); }
    .fullSBtn a:hover,
    .fullSBtn a:not([href]):not([tabindex]):hover {
      color: var(--bs-on-primary); }
  .fullSBtn .k-link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0 2rem;
    font-size: var(--ims-font__size--80);
    line-height: 3.125rem; }
    .fullSBtn .k-link:before {
      background-color: var(--bs-primary);
      color: var(--bs-on-primary);
      line-height: 1.3125rem;
      border-radius: 50%;
      width: 1.3125rem;
      height: 1.3125rem;
      margin-right: 0.4375rem;
      margin-left: 0; }
  .fullSBtn:active {
    background-color: var(--bs-primary); }
  .fullSBtn:hover {
    color: var(--bs-on-primary) !important; }

.toolButtonArea > span:not(.selectedArrow) {
  padding: 0.4375rem; }
  .toolButtonArea > span:not(.selectedArrow).right-nomargin:first-of-type {
    margin-right: 0; }

.toolButtonArea .k-menu > .k-item > .k-link {
  padding: 0.28125rem 0.5625rem 0.28125rem 0.28125rem; }

.rightPane {
  box-shadow: -0.125rem 0 0.5rem rgba(var(--bs-body-color-rgb), 0.2);
  z-index: 1; }
  .rightPane .contentArea {
    height: 100%;
    width: calc(100% - 12.5rem);
    margin-left: 12.5rem;
    background-color: var(--bs-on-primary);
    box-shadow: inherit; }
    .rightPane .contentArea .toolArea {
      margin: inherit;
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      height: 3.125rem;
      box-shadow: -0.1875rem 0.1875rem 0.1875rem -0.1875rem rgba(0, 0, 0, 0.2); }
  .rightPane .titleText {
    width: calc(100% - 31.25rem); }
  .rightPane .toolButtonArea {
    float: right;
    height: inherit;
    padding: var(--ims-spacing--15) var(--ims-spacing--0); }
    .rightPane .toolButtonArea .row {
      height: inherit; }
    .rightPane .toolButtonArea .k-button:not(.shadow-button) {
      margin: 0;
      box-shadow: none;
      padding: 0;
      border-radius: var(--ims-border__radius--0); }
      .rightPane .toolButtonArea .k-button:not(.shadow-button) .btnLabel {
        line-height: 2.75rem; }

.searchBoxArea .k-autocomplete.k-state-hover:hover {
  box-shadow: none; }

.searchBoxArea .k-autocomplete .k-input {
  padding: .25em 0; }

.ims-drag-handle {
  cursor: move; }

#rightPaneTitle > div > span.ims-icon.buttonType, #rightPaneTitle > div > span.buttonType.ims-icon-arrow-left, #rightPaneTitle > div > span.buttonType.ims-icon-arrow-right,
#rightPaneTitle > div > div.k-button,
#rightPaneSaveAction > div > span.ims-icon.buttonType,
#rightPaneSaveAction > div > span.buttonType.ims-icon-arrow-left,
#rightPaneSaveAction > div > span.buttonType.ims-icon-arrow-right,
#rightPaneSaveAction > div > div.k-button {
  vertical-align: middle; }

#rightPaneTitle > span.titleText,
#rightPaneSaveAction > span.titleText {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left; }

#rightPaneTitle .toolButtonArea,
#rightPaneSaveAction .toolButtonArea {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center; }

#toolBarMenu {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px; }

.detailsHeader {
  position: relative; }
  .detailsHeader h3 {
    padding-top: var(--ims-spacing--30);
    margin-bottom: var(--ims-spacing--60); }
  .detailsHeader h4 {
    padding-top: var(--ims-spacing--20);
    margin-top: var(--ims-spacing--60);
    margin-bottom: var(--ims-spacing--40);
    font-size: 0.9375rem;
    font-weight: 600; }
  .detailsHeader h3,
  .detailsHeader h4 {
    display: inline-block;
    text-transform: none;
    border-top: 0.125rem solid var(--bs-primary-dark); }
  .detailsHeader:first-of-type h3 {
    margin-top: 0; }
  .detailsHeader .headerText {
    width: 200px; }
  .detailsHeader hr {
    border-bottom: 1px solid var(--bs-border-color);
    border-top: 0;
    width: 100%;
    margin: 5px 15px 0 15px; }

#rightPaneSaveAction {
  display: none;
  background-color: var(ims-color__gray--100); }
  #rightPaneSaveAction.paneTitle {
    padding-left: var(--ims-spacing--140); }
  #rightPaneSaveAction .toolButtonArea {
    width: 100%; }
    #rightPaneSaveAction .toolButtonArea button {
      flex-basis: 14.375rem; }

.k-grouping-header {
  display: none; }

@supports (-ms-ime-align: auto) {
  /* IE Edge 16+ CSS */
  .rightPane .tooltip-text-label > label {
    display: inline; } }

@media only screen and (max-width: 1366px) {
  #rightPaneSaveAction .toolButtonArea {
    justify-content: space-between; }
    #rightPaneSaveAction .toolButtonArea button {
      margin-right: auto; }
      #rightPaneSaveAction .toolButtonArea button:first-child {
        margin-left: auto;
        margin-right: var(--ims-spacing--15); } }

.ims-grid-container {
  overflow: hidden; }

.ims-grid-container,
.left-pane .grid-placeholder .k-grid {
  height: 100%;
  border: none; }

.left-pane .grid-placeholder {
  height: 100%;
  position: relative;
  border: 0.0625rem solid var(--bs-dark-border-subtle); }

.left-pane .treelist-placeholder .k-grid {
  height: 100%; }

.ims-tooltip-icon.ims-icon:before, .ims-tooltip-icon.ims-icon-arrow-left:before, .ims-tooltip-icon.ims-icon-arrow-right:before {
  border-radius: 50%;
  background-color: var(--bs-primary);
  color: var(--bs-on-primary);
  line-height: 1.125rem;
  width: 1.125rem; }

.ims-tooltip-icon:hover {
  cursor: pointer; }

.active-td .ims-tooltip-icon.ims-icon:before, .active-td .ims-tooltip-icon.ims-icon-arrow-left:before, .active-td .ims-tooltip-icon.ims-icon-arrow-right:before {
  background-color: var(--bs-primary);
  color: var(--bs-on-primary); }

@media only screen and (max-width: 858px) {
  .ims-main-header h1 {
    display: none; } }

@media only screen and (max-width: 626px) {
  .ims-header-row {
    padding-left: var(--ims-spacing--25); } }

@media all and (-ms-high-contrast: none) and (max-width: 720px), (-ms-high-contrast: active) and (max-width: 720px) {
  .ims-main-header {
    right: 17px; } }

@media (min-width: 768px) {
  #ims-headerNav .nav-link:hover > span, #ims-headerNav .nav-link:hover > .ims-icon, #ims-headerNav .nav-link:hover > .ims-icon-arrow-left, #ims-headerNav .nav-link:hover > .ims-icon-arrow-right {
    color: var(--ims-main-nav-link-item--hover) !important; }
  #ims-headerNav #ie-stat-02 .dropdown-item .nav-link:hover > span, #ims-headerNav #ie-stat-02 .dropdown-item .nav-link:hover > .ims-icon, #ims-headerNav #ie-stat-02 .dropdown-item .nav-link:hover > .ims-icon-arrow-left, #ims-headerNav #ie-stat-02 .dropdown-item .nav-link:hover > .ims-icon-arrow-right {
    color: var(--ims-main-nav-link-item-dropdown--hover) !important; } }

#mediaLibraryWindow .k-widget.k-listview {
  padding: var(--ims-spacing--40) var(--ims-spacing--30); }

#mediaLibraryWindow .detailsContent .detailsColumn .detailsRow {
  padding-bottom: 0; }

#mediaLibraryWindow div.k-treeview, #mediaLibraryWindow #mediaLibraryDirectoryItemsListView {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0 0 0.125rem 0.125rem; }

.ims-galleryItem {
  display: flex;
  flex-wrap: nowrap;
  float: left;
  margin: 0.2rem;
  padding: 0.2rem;
  background-color: rgba(var(--bs-border-color-subtle-rgb), 0.5); }
  .ims-galleryItem:hover {
    cursor: pointer;
    background-color: var(--bs-dark-border-subtle); }
  .ims-galleryItem.k-state-selected, .ims-galleryItem.k-state-selected:hover {
    background-color: var(--bs-primary-dark);
    border-top: none; }
    .ims-galleryItem.k-state-selected .fileInfo, .ims-galleryItem.k-state-selected:hover .fileInfo {
      color: var(--bs-on-primary); }
  .ims-galleryItem .imagePreview img {
    height: 4.5rem; }
  .ims-galleryItem img {
    max-width: 5.5rem !important;
    max-height: 5.5rem !important; }
  .ims-galleryItem .fileInfo {
    font-size: var(--ims-font__size--60);
    color: var(--bs-body-color);
    min-width: 7.5rem;
    padding-left: var(--ims-spacing--25); }

/*#region MediaLibrary styles*/
.mediaLibrary {
  margin-top: 20px; }
  .mediaLibrary .detailsLeftColumn {
    width: 33%;
    max-width: 33% !important; }
  .mediaLibrary .detailsRightColumn {
    padding-left: 2%;
    width: 66%;
    max-width: 66%; }
  .mediaLibrary .k-listview {
    padding-top: 20px;
    margin-bottom: 20px; }
  .mediaLibrary .k-treeview {
    padding-top: 20px;
    min-height: 510px;
    margin: 0 10px; }
    .mediaLibrary .k-treeview .k-in {
      width: 90%;
      padding: 5px;
      border-radius: var(--ims-border__radius--0);
      white-space: normal; }
  .mediaLibrary .buttonsRow {
    float: right;
    padding-top: 20px; }
  .mediaLibrary .imageInfo {
    float: left;
    height: 90px;
    margin: 10px;
    padding: 5px;
    width: 190px; }
  .mediaLibrary .imagePreview {
    float: left;
    height: 80px;
    margin: 0 auto;
    overflow: hidden;
    padding: 2px;
    text-overflow: ellipsis;
    vertical-align: middle;
    width: 80px; }
  .mediaLibrary .fileInfo {
    float: right;
    width: 100px;
    height: 80px; }
  .mediaLibrary #listView {
    min-width: 555px;
    min-height: 510px;
    width: 100%; }
  .mediaLibrary .delete-link {
    display: none;
    margin-right: 15px;
    float: right; }
  .mediaLibrary .icon.image.tree-icon.thumbnailFolderIcon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px; }
  .mediaLibrary .k-in:hover .delete-link {
    display: inline-block; }
  .mediaLibrary .k-menu .k-item > .k-link {
    display: inline-block; }

.mediaLibrary .k-listview:after,
.product dl:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

#mediaLibrary .k-animation-container {
  width: 200px !important; }

/*#endregion */
#tooltipContainer {
  padding: 0 0.9375rem;
  width: calc(35% - 3.125rem);
  right: 0;
  bottom: 0;
  top: 3rem;
  border-left: solid 0.5rem var(--bs-on-primary);
  box-shadow: -0.3125rem 0.1875rem 0.3125rem rgba(0, 0, 0, 0.1);
  background: var(--bs-white);
  z-index: 1;
  position: fixed; }

.tooltip-header {
  height: 3.125rem;
  padding: var(--ims-spacing--15) var(--ims-spacing--0); }
  .tooltip-header h3 {
    color: var(--bs-body-color);
    line-height: 2.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: none; }

.tooltip-tabArea {
  border-bottom: 0.9375rem solid var(--bs-primary-lightest); }

.ims-tooltip-details {
  background-color: var(--bs-white);
  overflow: auto;
  position: absolute;
  bottom: var(--ims-spacing--0);
  top: 3.125rem;
  right: 0.9375rem;
  left: 0.9375rem;
  padding: 0.9375rem;
  padding-top: 0; }

.ims-tooltip-content {
  padding-bottom: var(--ims-spacing--80); }

.ims-tooltip-label {
  padding-bottom: var(--ims-spacing--15);
  padding-left: var(--ims-spacing--0);
  padding-top: var(--ims-spacing--60); }
  .ims-tooltip-label h4 {
    font-weight: 600; }

.tooltip-text-label {
  line-height: 1.375rem;
  flex-direction: row-reverse;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  color: var(--bs-body-color);
  font-weight: 600; }
  .tooltip-text-label .tooltip-text {
    font-size: var(--ims-font__size--80);
    align-items: center;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    line-height: 1.125rem;
    flex-direction: row;
    color: var(--bs-body-color); }

@media only screen and (max-width: 1430px) {
  #tooltipContainer {
    width: calc(55% - 3.125rem); } }

@media only screen and (max-width: 1024px) {
  #tooltipContainer {
    width: calc(70% - 3.125rem); } }

@media only screen and (max-width: 768px) {
  #tooltipContainer {
    width: calc(95% - 3.125rem); } }

@media only screen and (max-width: 720px) {
  #tooltipContainer {
    min-width: 26rem;
    width: 100%; }
  .tooltip-trapezoid {
    min-width: 9.375rem; } }

.ims-icon-success,
.ims-icon-failed {
  border-radius: 50%;
  padding: 0.07812rem; }

.ims-icon-attention-1,
.ims-icon-attention-alt {
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  background-color: var(--bs-danger);
  color: var(--bs-on-primary); }

.circle, .circle-border, .label-icon {
  border-radius: 50%; }

.circle-border, .label-icon {
  border: 1px solid; }

.label-icon {
  padding: 1px;
  margin-right: 7px; }

.category-label .ims-icon, .category-label .ims-icon-arrow-left, .category-label .ims-icon-arrow-right {
  margin: var(--ims-spacing--0) var(--ims-spacing--15) var(--ims-spacing--0) var(--ims-spacing--25);
  color: var(--bs-primary); }

.ims-flag-box .flagContainer {
  margin-right: var(--ims-spacing--15);
  display: inline-block; }

.ims-icon-xs {
  font-size: var(--ims-font__size--100); }
  .ims-icon-xs.success {
    color: var(--bs-success); }
  .ims-icon-xs.warning {
    color: var(--bs-warning); }
  .ims-icon-xs.danger {
    color: var(--bs-danger); }
  .ims-icon-xs.in-progress {
    color: var(--bs-primary); }

.ims-icon-sm {
  font-size: 1.2rem; }
  .ims-icon-sm.success {
    color: var(--bs-success); }
  .ims-icon-sm.warning {
    color: var(--bs-warning); }
  .ims-icon-sm.danger {
    color: var(--bs-danger); }
  .ims-icon-sm.in-progress {
    color: var(--bs-primary); }

.ims-icon-md {
  font-size: var(--ims-font__size--500); }
  .ims-icon-md.success {
    color: var(--bs-success); }
  .ims-icon-md.warning {
    color: var(--bs-warning); }
  .ims-icon-md.danger {
    color: var(--bs-danger); }
  .ims-icon-md.in-progress {
    color: var(--bs-primary); }

.ims-icon-xl {
  font-size: var(--ims-font__size--700); }
  .ims-icon-xl.success {
    color: var(--bs-success); }
  .ims-icon-xl.warning {
    color: var(--bs-warning); }
  .ims-icon-xl.danger {
    color: var(--bs-danger); }
  .ims-icon-xl.in-progress {
    color: var(--bs-primary); }

.ims-icon.success, .success.ims-icon-arrow-left, .success.ims-icon-arrow-right {
  color: var(--bs-success); }

.ims-icon.warning, .warning.ims-icon-arrow-left, .warning.ims-icon-arrow-right {
  color: var(--bs-warning); }

.ims-icon.danger, .danger.ims-icon-arrow-left, .danger.ims-icon-arrow-right {
  color: var(--bs-danger); }

.ims-icon.in-progress, .in-progress.ims-icon-arrow-left, .in-progress.ims-icon-arrow-right {
  color: var(--bs-primary); }

.ims-reset-icons-spacing-left[class*=" ims-icon-"]::before {
  margin-left: 0rem; }

a.ims-accordion,
a.ims-accordion-btn {
  position: relative; }

a.ims-accordion .custom-accordion-plus {
  position: relative;
  width: 0.875rem;
  height: 0.875rem;
  margin: 0 0.875rem 0 0.875rem;
  border-radius: 50%;
  padding: var(--ims-spacing--10) var(--ims-spacing--10);
  line-height: 1; }

a.ims-accordion-btn .custom-accordion-plus {
  position: relative;
  width: 0.875rem;
  height: 0.875rem;
  padding: var(--ims-spacing--30) var(--ims-spacing--30);
  display: inline-block; }

a.ims-accordion.collapsed .custom-accordion-plus:after,
a.ims-accordion-btn.collapsed .custom-accordion-plus:after {
  transform: translate(-50%, -50%) rotate(90deg); }

a.ims-accordion .custom-accordion-plus:before,
a.ims-accordion .custom-accordion-plus:after,
a.ims-accordion-btn .custom-accordion-plus:before,
a.ims-accordion-btn .custom-accordion-plus:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.875rem;
  transform: translate(-50%, -50%) rotate(0);
  transition: all 300ms ease; }

a.ims-accordion-btn .custom-accordion-plus:after,
a.ims-accordion .custom-accordion-plus:after {
  transform: translate(-50%, -50%) rotate(0); }

.animate-spin, .ims-icon.ims-icon-spinner, .ims-icon-spinner.ims-icon-arrow-left, .ims-icon-spinner.ims-icon-arrow-right {
  animation: spin 2s infinite linear;
  display: inline-block; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

.ims-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--bs-white-rgb), 0.75);
  display: none; }
  .ims-loading-overlay .ims-icon.ims-icon-spinner, .ims-loading-overlay .ims-icon-spinner.ims-icon-arrow-left, .ims-loading-overlay .ims-icon-spinner.ims-icon-arrow-right {
    position: fixed;
    top: 42%;
    left: 50%; }

.session-header > .ims-icon, .session-header > .ims-icon-arrow-left, .session-header > .ims-icon-arrow-right {
  font-size: var(--ims-font__size--600);
  color: var(--bs-border-color); }

.ims-panel-header > .ims-icon, .ims-panel-header > .ims-icon-arrow-left, .ims-panel-header > .ims-icon-arrow-right {
  font-size: 1.375rem;
  color: var(--bs-border-color); }

.ims-panel-header a > .ims-icon, .ims-panel-header a > .ims-icon-arrow-left, .ims-panel-header a > .ims-icon-arrow-right,
.ims-panel-header .ims-button > .ims-icon,
.ims-panel-header .ims-button > .ims-icon-arrow-left,
.ims-panel-header .ims-button > .ims-icon-arrow-right {
  color: var(--bs-primary); }

.ims-course-types .ims-icon, .ims-course-types .ims-icon-arrow-left, .ims-course-types .ims-icon-arrow-right {
  font-size: 1.625rem;
  color: var(--bs-border-color); }
  .ims-course-types .ims-icon.active, .ims-course-types .active.ims-icon-arrow-left, .ims-course-types .active.ims-icon-arrow-right {
    color: var(--bs-dark-border-subtle); }

a.ims-accordion .custom-accordion-plus::before,
a.ims-accordion .custom-accordion-plus::after {
  border-top: 0.125rem solid var(--bs-secondary); }

a.ims-accordion:not(.collapsed) .custom-accordion-plus::after {
  border-color: var(--bs-primary); }

.ims-base-icon-details {
  color: var(--bs-border-color); }

.ims-icon-angle-right::before, .ims-icon-arrow-right::before,
.ims-icon-arrow-right::before,
button.slick-arrow.slick-next:not(.k-button-bare):not(.navbar-toggle)::before,
.ims-list-footer .ims-icon-next::before {
  color: inherit !important; }

.ims-icon-angle-left::before, .ims-icon-arrow-left::before,
.ims-icon-arrow-left::before,
button.slick-arrow.slick-prev:not(.k-button-bare):not(.navbar-toggle)::before,
.ims-list-footer .ims-icon-prev::before {
  color: inherit !important; }

/* main content container */
.navbar-toggler {
  height: 2.1875rem;
  width: 2.5rem;
  padding: 0;
  border-color: var(--ims-header-nav-bar-toggler__border-color) !important;
  background-color: var(--bs-white); }
  .navbar-toggler .ims-icon, .navbar-toggler .ims-icon-arrow-left, .navbar-toggler .ims-icon-arrow-right {
    color: var(--ims-header-nav-bar-toggler__color) !important; }
  .navbar-toggler:hover .ims-icon, .navbar-toggler:hover .ims-icon-arrow-left, .navbar-toggler:hover .ims-icon-arrow-right {
    color: var(--bs-on-primary); }

.ims-main-nav {
  font-size: var(--ims-font__size--200);
  text-transform: capitalize; }

#ims-headerNav {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  #ims-headerNav .nav {
    display: flex; }
    #ims-headerNav .nav > .dropdown-item {
      background-color: transparent; }
      #ims-headerNav .nav > .dropdown-item:hover {
        background-color: transparent; }
    #ims-headerNav .nav .nav-item .ims-icon, #ims-headerNav .nav .nav-item .ims-icon-arrow-left, #ims-headerNav .nav .nav-item .ims-icon-arrow-right {
      margin-right: var(--ims-spacing--15); }
    #ims-headerNav .nav .nav-item:last-child > .nav-link {
      padding-right: var(--ims-spacing--0); }

.ribbon-container-mainNav .ims-page-logo {
  position: absolute;
  top: var(--ims-spacing--25);
  right: 6.25rem; }
  .ribbon-container-mainNav .ims-page-logo img {
    height: 2.5rem; }

.navbar-light .navbar-nav .nav-link {
  padding: 0.9375rem 0.9375rem 0.3125rem 0.9375rem; }
  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .nav-link .router-link-active {
    color: var(--bs-primary);
    border-bottom: 0.0625rem solid var(--bs-primary); }

.navbar-collapse {
  display: flex; }

.navbar-nav {
  flex-direction: row; }
  .navbar-nav .dropdown-menu {
    position: absolute; }

.collapse .navbar-nav .dropdown-item {
  color: var(--bs-body-color) !important; }

.collapse .navbar-nav .nav-link {
  color: var(--bs-body-color) !important; }

#ims-mainNav .nav-item > .nav-link {
  color: var(--ims-header__menu-item__font-color);
  padding: 0;
  padding: 0.1875rem 0rem; }
  #ims-mainNav .nav-item > .nav-link:hover, #ims-mainNav .nav-item > .nav-link:focus, #ims-mainNav .nav-item > .nav-link:active, #ims-mainNav .nav-item > .nav-link.active, #ims-mainNav .nav-item > .nav-link.router-link-active {
    color: var(--ims-header__menu-item__font-color--active); }

#ims-headerNav .nav {
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap; }
  #ims-headerNav .nav > * {
    margin-left: var(--ims-spacing--25); }

.nav-link,
.widget-search {
  padding: var(--ims-spacing--15) var(--ims-spacing--25); }

.ims-main-nav {
  padding: var(--ims-spacing--45) var(--ims-spacing--0); }

.ims-feed-subtitle a {
  color: var(--bs-body-color) !important; }
  .ims-feed-subtitle a:hover:not([disabled]) {
    color: var(--bs-primary) !important; }

.ims-main-header {
  position: fixed;
  right: 0;
  left: 15.65rem;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--bs-white); }
  .ims-main-header > .flex-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    .ims-main-header > .flex-container > .flex-item {
      flex-grow: 1;
      flex-basis: auto; }

.ims-navigation {
  position: fixed;
  width: 15.65rem;
  background-color: var(--bs-primary-lightest); }
  .ims-navigation .dropdown-item {
    height: auto;
    min-height: 2.375rem; }
    .ims-navigation .dropdown-item .nav-element .ims-nav-item-title {
      width: 195px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .ims-navigation .dropdown-item a.navigation-area :nth-child(2) {
      width: 195px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .ims-navigation .dropdown-item .ims-icon:before, .ims-navigation .dropdown-item .ims-icon-arrow-left:before, .ims-navigation .dropdown-item .ims-icon-arrow-right:before {
      font-size: var(--ims-font__size--200); }

#ims-mainNav .nav-item > .nav-link {
  color: var(--bs-body-color);
  padding: 0;
  margin-right: var(--ims-header__menu-item__margin-r); }

.sticky-navigation.sticked #ims-mainNav .nav-item > .nav-link {
  margin-right: var(--ims-header-sticked__menu-item__margin-r); }

.navbar .ims-icon, .navbar .ims-icon-arrow-left, .navbar .ims-icon-arrow-right {
  font-size: 1rem; }

.navbar .collapse,
.navbar .collapsing {
  margin-left: 1.4rem; }

.collapsedNavigation .navbar .standalone-item span.ims-icon, .collapsedNavigation .navbar .standalone-item span.ims-icon-arrow-left, .collapsedNavigation .navbar .standalone-item span.ims-icon-arrow-right {
  margin-left: var(--ims-spacing--40); }

.collapsedNavigation .pageMain {
  left: 4rem; }

.collapsedNavigation .navbar.ims-navbar-toplevel {
  width: 4rem; }

.ims-right-pane-container-extend {
  width: calc(calc(100vw - var(--ims-spacing--120)) - 15.65rem + 2px); }

.collapsedNavigation .ims-right-pane-container-extend {
  width: calc(calc(100vw - 2rem) - 4rem); }

.collapsedNavigation .ims-main-header {
  left: 4rem; }

.row.main {
  margin-left: 15.65rem;
  margin-top: 4.25rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem; }

.pageMain {
  position: absolute;
  top: 4.25rem;
  bottom: 0;
  left: 15.65rem;
  right: 0;
  padding: 0 0.9375rem; }

.navbar.ims-navbar-toplevel {
  position: fixed;
  padding: 0;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: var(--bs-primary-dark);
  width: 15.65rem;
  height: calc(100% - 5.5rem);
  overflow: auto; }
  .navbar.ims-navbar-toplevel .navbar-toggler {
    background-color: var(--ims-color__primary--100);
    border-radius: var(--ims-border__radius--0);
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: var(--ims-spacing--75);
    padding: 0;
    color: var(--bs-on-primary);
    text-align: left; }
  .navbar.ims-navbar-toplevel ul li {
    padding: 0.5rem; }
    .navbar.ims-navbar-toplevel ul li a {
      color: var(--bs-on-primary);
      display: flex; }
      .navbar.ims-navbar-toplevel ul li a:hover {
        color: var(--bs-on-primary); }
    .navbar.ims-navbar-toplevel ul li [class^="ims-icon-"]::before, .navbar.ims-navbar-toplevel ul li [class*=" ims-icon-"]::before {
      margin-right: 0.5rem !important; }
    .navbar.ims-navbar-toplevel ul li.active {
      background-color: var(--bs-primary); }
    .navbar.ims-navbar-toplevel ul li:hover {
      background-color: var(--bs-primary); }
      .navbar.ims-navbar-toplevel ul li:hover a {
        color: var(--bs-on-primary) !important; }

.nav-item:hover > .dropdown-menu,
.nav-item.mobile-hover > .dropdown-menu {
  display: block; }

.ims-navigation-list .dropdown-menu {
  display: none;
  left: 100%;
  width: 15rem;
  border-radius: var(--ims-border__radius--0);
  border: none;
  padding: 0;
  background-color: var(--bs-primary-dark);
  box-shadow: 0.125rem 0.125rem 0.625rem rgba(51, 51, 51, 0.2);
  z-index: 0;
  padding: 0rem !important;
  top: 0;
  bottom: auto;
  border-radius: var(--ims-border__radius--0); }

.ims-navigation-list .dropdown-menu .dropdown-item,
.ims-admin-menu .dropdown-menu .dropdown-item {
  padding: 0 !important; }

.tabContentWithoutSaveAction {
  height: calc(100% - 3.125rem); }
  .tabContentWithoutSaveAction .dropdown-menu {
    padding: 0rem; }
  .tabContentWithoutSaveAction .navbar-light .navbar-nav .nav-link {
    color: var(--ims-colDark); }
  .tabContentWithoutSaveAction li.nav-item.dropright .dropdown-menu {
    margin-left: 0rem !important; }

#userLoginArea .btn {
  margin-left: var(--ims-spacing--25);
  margin-top: var(--ims-spacing--20);
  background-color: var(--bs-primary);
  color: var(--bs-on-primary);
  border: none; }

@media only screen and (max-width: 1366px), only screen and (max-height: 760px) {
  .ims-right-pane-container-extend {
    width: calc(calc(100vw - var(--ims-spacing--120)) - ($ims-navbar-small)); } }

@media only screen and (max-height: 645px) {
  .collapsedNavigation .navbar [data-testid='nav-dashboard'] span.ims-icon, .collapsedNavigation .navbar [data-testid='nav-dashboard'] span.ims-icon-arrow-left, .collapsedNavigation .navbar [data-testid='nav-dashboard'] span.ims-icon-arrow-right, .collapsedNavigation .navbar [data-testid='nav-help'] span.ims-icon, .collapsedNavigation .navbar [data-testid='nav-help'] span.ims-icon-arrow-left, .collapsedNavigation .navbar [data-testid='nav-help'] span.ims-icon-arrow-right, .collapsedNavigation .navbar [data-testid='nav-settings'] span.ims-icon, .collapsedNavigation .navbar [data-testid='nav-settings'] span.ims-icon-arrow-left, .collapsedNavigation .navbar [data-testid='nav-settings'] span.ims-icon-arrow-right {
    margin-left: var(--ims-spacing--20); } }

/*#region Custom Step Builder Component Styles */
.ims-step-list, ul.ims-step-list, ul.ims-step-list.k-widget {
  padding: 0;
  border: none; }
  .ims-step-list li:not(:last-of-type), ul.ims-step-list li:not(:last-of-type), ul.ims-step-list.k-widget li:not(:last-of-type) {
    border-bottom: 0.0625rem solid var(--bs-dark-border-subtle);
    margin-bottom: 0.9375rem; }

.ims-step {
  border: solid 1px var(--bs-dark-border-subtle);
  margin: 5px 0;
  list-style-type: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.ims-step-header {
  position: relative;
  background-color: var(--bs-light-bg-subtle);
  padding: 10px;
  font-weight: bold; }

.ims-step-header-active {
  background-color: var(--bs-primary-lightest);
  color: var(--bs-on-primary);
  border-color: var(--bs-primary-lightest); }

.ims-step-sortable-list .ims-step-header {
  cursor: move; }

.ims-step-body {
  padding: 10px; }
  .ims-step-body .ims-step-form .k-widget {
    width: 100%; }
  .ims-step-body .ims-step-form select {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    -moz-box-sizing: border-box; }

.k-upload .k-upload-button,
.k-upload .k-upload-button:hover,
.ims-popup-btn,
div:not(.containerBar) > .ddMenu ul.k-widget.k-menu > li.k-item,
#rightPaneTitle .toolButtonArea .k-button,
#rightPaneTitle .toolButtonArea .btn-outline-secondary,
#rightPaneTitle .toolButtonArea .btn-outline-primary,
.toolButtonArea .k-button.shadow-button,
#rightPaneTitle .toolButtonArea #gridMenu > ul > li > .k-link,
#rightPaneTitle .toolButtonArea #gridMenu div div > ul.k-widget > li.k-item > .k-link,
#rightPaneSaveAction .toolButtonArea .k-button,
#rightPaneSaveAction .toolButtonArea #gridMenu > ul > li > .k-link {
  box-sizing: border-box;
  background-color: var(--bs-surface-high);
  color: inherit;
  padding: var(--ims-spacing--20) 0.6875rem;
  margin: var(--ims-spacing--0) var(--ims-spacing--10);
  width: 2.625rem;
  height: 2.25rem;
  text-align: center;
  border: none;
  border-radius: var(--ims-button__border-radius);
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  /* Safari */
  cursor: pointer; }
  .k-upload .k-upload-button .k-icon,
  .k-upload .k-upload-button:hover .k-icon,
  .ims-popup-btn .k-icon,
  div:not(.containerBar) > .ddMenu ul.k-widget.k-menu > li.k-item .k-icon,
  #rightPaneTitle .toolButtonArea .k-button .k-icon,
  #rightPaneTitle .toolButtonArea .btn-outline-secondary .k-icon,
  #rightPaneTitle .toolButtonArea .btn-outline-primary .k-icon,
  .toolButtonArea .k-button.shadow-button .k-icon,
  #rightPaneTitle .toolButtonArea #gridMenu > ul > li > .k-link .k-icon,
  #rightPaneTitle .toolButtonArea #gridMenu div div > ul.k-widget > li.k-item > .k-link .k-icon,
  #rightPaneSaveAction .toolButtonArea .k-button .k-icon,
  #rightPaneSaveAction .toolButtonArea #gridMenu > ul > li > .k-link .k-icon {
    display: none; }
  .k-upload .k-upload-button .ims-icon, .k-upload .k-upload-button .ims-icon-arrow-left, .k-upload .k-upload-button .ims-icon-arrow-right,
  .k-upload .k-upload-button:hover .ims-icon,
  .k-upload .k-upload-button:hover .ims-icon-arrow-left,
  .k-upload .k-upload-button:hover .ims-icon-arrow-right,
  .ims-popup-btn .ims-icon,
  .ims-popup-btn .ims-icon-arrow-left,
  .ims-popup-btn .ims-icon-arrow-right,
  div:not(.containerBar) > .ddMenu ul.k-widget.k-menu > li.k-item .ims-icon,
  div:not(.containerBar) > .ddMenu ul.k-widget.k-menu > li.k-item .ims-icon-arrow-left,
  div:not(.containerBar) > .ddMenu ul.k-widget.k-menu > li.k-item .ims-icon-arrow-right,
  #rightPaneTitle .toolButtonArea .k-button .ims-icon,
  #rightPaneTitle .toolButtonArea .k-button .ims-icon-arrow-left,
  #rightPaneTitle .toolButtonArea .k-button .ims-icon-arrow-right,
  #rightPaneTitle .toolButtonArea .btn-outline-secondary .ims-icon,
  #rightPaneTitle .toolButtonArea .btn-outline-secondary .ims-icon-arrow-left,
  #rightPaneTitle .toolButtonArea .btn-outline-secondary .ims-icon-arrow-right,
  #rightPaneTitle .toolButtonArea .btn-outline-primary .ims-icon,
  #rightPaneTitle .toolButtonArea .btn-outline-primary .ims-icon-arrow-left,
  #rightPaneTitle .toolButtonArea .btn-outline-primary .ims-icon-arrow-right,
  .toolButtonArea .k-button.shadow-button .ims-icon,
  .toolButtonArea .k-button.shadow-button .ims-icon-arrow-left,
  .toolButtonArea .k-button.shadow-button .ims-icon-arrow-right,
  #rightPaneTitle .toolButtonArea #gridMenu > ul > li > .k-link .ims-icon,
  #rightPaneTitle .toolButtonArea #gridMenu > ul > li > .k-link .ims-icon-arrow-left,
  #rightPaneTitle .toolButtonArea #gridMenu > ul > li > .k-link .ims-icon-arrow-right,
  #rightPaneTitle .toolButtonArea #gridMenu div div > ul.k-widget > li.k-item > .k-link .ims-icon,
  #rightPaneTitle .toolButtonArea #gridMenu div div > ul.k-widget > li.k-item > .k-link .ims-icon-arrow-left,
  #rightPaneTitle .toolButtonArea #gridMenu div div > ul.k-widget > li.k-item > .k-link .ims-icon-arrow-right,
  #rightPaneSaveAction .toolButtonArea .k-button .ims-icon,
  #rightPaneSaveAction .toolButtonArea .k-button .ims-icon-arrow-left,
  #rightPaneSaveAction .toolButtonArea .k-button .ims-icon-arrow-right,
  #rightPaneSaveAction .toolButtonArea #gridMenu > ul > li > .k-link .ims-icon,
  #rightPaneSaveAction .toolButtonArea #gridMenu > ul > li > .k-link .ims-icon-arrow-left,
  #rightPaneSaveAction .toolButtonArea #gridMenu > ul > li > .k-link .ims-icon-arrow-right {
    margin: 0; }
  .k-upload .k-upload-button .ims-icon:before, .k-upload .k-upload-button .ims-icon-arrow-left:before, .k-upload .k-upload-button .ims-icon-arrow-right:before,
  .k-upload .k-upload-button:hover .ims-icon:before,
  .k-upload .k-upload-button:hover .ims-icon-arrow-left:before,
  .k-upload .k-upload-button:hover .ims-icon-arrow-right:before,
  .ims-popup-btn .ims-icon:before,
  .ims-popup-btn .ims-icon-arrow-left:before,
  .ims-popup-btn .ims-icon-arrow-right:before,
  div:not(.containerBar) > .ddMenu ul.k-widget.k-menu > li.k-item .ims-icon:before,
  div:not(.containerBar) > .ddMenu ul.k-widget.k-menu > li.k-item .ims-icon-arrow-left:before,
  div:not(.containerBar) > .ddMenu ul.k-widget.k-menu > li.k-item .ims-icon-arrow-right:before,
  #rightPaneTitle .toolButtonArea .k-button .ims-icon:before,
  #rightPaneTitle .toolButtonArea .k-button .ims-icon-arrow-left:before,
  #rightPaneTitle .toolButtonArea .k-button .ims-icon-arrow-right:before,
  #rightPaneTitle .toolButtonArea .btn-outline-secondary .ims-icon:before,
  #rightPaneTitle .toolButtonArea .btn-outline-secondary .ims-icon-arrow-left:before,
  #rightPaneTitle .toolButtonArea .btn-outline-secondary .ims-icon-arrow-right:before,
  #rightPaneTitle .toolButtonArea .btn-outline-primary .ims-icon:before,
  #rightPaneTitle .toolButtonArea .btn-outline-primary .ims-icon-arrow-left:before,
  #rightPaneTitle .toolButtonArea .btn-outline-primary .ims-icon-arrow-right:before,
  .toolButtonArea .k-button.shadow-button .ims-icon:before,
  .toolButtonArea .k-button.shadow-button .ims-icon-arrow-left:before,
  .toolButtonArea .k-button.shadow-button .ims-icon-arrow-right:before,
  #rightPaneTitle .toolButtonArea #gridMenu > ul > li > .k-link .ims-icon:before,
  #rightPaneTitle .toolButtonArea #gridMenu > ul > li > .k-link .ims-icon-arrow-left:before,
  #rightPaneTitle .toolButtonArea #gridMenu > ul > li > .k-link .ims-icon-arrow-right:before,
  #rightPaneTitle .toolButtonArea #gridMenu div div > ul.k-widget > li.k-item > .k-link .ims-icon:before,
  #rightPaneTitle .toolButtonArea #gridMenu div div > ul.k-widget > li.k-item > .k-link .ims-icon-arrow-left:before,
  #rightPaneTitle .toolButtonArea #gridMenu div div > ul.k-widget > li.k-item > .k-link .ims-icon-arrow-right:before,
  #rightPaneSaveAction .toolButtonArea .k-button .ims-icon:before,
  #rightPaneSaveAction .toolButtonArea .k-button .ims-icon-arrow-left:before,
  #rightPaneSaveAction .toolButtonArea .k-button .ims-icon-arrow-right:before,
  #rightPaneSaveAction .toolButtonArea #gridMenu > ul > li > .k-link .ims-icon:before,
  #rightPaneSaveAction .toolButtonArea #gridMenu > ul > li > .k-link .ims-icon-arrow-left:before,
  #rightPaneSaveAction .toolButtonArea #gridMenu > ul > li > .k-link .ims-icon-arrow-right:before {
    line-height: 1.25rem; }
  .k-upload .k-upload-button:hover,
  .k-upload .k-upload-button:hover:hover,
  .ims-popup-btn:hover,
  div:not(.containerBar) > .ddMenu ul.k-widget.k-menu > li.k-item:hover,
  #rightPaneTitle .toolButtonArea .k-button:hover,
  #rightPaneTitle .toolButtonArea .btn-outline-secondary:hover,
  #rightPaneTitle .toolButtonArea .btn-outline-primary:hover,
  .toolButtonArea .k-button.shadow-button:hover,
  #rightPaneTitle .toolButtonArea #gridMenu > ul > li > .k-link:hover,
  #rightPaneTitle .toolButtonArea #gridMenu div div > ul.k-widget > li.k-item > .k-link:hover,
  #rightPaneSaveAction .toolButtonArea .k-button:hover,
  #rightPaneSaveAction .toolButtonArea #gridMenu > ul > li > .k-link:hover {
    background-color: var(--bs-surface-highest); }
  .k-upload .k-upload-button:active,
  .k-upload .k-upload-button:hover:active,
  .ims-popup-btn:active,
  div:not(.containerBar) > .ddMenu ul.k-widget.k-menu > li.k-item:active,
  #rightPaneTitle .toolButtonArea .k-button:active,
  #rightPaneTitle .toolButtonArea .btn-outline-secondary:active,
  #rightPaneTitle .toolButtonArea .btn-outline-primary:active,
  .toolButtonArea .k-button.shadow-button:active,
  #rightPaneTitle .toolButtonArea #gridMenu > ul > li > .k-link:active,
  #rightPaneTitle .toolButtonArea #gridMenu div div > ul.k-widget > li.k-item > .k-link:active,
  #rightPaneSaveAction .toolButtonArea .k-button:active,
  #rightPaneSaveAction .toolButtonArea #gridMenu > ul > li > .k-link:active {
    background-color: var(--bs-surface-highest); }

.ddMenu ul.k-widget.k-menu > li.k-item {
  margin: 0 0.3125rem; }

.ddMenu > .k-menu > .k-item > .k-link {
  padding: 0; }

#rightPaneTitle .toolButtonArea #gridMenu > ul > li,
#rightPaneSaveAction .toolButtonArea #gridMenu > ul > li {
  margin-right: 0; }

.bottom-toolArea {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  padding-right: var(--ims-spacing--50); }
  .bottom-toolArea .k-button {
    height: 2.375rem;
    width: 10rem;
    padding: 0;
    margin: 0.3125rem 0;
    box-shadow: none;
    border-radius: var(--ims-border__radius--0);
    background-color: var(--bs-border-color);
    color: var(--bs-on-primary); }
    .bottom-toolArea .k-button .btnLabel {
      line-height: 2.375rem; }
    .bottom-toolArea .k-button:last-child {
      background-color: var(--bs-primary-lightest);
      color: var(--bs-on-primary); }

a.delete-button {
  font-size: var(--ims-font__size--400); }

.searchFilterArea hr {
  display: inline-block;
  height: 1px;
  margin: 20px 0;
  width: 100%; }

.searchForm {
  margin: var(--ims-spacing--25) var(--ims-spacing--25);
  margin-bottom: var(--ims-spacing--75); }
  .searchForm label {
    color: var(--bs-body-color); }
  .searchForm .detailsColumn {
    padding-left: 3.125rem;
    padding-right: 3.125rem; }
  .searchForm .dataColumn input:not([type="radio"]):not([type="checkbox"]),
  .searchForm .dataColumn textarea {
    background-color: transparent; }

.left-pane-actionbar .toolArea .k-dropdown-wrap {
  background-image: none;
  background-color: var(--bs-primary-dark);
  height: 2.5rem;
  box-shadow: none;
  margin-right: var(--ims-spacing--25); }
  .left-pane-actionbar .toolArea .k-dropdown-wrap .k-select .k-icon {
    background-image: none;
    color: var(--bs-on-primary);
    padding: 0.3125rem; }
  .left-pane-actionbar .toolArea .k-dropdown-wrap .k-input {
    color: var(--bs-on-primary);
    padding: 0.3125rem; }
  .left-pane-actionbar .toolArea .k-dropdown-wrap span.k-input:hover {
    background-color: transparent !important; }

.left-pane-actionbar .toolArea .ims-dropdown-menu .ddMenu .toolBarArea {
  padding: 0; }

.left-pane-actionbar .toolArea .ims-dropdown-menu .inline-container {
  align-items: inherit; }

.ims-icon.highlighted, .highlighted.ims-icon-arrow-left, .highlighted.ims-icon-arrow-right {
  color: var(--bs-warning); }

.ims-listBox {
  padding: var(--ims-spacing--25) var(--ims-spacing--25);
  margin: var(--ims-spacing--25) var(--ims-spacing--25);
  display: inline-block;
  width: 42%;
  vertical-align: top;
  border-radius: inherit;
  border: 0.0625rem solid var(--bs-dark-border-subtle); }
  .ims-listBox:hover {
    cursor: pointer; }
  .ims-listBox.k-state-selected {
    box-shadow: none;
    color: inherit;
    background-color: var(--bs-surface-high); }
  .ims-listBox .ims-listRow {
    padding: 2px 0; }
    .ims-listBox .ims-listRow .profile-image {
      width: 70px;
      height: 70px; }
  .ims-listBox .ims-listRowLabel {
    display: inline-block;
    min-width: 125px; }
  .ims-listBox .ims-listRowVal {
    font-weight: bold;
    display: inline-block; }
  .ims-listBox .ims-map {
    width: 30px;
    height: 26px;
    float: right; }

.ims-flex-helper-child-full-width > div {
  width: 100%; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: var(--bs-light-bg-subtle);
  border-color: var(--bs-dark-border-subtle); }

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: var(--bs-light-bg-subtle) !important;
  border-color: var(--bs-dark-border-subtle) !important; }

.toolButtonArea .k-widget, .toolArea .k-widget, .ddMenu .k-widget {
  border-width: 0px !important; }

.toolArea .searchBoxArea a.k-icon {
  color: var(--bs-on-primary); }
  .toolArea .searchBoxArea a.k-icon:hover {
    color: rgba(var(--bs-white-rgb), 0.75); }

.toolArea .searchBoxArea span.k-clear-value {
  color: rgba(var(--bs-body-color-rgb), 0.8); }

.toolArea .searchBoxArea input.k-input {
  border: 1px solid var(--bs-primary) !important; }

.ims-icon-success,
.ims-icon-failed {
  border-radius: 50%;
  padding: 0.07812rem; }

.ims-icon-attention-1,
.ims-icon-attention-alt {
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  background-color: var(--bs-danger);
  color: var(--bs-on-primary); }

.circle, .circle-border, .label-icon {
  border-radius: 50%; }

.circle-border, .label-icon {
  border: 1px solid; }

.label-icon {
  padding: 1px;
  margin-right: 7px; }

.category-label .ims-icon, .category-label .ims-icon-arrow-left, .category-label .ims-icon-arrow-right {
  margin: var(--ims-spacing--0) var(--ims-spacing--15) var(--ims-spacing--0) var(--ims-spacing--25);
  color: var(--bs-primary); }

.ims-flag-box .flagContainer {
  margin-right: var(--ims-spacing--15);
  display: inline-block; }

.ims-icon-xs {
  font-size: var(--ims-font__size--100); }
  .ims-icon-xs.success {
    color: var(--bs-success); }
  .ims-icon-xs.warning {
    color: var(--bs-warning); }
  .ims-icon-xs.danger {
    color: var(--bs-danger); }
  .ims-icon-xs.in-progress {
    color: var(--bs-primary); }

.ims-icon-sm {
  font-size: 1.2rem; }
  .ims-icon-sm.success {
    color: var(--bs-success); }
  .ims-icon-sm.warning {
    color: var(--bs-warning); }
  .ims-icon-sm.danger {
    color: var(--bs-danger); }
  .ims-icon-sm.in-progress {
    color: var(--bs-primary); }

.ims-icon-md {
  font-size: var(--ims-font__size--500); }
  .ims-icon-md.success {
    color: var(--bs-success); }
  .ims-icon-md.warning {
    color: var(--bs-warning); }
  .ims-icon-md.danger {
    color: var(--bs-danger); }
  .ims-icon-md.in-progress {
    color: var(--bs-primary); }

.ims-icon-xl {
  font-size: var(--ims-font__size--700); }
  .ims-icon-xl.success {
    color: var(--bs-success); }
  .ims-icon-xl.warning {
    color: var(--bs-warning); }
  .ims-icon-xl.danger {
    color: var(--bs-danger); }
  .ims-icon-xl.in-progress {
    color: var(--bs-primary); }

.ims-icon.success, .success.ims-icon-arrow-left, .success.ims-icon-arrow-right {
  color: var(--bs-success); }

.ims-icon.warning, .warning.ims-icon-arrow-left, .warning.ims-icon-arrow-right {
  color: var(--bs-warning); }

.ims-icon.danger, .danger.ims-icon-arrow-left, .danger.ims-icon-arrow-right {
  color: var(--bs-danger); }

.ims-icon.in-progress, .in-progress.ims-icon-arrow-left, .in-progress.ims-icon-arrow-right {
  color: var(--bs-primary); }

.ims-reset-icons-spacing-left[class*=" ims-icon-"]::before {
  margin-left: 0rem; }

a.ims-accordion,
a.ims-accordion-btn {
  position: relative; }

a.ims-accordion .custom-accordion-plus {
  position: relative;
  width: 0.875rem;
  height: 0.875rem;
  margin: 0 0.875rem 0 0.875rem;
  border-radius: 50%;
  padding: var(--ims-spacing--10) var(--ims-spacing--10);
  line-height: 1; }

a.ims-accordion-btn .custom-accordion-plus {
  position: relative;
  width: 0.875rem;
  height: 0.875rem;
  padding: var(--ims-spacing--30) var(--ims-spacing--30);
  display: inline-block; }

a.ims-accordion.collapsed .custom-accordion-plus:after,
a.ims-accordion-btn.collapsed .custom-accordion-plus:after {
  transform: translate(-50%, -50%) rotate(90deg); }

a.ims-accordion .custom-accordion-plus:before,
a.ims-accordion .custom-accordion-plus:after,
a.ims-accordion-btn .custom-accordion-plus:before,
a.ims-accordion-btn .custom-accordion-plus:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.875rem;
  transform: translate(-50%, -50%) rotate(0);
  transition: all 300ms ease; }

a.ims-accordion-btn .custom-accordion-plus:after,
a.ims-accordion .custom-accordion-plus:after {
  transform: translate(-50%, -50%) rotate(0); }

.animate-spin, .ims-icon.ims-icon-spinner, .ims-icon-spinner.ims-icon-arrow-left, .ims-icon-spinner.ims-icon-arrow-right {
  animation: spin 2s infinite linear;
  display: inline-block; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

.ims-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--bs-white-rgb), 0.75);
  display: none; }
  .ims-loading-overlay .ims-icon.ims-icon-spinner, .ims-loading-overlay .ims-icon-spinner.ims-icon-arrow-left, .ims-loading-overlay .ims-icon-spinner.ims-icon-arrow-right {
    position: fixed;
    top: 42%;
    left: 50%; }

.session-header > .ims-icon, .session-header > .ims-icon-arrow-left, .session-header > .ims-icon-arrow-right {
  font-size: var(--ims-font__size--600);
  color: var(--bs-border-color); }

.ims-panel-header > .ims-icon, .ims-panel-header > .ims-icon-arrow-left, .ims-panel-header > .ims-icon-arrow-right {
  font-size: 1.375rem;
  color: var(--bs-border-color); }

.ims-panel-header a > .ims-icon, .ims-panel-header a > .ims-icon-arrow-left, .ims-panel-header a > .ims-icon-arrow-right,
.ims-panel-header .ims-button > .ims-icon,
.ims-panel-header .ims-button > .ims-icon-arrow-left,
.ims-panel-header .ims-button > .ims-icon-arrow-right {
  color: var(--bs-primary); }

.ims-course-types .ims-icon, .ims-course-types .ims-icon-arrow-left, .ims-course-types .ims-icon-arrow-right {
  font-size: 1.625rem;
  color: var(--bs-border-color); }
  .ims-course-types .ims-icon.active, .ims-course-types .active.ims-icon-arrow-left, .ims-course-types .active.ims-icon-arrow-right {
    color: var(--bs-dark-border-subtle); }

a.ims-accordion .custom-accordion-plus::before,
a.ims-accordion .custom-accordion-plus::after {
  border-top: 0.125rem solid var(--bs-secondary); }

a.ims-accordion:not(.collapsed) .custom-accordion-plus::after {
  border-color: var(--bs-primary); }

.ims-base-icon-details {
  color: var(--bs-border-color); }

.ims-icon-angle-right::before, .ims-icon-arrow-right::before,
.ims-icon-arrow-right::before,
button.slick-arrow.slick-next:not(.k-button-bare):not(.navbar-toggle)::before,
.ims-list-footer .ims-icon-next::before {
  color: inherit !important; }

.ims-icon-angle-left::before, .ims-icon-arrow-left::before,
.ims-icon-arrow-left::before,
button.slick-arrow.slick-prev:not(.k-button-bare):not(.navbar-toggle)::before,
.ims-list-footer .ims-icon-prev::before {
  color: inherit !important; }

.btn-mobile {
  display: none; }

.catalogue-filterbar .filter-collapse-btn {
  padding: var(--ims-spacing--30) 3.125rem;
  margin-left: var(--ims-spacing--35); }

.ims-filter-header .ims-collapse-icon {
  position: absolute;
  right: 0.9375rem; }

.ims-filter-header:hover {
  cursor: pointer; }

.ims-filter-body .ims-filter-action-bar {
  margin-top: -0.875rem;
  border-bottom: 0.0625rem solid var(--bs-dark-border-subtle); }
  .ims-filter-body .ims-filter-action-bar > *:not(:last-child) {
    border-right: 0.0625rem solid var(--bs-dark-border-subtle); }
  .ims-filter-body .ims-filter-action-bar a {
    display: block;
    text-align: center;
    padding: var(--ims-spacing--25) var(--ims-spacing--15);
    font-size: var(--ims-font__size--300); }

@media (min-width: 576px) {
  .ims-filter-body .ims-filter-action-bar {
    display: none; } }

@media (max-width: 575px) {
  .ims-filter-header .ims-filter-action-bar {
    display: none; }
  .btn-mobile {
    display: block; }
  .ims-offcanvas-left.active {
    left: 0; }
  .ims-offcanvas-left .sidebar-offcanvas {
    left: -100%;
    transition: top, 0.5s, ease-out;
    display: none; }
  .ims-offcanvas-left.active .sidebar-offcanvas {
    left: 0;
    padding: 0;
    transition: top, 0.5s, ease-out;
    display: block; }
  .ims-mainColumn,
  div[id^='widgetDataList'] {
    left: 0;
    transition: top, 0.5s, ease-out;
    display: block; }
  .ims-offcanvas-left.active .ims-mainColumn,
  .ims-offcanvas-left.active div[id^='widgetDataList'] {
    left: 100%;
    transition: top, 0.5s, ease-out;
    display: none; }
  .pull-left {
    position: relative;
    margin-top: 0.78125rem; }
    .pull-left .btn-mobile {
      font-size: var(--ims-font__size--100); }
  .ims-offcanvas {
    position: relative;
    display: block; }
  .ims-widget-formFilter {
    margin-right: var(--ims-spacing--0); } }

.ims-generic-list .generic-list-item {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem; }

.ims-generic-list .generic-list-item + .generic-list-item {
  border-top: 1px solid var(--bs-dark-border-subtle); }

.ims-bulletpoint-list-entry {
  display: flex;
  flex-wrap: nowrap; }
  .ims-bulletpoint-list-entry:not(:first-of-type) {
    margin-top: var(--ims-spacing--25);
    padding-top: var(--ims-spacing--25); }
  .ims-bulletpoint-list-entry .ims-large-bulletin-point {
    width: 3.75rem;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: flex-start; }
    .ims-bulletpoint-list-entry .ims-large-bulletin-point > * {
      margin: 0; }
    .ims-bulletpoint-list-entry .ims-large-bulletin-point .ims-profile-photo-frame {
      width: 2.5rem;
      height: 2.5rem;
      margin: 0; }
  .ims-bulletpoint-list-entry .ims-bulletpoint-list-content {
    width: calc(100% - 3.75rem); }

.ims-tile .ims-large-bulletin-point {
  padding-right: var(--ims-spacing--25); }

.ims-bulletpoint-list-entry:not(:first-of-type) {
  border-top: 0.0625rem solid var(--bs-dark-border-subtle); }

ul li.ims-list-desc {
  list-style-type: disc;
  margin-left: 0.875rem; }

.catalogue-searchbar {
  margin-bottom: var(--ims-spacing--60); }
  .catalogue-searchbar .custom-select-label {
    margin-right: var(--ims-spacing--25); }
  .catalogue-searchbar .ims-custom-select {
    height: 2.25rem;
    width: 13.75rem;
    max-width: 100%;
    border: 0.0625rem solid var(--bs-border-color);
    padding: var(--ims-spacing--20) var(--ims-spacing--20); }
  .catalogue-searchbar .k-widget.k-multiselect .k-multiselect-wrap.k-floatwrap .k-button {
    height: auto !important; }

.catalogue-item-thumbnail {
  width: 20rem;
  height: calc(20rem / 16 * 9);
  flex-shrink: 0; }

.thumbnail-image.auto-dimensions {
  flex-shrink: 0;
  width: 20rem; }
  .thumbnail-image.auto-dimensions img {
    width: inherit;
    height: auto; }

.thumbnail-image.auto-dimensions-medium {
  flex-shrink: 0;
  width: 100%; }
  .thumbnail-image.auto-dimensions-medium img {
    width: inherit;
    height: auto; }

.catalogue-item-details, .catalogue-item-thumbnail-small, .thumbnail-image.auto-dimensions {
  margin: var(--ims-spacing--25) var(--ims-spacing--25); }

.imageBrandingStyleObject {
  padding-left: var(--ims-spacing--25) !important;
  margin-left: var(--ims-spacing--0) !important;
  margin-right: var(--ims-spacing--50) !important; }

.imageBrandingStyleObjectMinHeight {
  padding-left: var(--ims-spacing--25) !important;
  margin-left: var(--ims-spacing--0) !important;
  margin-right: var(--ims-spacing--50) !important;
  height: -moz-min-content !important;
  height: min-content !important; }

.catalogue-item-details {
  width: 100%;
  margin-right: var(--ims-spacing--50); }
  .catalogue-item-details:first-child {
    margin-left: var(--ims-spacing--50); }

.additional-course-info {
  font-weight: 500; }
  .additional-course-info-ims-variation {
    font-weight: bold; }
    .additional-course-info-ims-variation > span {
      padding-right: var(--ims-spacing--20);
      margin-right: var(--ims-spacing--20);
      white-space: nowrap; }
    .additional-course-info-ims-variation .ims-icon, .additional-course-info-ims-variation .ims-icon-arrow-left, .additional-course-info-ims-variation .ims-icon-arrow-right {
      color: var(--bs-border-color); }
      .additional-course-info-ims-variation .ims-icon-with-border-radius {
        border: 0.0625rem solid var(--bs-body-color);
        border-radius: 15px;
        color: var(--bs-body-color);
        padding: 0.125rem; }
  .additional-course-info > span {
    padding-right: var(--ims-spacing--20);
    margin-right: var(--ims-spacing--20);
    white-space: nowrap; }
    .additional-course-info > span:not(:last-of-type):not(.ims-hint-label) {
      border-right: 0.0625rem solid var(--bs-dark-border-subtle); }
    .additional-course-info > span::before {
      margin-right: var(--ims-spacing--15); }
    .additional-course-info > span span::before {
      margin-right: var(--ims-spacing--15); }
  .additional-course-info .ims-icon, .additional-course-info .ims-icon-arrow-left, .additional-course-info .ims-icon-arrow-right {
    color: var(--bs-border-color); }
  .additional-course-info .ims-icon-location::before {
    margin-right: var(--ims-spacing--15); }

.catalogue-item-details-row {
  margin-bottom: var(--ims-spacing--35); }

.ims-booking-select {
  cursor: pointer;
  color: var(--bs-primary); }
  .ims-booking-select:hover .booking-dropdown {
    color: var(--bs-on-primary);
    display: block;
    position: absolute;
    z-index: 1; }
    .ims-booking-select:hover .booking-dropdown .booking-type:hover {
      background-color: var(--bs-primary); }
      .ims-booking-select:hover .booking-dropdown .booking-type:hover a {
        color: var(--bs-on-primary); }
  .ims-booking-select .booking-dropdown {
    display: none;
    min-width: 12.5rem;
    padding: 0;
    right: 0; }
    .ims-booking-select .booking-dropdown .booking-type a {
      display: block;
      padding: var(--ims-spacing--20) 0.9375rem; }

.course-type {
  position: absolute;
  right: 0.9375rem; }
  .course-type .ims-icon, .course-type .ims-icon-arrow-left, .course-type .ims-icon-arrow-right {
    font-size: var(--ims-font__size--600);
    color: var(--bs-border-color); }

.course-info > span {
  margin-right: 0.9375rem; }

.course-info .ims-icon, .course-info .ims-icon-arrow-left, .course-info .ims-icon-arrow-right {
  color: var(--bs-border-color); }

.ims-collapse-block {
  background-color: var(--bs-light-bg-subtle);
  padding: var(--ims-spacing--50) var(--ims-spacing--25); }
  .ims-collapse-block:not(:last-of-type) {
    border-bottom: 0.0625rem solid var(--bs-dark-border-subtle); }

.inline-container.resource-metadata .ims-metadata {
  margin-right: 4rem;
  margin-bottom: 0; }

.resource-metadata .progress {
  min-width: 6.25rem;
  margin-left: 0;
  max-width: 100%; }

@media only screen and (max-width: 1366px) {
  .catalogue-item-thumbnail, .thumbnail-image.auto-dimensions {
    width: 16.25rem;
    height: -moz-max-content;
    height: max-content; }
  .catalogue-item-thumbnail {
    height: calc(16.25rem / 16 * 9); } }

@media (max-width: 767px) {
  .catalogue-item-thumbnail, .thumbnail-image.auto-dimensions {
    width: 11.25rem; }
  .catalogue-item-thumbnail {
    width: 11.25rem;
    height: calc(11.25rem / 16 * 9); } }

@media (max-width: 575px) {
  .catalogue-searchbar {
    flex-direction: column; }
  .catalogue-item-thumbnail, .ims-thumbnail-frame-md,
  .catalogue-item-thumbnail.ims-thumbnail-frame-md {
    width: calc(100% - 1.25rem);
    height: auto;
    padding-top: calc(100% / 16 * 9 - 1.25rem);
    position: relative;
    overflow: hidden; }
    .catalogue-item-thumbnail .thumbnail-image, .ims-thumbnail-frame-md .thumbnail-image,
    .catalogue-item-thumbnail.ims-thumbnail-frame-md .thumbnail-image {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      width: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; }
  .catalogue-item-thumbnail-sm-reset {
    padding-top: 0 !important; }
  .catalogue-item-details {
    width: auto;
    margin-right: var(--ims-spacing--25);
    margin-left: var(--ims-spacing--25); }
  .thumbnail-image.auto-dimensions {
    width: calc(100% - 1.25rem); }
    .thumbnail-image.auto-dimensions.imageBrandingStyleObjectMinHeight {
      width: calc(100% - 0.625rem);
      margin-right: var(--ims-spacing--25); }
    .thumbnail-image.auto-dimensions img {
      width: 100%; }
  .catalogue-item-thumbnail.auto-dimensions {
    width: calc(100% - 1.25rem); }
    .catalogue-item-thumbnail.auto-dimensions.imageBrandingStyleObject {
      width: calc(100% - 0.625rem);
      margin-right: var(--ims-spacing--25); }
    .catalogue-item-thumbnail.auto-dimensions img {
      width: 100%; }
    .catalogue-item-thumbnail.auto-dimensions .thumbnail-image {
      left: var(--ims-spacing--25); } }

.booking-dropdown {
  background-color: var(--bs-white); }

.ims-pager ul {
  padding: var(--ims-spacing--25) var(--ims-spacing--0) var(--ims-spacing--0) var(--ims-spacing--0);
  float: right; }
  .ims-pager ul li {
    list-style-type: none;
    display: inline-block;
    margin: 0;
    padding: 0; }
    .ims-pager ul li button {
      border-radius: var(--ims-button__border-radius);
      margin-right: var(--ims-spacing--10); }
    .ims-pager ul li.selected button {
      color: var(--ims-btn-secondary__font-color--hovered);
      background-color: var(--ims-btn-secondary__background--hovered);
      border-color: var(--ims-btn-secondary__border-color--hovered); }

.ims-pager li.selected a {
  text-decoration: none;
  color: var(--bs-primary);
  background-color: var(--bs-white);
  border: 0.0625rem solid var(--bs-primary); }
  .ims-pager li.selected a:hover {
    color: var(--bs-on-primary) !important;
    background-color: var(--bs-primary); }

.ims-pager li .ims-icon-arrow-right,
.ims-pager li .ims-icon-arrow-left {
  padding-left: 0;
  padding-right: 0; }

.ims-pager li a {
  border-color: transparent;
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  padding-top: var(--ims-spacing--10);
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  text-align: center;
  border-radius: 50%;
  color: var(--bs-primary);
  background-color: var(--bs-white);
  border: 0.0625rem solid var(--bs-primary); }
  .ims-pager li a:hover {
    color: var(--bs-on-primary) !important;
    background-color: var(--bs-primary); }

li.inactive a {
  text-decoration: none; }

.ims-b-pagination .page-link {
  border-radius: var(--ims-buttonBorderRadius) !important;
  text-align: center;
  font-family: inherit;
  font-size: inherit;
  color: var(--bs-primary);
  border-color: var(--bs-border-color);
  width: auto; }

.pagination .page-item {
  margin: 0.125rem; }
  .pagination .page-item .page-link {
    color: var(--ims-btn-secondary__font-color) !important;
    background-color: var(--ims-btn-secondary__background) !important;
    border-color: var(--ims-btn-secondary__border-color) !important;
    padding: var(--ims-spacing--10) var(--ims-spacing--25); }
    .pagination .page-item .page-link.active {
      background-color: var(--ims-btn-secondary__background--active) !important;
      border-color: var(--ims-btn-secondary__border-color) !important;
      z-index: 0; }

.pagination .active > .page-link {
  background-color: var(--ims-btn-secondary__background--active) !important;
  border-color: var(--ims-btn-secondary__border-color) !important;
  width: auto;
  border-color: var(--bs-primary);
  z-index: 0;
  color: var(--bs-on-primary); }

.catalogue-pagination {
  margin-top: var(--ims-spacing--25); }
  .catalogue-pagination button {
    padding: var(--ims-spacing--10) var(--ims-spacing--25);
    width: auto;
    box-sizing: content-box;
    margin: 0 0.0625rem;
    display: inline-block;
    text-align: center;
    vertical-align: middle; }
    .catalogue-pagination button.ims-icon:before, .catalogue-pagination button.ims-icon-arrow-left:before, .catalogue-pagination button.ims-icon-arrow-right:before {
      margin-left: 0rem !important;
      margin-right: 0rem !important;
      width: 0.875rem;
      position: static; }
    .catalogue-pagination button.ims-icon, .catalogue-pagination button.ims-icon-arrow-left, .catalogue-pagination button.ims-icon-arrow-right {
      margin: 0 0 !important; }
  .catalogue-pagination .results-amount {
    display: flex;
    justify-content: flex-end; }

.ims-sortingMenu.open {
  display: block; }

.ims-sortingMenu > li {
  padding: var(--ims-spacing--25) var(--ims-spacing--25); }
  .ims-sortingMenu > li:hover {
    background-color: var(--bs-primary); }
    .ims-sortingMenu > li:hover a {
      color: var(--bs-on-primary); }

@media (min-width: 576px) {
  .ims-paging-display {
    text-align: right; } }

@media (max-width: 575px) {
  .ims-pager ul {
    float: left; }
  .ims-pager span {
    float: left; } }

.ims-progress-bar {
  position: relative; }
  .ims-progress-bar i.ims-icon, .ims-progress-bar i.ims-icon-arrow-left, .ims-progress-bar i.ims-icon-arrow-right {
    position: absolute;
    left: -0.125rem;
    top: 0; }
  .ims-progress-bar .progress {
    z-index: 0;
    width: calc(100% - 0.75rem);
    height: 0.25rem;
    display: inline-flex;
    border-radius: var(--ims-border__radius--0);
    vertical-align: middle;
    margin-left: var(--ims-spacing--30); }

.progress {
  height: 0.3125rem; }
  .progress .progress-bar {
    background-color: var(--bs-success); }
  .progress .progress-bar-alert {
    background-color: var(--bs-orange); }
  .progress .progress-bar-warning {
    background-color: var(--bs-danger); }

.ims-course-progress--sm .progress {
  height: 0.25rem !important;
  border-radius: var(--ims-progress__border-radius--md);
  background-color: var(--ims-progress__bg);
  margin-top: var(--ims-spacing--10); }

.ims-course-progress--sm.squared .progress {
  border-radius: var(--ims-border__radius--25); }

.ims-course-progress--sm.bordered .progress {
  border: 0.0625rem solid var(--bs-dark-border-subtle); }

.ims-course-progress--md .progress {
  height: .875rem !important;
  border-radius: var(--ims-progress__border-radius--md);
  background-color: var(--ims-progress__bg);
  margin-top: var(--ims-spacing--10); }

.ims-course-progress--md.squared .progress {
  border-radius: var(--ims-border__radius--25); }

.ims-course-progress--md.bordered .progress {
  border: 0.0625rem solid var(--bs-dark-border-subtle); }

.ims-course-progress--lg .progress {
  height: 1.5rem !important;
  border-radius: var(--ims-progress__border-radius);
  background-color: var(--ims-progress__bg);
  margin-top: var(--ims-spacing--10); }

.ims-course-progress--lg.squared .progress {
  border-radius: var(--ims-border__radius--25); }

.ims-course-progress--lg.bordered .progress {
  border: 0.0625rem solid var(--bs-dark-border-subtle); }

#Loading {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(var(--bs-white-rgb), 0.5);
  position: fixed;
  z-index: 1; }

.modal.load-spinner {
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .modal.load-spinner .modal-dialog {
    position: absolute;
    top: calc(50% - 6.25rem);
    left: calc(50% - 8.125rem); }
  .modal.load-spinner .modal-content {
    background-color: transparent;
    border: none;
    text-align: center;
    width: 16.25rem; }
  .modal.load-spinner .spinner-label {
    color: var(--bs-on-primary); }

.ims-starRating .ims-icon, .ims-starRating .ims-icon-arrow-left, .ims-starRating .ims-icon-arrow-right {
  margin: 0;
  color: var(--bs-orange); }
  .ims-starRating .ims-icon:nth-last-child(2), .ims-starRating .ims-icon-arrow-left:nth-last-child(2), .ims-starRating .ims-icon-arrow-right:nth-last-child(2) {
    padding-right: 0.3125rem; }

.ims-starRating.editable .ims-icon:hover:before, .ims-starRating.editable .ims-icon-arrow-left:hover:before, .ims-starRating.editable .ims-icon-arrow-right:hover:before {
  cursor: pointer; }

.ims-starRating.editable:hover .ims-icon-star-empty:before {
  content: "\e818";
  opacity: 0.6; }

.ims-starRating.editable:hover .ims-icon-star:before {
  color: var(--bs-orange); }

.ims-starRating.editable .ims-icon-star-empty:hover ~ .ims-icon-star-empty:before, .ims-starRating.editable .ims-icon-star:hover ~ .ims-icon-star-empty:before {
  content: "\e819";
  opacity: 1;
  color: var(--bs-orange); }

.ims-average-rating {
  font-size: var(--ims-font__size--100);
  font-weight: 600; }
  .ims-average-rating + .ims-icon, .ims-average-rating + .ims-icon-arrow-left, .ims-average-rating + .ims-icon-arrow-right {
    color: var(--bs-orange); }
    .ims-average-rating + .ims-icon::before, .ims-average-rating + .ims-icon-arrow-left::before, .ims-average-rating + .ims-icon-arrow-right::before {
      margin-right: 0; }

.ims-timeline {
  display: flex;
  flex-direction: column;
  margin-left: 5rem; }
  .ims-timeline__event {
    background: var(--bs-white);
    margin-bottom: 20px;
    position: relative;
    display: flex;
    margin: 10px 0;
    border: 2px solid transparent; }
    .ims-timeline__event.active {
      border: 2px solid var(--bs-primary); }
    .ims-timeline__event.inactive {
      background: var(--bs-light-bg-subtle); }
      .ims-timeline__event.inactive a {
        color: var(--bs-body-color); }
    .ims-timeline__event__title {
      color: var(--bs-primary); }
    .ims-timeline__event__content {
      padding: 20px;
      width: 100%; }
    .ims-timeline__event__icon {
      border-radius: 8px 0 0 8px;
      background: #9251ac;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--ims-font__size--700);
      color: #9251ac; }
      .ims-timeline__event__icon i {
        position: absolute;
        top: 3.3rem;
        left: -67.8px;
        font-size: 1.2rem;
        transform: translateY(-50%); }
    .ims-timeline__event__description {
      flex-basis: 60%; }
    .ims-timeline__event:after {
      content: "";
      width: 2px;
      height: 122%;
      background: #9251ac;
      position: absolute;
      left: -3.5rem;
      z-index: -1;
      top: 3.4rem; }
    .ims-timeline__event:before {
      content: "";
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      background: #f6a4ec;
      border-radius: 100%;
      left: -4.2rem;
      top: 3.4rem;
      transform: translateY(-50%);
      border: 2px solid #9251ac; }
    .ims-timeline__event--type1:before {
      background: #ebebeb;
      border-color: #69c933; }
    .ims-timeline__event--type1:after {
      background: var(--bs-gray); }
    .ims-timeline__event--type1 .ims-timeline__event__icon {
      background: #555ac0;
      color: #69c933; }
    .ims-timeline__event--type1 .ims-timeline__event__title {
      color: var(--bs-primary); }
    .ims-timeline__event--type2:before {
      background: var(--bs-primary);
      border-color: var(--bs-primary); }
    .ims-timeline__event--type2:after {
      background: var(--bs-light-bg-subtle); }
    .ims-timeline__event--type2 .ims-timeline__event__icon {
      background: var(--bs-primary);
      color: var(--bs-primary); }
    .ims-timeline__event--type2 .ims-timeline__event__title {
      color: var(--bs-primary); }
    .ims-timeline__event--type3:before {
      background: var(--bs-light-bg-subtle);
      border-color: var(--bs-dark-border-subtle); }
    .ims-timeline__event--type3:after {
      background: var(--bs-light-bg-subtle); }
    .ims-timeline__event--type3 .ims-timeline__event__icon {
      background: var(--bs-light-bg-subtle);
      color: var(--bs-dark-border-subtle); }
    .ims-timeline__event--type3 .ims-timeline__event__title {
      color: #696c6c; }
    .ims-timeline__event:last-child:after {
      content: none; }
    .ims-timeline__event .ims-inner-collapse-block-frame {
      padding-bottom: var(--ims-spacing--40);
      border-bottom: 0.1rem solid var(--bs-dark-border-subtle);
      margin-bottom: var(--ims-spacing--40); }
      .ims-timeline__event .ims-inner-collapse-block-frame:last-of-type {
        padding-bottom: 0rem;
        border-bottom: 0rem solid var(--bs-dark-border-subtle); }

@media (max-width: 786px) {
  .ims-timeline__event {
    flex-direction: column; }
  .ims-timeline__event::after {
    height: 122%; }
  .ims-timeline__event__icon {
    border-radius: 4px 4px 0 0; } }

.ims-path-view-nav {
  position: fixed;
  left: 0rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(250, 250, 250, 0.8);
  box-shadow: 0.125rem 0.1875rem 0.3125rem rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: all 0.3s ease-in-out;
  top: 25%;
  /*
 * And let's slide it in from the left
 */ }
  .ims-path-view-nav #menuToggle {
    display: block;
    position: relative;
    top: 0rem;
    left: 0rem;
    z-index: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
         user-select: none;
    background-color: var(--bs-white);
    width: 1.8rem;
    height: 3.2rem; }
    .ims-path-view-nav #menuToggle .ims-icon::before, .ims-path-view-nav #menuToggle .ims-icon-arrow-left::before, .ims-path-view-nav #menuToggle .ims-icon-arrow-right::before {
      margin-left: 0.4rem;
      margin-top: 1.2rem; }
  .ims-path-view-nav #menuToggle a {
    text-decoration: none;
    margin-top: var(--ims-spacing--20); }
  .ims-path-view-nav #menuToggle a:hover {
    color: var(--bs-primary); }
  .ims-path-view-nav #menuToggle input {
    display: block;
    width: 30px;
    height: 52px;
    position: absolute;
    top: var(--ims-spacing--0);
    left: var(--ims-spacing--0);
    background: var(--bs-white);
    cursor: pointer;
    opacity: 0;
    /* hide this */
    z-index: 2;
    /* and place it over the hamburger */
    -webkit-touch-callout: none; }
  .ims-path-view-nav #menu {
    position: absolute;
    transition: all 0.8s ease-in-out;
    width: 38.5rem;
    max-height: 40rem;
    overflow: auto;
    margin: 0;
    margin-top: 0.9rem;
    margin-left: -1rem;
    padding: 1rem 1rem 1rem 2.8rem;
    background: var(--bs-white);
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1); }
    .ims-path-view-nav #menu li {
      padding: 0.3rem 0.3rem 0.3rem 0rem;
      margin-left: var(--ims-spacing--0);
      list-style: circle;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      list-style-position: inside;
      font-size: var(--ims-font__size--300);
      font-weight: bold; }
      .ims-path-view-nav #menu li ul li {
        margin-left: var(--ims-spacing--40);
        font-weight: normal;
        list-style-position: initial;
        margin-left: var(--ims-spacing--40); }
        .ims-path-view-nav #menu li ul li.active {
          color: var(--bs-primary);
          list-style: disc;
          list-style-position: inside; }
        .ims-path-view-nav #menu li ul li.inactive {
          color: grey;
          list-style: disc;
          list-style-position: initial;
          margin-left: 2rem; }
        .ims-path-view-nav #menu li ul li.visited {
          color: var(--bs-primary);
          list-style: disc;
          list-style-position: initial;
          margin-left: 2rem; }
        .ims-path-view-nav #menu li ul li:last-of-type {
          padding-bottom: 0rem; }
      .ims-path-view-nav #menu li.active {
        color: var(--bs-primary);
        list-style: disc;
        list-style-position: inside; }
      .ims-path-view-nav #menu li.inactive {
        color: grey;
        list-style: disc;
        list-style-position: inside; }
    .ims-path-view-nav #menu span {
      display: block; }
  .ims-path-view-nav #menuToggle input:checked ~ div {
    transform: none; }

@media (max-width: 767px) {
  .ims-path-view-nav {
    transition: all 0.3s ease-in-out;
    top: 15%; }
    .ims-path-view-nav #menu {
      transition: all 0.3s ease-in-out;
      width: 28.5rem; } }

.modal-active {
  display: block; }

.ims-table tbody tr:nth-of-type(even),
.table-striped tbody tr:nth-of-type(even) {
  background-color: var(--bs-white); }

.ims-table tbody tr:nth-of-type(odd),
.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--bs-secondary-bg-subtle); }

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid var(--bs-dark-border-subtle);
  align-content: center; }

.table td,
.table th {
  padding: var(--ims-spacing--30);
  padding-right: var(--ims-spacing--30);
  vertical-align: top;
  border-top: 1px solid var(--bs-dark-border-subtle); }

.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right .375rem center;
  padding-right: calc(.75rem + .65em); }

.table.b-table > tfoot > tr > [aria-sort="none"],
.table.b-table > thead > tr > [aria-sort="none"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22zm0 100l25-23 24-22H1l25 22z'/%3E%3C/svg%3E"); }

.table.b-table > tfoot > tr > [aria-sort="ascending"],
.table.b-table > thead > tr > [aria-sort="ascending"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E"); }

.table.b-table > tfoot > tr > [aria-sort="descending"],
.table.b-table > thead > tr > [aria-sort="descending"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E"); }

.table.b-table > tfoot > tr > [aria-sort],
.table.b-table > thead > tr > [aria-sort] {
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: .65em 1em; }

.table [role="columnheader"] .sr-only {
  display: none !important; }

#toast-container .toast-message {
  font-weight: var(--ims-font__weight--medium);
  font-family: var(--ims-font__family--default);
  font-size: var(--ims-font__size--100); }

.toast {
  background-color: var(--bs-body-color);
  font-size: var(--ims-font__size--100); }

.toast-success {
  background-color: var(--bs-success); }

.toast-error {
  background-color: var(--bs-danger); }

.toast-info {
  background-color: var(--bs-blue); }

.toast-warning {
  background-color: var(--bs-warning); }

.carousel {
  margin-bottom: var(--ims-spacing--75); }
  .carousel .carousel-control-next, .carousel .carousel-control-prev {
    margin-top: var(--ims-spacing--10);
    width: auto;
    z-index: 1;
    box-shadow: none;
    align-items: end !important;
    top: auto !important;
    bottom: auto !important;
    border-radius: 0 !important; }
    .carousel .carousel-control-next:hover, .carousel .carousel-control-prev:hover {
      opacity: .5 !important;
      background-color: transparent !important;
      border-color: transparent !important; }
  .carousel .carousel-indicators {
    margin-bottom: -1.8rem; }
    .carousel .carousel-indicators button {
      background-color: var(--bs-primary-light); }
      .carousel .carousel-indicators button:hover {
        background-color: var(--bs-primary-light); }
  .carousel .carousel-caption {
    width: 100%;
    right: var(--ims-spacing--0);
    left: var(--ims-spacing--0);
    padding-bottom: var(--ims-spacing--80);
    bottom: 0; }
    .carousel .carousel-caption p {
      margin-bottom: var(--ims-spacing--40); }
  .carousel h3 {
    padding: var(--ims-spacing--40) var(--ims-spacing--100) var(--ims-spacing--40) var(--ims-spacing--100);
    font-size: var(--ims-font__size--600);
    font-weight: bold; }
  .carousel .card-header, .carousel .card-footer {
    background-color: inherit;
    border-width: 0; }

.carousel .carousel-control-next .carousel-control-next-icon {
  background-color: var(--bs-primary-light);
  border-radius: 50%; }

.carousel .carousel-control-prev .carousel-control-prev-icon {
  background-color: var(--bs-primary-light);
  border-radius: 50%; }

.carousel .btn.btn-minimal-primary {
  border-color: transparent !important;
  background-color: transparent !important; }

.availableTrainingsListWidget .carousel-inner,
.resourceListWidget .carousel-inner,
.myTrainingsListWidget .carousel-inner {
  border: none !important; }

.carousel .carousel-inner .carousel-item .card {
  height: 100%;
  width: 100%; }

.carousel .carousel-inner .carousel-item .ims-basecard {
  border: 1px solid var(--ims-card__border-color);
  border-radius: var(--ims-card__border-radius); }

.carousel .carousel-inner.showSlide .ims-carousel-items-2.carousel-item-end.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-2.carousel-item-next {
  transform: translateX(50%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-2.carousel-item-start.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-2.carousel-item-prev {
  transform: translateX(-50%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-2.carousel-item-end,
.carousel .carousel-inner.showSlide .ims-carousel-items-2.carousel-item-start {
  transform: translateX(0); }

.carousel .carousel-inner.showSlide .ims-carousel-items-3.carousel-item-end.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-3.carousel-item-next {
  transform: translateX(33.33333%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-3.carousel-item-start.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-3.carousel-item-prev {
  transform: translateX(-33.33333%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-3.carousel-item-end,
.carousel .carousel-inner.showSlide .ims-carousel-items-3.carousel-item-start {
  transform: translateX(0); }

.carousel .carousel-inner.showSlide .ims-carousel-items-4.carousel-item-end.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-4.carousel-item-next {
  transform: translateX(25%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-4.carousel-item-start.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-4.carousel-item-prev {
  transform: translateX(-25%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-4.carousel-item-end,
.carousel .carousel-inner.showSlide .ims-carousel-items-4.carousel-item-start {
  transform: translateX(0); }

.carousel .carousel-inner.showSlide .ims-carousel-items-5.carousel-item-end.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-5.carousel-item-next {
  transform: translateX(20%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-5.carousel-item-start.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-5.carousel-item-prev {
  transform: translateX(-20%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-5.carousel-item-end,
.carousel .carousel-inner.showSlide .ims-carousel-items-5.carousel-item-start {
  transform: translateX(0); }

.carousel .carousel-inner.showSlide .ims-carousel-items-6.carousel-item-end.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-6.carousel-item-next {
  transform: translateX(16.66667%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-6.carousel-item-start.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-6.carousel-item-prev {
  transform: translateX(-16.66667%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-6.carousel-item-end,
.carousel .carousel-inner.showSlide .ims-carousel-items-6.carousel-item-start {
  transform: translateX(0); }

.carousel .carousel-inner.showSlide .ims-carousel-items-7.carousel-item-end.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-7.carousel-item-next {
  transform: translateX(14.28571%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-7.carousel-item-start.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-7.carousel-item-prev {
  transform: translateX(-14.28571%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-7.carousel-item-end,
.carousel .carousel-inner.showSlide .ims-carousel-items-7.carousel-item-start {
  transform: translateX(0); }

.carousel .carousel-inner.showSlide .ims-carousel-items-8.carousel-item-end.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-8.carousel-item-next {
  transform: translateX(12.5%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-8.carousel-item-start.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-8.carousel-item-prev {
  transform: translateX(-12.5%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-8.carousel-item-end,
.carousel .carousel-inner.showSlide .ims-carousel-items-8.carousel-item-start {
  transform: translateX(0); }

.carousel .carousel-inner.showSlide .ims-carousel-items-9.carousel-item-end.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-9.carousel-item-next {
  transform: translateX(11.11111%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-9.carousel-item-start.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-9.carousel-item-prev {
  transform: translateX(-11.11111%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-9.carousel-item-end,
.carousel .carousel-inner.showSlide .ims-carousel-items-9.carousel-item-start {
  transform: translateX(0); }

.carousel .carousel-inner.showSlide .ims-carousel-items-10.carousel-item-end.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-10.carousel-item-next {
  transform: translateX(10%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-10.carousel-item-start.active,
.carousel .carousel-inner.showSlide .ims-carousel-items-10.carousel-item-prev {
  transform: translateX(-10%); }

.carousel .carousel-inner.showSlide .ims-carousel-items-10.carousel-item-end,
.carousel .carousel-inner.showSlide .ims-carousel-items-10.carousel-item-start {
  transform: translateX(0); }

.carousel .carousel-inner.showSlide .ims-sliding-partial.carousel-item-next > div > * .card {
  display: none; }

.carousel .carousel-inner.showSlide .ims-sliding-partial.carousel-item-next > div > :last-child > .card {
  display: block; }

.carousel .carousel-inner.showSlide .ims-sliding-partial.carousel-item-prev > div > * .card {
  display: none; }

.carousel .carousel-inner.showSlide .ims-sliding-partial.carousel-item-prev > div > :first-child > .card {
  display: block; }

.carousel .carousel-inner.showNoSlide .carousel-item-end.active,
.carousel .carousel-inner.showNoSlide .carousel-item-next {
  transform: translateX(0%); }

.carousel .carousel-inner.showNoSlide .carousel-item-start.active,
.carousel .carousel-inner.showNoSlide .carousel-item-prev {
  transform: translateX(0%); }

.carousel .carousel-inner.showNoSlide .carousel-item-end,
.carousel .carousel-inner.showNoSlide .carousel-item-start {
  transform: translateX(0); }

.carousel .ims-list-row-title h1, .carousel .card-body h1 {
  white-space: nowrap;
  max-width: 12.5rem;
  overflow: hidden;
  text-overflow: ellipsis; }

.carousel .ims-list-row-title h1, .carousel .card-body h1 * {
  white-space: nowrap;
  max-width: 12.5rem;
  overflow: hidden;
  text-overflow: ellipsis; }

.carousel-control-next-icon, .carousel-control-prev-icon {
  /* Use to adjust size of icons */
  width: 1.4rem;
  height: 1.4rem; }

.teaserWidget .carousel .carousel-control-next,
.teaserWidget .carousel .carousel-control-prev {
  top: 0 !important;
  bottom: 0 !important;
  align-items: center !important; }

.teaserWidget .carousel .carousel-control-prev {
  margin-left: var(--ims-spacing--10); }

.teaserWidget .carousel-indicators {
  margin-bottom: 0rem; }

.teaserWidget .carousel-control-next-icon, .teaserWidget .carousel-control-prev-icon {
  /* Use to adjust size of icons */
  width: 3rem;
  height: 3rem; }

.ims-comp-base-card.with-icon-centered-items {
  align-items: center; }
  .ims-comp-base-card.with-icon-centered-items .ratio {
    width: 5rem !important;
    height: 5rem !important; }
    .ims-comp-base-card.with-icon-centered-items .ratio::before {
      margin-top: var(--ims-spacing-y--40); }

/*#####Outsource from old crm.css file#####*/
/*#region Drop Files */
/* Files Uploads + D&D Styles */
.imageUploadContainer .fileDropDox {
  position: relative; }
  .imageUploadContainer .fileDropDox .formulate-input[data-classification="file"] .formulate-files .formulate-file-remove {
    right: 2rem !important; }

.imageUploadContainer ul.formulate-files {
  margin-bottom: 0rem !important; }

.imageUploadContainer .imagePreview {
  width: auto;
  max-width: 100%;
  overflow: hidden; }
  .imageUploadContainer .imagePreview img {
    width: auto;
    max-height: 76px !important;
    padding: var(--ims-spacing--20) var(--ims-spacing--20) !important; }
  
  .imageUploadContainer .imagePreview .ims-icon,
  .imageUploadContainer .imagePreview .ims-icon-arrow-left,
  .imageUploadContainer .imagePreview .ims-icon-arrow-right {
    position: absolute;
    cursor: pointer;
    margin-left: var(--ims-spacing--25);
    border-radius: 50%; }
  .imageUploadContainer .imagePreview img.empty ~ .ims-icon, .imageUploadContainer .imagePreview img.empty ~ .ims-icon-arrow-left, .imageUploadContainer .imagePreview img.empty ~ .ims-icon-arrow-right {
    display: none; }

.imageUploadContainer .imageTitle {
  left: 70px;
  position: absolute;
  right: 30px; }

.imageUploadContainer .add {
  position: absolute;
  bottom: 6px;
  right: 0; }

.imageUploadContainer .delete {
  position: absolute;
  top: 6px;
  right: 0; }

.imageUploadContainer .detailsRow label {
  padding: var(--ims-spacing--25) var(--ims-spacing--25); }

.imageUploadContainer .btn:hover {
  background-color: transparent;
  border-color: transparent; }

.imageUploadContainer.with-thumbnailLibrary .k-upload {
  border-radius: var(--ims-form-input__border-radius) !important; }
  .imageUploadContainer.with-thumbnailLibrary .k-upload .k-upload-files .k-file {
    border-bottom-left-radius: var(--ims-form-input__border-radius) !important;
    border-bottom-right-radius: var(--ims-form-input__border-radius) !important; }

.imageUploadContainer .k-file {
  background-color: var(--bs-white); }

.imagePreview {
  border-radius: var(--ims-form-input__border-radius) !important;
  border: 0.1rem solid var(--bs-border-color); }

.fileDropDox .formulate-input-upload-area {
  border-radius: var(--ims-form-input__border-radius) !important; }
  .fileDropDox .formulate-input-upload-area .formulate-files .formulate-file {
    background-color: var(--bs-white) !important;
    border-radius: var(--ims-form-input__border-radius) !important; }

.minimumWidth .imageUploadContainer .fileDropDox {
  position: absolute;
  bottom: 0;
  right: 30px;
  left: 65px;
  max-width: 210px; }

.with-thumbnailLibrary .thumbLibraryIcon,
.with-thumbnailLibrary .modal-window-thumbLibraryIcon {
  position: absolute;
  right: var(--ims-spacing--15);
  padding-top: var(--ims-spacing--30); }

.with-thumbnailLibrary .thumbLibraryIcon {
  top: 4px; }

.with-thumbnailLibrary .modal-window-thumbLibraryIcon {
  top: 14px; }

.with-thumbnailLibrary .thumbLibraryIcon:active,
.with-thumbnailLibrary .modal-window-thumbLibraryIcon:active {
  box-shadow: none; }

.with-thumbnailLibrary .thumbLibraryIcon:hover,
.with-thumbnailLibrary .modal-window-thumbLibraryIcon:hover {
  opacity: 0.6; }

.modal-window-thumbLibraryIcon {
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 100; }

.ims-dashboard-card-icon {
  color: var(--bs-on-primary);
  padding-left: var(--ims-spacing--10);
  padding-top: 0.1875rem;
  font-size: 1.5625rem; }

.card-item {
  align-items: center;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  margin-right: var(--ims-spacing--50);
  position: relative; }
  .card-item:last-child .card-item-border {
    display: none; }

.ims-dashboard-header {
  color: var(--bs-primary-dark);
  margin-bottom: 0.9375rem;
  padding-left: var(--ims-spacing--75); }

.ims-dashboard-card {
  background-color: var(--bs-surface-high);
  border-top-right-radius: 15px; }

.card-item-border {
  border-bottom: 1px solid var(--bs-dark-border-subtle);
  position: absolute;
  bottom: 0;
  left: 12.5%;
  right: 0; }

.dashboard-card-header, .card-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start; }

.card-header-container {
  flex-grow: 8;
  flex-basis: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--bs-primary-bg-subtle);
  border-top-right-radius: 15px;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem; }

.card-placeholder {
  flex-grow: 1;
  flex-basis: 0; }

.card-icon-container {
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 1; }

.card-icon, .item-icon {
  margin-left: var(--ims-spacing--60);
  margin-right: var(--ims-spacing--60);
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%; }

.card-icon {
  background-color: var(--bs-primary-dark); }

.item-icon {
  background-color: var(--bs-secondary-color); }

.dashboard-card-title {
  flex-grow: 7;
  flex-basis: 0;
  padding-right: 6.25rem; }

.dashboard-item-content {
  flex-grow: 6;
  flex-basis: 0;
  flex-shrink: 3;
  padding-right: var(--ims-spacing--25); }

.four-columns .dashboard-item-content {
  flex-grow: 4; }

.item-description,
.item-timestamp > div,
.creator-label, .creator-name {
  margin-top: var(--ims-spacing--15);
  margin-bottom: var(--ims-spacing--15); }

.item-timestamp {
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 0; }

.item-date {
  width: 4.75rem; }

.item-time {
  width: 2.375rem; }

.item-creator {
  flex-grow: 2;
  flex-basis: 0;
  flex-shrink: 3;
  padding-right: var(--ims-spacing--25); }

.creator-label {
  width: 3.75rem; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .item-timestamp {
    padding-right: var(--ims-spacing--25); } }

@media all and (-ms-high-contrast: none) and (max-width: 540px), (-ms-high-contrast: active) and (max-width: 540px) {
  .item-timestamp, .item-creator {
    min-width: 100%; } }

@media all and (-ms-high-contrast: none) and (max-width: 386px), (-ms-high-contrast: active) and (max-width: 386px) {
  .ims-dashboard-card {
    min-width: 13.75rem; } }

@media only screen and (max-width: 540px) {
  .card-icon-container {
    display: none; }
  .ims-dashboard-header {
    padding-left: 0; }
  .dashboard-card-title,
  .dashboard-item-content,
  .item-timestamp,
  .item-creator {
    padding-left: 0.9375rem; } }

/*#region Survey Editor in Content Tab styles */
.surveyEditor .surveyEditorHeader {
  margin-top: 15px;
  margin-bottom: 15px; }
  .surveyEditor .surveyEditorHeader > .form-action-menu {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap; }
    .surveyEditor .surveyEditorHeader > .form-action-menu .k-button {
      margin-left: var(--ims-spacing--25); }

.surveyEditor .surveyEditorFooter {
  margin-bottom: 15px; }

.surveyEditor .ims-survey-page {
  margin: 0;
  border: 0.0625rem solid var(--bs-dark-border-subtle);
  border-radius: 0.3125rem;
  margin-bottom: var(--ims-spacing--75); }
  .surveyEditor .ims-survey-page .surveyPageHeader {
    margin-top: 5px;
    margin-bottom: 5px; }
  .surveyEditor .ims-survey-page .surveyPageFooter {
    padding: 0 30px; }
    .surveyEditor .ims-survey-page .surveyPageFooter .k-animation-container .k-item.k-menu-item.k-state-hover .k-link.k-menu-link .ims-icon-smile {
      color: var(--bs-success-border-subtle-hover); }
  .surveyEditor .ims-survey-page .form-action-menu {
    padding-top: var(--ims-spacing--25);
    display: flex;
    justify-content: flex-end; }
    .surveyEditor .ims-survey-page .form-action-menu > .ims-icon, .surveyEditor .ims-survey-page .form-action-menu > .ims-icon-arrow-left, .surveyEditor .ims-survey-page .form-action-menu > .ims-icon-arrow-right {
      color: var(--bs-border-color); }
      .surveyEditor .ims-survey-page .form-action-menu > .ims-icon::before, .surveyEditor .ims-survey-page .form-action-menu > .ims-icon-arrow-left::before, .surveyEditor .ims-survey-page .form-action-menu > .ims-icon-arrow-right::before {
        font-size: var(--ims-font__size--400);
        margin: 0; }
      .surveyEditor .ims-survey-page .form-action-menu > .ims-icon:hover, .surveyEditor .ims-survey-page .form-action-menu > .ims-icon-arrow-left:hover, .surveyEditor .ims-survey-page .form-action-menu > .ims-icon-arrow-right:hover {
        color: var(--bs-primary);
        cursor: pointer; }
      .surveyEditor .ims-survey-page .form-action-menu > .ims-icon:active, .surveyEditor .ims-survey-page .form-action-menu > .ims-icon-arrow-left:active, .surveyEditor .ims-survey-page .form-action-menu > .ims-icon-arrow-right:active {
        color: var(--bs-primary-lightest); }
  .surveyEditor .ims-survey-page .dragArea {
    min-height: 50px; }

.surveyEditor .ims-survey-question {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid var(--bs-dark-border-subtle);
  position: relative; }
  .surveyEditor .ims-survey-question .ims-delButton {
    position: absolute;
    display: none;
    bottom: var(--ims-spacing--40);
    right: var(--ims-spacing--40); }
    .surveyEditor .ims-survey-question .ims-delButton .k-button {
      border: none;
      border-radius: var(--ims-border__radius--0);
      padding-left: var(--ims-spacing--25);
      padding-right: var(--ims-spacing--25); }
  .surveyEditor .ims-survey-question:hover {
    box-shadow: 0 1px 2px 1px rgba(var(--bs-body-color-rgb), 0.08), 0 3px 6px rgba(var(--bs-body-color-rgb), 0.08); }
    .surveyEditor .ims-survey-question:hover .ims-delButton {
      display: block; }

.surveyEditor label {
  width: 100%; }

.surveyEditor .ims-clearable {
  position: relative; }
  .surveyEditor .ims-clearable:hover .k-input {
    border-bottom: 1px dotted var(--bs-border-color); }
  .surveyEditor .ims-clearable:hover [class*="ims-icon-"] {
    display: inline-block;
    outline: 0; }
  .surveyEditor .ims-clearable .k-textbox {
    height: auto;
    max-height: 150px;
    overflow: auto;
    resize: vertical;
    border-radius: var(--ims-border__radius--0);
    border: 1px solid var(--bs-dark-border-subtle); }
  .surveyEditor .ims-clearable .k-input {
    border: 1px solid var(--bs-dark-border-subtle);
    padding-right: 1.1875rem; }
    .surveyEditor .ims-clearable .k-input:focus {
      box-shadow: 0 1px 2px 1px rgba(var(--bs-body-color-rgb), 0.08), 0 2px 2px rgba(var(--bs-body-color-rgb), 0.08); }
    .surveyEditor .ims-clearable .k-input:focus ~ [class*="ims-icon-"] {
      display: inline-block;
      outline: 0; }
  .surveyEditor .ims-clearable .ims-icon-close, .surveyEditor .ims-clearable .ims-icon-failed {
    display: none;
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer; }
    .surveyEditor .ims-clearable .ims-icon-close:before, .surveyEditor .ims-clearable .ims-icon-failed:before {
      font-size: var(--ims-font__size--60); }

.surveyEditor .ims-survey-question .ims-question-options .k-input.invalid {
  border: 1px dotted var(--bs-danger); }

.surveyEditor .ims-survey-question .k-input.invalid {
  border: 1px dotted var(--bs-danger); }

.surveyEditor .ims-survey-question .k-input:focus {
  border-color: var(--bs-dark-border-subtle); }

.surveyEditor .ims-survey-question .k-input:hover {
  border-bottom: 1px dotted var(--bs-border-color); }

.surveyEditor .ims-question-options .ims-txt-answer {
  color: var(--bs-dark-border-subtle); }

.surveyEditor .ims-question-options ul {
  padding: 0;
  width: 100%; }

.surveyEditor .ims-question-options li {
  list-style: none; }

.surveyEditor .ims-question-options .k-input {
  width: calc(100% - 28px);
  padding-right: 2.375rem; }

.surveyEditor .ims-question-options.ScaleQuestion .k-input {
  width: 100%;
  padding-right: .5em; }

.surveyEditor .ims-question-options .option-image {
  position: relative; }
  .surveyEditor .ims-question-options .option-image > img {
    min-width: 80px;
    min-height: 80px;
    border: 1px solid var(--bs-dark-border-subtle);
    margin-left: 30px;
    margin-top: 10px; }
  .surveyEditor .ims-question-options .option-image [class*=" ims-icon-"] {
    font-size: 0.625rem;
    position: absolute;
    top: -1.375rem;
    right: var(--ims-spacing--10);
    color: var(--bs-dark-border-subtle);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); }
    .surveyEditor .ims-question-options .option-image [class*=" ims-icon-"]:hover {
      cursor: pointer; }
    .surveyEditor .ims-question-options .option-image [class*=" ims-icon-"]:active, .surveyEditor .ims-question-options .option-image [class*=" ims-icon-"]:focus {
      color: var(--bs-dark-border-subtle); }

.surveyEditor .ims-question-options .ims-icon-picture-1 {
  display: none;
  position: absolute;
  right: var(--ims-spacing--60);
  margin-right: var(--ims-spacing--25);
  top: 50%;
  transform: translateY(-50%);
  font-size: var(--ims-font__size--60);
  cursor: pointer;
  margin-top: 1px; }

.surveyEditor .ims-question-options .ims-icon-smile:before,
.surveyEditor .ims-question-options .ims-icon-meh:before,
.surveyEditor .ims-question-options .ims-icon-frown:before {
  font-size: 4.375rem; }

.surveyEditor .ims-question-options .ims-icon-smile {
  color: var(--bs-success); }

.surveyEditor .ims-question-options .ims-icon-meh {
  color: var(--bs-warning); }

.surveyEditor .ims-question-options .ims-icon-frown {
  color: var(--bs-danger); }

.surveyEditor .ims-question-options .ims-clearable:has(> :last-child:nth-child(2)) {
  /* 2 elements */ }
  .surveyEditor .ims-question-options .ims-clearable:has(> :last-child:nth-child(2)) span {
    margin-right: -10px !important; }

.surveyEditor .transition {
  transition: all 300ms ease; }

.surveyEditor .k-menu {
  border: none; }
  .surveyEditor .k-menu.k-menu-horizontal > .k-item,
  .surveyEditor .k-menu li.k-item {
    border: none; }

.surveyEditor .k-menu .k-i-arrow-s {
  display: none; }

.surveyEditor .ims-single_choice:before,
.surveyEditor .ims-multiple_choice:before,
.surveyEditor .ims-long_answer:before,
.surveyEditor .ims-linear_scale:before,
.surveyEditor .ims-happy_sheet:before {
  padding: var(--ims-spacing--0) var(--ims-spacing--10); }

.surveyEditor .addPage span {
  display: block;
  padding: 0.5em 1.1em 0.4em;
  line-height: 1.34em; }

/*#endregion */
.pageEditor div.containerBar .ddMenu .k-menu > li.k-item > .k-link {
  padding: 0em;
  width: 0rem !important; }
  .pageEditor div.containerBar .ddMenu .k-menu > li.k-item > .k-link .image {
    width: 0; }

.pageEditor .ddMenu ul.k-widget.k-menu {
  height: 1.25rem; }

.pageEditor .ddMenu > ul > li > .k-animation-container {
  left: auto !important;
  right: 0; }

.pageEditor .k-menu .k-item > .k-link > .k-i-arrow-60-down {
  margin: 0;
  top: -0.125rem;
  right: 1.25rem; }

.pageEditor a.ims-icon, .pageEditor a.ims-icon-arrow-left, .pageEditor a.ims-icon-arrow-right {
  position: inherit; }

.pageEditor div {
  box-sizing: border-box !important; }

.pageEditor .col > div {
  margin: 5px 0;
  padding: 5px; }

.pageEditor .widget {
  width: 100%;
  display: inline-block;
  border: 1px dashed var(--bs-primary-lightest);
  margin: 20px 0; }
  .pageEditor .widget .widgetName {
    text-align: center;
    margin: 10px 0; }

.pageEditor .containerBar {
  background-color: var(--bs-primary-dark);
  padding: 8px;
  position: relative;
  box-sizing: border-box;
  color: var(--bs-on-primary);
  width: 100%; }
  .pageEditor .containerBar .ddMenu {
    position: absolute;
    top: 0;
    border: none; }

.pageEditor .containerBarWidget {
  background-color: var(--bs-primary-dark);
  cursor: -moz-grab;
  color: var(--bs-on-primary); }
  .pageEditor .containerBarWidget .active {
    opacity: 0.3; }

.pageEditor .widgetItem.draggable {
  cursor: -moz-grab; }

.pageEditor .containerBar .ddMenu {
  right: 30px; }

.pageEditor .containerBarLabel {
  font-weight: bold !important;
  font-size: var(--ims-font__size--60) !important; }

.pageEditor .matrixVariationName {
  margin-left: 8px;
  margin-right: 8px; }

.pageEditor .widgetBar {
  height: 25px;
  border-bottom: 1px dashed var(--bs-blue); }
  .pageEditor .widgetBar .iconButtonsBox {
    float: right; }

.pageEditor .pageEditorContainer {
  width: 100%;
  border: 1px dashed var(--bs-primary);
  margin: 0 20px 20px 0; }

.pageEditor .dropZone {
  height: 30px;
  width: 100%;
  display: inline-block;
  border: 1px dashed var(--bs-red);
  margin: 20px 0; }

.left-pane-actionbar .toolArea .ddMenu ul.k-widget.k-menu > li.k-item > .k-link {
  background-color: var(--bs-primary); }

.matrixContent .matrixSignaturePane .matrixSignatureHead .k-button:last-child {
  margin-right: var(--ims-spacing--20); }

.matrixContent .matrixSignaturePane .matrixSignatureHead .k-button {
  text-transform: capitalize;
  color: var(--bs-primary-dark);
  height: 2.25rem;
  padding: var(--ims-spacing--25) var(--ims-spacing--25);
  float: right; }

.matrixTree ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 0 10px; }

.matrixTree > ul {
  margin: 0 0 0 0; }

.matrixTree li {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.matrixTree input {
  width: auto;
  display: inline; }

.matrixTree label {
  width: auto;
  display: inline;
  float: none; }

.matrixDimension .dimensionName {
  font-weight: bold; }

.ims-survey-page.read-only {
  padding-top: var(--ims-spacing--25); }
  .ims-survey-page.read-only input:hover, .ims-survey-page.read-only textarea:hover {
    box-shadow: none; }

.form-action-menu {
  position: relative; }

.cards-container {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem; }

.cards-container.read-only .dragable-card, .cards-container.read-only .dragable-card * {
  cursor: default; }

.cards-container.read-only .card-content {
  margin-left: 0; }

.cards-container.read-only .drag-field-indicator {
  display: none; }

.dragable-card, .dragable-card * {
  cursor: grab; }
  .dragable-card:active, .dragable-card.sortable-chosen, .dragable-card *:active, .dragable-card *.sortable-chosen {
    cursor: grabbing; }

.dragable-card.sortable-chosen {
  border-color: var(--bs-primary-light); }
  .dragable-card.sortable-chosen .drag-field-indicator, .dragable-card.sortable-chosen .drag-field-indicator::before {
    background-image: radial-gradient(var(--bs-primary-light) 40%, transparent 40%); }

.dragable-card, .add-card {
  border: 0.0625rem solid var(--bs-dark-border-subtle);
  border-radius: 0.1875rem;
  margin-bottom: 0.9375rem; }

.dragable-card {
  background-color: var(--bs-white);
  position: relative; }
  .dragable-card .toolButtonArea {
    position: absolute;
    right: var(--ims-spacing--25);
    top: var(--ims-spacing--15); }
    .dragable-card .toolButtonArea .k-button, .dragable-card .toolButtonArea .k-button *:hover {
      cursor: pointer; }

.add-card {
  width: 100%;
  height: 3.125rem;
  font-size: var(--ims-font__size--400);
  color: var(--bs-on-primary);
  background-color: var(--bs-primary-light);
  border: none;
  box-sizing: border-box; }
  .add-card:hover {
    background-color: var(--bs-primary-lightest); }

.drag-field-indicator {
  position: absolute;
  left: var(--ims-spacing--20);
  top: calc(50% - 0.875rem);
  display: inline-block;
  width: 0.5rem;
  height: 1.75rem; }
  .drag-field-indicator::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%; }

.drag-field-indicator,
.drag-field-indicator::before {
  background-image: radial-gradient(var(--bs-dark-border-subtle) 40%, transparent 40%);
  background-size: 0.25rem 0.25rem;
  background-position: 0 100%;
  background-repeat: repeat; }

.card-content {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: var(--ims-spacing--25); }
  .card-content > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--ims-spacing--25) var(--ims-spacing--30) 0.9375rem var(--ims-spacing--30);
    flex-basis: 9.375rem; }

.container.analyticsWidget,
.container-fluid.analyticsWidget {
  margin-bottom: 30px; }

.analyticsWidget {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100%;
  width: 100%;
  /*************************************************/
  /**/ }
  .analyticsWidget #visitsPieChart {
    min-width: 350px; }
  .analyticsWidget .input-group {
    position: relative;
    display: table;
    border-collapse: separate; }
  .analyticsWidget .input-group-addon, .analyticsWidget .input-group-btn, .analyticsWidget .input-group .form-control {
    display: table-cell; }
  .analyticsWidget .input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0; }
  .analyticsWidget .form-control,
  .analyticsWidget .ims-input:not([type="file"]),
  .analyticsWidget .ims-select-box,
  .analyticsWidget .ims-select-box-multi,
  .analyticsWidget .input-textarea, .analyticsWidget select {
    display: block;
    width: 100%;
    padding: 0.23438rem 0.46875rem;
    font-size: var(--ims-font__size--60);
    line-height: 1.3;
    color: var(--bs-border-color);
    background-color: var(--bs-white);
    background-image: none;
    border: 1px solid var(--bs-dark-border-subtle); }
  .analyticsWidget .input-group-addon, .analyticsWidget .input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle; }
  .analyticsWidget .input-group-addon, .analyticsWidget .input-group-btn, .analyticsWidget .input-group .form-control {
    display: table-cell; }
  .analyticsWidget .input-group-addon {
    padding: 3px 6px;
    font-size: 0.625rem;
    font-weight: normal;
    line-height: 1;
    color: var(--bs-border-color);
    text-align: center;
    background-color: var(--bs-light-bg-subtle);
    border: 1px solid var(--bs-dark-border-subtle);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #cccccc; }
  .analyticsWidget .input-group-addon:last-child {
    border-left: 0; }
  .analyticsWidget .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .analyticsWidget .form-inline .form-control,
  .analyticsWidget .form-inline .k-dropdown,
  .analyticsWidget .form-inline .k-datepicker,
  .analyticsWidget .form-inline .k-datetimepicker,
  .analyticsWidget .form-inline .k-timepicker,
  .analyticsWidget .form-inline span.k-numerictextbox,
  .analyticsWidget .form-inline .k-multiselect,
  .analyticsWidget .form-inline .k-combobox,
  .analyticsWidget .form-inline input.k-textbox,
  .analyticsWidget .form-inline .input-textarea,
  .analyticsWidget .form-inline select {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .analyticsWidget label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px; }
  .analyticsWidget .form-inline label {
    padding-right: 5px; }
  .analyticsWidget .form-inline .k-multiselect input {
    width: auto !important; }
  .analyticsWidget .ims-panel ul:not(.k-tabstrip-items),
  .analyticsWidget ol:not(.k-tabstrip-items) {
    margin-top: 0;
    margin-bottom: 10px; }
  .analyticsWidget .ims-panel h3, .analyticsWidget .ims-panel h4 {
    margin-bottom: .75em; }
  .analyticsWidget .panel {
    background-color: var(--bs-white);
    box-sizing: border-box; }
  .analyticsWidget [class^="ims-icon-"]::before,
  .analyticsWidget [class*=" ims-icon-"]::before {
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    margin-right: 0.1875rem;
    text-align: center;
    font-variant: normal;
    text-transform: none; }
  .analyticsWidget .k-datepicker {
    width: 100%; }
  .analyticsWidget .k-header {
    background-color: var(--bs-primary-light); }
  .analyticsWidget .k-tabstrip {
    background-color: transparent !important; }
    .analyticsWidget .k-tabstrip > .k-content {
      padding-top: 0;
      overflow: inherit; }
    .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper {
      background-color: var(--bs-primary-light); }
      .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items {
        border-width: 0;
        padding: 0; }
        .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items > li,
        .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items > li.k-state-hover:hover {
          color: var(--bs-on-primary);
          background-color: var(--bs-primary-dark); }
        .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items .k-item {
          background-color: transparent; }
        .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items > li.k-item:hover.users {
          border-bottom-color: var(--bs-blue); }
        .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items > li.k-item:hover.bookings {
          border-bottom-color: var(--bs-green); }
        .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items > li.k-item:hover.views {
          border-bottom-color: var(--bs-orange); }
        .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items > li.k-item:hover.visits {
          border-bottom-color: var(--bs-purple); }
        .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items > li.k-state-active.users {
          background-color: var(--bs-blue);
          border-bottom-color: var(--bs-blue); }
        .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items > li.k-state-active.bookings {
          background-color: var(--bs-green);
          border-bottom-color: var(--bs-green); }
        .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items > li.k-state-active.views {
          background-color: var(--bs-orange);
          border-bottom-color: var(--bs-orange); }
        .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items > li.k-state-active.visits {
          background-color: var(--bs-purple);
          border-bottom-color: var(--bs-purple); }
        .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items .k-link {
          font-size: var(--ims-font__size--200);
          vertical-align: middle; }
          .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items .k-link .ims-icon:before, .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items .k-link .ims-icon-arrow-left:before, .analyticsWidget .k-tabstrip .k-tabstrip-items-wrapper > ul.k-tabstrip-items .k-link .ims-icon-arrow-right:before {
            font-size: 1.375rem;
            margin-right: var(--ims-spacing--25); }
  .analyticsWidget .tab-content .v-middle {
    line-height: 2.8;
    font-size: var(--ims-font__size--60); }
  .analyticsWidget .tab-content .ims-panel .ims-panel-body {
    max-height: 320px;
    overflow: auto;
    margin-bottom: 10px; }
  .analyticsWidget .tab-content .ims-tab-overview {
    background-color: var(--bs-dark-border-subtle);
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 0 -12px 3em -12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .analyticsWidget .tab-content .ims-tab-overview > div.ims-panel:not(:first-of-type) {
      border-left: 2px solid var(--bs-dark-border-subtle); }
    .analyticsWidget .tab-content .ims-tab-overview > div:not(:first-of-type) {
      border-color: var(--bs-dark-border-subtle); }
    .analyticsWidget .tab-content .ims-tab-overview .ims-panel-filter [class*='col-']:first-of-type {
      margin-left: -8px;
      padding-right: 0; }
    .analyticsWidget .tab-content .ims-tab-overview .ims-panel-filter [class*='col-']:last-of-type {
      padding-right: 0; }
  .analyticsWidget .boxShadow {
    box-shadow: 0 1px 2px 1px rgba(var(--bs-body-color-rgb), 0.08), 0 2px 2px rgba(var(--bs-body-color-rgb), 0.08); }
  .analyticsWidget .MatrixFilter > .row {
    margin-right: -12px;
    margin-left: -12px; }
    .analyticsWidget .MatrixFilter > .row:first-of-type {
      background-color: var(--bs-dark-border-subtle); }
  .analyticsWidget .MatrixFilter .filterHeader {
    padding-top: 0.46875rem;
    padding-bottom: 0.46875rem; }
    .analyticsWidget .MatrixFilter .filterHeader .form-group {
      margin-right: 30px; }
  .analyticsWidget .MatrixFilter .filterBTN {
    display: inline-block;
    margin-bottom: 0;
    padding: 0.23438rem 0.46875rem;
    line-height: 1.3; }
    .analyticsWidget .MatrixFilter .filterBTN:hover {
      cursor: pointer; }
    .analyticsWidget .MatrixFilter .filterBTN.active .ims-icon, .analyticsWidget .MatrixFilter .filterBTN.active .ims-icon-arrow-left, .analyticsWidget .MatrixFilter .filterBTN.active .ims-icon-arrow-right {
      color: var(--bs-primary); }
  .analyticsWidget .filterContent {
    background-color: rgba(63, 81, 181, 0.6);
    padding: 15px 15px 30px 15px; }
    .analyticsWidget .filterContent > .row {
      margin-bottom: 15px; }
  .analyticsWidget .ims-panel-filter {
    margin-bottom: var(--ims-spacing--25); }
    .analyticsWidget .ims-panel-filter label {
      color: var(--bs-border-color); }
  .analyticsWidget .ims-panel {
    border: none;
    border-radius: var(--ims-border__radius--0);
    padding: 0 50px; }
    .analyticsWidget .ims-panel .panel-heading {
      border-radius: var(--ims-border__radius--0);
      padding: var(--ims-spacing--25) var(--ims-spacing--25); }
      .analyticsWidget .ims-panel .panel-heading > span {
        display: inline-block;
        vertical-align: top;
        width: 59%; }
      .analyticsWidget .ims-panel .panel-heading > span:first-of-type {
        font-size: 1.5625rem;
        line-height: 1.875rem;
        width: 33%;
        text-align: right; }
      .analyticsWidget .ims-panel .panel-heading > span:last-of-type {
        margin-left: var(--ims-spacing--25); }
    .analyticsWidget .ims-panel .panel .panel-heading > span:first-of-type {
      font-size: 1.5625rem;
      line-height: 1.875rem;
      width: 33%;
      text-align: right;
      padding-top: 0px; }
    .analyticsWidget .ims-panel .panel .panel-heading > span {
      display: inline-block;
      vertical-align: top;
      width: 59%;
      padding-top: 5px; }
    .analyticsWidget .ims-panel .panel-heading.users {
      background-color: var(--bs-blue);
      color: var(--bs-white); }
    .analyticsWidget .ims-panel .panel-heading.bookings {
      background-color: var(--bs-green);
      color: var(--bs-white); }
    .analyticsWidget .ims-panel .panel-heading.views {
      background-color: var(--bs-orange);
      color: var(--bs-white); }
    .analyticsWidget .ims-panel .panel-heading.visits {
      background-color: var(--bs-purple);
      color: var(--bs-white); }
    .analyticsWidget .ims-panel .panel ul.list-group {
      margin: 0; }
      .analyticsWidget .ims-panel .panel ul.list-group .list-group-item {
        border: none; }
    .analyticsWidget .ims-panel ul .list-group-item > span {
      vertical-align: text-top;
      display: inline-block;
      width: 59%;
      padding-top: 5px; }
    .analyticsWidget .ims-panel ul .list-group-item > span:first-of-type {
      font-size: 1.5625rem;
      line-height: 1.875rem;
      margin-left: var(--ims-spacing--15);
      width: 33%;
      text-align: right;
      padding-top: 0px; }
    .analyticsWidget .ims-panel ul .list-group-item > span:last-of-type {
      margin-left: var(--ims-spacing--25); }
    .analyticsWidget .ims-panel ul li {
      background-color: var(--bs-white); }
    .analyticsWidget .ims-panel ul.listWithColorBorder li.color1 {
      border-left-color: var(--bs-orange); }
    .analyticsWidget .ims-panel ul.listWithColorBorder li.color2 {
      border-left-color: var(--bs-red); }
    .analyticsWidget .ims-panel ul.listWithColorBorder li.color3 {
      border-left-color: rgba(var(--bs-green-rgb), 0.8); }
    .analyticsWidget .ims-panel ul.listWithColorBorder li.color4 {
      border-left-color: rgba(var(--bs-blue-rgb), 0.8); }
    .analyticsWidget .ims-panel ul.listWithColorBorder li.color5 {
      border-left-color: var(--bs-indigo); }
    .analyticsWidget .ims-panel ul.listWithColorBorder li.color6 {
      border-left-color: rgba(var(--bs-green-rgb), 0.8); }
    .analyticsWidget .ims-panel ul.listWithColorBorder li.color7 {
      border-left-color: var(--bs-blue); }
    .analyticsWidget .ims-panel ul.listWithColorBorder li.color8 {
      border-left-color: rgba(var(--bs-red-rgb), 0.8); }
    .analyticsWidget .ims-panel ul.listWithColorBorder li.color9 {
      border-left-color: var(--bs-green); }
    .analyticsWidget .ims-panel ul.listWithColorBorder li.color10 {
      border-color: var(--bs-danger-hover); }
    .analyticsWidget .ims-panel .panel-footer {
      text-align: center;
      padding: 0;
      border: none;
      border-radius: var(--ims-border__radius--0);
      overflow: hidden; }
      .analyticsWidget .ims-panel .panel-footer [class^="ims-icon-"]:before, .analyticsWidget .ims-panel .panel-footer [class*=" ims-icon-"]:before {
        font-size: 6.25rem;
        margin: 0;
        margin-bottom: -2.1875rem;
        opacity: 0.1; }
    .analyticsWidget .ims-panel .ims-panel-bottom {
      padding-bottom: 15px; }
  .analyticsWidget .tab-content ul {
    padding: 0;
    margin: 0 10px 5px 10px; }
    .analyticsWidget .tab-content ul li {
      padding: 5px 0px; }
    .analyticsWidget .tab-content ul table.table td {
      padding: var(--ims-spacing--15) var(--ims-spacing--15);
      border: none; }
    .analyticsWidget .tab-content ul table.ims-ranking td.numeric-val {
      width: 0.625rem; }
    .analyticsWidget .tab-content ul table.ims-ranking td.ims-text-overflow-container {
      max-width: 6.25rem;
      overflow-x: hidden;
      text-overflow: ellipsis; }
    .analyticsWidget .tab-content ul.listWithColorBorder li {
      border-left: 6px solid;
      margin-bottom: 2px; }
  .analyticsWidget table.table td {
    padding: var(--ims-spacing--15) var(--ims-spacing--15);
    border: none; }
  .analyticsWidget .analytics-chart-buttons {
    padding: 15px 30px; }
    .analyticsWidget .analytics-chart-buttons a.button {
      color: var(--ims--colDark);
      text-decoration: none;
      padding: 3px 18px;
      border: 1px solid var(--bs-dark-border-subtle);
      height: 0.75rem;
      transition: background-color 0.2s ease;
      line-height: 1.2; }
      .analyticsWidget .analytics-chart-buttons a.button.active {
        background-color: var(--bs-dark-border-subtle); }

@media only screen and (max-width: 1200px) {
  .analyticsWidget .ims-panel {
    padding: 0 15px; } }

.ims-comp-base-app {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: inherit;
  outline: none;
  letter-spacing: inherit;
  text-decoration: none;
  cursor: inherit; }
  .ims-comp-base-app .sidebar {
    width: 264px;
    box-sizing: border-box;
    height: 100vh;
    flex-shrink: 0;
    border-right: 1px solid var(--color-border, #e5e5e5);
    transition: .2s ease-out;
    background-color: var(--bs-primary-dark);
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
    .ims-comp-base-app .sidebar .top {
      border-bottom: 1px solid var(--color-border, #e5e5e5); }
    .ims-comp-base-app .sidebar .bottom {
      z-index: 1;
      border-top: 1px solid var(--color-border, #e5e5e5);
      background-color: var(--bs-primary-dark, #fff); }
    .ims-comp-base-app .sidebar .nav-link:hover {
      background-color: var(--bs-primary) !important;
      color: var(--bs-on-primary) !important; }
    .ims-comp-base-app .sidebar .nav-link.router-link-active {
      background-color: var(--bs-primary) !important;
      color: var(--bs-on-primary) !important; }
    .ims-comp-base-app .sidebar .nav-item .nav-link:hover {
      background-color: var(--bs-primary) !important;
      color: var(--bs-on-primary) !important; }
    .ims-comp-base-app .sidebar .nav-item ul.nav .is-child {
      margin-left: 1.6rem; }
    .ims-comp-base-app .sidebar .ims-comp-session-timer {
      padding: 0rem 0.5rem;
      color: var(--bs-on-primary) !important; }
    .ims-comp-base-app .sidebar .ims-menu-splitter {
      margin-left: 1rem;
      margin-right: .8rem; }
    .ims-comp-base-app .sidebar .btn-minimal-secondary {
      color: var(--bs-on-primary) !important; }
      .ims-comp-base-app .sidebar .btn-minimal-secondary:hover {
        background-color: var(--bs-primary) !important;
        border-color: var(--bs-primary) !important; }
    .ims-comp-base-app .sidebar .collapsedNavigation .navbar #areaStickyNavigation {
      width: 4rem; }
    .ims-comp-base-app .sidebar .collapsedNavigation .navbar .standalone-item span.ims-icon, .ims-comp-base-app .sidebar .collapsedNavigation .navbar .standalone-item span.ims-icon-arrow-left, .ims-comp-base-app .sidebar .collapsedNavigation .navbar .standalone-item span.ims-icon-arrow-right {
      margin-left: var(--ims-spacing--40); }
    .ims-comp-base-app .sidebar.collapsedNavigation {
      width: 4rem; }
      .ims-comp-base-app .sidebar.collapsedNavigation .scroll_container--clipContainer {
        width: 4rem; }
      .ims-comp-base-app .sidebar.collapsedNavigation .sidebar--navContent {
        width: 4rem; }
      .ims-comp-base-app .sidebar.collapsedNavigation .bottom {
        width: 4rem; }
        .ims-comp-base-app .sidebar.collapsedNavigation .bottom .ims-comp-session-timer {
          display: none; }
    .ims-comp-base-app .sidebar .ims-comp-base-account-switcher .account_switcher--dropdownButtonContent--username {
      color: var(--bs-white); }
    .ims-comp-base-app .sidebar .ims-comp-base-account-switcher .ims-icon, .ims-comp-base-app .sidebar .ims-comp-base-account-switcher .ims-icon-arrow-left, .ims-comp-base-app .sidebar .ims-comp-base-account-switcher .ims-icon-arrow-right {
      color: var(--bs-white); }
    .ims-comp-base-app .sidebar.sidebar-neutral {
      background-color: #E0E2E4; }
      .ims-comp-base-app .sidebar.sidebar-neutral .ims-comp-session-timer {
        color: #7A858F !important; }
      .ims-comp-base-app .sidebar.sidebar-neutral .sidebar--navContent {
        background-color: #E0E2E4; }
      .ims-comp-base-app .sidebar.sidebar-neutral .bottom {
        background-color: #E0E2E4; }
      .ims-comp-base-app .sidebar.sidebar-neutral .nav-link {
        color: #1E2124 !important; }
        .ims-comp-base-app .sidebar.sidebar-neutral .nav-link:hover {
          background-color: var(--bs-surface-hover) !important;
          color: #1E2124 !important; }
        .ims-comp-base-app .sidebar.sidebar-neutral .nav-link.router-link-active {
          background-color: var(--bs-surface-hover) !important; }
      .ims-comp-base-app .sidebar.sidebar-neutral .ims-comp-base-account-switcher .account_switcher--dropdownButtonContent--username {
        color: var(--bs-body-color); }
      .ims-comp-base-app .sidebar.sidebar-neutral .ims-comp-base-account-switcher .ims-icon, .ims-comp-base-app .sidebar.sidebar-neutral .ims-comp-base-account-switcher .ims-icon-arrow-left, .ims-comp-base-app .sidebar.sidebar-neutral .ims-comp-base-account-switcher .ims-icon-arrow-right {
        color: var(--bs-body-color); }
    .ims-comp-base-app .sidebar.w-64px .top .ms-3 {
      transition: all .3s ease-in-out;
      margin-left: 0.4rem !important; }
    .ims-comp-base-app .sidebar.w-64px .nav-link {
      transition: all .3s ease-in-out;
      padding-left: var(--ims-spacing--60); }
  .ims-comp-base-app .sidebar--sidebarScrollContainer {
    flex: 1 1 auto;
    height: 100%;
    background-color: var(--bs-surface-high); }
  .ims-comp-base-app .scroll_container--clipContainer {
    overflow: hidden;
    position: relative; }
  .ims-comp-base-app .nav-link {
    color: var(--bs-on-primary) !important; }
  .ims-comp-base-app .navbar.navbar-light .nav-link {
    color: var(--bs-body-color) !important; }
  .ims-comp-base-app .main {
    color: var(--color-text, #333333);
    background-color: var(--bs-white);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow-y: auto; }
    .ims-comp-base-app .main .header {
      border-bottom: 1px solid var(--color-border, #e5e5e5);
      background-color: var(--bs-white, #fff);
      z-index: 3; }
    .ims-comp-base-app .main .content {
      background-color: var(--bs-white, #fff); }
  .ims-comp-base-app .scroll_container--scrollContainer {
    touch-action: pan-y; }
  .ims-comp-base-app .scroll_container--scrollContainer {
    max-height: inherit;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    scrollbar-width: none;
    overscroll-behavior: none;
    -ms-overflow-style: none; }
    .ims-comp-base-app .scroll_container--scrollContainer:hover {
      scrollbar-width: thin; }
    .ims-comp-base-app .scroll_container--scrollContainer::-webkit-scrollbar {
      width: 0px; }
    .ims-comp-base-app .scroll_container--scrollContainer:hover::-webkit-scrollbar {
      width: 7px; }
    .ims-comp-base-app .scroll_container--scrollContainer::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 5px; }
    .ims-comp-base-app .scroll_container--scrollContainer .sidebar--navContent {
      padding-right: 0.2rem; }
  .ims-comp-base-app .scroll_container--full {
    width: 100%;
    height: 100%; }
  .ims-comp-base-app .sidebar--navContent {
    background-color: var(--bs-primary-dark);
    position: relative;
    width: 264px;
    min-height: 100%;
    box-sizing: border-box;
    cursor: default;
    display: flex;
    flex-direction: column; }
  .ims-comp-base-app .ims-project-container-sidenav {
    min-height: 12rem; }

.ims-comp-base-content-editor .ProseMirror-widget #comment-toggle-icon {
  right: -1.3rem !important; }

.ims-comp-base-account-switcher .dropdown_button--button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 4px;
  height: 34px;
  overflow: hidden;
  max-width: 100%; }

.ims-comp-base-account-switcher .account_switcher--dropdownButtonContent {
  min-width: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 8px;
  gap: 8px; }

.ims-comp-base-account-switcher .account_switcher--dropdownButtonContent--username {
  font-size: 13px;
  line-height: 24px;
  letter-spacing: calc(-0.003px + var(--text-tracking-pos, 0) * 13px);
  font-weight: 500;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.ims-comp-base-account-switcher .nav-link {
  color: var(--bs-body-color) !important; }

.ims-default-container,
.container-wrapper {
  max-width: 1632px;
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  --bs-gutter-x: 3rem; }

.ims-comp-base-surface .image .ims-comp-base-image.brand.ratio > * {
  height: calc(100% - 0.6rem); }

.ims-comp-base-image > span.cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.ims-comp-base-image > span.contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.ims-comp-base-divider {
  border: 0;
  opacity: .25;
  --border-width: 1px;
  --border-color: var(--bs-border-color); }
  .ims-comp-base-divider.size-0 {
    --border-width: var(--ims-border__width--0); }
  .ims-comp-base-divider.size-1 {
    --border-width: var(--ims-border__width--1); }
  .ims-comp-base-divider.size-2 {
    --border-width: var(--ims-border__width--2); }
  .ims-comp-base-divider.size-3 {
    --border-width: var(--ims-border__width--3); }
  .ims-comp-base-divider.size-4 {
    --border-width: var(--ims-border__width--4); }
  .ims-comp-base-divider.size-5 {
    --border-width: var(--ims-border__width--5); }
  .ims-comp-base-divider.vertical {
    display: inline-block;
    border-left: var(--border-width) solid var(--border-color);
    height: 100%; }
  .ims-comp-base-divider.horizontal {
    display: block;
    border-top: var(--border-width) solid var(--border-color); }

.hstack .ims-comp-base-divider.vertical,
.d-flex.flex-row .ims-comp-base-divider.vertical {
  height: auto;
  align-self: stretch; }

.ims-comp-base-cover {
  display: flex;
  flex-direction: column;
  min-block-size: 50vh;
  padding: 0; }
  .ims-comp-base-cover > * {
    margin-block: 1rem; }
  .ims-comp-base-cover > :first-child:not(.main) {
    margin-block-start: 0; }
  .ims-comp-base-cover > :last-child:not(.main) {
    margin-block-end: 0; }
  .ims-comp-base-cover > .main {
    margin-block: auto; }

.ims-comp-base-card {
  --local-card--image-vertical__border-radius: var(--ims-card--image-vertical__border-radius);
  --local-card--image-horizontal__border-radius: var(--ims-card--image-horizontal__border-radius); }
  .ims-comp-base-card.ims-size-sm {
    --local-card--image-horizontal__border-radius: var(--ims-card--image-horizontal__border-radius-sm);
    --local-card--image-vertical__border-radius: var(--ims-card--image-vertical__border-radius-sm); }
  .ims-comp-base-card.ims-size-lg {
    --local-card--image-horizontal__border-radius: var(--ims-card--image-horizontal__border-radius-lg);
    --local-card--image-vertical__border-radius: var(--ims-card--image-vertical__border-radius-lg); }
  .ims-comp-base-card .image-flex {
    --local-image-width: 30%; }
    .ims-comp-base-card .image-flex.ims-image-width-10 {
      --local-image-width: 10%; }
    .ims-comp-base-card .image-flex.ims-image-width-20 {
      --local-image-width: 20%; }
    .ims-comp-base-card .image-flex.ims-image-width-25 {
      --local-image-width: 25%; }
    .ims-comp-base-card .image-flex.ims-image-width-30 {
      --local-image-width: 30%; }
    .ims-comp-base-card .image-flex.ims-image-width-50 {
      --local-image-width: 50%; }
    .ims-comp-base-card .image-flex.d-flex.horizontal > .brand {
      border-style: solid;
      border-width: 0 0 0 0.6rem; }
    .ims-comp-base-card .image-flex.d-flex.vertical > .brand {
      border-style: solid;
      border-width: 0.6rem 0 0 0; }
    .ims-comp-base-card .image-flex.d-flex.horizontal {
      width: var(--local-image-width) !important; }
    .ims-comp-base-card .image-flex.d-flex.horizontal > a > .image,
    .ims-comp-base-card .image-flex.d-flex.horizontal > .image {
      width: 100% !important; }
      .ims-comp-base-card .image-flex.d-flex.horizontal > a > .image > .brand,
      .ims-comp-base-card .image-flex.d-flex.horizontal > .image > .brand {
        border-style: solid;
        border-width: 0 0 0 0.6rem; }
      .ims-comp-base-card .image-flex.d-flex.horizontal > a > .image .ims-comp-base-image > span,
      .ims-comp-base-card .image-flex.d-flex.horizontal > .image .ims-comp-base-image > span {
        border-radius: var(--local-card--image-horizontal__border-radius); }
    .ims-comp-base-card .image-flex.d-flex.vertical > a > .image,
    .ims-comp-base-card .image-flex.d-flex.vertical > .image {
      width: auto !important; }
      .ims-comp-base-card .image-flex.d-flex.vertical > a > .image > .brand,
      .ims-comp-base-card .image-flex.d-flex.vertical > .image > .brand {
        border-style: solid;
        border-width: 0.6rem 0 0 0; }
      .ims-comp-base-card .image-flex.d-flex.vertical > a > .image .ims-comp-base-image > span,
      .ims-comp-base-card .image-flex.d-flex.vertical > .image .ims-comp-base-image > span {
        border-radius: var(--local-card--image-vertical__border-radius); }
    @media (max-width: 575px) {
      .ims-comp-base-card .image-flex.d-flex.horizontal.adaptive {
        width: auto !important; }
      .ims-comp-base-card .image-flex.d-flex.vertical.adaptive {
        width: var(--local-image-width) !important; }
      .ims-comp-base-card .image-flex.d-flex.horizontal.adaptive > a > .image,
      .ims-comp-base-card .image-flex.d-flex.horizontal.adaptive > .image {
        width: auto !important; }
        .ims-comp-base-card .image-flex.d-flex.horizontal.adaptive > a > .image > .brand,
        .ims-comp-base-card .image-flex.d-flex.horizontal.adaptive > .image > .brand {
          border-style: solid;
          border-width: 0.6rem 0 0 0; }
        .ims-comp-base-card .image-flex.d-flex.horizontal.adaptive > a > .image .ims-comp-base-image > span,
        .ims-comp-base-card .image-flex.d-flex.horizontal.adaptive > .image .ims-comp-base-image > span {
          border-radius: var(--ims-card--image-vertical__border-radius); }
      .ims-comp-base-card .image-flex.d-flex.vertical.adaptive > a > .image,
      .ims-comp-base-card .image-flex.d-flex.vertical.adaptive > .image {
        width: 100% !important; }
        .ims-comp-base-card .image-flex.d-flex.vertical.adaptive > a > .image > .brand,
        .ims-comp-base-card .image-flex.d-flex.vertical.adaptive > .image > .brand {
          border-style: solid;
          border-width: 0 0 0 0.6rem; }
        .ims-comp-base-card .image-flex.d-flex.vertical.adaptive > a > .image .ims-comp-base-image > span,
        .ims-comp-base-card .image-flex.d-flex.vertical.adaptive > .image .ims-comp-base-image > span {
          border-radius: var(--ims-card--image-horizontal__border-radius); }
      .ims-comp-base-card .image-flex.d-flex.horizontal.adaptive > .brand {
        border-style: solid;
        border-width: 0.6rem 0 0 0; }
      .ims-comp-base-card .image-flex.d-flex.vertical.adaptive > .brand {
        border-style: solid;
        border-width: 0 0 0 0.6rem; } }
    .ims-comp-base-card .image-flex.d-flex > .body .card-body span {
      text-wrap: balance; }

.ims-comp-base-panel.ims-rounded {
  border-radius: var(--ims-panel__border-radius); }
  .ims-comp-base-panel.ims-rounded.ims-size-sm {
    border-radius: var(--ims-panel__border-radius-sm); }
  .ims-comp-base-panel.ims-rounded.ims-size-lg {
    border-radius: var(--ims-panel__border-radius-lg); }
  .ims-comp-base-panel.ims-rounded.ims-size-xl {
    border-radius: var(--ims-panel__border-radius-xl); }

@media (max-width: 991px) {
  .ims-comp-base-panel.full-width-responsive {
    margin-left: -15px !important;
    margin-right: -15px  !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

.ims-comp-base-content-editor div[editor] {
  height: 100%; }

.ugc-page-header {
  background-color: var(--bs-primary) !important;
  color: var(--bs-on-primary) !important; }

.tiptap.ProseMirror {
  width: 100%;
  min-height: 100%;
  max-height: 100%; }
  .tiptap.ProseMirror[contenteditable="true"] {
    padding-bottom: 3rem !important; }
  .tiptap.ProseMirror [data-node-view-content] > * {
    width: 100%; }
  .tiptap.ProseMirror:focus-visible {
    outline: none; }
  .tiptap.ProseMirror .contentblock-rootblock > div > [data-node-view-content], .tiptap.ProseMirror div[data-type="rootblock"] > div > [data-node-view-content] {
    position: relative; }
    .tiptap.ProseMirror .contentblock-rootblock > div > [data-node-view-content] > *, .tiptap.ProseMirror div[data-type="rootblock"] > div > [data-node-view-content] > * {
      padding-top: var(--ims-spacing--60) !important;
      padding-bottom: var(--ims-spacing--60) !important; }
      .tiptap.ProseMirror .contentblock-rootblock > div > [data-node-view-content] > * p, .tiptap.ProseMirror div[data-type="rootblock"] > div > [data-node-view-content] > * p {
        font-size: var(--ims-font__size--200) !important; }
      .tiptap.ProseMirror .contentblock-rootblock > div > [data-node-view-content] > * .rootblock-overlay, .tiptap.ProseMirror div[data-type="rootblock"] > div > [data-node-view-content] > * .rootblock-overlay {
        margin-top: -2.4rem;
        margin-bottom: -2.4rem; }
    .tiptap.ProseMirror .contentblock-rootblock > div > [data-node-view-content]:has(> .contentblock-columncontainer) > * , .tiptap.ProseMirror div[data-type="rootblock"] > div > [data-node-view-content]:has(> .contentblock-columncontainer) > * {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important; }
      .tiptap.ProseMirror .contentblock-rootblock > div > [data-node-view-content]:has(> .contentblock-columncontainer) > *  .rootblock-overlay, .tiptap.ProseMirror div[data-type="rootblock"] > div > [data-node-view-content]:has(> .contentblock-columncontainer) > *  .rootblock-overlay {
        margin-top: 0;
        margin-bottom: 0; }
    .tiptap.ProseMirror .contentblock-rootblock > div > [data-node-view-content]:has(> .contentblock-heading) > *, .tiptap.ProseMirror div[data-type="rootblock"] > div > [data-node-view-content]:has(> .contentblock-heading) > * {
      padding-top: var(--ims-spacing--80) !important;
      padding-bottom: var(--ims-spacing--30) !important; }
      .tiptap.ProseMirror .contentblock-rootblock > div > [data-node-view-content]:has(> .contentblock-heading) > * .rootblock-overlay, .tiptap.ProseMirror div[data-type="rootblock"] > div > [data-node-view-content]:has(> .contentblock-heading) > * .rootblock-overlay {
        margin-top: -2.9rem;
        margin-bottom: -1.6rem; }
  .tiptap.ProseMirror .contentblock-rootblock > section, .tiptap.ProseMirror div[data-type="rootblock"] > section {
    padding-top: var(--ims-spacing--60) !important; }
  .tiptap.ProseMirror .contentblock-rootblock:has(> div > [data-node-view-content] > .contentblock-heading) > section, .tiptap.ProseMirror div[data-type="rootblock"]:has(> div > [data-node-view-content] > .contentblock-heading) > section {
    padding-top: var(--ims-spacing--80) !important; }
  .tiptap.ProseMirror .contentblock-rootblock:has(> div > [data-node-view-content] > .contentblock-horizontalrule) > section, .tiptap.ProseMirror div[data-type="rootblock"]:has(> div > [data-node-view-content] > .contentblock-horizontalrule) > section {
    padding-top: var(--ims-spacing--40) !important; }
  .tiptap.ProseMirror .contentblock-rootblock:hover > section, .tiptap.ProseMirror div[data-type="rootblock"]:hover > section {
    visibility: visible !important; }
  .tiptap.ProseMirror .contentblock-rootblock .show-bounds, .tiptap.ProseMirror div[data-type="rootblock"] .show-bounds {
    border-top: 1px dashed #999b9c;
    border-bottom: 1px dashed #999b9c;
    top: 0; }
  .tiptap.ProseMirror .contentblock-rootblock:last-child .contentblock-rootblock:last-child, .tiptap.ProseMirror div[data-type="rootblock"]:last-child .contentblock-rootblock:last-child {
    padding-bottom: var(--ims-spacing--0); }
  .tiptap.ProseMirror .contentblock-rootblock .contentblock-columncontainer .contentblock-rootblock, .tiptap.ProseMirror div[data-type="rootblock"] .contentblock-columncontainer .contentblock-rootblock {
    padding-bottom: var(--ims-spacing--0); }
  .tiptap.ProseMirror .contentblock-rootblock .rootblock-hover .ims-comp-base-dropdown[data-add-row-before] .dropdown-menu, .tiptap.ProseMirror .contentblock-rootblock .rootblock-hover .ims-comp-base-dropdown[data-add-row-after] .dropdown-menu, .tiptap.ProseMirror div[data-type="rootblock"] .rootblock-hover .ims-comp-base-dropdown[data-add-row-before] .dropdown-menu, .tiptap.ProseMirror div[data-type="rootblock"] .rootblock-hover .ims-comp-base-dropdown[data-add-row-after] .dropdown-menu {
    transform: translate(0px, 27px) !important; }
  .tiptap.ProseMirror[contenteditable="false"] .contentblock-rootblock > div > [data-node-view-content]:has(> .contentblock-columncontainer) > * , .tiptap.ProseMirror div[data-type="rootblock"] > div > [data-node-view-content]:has(> .contentblock-columncontainer) > * {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important; }
  .tiptap.ProseMirror .contentblock-initialblock {
    padding-top: var(--ims-spacing--60) !important;
    padding-bottom: var(--ims-spacing--60) !important; }
  .tiptap.ProseMirror .contentblock-paragraph {
    font-size: var(--ims-font__size--200) !important; }
  .tiptap.ProseMirror .contentblock-video .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0; }
    .tiptap.ProseMirror .contentblock-video .videoWrapper iframe, .tiptap.ProseMirror .contentblock-video .videoWrapper video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .tiptap.ProseMirror .contentblock-video .videoWrapper iframe.preventclick, .tiptap.ProseMirror .contentblock-video .videoWrapper video.preventclick {
        pointer-events: none; }
  .tiptap.ProseMirror .contentblock-image {
    max-width: 50%;
    margin-left: auto;
    margin-right: auto; }
  .tiptap.ProseMirror .contentblock-bulletlist {
    padding-left: 2rem;
    margin: 0; }
    .tiptap.ProseMirror .contentblock-bulletlist > li {
      list-style: disc; }
      .tiptap.ProseMirror .contentblock-bulletlist > li:nth-last-child(n+2) {
        padding-bottom: var(--ims-spacing--20); }
  .tiptap.ProseMirror .contentblock-orderedlist {
    margin: 0; }
    .tiptap.ProseMirror .contentblock-orderedlist > li {
      list-style: decimal; }
      .tiptap.ProseMirror .contentblock-orderedlist > li:nth-last-child(n+2) {
        padding-bottom: var(--ims-spacing--20); }
  .tiptap.ProseMirror .contentblock-codeblock pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    margin-bottom: var(--ims-spacing--60) !important; }
    .tiptap.ProseMirror .contentblock-codeblock pre code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem; }
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-comment,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-quote {
      color: #616161; }
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-variable,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-template-variable,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-attribute,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-tag,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-name,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-regexp,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-link,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-name,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-selector-id,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-selector-class {
      color: #F98181; }
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-number,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-meta,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-built_in,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-builtin-name,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-literal,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-type,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-params {
      color: #FBBC88; }
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-string,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-symbol,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-bullet {
      color: #B9F18D; }
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-title,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-section {
      color: #FAF594; }
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-keyword,
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-selector-tag {
      color: #70CFF8; }
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-emphasis {
      font-style: italic; }
    .tiptap.ProseMirror .contentblock-codeblock pre .hljs-strong {
      font-weight: 700; }
  .tiptap.ProseMirror .contentblock-codeblock [data-node-view-wrapper] {
    padding-top: var(--ims-spacing--60) !important; }
  .tiptap.ProseMirror .contentblock-columncontainer > .contentblock-column {
    padding-left: 0 !important;
    padding-right: 1.4rem !important; }
    .tiptap.ProseMirror .contentblock-columncontainer > .contentblock-column [data-node-view-content] {
      width: 100% !important; }
  .tiptap.ProseMirror .contentblock-columncontainer .contentblock-column ~ .contentblock-column {
    padding-left: 0.7rem !important;
    padding-right: 0.7rem !important; }
  .tiptap.ProseMirror .contentblock-columncontainer [class~='contentblock-column']:last-of-type {
    padding-left: 1.4rem !important;
    padding-right: 0 !important; }
  .tiptap.ProseMirror .contentblock-horizontalrule hr {
    border-color: rgba(var(--bs-black-rgb), 0.5);
    opacity: 1; }
  .tiptap.ProseMirror .contentblock-questionpool .contentblock-question {
    border-top-left-radius: var(--ims-panel__border-radius-sm);
    border-top-right-radius: var(--ims-panel__border-radius-sm);
    background-color: rgba(var(--bs-surface-rgb), 1); }
  .tiptap.ProseMirror .contentblock-questionpool .contentblock-question ~ .contentblock-question {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .tiptap.ProseMirror .contentblock-questionpool [class~='contentblock-question']:last-of-type {
    border-bottom-left-radius: var(--ims-panel__border-radius-sm);
    border-bottom-right-radius: var(--ims-panel__border-radius-sm); }
  .tiptap.ProseMirror .contentblock-questionpool > div > p.contentblock-paragraph {
    margin-top: 1rem;
    margin-bottom: 1.5rem; }
  .tiptap.ProseMirror .contentblock-questionpool .contentblock-question {
    border-width: var(--bs-border-width);
    border-color: var(--bs-border-color);
    border-style: var(--bs-border-style); }
  .tiptap.ProseMirror .contentblock-questionpool .contentblock-question + .contentblock-question {
    border-top-width: 0; }
  .tiptap.ProseMirror .contentblock-questionpool .contentblock-question:nth-last-child(n+2) {
    border-bottom-width: 0; }
    .tiptap.ProseMirror .contentblock-questionpool .contentblock-question:nth-last-child(n+2) > *:last-child {
      border-radius: 0;
      border-bottom-width: var(--ims-border__width--2);
      border-bottom-color: var(--bs-primary) !important;
      border-bottom-style: var(--bs-border-style); }
  .tiptap.ProseMirror .contentblock-question .border-surface {
    background-color: rgba(var(--bs-surface-rgb), 1); }
  .tiptap.ProseMirror .contentblock-question p.contentblock-paragraph {
    margin-top: var(--ims-spacing--30) !important;
    margin-bottom: var(--ims-spacing--60) !important; }
  .tiptap.ProseMirror .contentblock-question .draggable-answer {
    color: var(--bs-on-primary);
    background-color: var(--bs-primary); }
  .tiptap.ProseMirror .contentblock-answer {
    font-size: var(--ims-font__size--200) !important; }
    .tiptap.ProseMirror .contentblock-answer label {
      font-weight: normal;
      font-size: var(--ims-font__size--200) !important; }
    .tiptap.ProseMirror .contentblock-answer .contentblock-paragraph {
      margin-top: unset;
      margin-bottom: unset; }
  .tiptap.ProseMirror .comment-resolved {
    opacity: 0.5; }
  .tiptap.ProseMirror .contentblock-table {
    margin-top: var(--ims-spacing--60) !important;
    margin-bottom: var(--ims-spacing--60) !important; }
  .tiptap.ProseMirror div:has(> table) {
    overflow-y: auto; }
  .tiptap.ProseMirror table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden; }
    .tiptap.ProseMirror table p {
      margin: 0; }
  .tiptap.ProseMirror td, .tiptap.ProseMirror th {
    min-width: 1em;
    border: 1px solid #ced4da;
    padding: var(--ims-spacing--20) var(--ims-spacing--20);
    vertical-align: top;
    box-sizing: border-box;
    position: relative; }
    .tiptap.ProseMirror td > *, .tiptap.ProseMirror th > * {
      margin-bottom: 0; }
  .tiptap.ProseMirror td {
    background-color: rgba(var(--bs-surface-rgb), 1); }
  .tiptap.ProseMirror th {
    font-size: 1rem;
    color: var(--ims-btn-primary__font-color);
    background-color: var(--ims-btn-primary__background); }
  .tiptap.ProseMirror .selectedCell:after {
    z-index: 2;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(200, 200, 255, 0.4);
    margin: .3rem;
    pointer-events: none; }
  .tiptap.ProseMirror .column-resize-handle {
    position: absolute;
    right: -2px;
    top: 0;
    bottom: -2px;
    width: 4px;
    background-color: #adf;
    cursor: col-resize; }
  .tiptap.ProseMirror .formulate-input, .tiptap.ProseMirror label, .tiptap.ProseMirror .formulate-input-label {
    font-size: var(--ims-font__size--200) !important; }

.base-editor-menu {
  border: 0.0625rem solid var(--bs-border-color);
  border-radius: var(--ims-form-input__border-radius); }
  .base-editor-menu > *:not(:last-child) {
    border-right: 0.0625rem solid var(--bs-border-color); }
  .base-editor-menu button {
    background-color: var(--ims-btn-light__background); }
    .base-editor-menu button:hover {
      background-color: var(--ims-btn-light__background--hovered); }
    .base-editor-menu button.active {
      background-color: var(--bs-gray-500);
      color: var(--bs-light); }

.ugc-draggable-page:hover {
  background-color: var(--bs-white) !important;
  border-radius: var(--ims-button__border-radius); }

.ugc-draggable-page.active {
  background-color: var(--bs-white) !important;
  border-radius: var(--ims-button__border-radius); }

.ugc-draggable-page .line-clamp-1 {
  max-width: 10rem; }

#review-comments-comment-status-toggle.ims-comp-base-button.text-success {
  background-color: var(--ims-btn-light__background); }

.ims-comp-base-page-footer.ims-footer {
  background-color: var(--ims-footer__main-row__bg-color);
  background-color: var(--ims-footer__main-row__bg-color); }
  .ims-comp-base-page-footer.ims-footer hr {
    color: var(--bs-dark-border-subtle); }
  .ims-comp-base-page-footer.ims-footer .ims-footer-links a.ims-footer-item {
    color: var(--ims-comp-footer-link-color) !important; }
    .ims-comp-base-page-footer.ims-footer .ims-footer-links a.ims-footer-item:hover {
      color: var(--ims-comp-footer-link-color-hover) !important; }
  .ims-comp-base-page-footer.ims-footer a, .ims-comp-base-page-footer.ims-footer a:not([href]):not([tabindex]) {
    color: var(--ims-comp-footer-link-color); }
    .ims-comp-base-page-footer.ims-footer a:hover, .ims-comp-base-page-footer.ims-footer a:not([href]):not([tabindex]):hover {
      color: var(--ims-comp-footer-link-color-hover); }
  .ims-comp-base-page-footer.ims-footer .dropdown-menu.show {
    background-color: var(--ims-comp-footer-dropdown-bg); }
    .ims-comp-base-page-footer.ims-footer .dropdown-menu.show .dropdown-item .nav-link {
      color: var(--ims-comp-footer-dropdown-link-item-color); }
    .ims-comp-base-page-footer.ims-footer .dropdown-menu.show .dropdown-item:hover, .ims-comp-base-page-footer.ims-footer .dropdown-menu.show .dropdown-item.mobile-hover {
      color: var(--ims-comp-footer-link-color-hover);
      background-color: var(--ims-comp-footer-dropdown-item-bg-hover); }
  .ims-comp-base-page-footer.ims-footer .dropdown-menu.show .dropdown-item:hover .nav-link, .ims-comp-base-page-footer.ims-footer .dropdown-menu.show .dropdown-item.mobile-hover .nav-link {
    color: var(--ims-comp-footer-dropdown-link-item-color-hover); }
  .ims-comp-base-page-footer.ims-footer .ims-share {
    visibility: visible;
    pointer-events: none; }
  .ims-comp-base-page-footer.ims-footer .ims-footer-logo-container {
    background-color: RGBA(var(--bs-surface-highest-rgb), var(--bs-bg-opacity, 1)) !important; }
  .ims-comp-base-page-footer.ims-footer .ims-footer-customer-name {
    color: var(--ims-comp-footer-info-color); }

.ims-comp-base-page-footer .ims-footer-logo-container {
  background-color: var(--bs-border-color); }
  .ims-comp-base-page-footer .ims-footer-logo-container a {
    color: var(--ims-comp-footer-link-color) !important; }
  .ims-comp-base-page-footer .ims-footer-logo-container img.img-responsive {
    width: 3.75rem;
    height: 2.5rem;
    margin-left: auto;
    margin-right: auto; }

.ims-comp-base-page-footer .ims-footer .ims-share {
  visibility: visible;
  pointer-events: none; }

@keyframes navTransition {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes navTransitionBack {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ims-comp-base-page-header #pageHeaderMobile {
  display: none; }

.ims-comp-base-page-header .ribbon-container-topBar {
  background-color: var(--ims-header__top-row__bg-color); }

.ims-comp-base-page-header .headerTopBar {
  background-color: var(--ims-header__top-row__bg-color); }

.ims-comp-base-page-header .headerBottomBar {
  background-color: var(--ims-header__second-row__bg-color); }

.ims-comp-base-page-header #pageHeaderDesktop {
  z-index: 1 !important;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
  background-color: var(--ims-header__top-row__bg-color); }
  .ims-comp-base-page-header #pageHeaderDesktop #ims-headerTopBar .nav-link .ims-icon, .ims-comp-base-page-header #pageHeaderDesktop #ims-headerTopBar .nav-link .ims-icon-arrow-left, .ims-comp-base-page-header #pageHeaderDesktop #ims-headerTopBar .nav-link .ims-icon-arrow-right {
    color: var(--bs-body-color); }
  .ims-comp-base-page-header #pageHeaderDesktop .headerTopBar .container-wrapper {
    min-height: 76px; }
  .ims-comp-base-page-header #pageHeaderDesktop.multiRowHeader .headerBottomBar {
    display: block; }
  .ims-comp-base-page-header #pageHeaderDesktop.multiRowHeader .headerTopBar #ims-mainNav {
    display: none; }
  .ims-comp-base-page-header #pageHeaderDesktop.multiRowHeader.scrolled .headerTopBar #ims-mainNav {
    display: block; }
  .ims-comp-base-page-header #pageHeaderDesktop.multiRowHeader.scrolled .headerBottomBar {
    display: none; }

.ims-comp-base-page-header .ribbon-container-topBar .dropdown-item:hover > a {
  color: var(--bs-white); }

.ims-comp-base-page-header #pageHeaderMobile {
  position: fixed;
  width: 100%;
  top: 0; }
  .ims-comp-base-page-header #pageHeaderMobile .navbar-collapse {
    border-top: 1px solid var(--bs-dark-border-subtle);
    position: absolute;
    top: var(--ims-header-mobile-top-spacing);
    left: 105%;
    padding-left: -0.9375rem;
    padding-bottom: 0.9375rem;
    width: 0%;
    transition-duration: 0.4s ease-in-out;
    display: block;
    overflow: auto;
    overflow-x: hidden;
    background-color: var(--ims-header-mobile__bg-color); }
  .ims-comp-base-page-header #pageHeaderMobile .navbar-collapse.show {
    left: 0vw;
    width: 100vw; }
  .ims-comp-base-page-header #pageHeaderMobile .nav-item a.nav-link:hover {
    color: var(--bs-white);
    background-color: var(--bs-primary); }
  .ims-comp-base-page-header #pageHeaderMobile li.nav-item {
    font-weight: bold !important; }
    .ims-comp-base-page-header #pageHeaderMobile li.nav-item .dropdown:hover {
      background-color: var(--bs-primary); }
    .ims-comp-base-page-header #pageHeaderMobile li.nav-item.dropdown .dropdown-item .nav-link:hover {
      background-color: transparent !important; }
  .ims-comp-base-page-header #pageHeaderMobile .navbar {
    display: flex;
    width: 100%; }
    .ims-comp-base-page-header #pageHeaderMobile .navbar .navbar-toggler {
      order: 1; }
  .ims-comp-base-page-header #pageHeaderMobile #ims-headerMenuBar ul {
    width: 100%; }
  .ims-comp-base-page-header #pageHeaderMobile .ribbon-container-topBar button span {
    color: var(--ims-header-nav-bar-toggler__color); }
  .ims-comp-base-page-header #pageHeaderMobile .collapse .nav-item a {
    display: flex; }
    .ims-comp-base-page-header #pageHeaderMobile .collapse .nav-item a span.ims-icon, .ims-comp-base-page-header #pageHeaderMobile .collapse .nav-item a span.ims-icon-arrow-left, .ims-comp-base-page-header #pageHeaderMobile .collapse .nav-item a span.ims-icon-arrow-right {
      display: inline-block;
      width: 1rem; }
    .ims-comp-base-page-header #pageHeaderMobile .collapse .nav-item a span {
      width: 100%;
      display: inline-block; }
  .ims-comp-base-page-header #pageHeaderMobile .collapse .dropdown-item a {
    width: 100%;
    display: inline-flex;
    flex-grow: 1; }
  .ims-comp-base-page-header #pageHeaderMobile .collapse .dropdown-item span.ims-icon-submenu-arrow {
    display: none;
    width: 1rem; }
  .ims-comp-base-page-header #pageHeaderMobile .collapse .dropdown-item span:not(.ims-icon):not(.ims-icon-arrow-left):not(.ims-icon-arrow-right) {
    display: inline-block; }

.ims-comp-base-page-header #mobile-header-collapse-menu,
.ims-comp-base-page-header #mobile-header-collapse-menu-user {
  border-top: 1px solid var(--bs-dark-border-subtle);
  width: 100%;
  height: calc(100vh - (var(--ims-header-mobile-height-calc)));
  margin-top: var(--ims-spacing--30);
  transition: all 0.4s ease;
  overflow: auto;
  overflow-x: hidden;
  margin-bottom: var(--ims-spacing--60); }
  .ims-comp-base-page-header #mobile-header-collapse-menu .navbar,
  .ims-comp-base-page-header #mobile-header-collapse-menu-user .navbar {
    padding: 0; }
  .ims-comp-base-page-header #mobile-header-collapse-menu .nav-link .ims-icon, .ims-comp-base-page-header #mobile-header-collapse-menu .nav-link .ims-icon-arrow-left, .ims-comp-base-page-header #mobile-header-collapse-menu .nav-link .ims-icon-arrow-right,
  .ims-comp-base-page-header #mobile-header-collapse-menu-user .nav-link .ims-icon,
  .ims-comp-base-page-header #mobile-header-collapse-menu-user .nav-link .ims-icon-arrow-left,
  .ims-comp-base-page-header #mobile-header-collapse-menu-user .nav-link .ims-icon-arrow-right {
    margin-right: 0rem; }
  .ims-comp-base-page-header #mobile-header-collapse-menu .nav-item.dropdown ul li.dropdown-item .nav-link,
  .ims-comp-base-page-header #mobile-header-collapse-menu-user .nav-item.dropdown ul li.dropdown-item .nav-link {
    padding-left: var(--ims-spacing--75); }
  .ims-comp-base-page-header #mobile-header-collapse-menu #ims-mainNav .nav-item > .nav-link,
  .ims-comp-base-page-header #mobile-header-collapse-menu-user #ims-mainNav .nav-item > .nav-link {
    margin-right: 0; }
  .ims-comp-base-page-header #mobile-header-collapse-menu .collapse-container .nav-link,
  .ims-comp-base-page-header #mobile-header-collapse-menu-user .collapse-container .nav-link {
    font-weight: bold !important; }
  .ims-comp-base-page-header #mobile-header-collapse-menu .nav-item > .nav-link,
  .ims-comp-base-page-header #mobile-header-collapse-menu-user .nav-item > .nav-link {
    padding: 0.5125rem 0rem 0.5125rem 1.48rem; }
  .ims-comp-base-page-header #mobile-header-collapse-menu .router-link-active,
  .ims-comp-base-page-header #mobile-header-collapse-menu-user .router-link-active {
    color: var(--bs-primary); }

.ims-comp-base-page-header.sticky-header #pageHeaderDesktop {
  position: fixed; }

.ims-comp-base-page-header.sticky-header.sticky-navigation.sticked {
  margin-bottom: 5rem; }
  .ims-comp-base-page-header.sticky-header.sticky-navigation.sticked #ims-logo {
    display: none; }
  .ims-comp-base-page-header.sticky-header.sticky-navigation.sticked #ims-headerMenuBar {
    position: fixed;
    z-index: 1;
    margin-top: -4.375rem;
    animation: navTransition 1s;
    width: 60% !important; }
  .ims-comp-base-page-header.sticky-header.sticky-navigation.sticked .d-flex.align-items-center.justify-content-between #ims-headerMenuBar {
    margin-top: 0rem;
    animation: navTransition 1s; }
  .ims-comp-base-page-header.sticky-header.sticky-navigation.sticked .d-flex.align-items-center.justify-content-between .navbar {
    padding: var(--ims-spacing--20) var(--ims-spacing--0) !important; }

.ims-comp-base-page-header.sticky-header.sticky-navigation:not(.sticked) #ims-headerMenuBar {
  animation: navTransitionBack 0.5s; }

@media (max-width: 575px) {
  #pageHeaderMobile .navbar-collapse.show {
    left: 0vw !important;
    width: 100vw !important; } }

@media (max-width: 991px) {
  #pageHeaderMobile {
    display: block !important;
    position: relative;
    z-index: 3; }
    #pageHeaderMobile .navbar {
      padding: var(--ims-spacing--20) var(--ims-spacing--0); }
    #pageHeaderMobile #ims-mainNav {
      padding: var(--ims-spacing--60) var(--ims-spacing--0); }
  #pageHeaderDesktop {
    display: none; } }

.ims-comp-base-badge {
  border-radius: var(--ims-badge__border-radius);
  font-weight: 500; }

.badge-row {
  display: flex;
  flex-wrap: wrap; }

.ims-badge {
  border-radius: var(--ims-badge__border-radius);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0.15625rem 0.3125rem 0.15625rem 0; }
  .ims-badge .badge-bg-filter {
    display: inline-block;
    border-radius: var(--ims-badge__border-radius);
    white-space: nowrap;
    background-color: rgba(var(--bs-white-rgb), 0.25); }
  .ims-badge .ims-icon, .ims-badge .ims-icon-arrow-left, .ims-badge .ims-icon-arrow-right {
    border-radius: var(--ims-badge__border-radius);
    padding: 0.0625rem var(--ims-spacing--10);
    margin: 0;
    line-height: normal;
    font-size: var(--ims-font__size--100);
    color: var(--bs-light); }
  .ims-badge .badge-title {
    font-size: var(--ims-font__size--80);
    font-style: italic;
    padding: 0.0625rem var(--ims-spacing--25) 0.0625rem 0.3125rem;
    margin: 0;
    vertical-align: baseline;
    white-space: nowrap;
    color: var(--bs-light); }

.ims-hint-label {
  font-size: var(--ims-font__size--80);
  padding: 0.0625rem var(--ims-spacing--15);
  margin-right: 0.3125rem;
  border-radius: var(--ims-badge__border-radius);
  color: var(--bs-light) !important; }
  .ims-hint-label.primary-bg {
    background-color: var(--bs-primary); }
  .ims-hint-label.warning-bg {
    background-color: var(--bs-warning); }
  .ims-hint-label.success-bg {
    background-color: var(--bs-success); }
  .ims-hint-label.danger-bg {
    background-color: var(--bs-danger); }
  .ims-hint-label.neutral-bg {
    background-color: var(--bs-gray); }

.badge {
  padding: var(--ims-spacing--10) var(--ims-spacing--10) !important; }
  .badge.badge-sm {
    padding: var(--ims-spacing--10) var(--ims-spacing--10) !important; }
  .badge.badge-md {
    padding: var(--ims-spacing--15) var(--ims-spacing--15) !important;
    font-size: 13px; }
  .badge.badge-lg {
    padding: var(--ims-spacing--25) var(--ims-spacing--25) !important;
    font-size: 14px; }
  .badge.read-only {
    border: 1px solid var(--bs-border-color-subtle) !important;
    background-color: RGBA(var(--bs-gray-500-rgb), 0.1) !important;
    color: var(--bs-body-color) !important; }

.personPageWidget .badge {
  margin-bottom: var(--ims-spacing--40); }

/*** start badge styling in backend multiselect ***/
.k-multiselect .badge, .k-list .badge {
  padding-top: var(--ims-spacing--20);
  padding-bottom: var(--ims-spacing--20);
  font-size: var(--ims-font__size--60);
  border-radius: var(--ims-badge__border-radius); }

.k-multiselect .k-multiselect-wrap .k-reset .k-button, .k-list .k-multiselect-wrap .k-reset .k-button {
  background-color: var(--bs-surface); }
  .k-multiselect .k-multiselect-wrap .k-reset .k-button:hover, .k-list .k-multiselect-wrap .k-reset .k-button:hover {
    background-color: var(--bs-surface); }

#BadgeMultiSelect_taglist .k-button {
  padding-left: var(--ims-spacing--0); }

.ims-comp-base-button.btn.btn-primary, .ims-comp-base-button.btn.btn-secondary, .ims-comp-base-button.btn.btn-outline-primary, .ims-comp-base-button.btn.btn-outline-secondary, .ims-comp-base-button.btn.btn-minimal-primary, .ims-comp-base-button.btn.btn-tertiary, .ims-comp-base-button.btn.btn-outline-tertiary, .ims-comp-base-button.btn.btn-light, .ims-comp-base-button.btn.btn-outline-light, .ims-comp-base-button.btn.btn-dark, .ims-comp-base-button.btn.btn-outline-dark, .ims-comp-base-button.btn.btn-success, .ims-comp-base-button.btn.btn-outline-success, .ims-comp-base-button.btn.btn-warning, .ims-comp-base-button.btn.btn-outline-warning, .ims-comp-base-button.btn.btn-danger, .ims-comp-base-button.btn.btn-outline-danger, .ims-comp-base-button.btn.btn-info, .ims-comp-base-button.btn.btn-outline-info {
  border-radius: var(--ims-button__border-radius); }

@-moz-document url-prefix() {
  .ims-comp-base-button.btn.btn-sm.rounded-circle {
    --bs-btn-padding-x: var(--ims-spacing--20);
    --bs-btn-padding-y: var(--ims-spacing--0);
    max-height: 2.2rem; } }

.ims-comp-base-button.btn.rounded-circle {
  --bs-btn-padding-x: var(--ims-spacing--15);
  --bs-btn-padding-y: var(--ims-spacing--15); }

.ims-comp-base-button.btn.btn-primary {
  color: var(--ims-btn-primary__font-color);
  background-color: var(--ims-btn-primary__background);
  border-color: var(--ims-btn-primary__border-color); }
  .ims-comp-base-button.btn.btn-primary:hover {
    color: var(--ims-btn-primary__font-color--hovered);
    background-color: var(--ims-btn-primary__background--hovered);
    border-color: var(--ims-btn-primary__border-color--hovered); }
  .ims-comp-base-button.btn.btn-primary.active {
    background-color: var(--ims-btn-primary__background--active); }

.ims-comp-base-button.btn.btn-outline-primary {
  color: var(--ims-btn-primary-subtle__font-color);
  background-color: transparent;
  border-color: var(--ims-btn-primary-subtle__border-color); }
  .ims-comp-base-button.btn.btn-outline-primary:hover {
    color: var(--ims-btn-primary-subtle__font-color--hovered);
    background-color: var(--ims-btn-primary-subtle__background--hovered);
    border-color: var(--ims-btn-primary-subtle__border-color--hovered); }
  .ims-comp-base-button.btn.btn-outline-primary.active {
    color: var(--bs-btn-active-color);
    background-color: var(--ims-btn-primary-subtle__background--active); }

.ims-comp-base-button.btn.btn-secondary {
  color: var(--ims-btn-secondary__font-color);
  background-color: var(--ims-btn-secondary__background);
  border-color: var(--ims-btn-secondary__border-color); }
  .ims-comp-base-button.btn.btn-secondary:hover {
    color: var(--ims-btn-secondary__font-color--hovered);
    background-color: var(--ims-btn-secondary__background--hovered);
    border-color: var(--ims-btn-secondary__border-color--hovered); }
  .ims-comp-base-button.btn.btn-secondary.active {
    background-color: var(--ims-btn-secondary__background--active); }

.ims-comp-base-button.btn.btn-outline-secondary {
  color: var(--ims-btn-secondary-subtle__font-color);
  background-color: transparent;
  border-color: var(--ims-btn-secondary__border-color); }
  .ims-comp-base-button.btn.btn-outline-secondary:hover {
    color: var(--ims-btn-secondary-subtle__font-color--hovered);
    background-color: var(--ims-btn-secondary__background--hovered);
    border-color: var(--ims-btn-secondary__border-color--hovered); }
  .ims-comp-base-button.btn.btn-outline-secondary.active {
    background-color: var(--ims-btn-secondary__background--active); }

.ims-comp-base-button.btn.btn-tertiary {
  color: var(--ims-btn-tertiary__font-color);
  background-color: var(--ims-btn-tertiary__background);
  border-color: var(--ims-btn-tertiary__border-color); }
  .ims-comp-base-button.btn.btn-tertiary:hover {
    color: var(--ims-btn-tertiary__font-color--hovered);
    background-color: var(--ims-btn-tertiary__background--hovered);
    border-color: var(--ims-btn-tertiary__border-color--hovered); }
  .ims-comp-base-button.btn.btn-tertiary.active {
    background-color: var(--ims-btn-tertiary__background--active); }

.ims-comp-base-button.btn.btn-light {
  color: var(--ims-btn-light__font-color);
  background-color: var(--ims-btn-light__background);
  border: none; }
  .ims-comp-base-button.btn.btn-light:hover {
    background-color: var(--ims-btn-light__background--hovered); }
  .ims-comp-base-button.btn.btn-light.active {
    background-color: var(--ims-btn-light__background--active); }

.ims-comp-base-button.btn.btn-minimal {
  color: var(--ims-btn-light__font-color);
  background-color: transparent;
  border: none; }
  .ims-comp-base-button.btn.btn-minimal:hover {
    background-color: var(--ims-btn-light__background); }
  .ims-comp-base-button.btn.btn-minimal.active {
    background-color: var(--ims-btn-light__background); }

.ims-comp-base-button.btn.normalize-numbers {
  font-variant-numeric: lining-nums; }

.catalogue-search-container button.cancel {
  border-top-right-radius: var(--ims-border__radius--0) !important;
  border-bottom-right-radius: var(--ims-border__radius--0) !important; }

.catalogue-search-container button:last-child {
  border-top-right-radius: var(--ims-form-input__border-radius) !important;
  border-bottom-right-radius: var(--ims-form-input__border-radius) !important; }

button {
  color: var(--ims-btn-light__font-color);
  background-color: var(--ims-btn-light__background);
  border: none; }

.k-scheduler-toolbar .k-button,
.k-scheduler-footer .k-button {
  color: var(--ims-btn-secondary__font-color);
  background-color: var(--ims-btn-secondary__background);
  border-color: var(--ims-btn-secondary__border-color);
  border: none; }
  .k-scheduler-toolbar .k-button:hover,
  .k-scheduler-footer .k-button:hover {
    color: var(--ims-btn-secondary__font-color--hovered);
    background-color: var(--ims-btn-secondary__background--hovered);
    border-color: var(--ims-btn-secondary__border-color--hovered); }
  .k-scheduler-toolbar .k-button.active, .k-scheduler-toolbar .k-button.k-state-selected, .k-scheduler-toolbar .k-button:focus,
  .k-scheduler-footer .k-button.active,
  .k-scheduler-footer .k-button.k-state-selected,
  .k-scheduler-footer .k-button:focus {
    color: var(--ims-btn-secondary__font-color--hovered);
    background-color: var(--ims-btn-secondary__background--hovered);
    border-color: var(--ims-btn-secondary__border-color--hovered); }
  .k-scheduler-toolbar .k-button.k-state-selected:hover,
  .k-scheduler-footer .k-button.k-state-selected:hover {
    color: var(--ims-btn-secondary__font-color--hovered);
    background-color: var(--ims-btn-secondary__background--hovered);
    border-color: var(--ims-btn-secondary__border-color--hovered); }
  .k-scheduler-toolbar .k-button.k-state-active,
  .k-scheduler-footer .k-button.k-state-active {
    color: var(--ims-btn-secondary__font-color--hovered);
    background-color: var(--ims-btn-secondary__background--hovered);
    border-color: var(--ims-btn-secondary__border-color--hovered); }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.k-window-content .k-button:hover {
  color: var(--ims-btn-primary__font-color) !important; }

.k-window-content .k-primary {
  color: var(--ims-btn-primary__font-color) !important;
  background-color: var(--bs-primary); }
  .k-window-content .k-primary:hover {
    border-color: var(--bs-primary); }

.ims-btn-container {
  border-radius: var(--ims-button__border-radius);
  border: 3px solid transparent;
  border-width: 3px !important; }
  .ims-btn-container.selected {
    border: 3px solid var(--bs-danger); }

.ims-comp-base-calendar .k-widget {
  border-radius: var(--ims-panel__border-radius) !important;
  background-color: rgba(var(--bs-surface-rgb), 1) !important;
  border-color: transparent !important; }

.ims-comp-base-calendar .k-calendar .k-header, .ims-comp-base-calendar .k-scheduler .k-header {
  background-color: rgba(var(--bs-surface-rgb), 1) !important; }

.ims-comp-base-calendar .k-calendar-view {
  width: 100% !important; }

.k-calendar .k-header,
.k-scheduler .k-header {
  background-color: rgba(var(--bs-surface-rgb), 1) !important; }

.ims-comp-base-carousel .carousel-control-prev, .ims-comp-base-carousel .carousel-control-next {
  background-color: transparent !important; }

@media (max-width: 991px) {
  .ims-widget-teaser .ims-comp-base-carousel.carousel .carousel-caption {
    transition: all 0.5s;
    width: 80% !important; }
  .ims-widget-teaser .ims-comp-base-carousel.carousel h3 {
    font-size: var(--ims-font__size--400); } }

@media (max-width: 767px) {
  .ims-widget-teaser .ims-comp-base-carousel.carousel .carousel-caption {
    transition: all 0.5s;
    text-align: center !important;
    border-radius: var(--ims-border__radius--300);
    width: 100% !important;
    height: 100% !important;
    left: 0vw !important;
    bottom: 0vh !important;
    margin-top: 0.25rem;
    border-radius: 0 !important; }
    .ims-widget-teaser .ims-comp-base-carousel.carousel .carousel-caption h3 {
      font-size: var(--ims-font__size--300); }
    .ims-widget-teaser .ims-comp-base-carousel.carousel .carousel-caption .ims-primary-button {
      margin-left: 0 !important;
      font-size: 0.75rem !important;
      margin-top: 0.2rem !important; }
    .ims-widget-teaser .ims-comp-base-carousel.carousel .carousel-caption p {
      max-width: 45rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
      font-size: 0.875rem !important; } }

.ims-comp-base-dropdown.dropdown button {
  display: flex;
  align-items: center !important; }
  .ims-comp-base-dropdown.dropdown button .ims-icon, .ims-comp-base-dropdown.dropdown button .ims-icon-arrow-left, .ims-comp-base-dropdown.dropdown button .ims-icon-arrow-right {
    margin-left: auto !important; }

.ims-comp-base-dropdown-item a.dropdown-item:hover {
  color: var(--bs-white); }

.dropdown-menu a.dropdown-item:hover {
  color: var(--bs-white) !important; }

.ims-comp-base-kendo-autocomplete.ims-kendo-search-component {
  display: flex; }
  .ims-comp-base-kendo-autocomplete.ims-kendo-search-component input.k-input {
    border: 1px solid var(--bs-border-color) !important; }
  .ims-comp-base-kendo-autocomplete.ims-kendo-search-component button {
    border: 0.1rem solid var(--bs-border-color) !important; }
    .ims-comp-base-kendo-autocomplete.ims-kendo-search-component button .ims-icon, .ims-comp-base-kendo-autocomplete.ims-kendo-search-component button .ims-icon-arrow-left, .ims-comp-base-kendo-autocomplete.ims-kendo-search-component button .ims-icon-arrow-right {
      color: inherit !important; }

.ims-comp-base-kendo-grid.toolBarArea {
  border: 0.0625rem solid var(--bs-border-color) !important; }

.ims-comp-base-kendo-grid .k-widget.k-grid {
  border: 0.0625rem solid var(--bs-border-color); }
  .ims-comp-base-kendo-grid .k-widget.k-grid .k-grid-header th.k-header:first-child {
    border: none; }
  .ims-comp-base-kendo-grid .k-widget.k-grid .k-grid-header th.k-header {
    border-left: 0.0625rem solid var(--bs-border-color); }

.ims-comp-base-kendo-grid .k-grid-header tr[role='row'] th[scope='col'] .k-header-column-menu.k-state-active {
  background-color: var(--bs-surface-low); }

.ims-comp-base-kendo-grid .k-grid-header tr[role='row'] .k-icon.k-i-sort-asc-sm, .ims-comp-base-kendo-grid .k-grid-header tr[role='row'] .k-icon.k-i-sort-desc-sm {
  color: inherit; }

.ims-comp-base-kendo-grid .k-grid-header th.k-header.k-sorted {
  background-color: var(--bs-surface-high); }

.objectDetailsContainer .ims-comp-base-kendo-grid .k-grid-content {
  max-height: 500px; }
  .objectDetailsContainer .ims-comp-base-kendo-grid .k-grid-content table[role="grid"] {
    overflow-y: auto; }

.ims-comp-base-kendo-menu.toolBarArea {
  border: 0.0625rem solid var(--bs-border-color); }

.ims-comp-base-kendo-menu .k-animation-container .k-item .k-link {
  color: var(--bs-body-color); }

.ims-comp-base-kendo-menu .k-animation-container .k-item.k-state-hover .k-link {
  color: var(--bs-white); }

.ims-comp-base-kendo-menu .k-animation-container .k-item.k-state-hover .k-animation-container .k-item .k-link {
  color: var(--bs-body-color); }

.ims-comp-base-kendo-menu .k-animation-container .k-animation-container .k-item.k-state-hover .k-link {
  color: var(--bs-white); }

.left-pane-actionbar .ims-comp-base-kendo-menu.toolBarArea {
  border: none; }

.ims-comp-base-kendo-menu-item #rightPaneTitle .toolButtonArea #gridMenu div div > ul.k-widget > li.k-item > .k-link {
  color: inherit;
  background-color: var(--bs-surface-low); }

.ims-comp-base-kendo-menu-item .k-animation-container .k-item .k-link {
  color: var(--bs-body-color); }

.ims-comp-base-kendo-menu-item .k-animation-container .k-item.k-state-hover .k-link {
  color: var(--bs-white); }

.ims-comp-base-kendo-menu-item .k-animation-container .k-item.k-state-hover .k-animation-container .k-item .k-link {
  color: var(--bs-body-color); }

.ims-comp-base-kendo-menu-item .k-animation-container .k-animation-container .k-item.k-state-hover .k-link {
  color: var(--bs-white); }

.ims-comp-base-kendo-tree-view {
  max-height: 52.125rem; }

.ims-comp-base-modal .modal-content {
  background-color: var(--bs-white);
  border-radius: var(--ims-modal__border-radius); }

.ims-comp-base-modal .modal-header {
  border-bottom: 0;
  padding: 1.25rem 2rem 0rem 2rem; }

.ims-comp-base-modal .modal-body {
  padding-top: 1.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem; }
  .ims-comp-base-modal .modal-body .ims-comp-base-kendo-grid .k-grid-content {
    max-height: 410.567px; }

.ims-comp-base-modal .modal-footer {
  background-color: var(--bs-gray-100);
  border-top: 0;
  padding: 1rem 2rem; }

.ims-comp-base-navigation-item .nav-link {
  display: flex;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  padding-left: var(--ims-spacing--50);
  font-weight: var(--ims-font__weight--semibold); }
  .ims-comp-base-navigation-item .nav-link span.ims-icon-angle-down, .ims-comp-base-navigation-item .nav-link span.ims-icon-angle-up {
    display: flex;
    margin-left: auto;
    margin-right: 0; }
  .ims-comp-base-navigation-item .nav-link.has-child div {
    padding-left: var(--ims-spacing--0); }

.nav .is-child .ims-comp-base-link {
  padding-left: var(--ims-spacing--50);
  font-weight: var(--ims-font__weight--medium); }

.ims-comp-base-offcanvas .offcanvas {
  --bs-offcanvas-width: 30rem;
  border-radius: 0; }

.ims-comp-base-progress-bar span.text-nowrap {
  font-size: 0.8125rem; }

.ims-comp-base-progress-bar .progress {
  background-color: var(--bs-surface-high);
  margin-top: var(--ims-spacing--5);
  height: 0.5rem; }

.ims-comp-base-slider .k-slider-wrap .k-slider-track .k-draghandle {
  top: -2px; }
  .ims-comp-base-slider .k-slider-wrap .k-slider-track .k-draghandle.k-state-selected {
    top: -4px; }

.ims-comp-base-table {
  background-color: var(--bs-white);
  padding: var(--ims-spacing--25); }
  .ims-comp-base-table th {
    border: 1px solid var(--bs-light-border-subtle) !important; }
  .ims-comp-base-table .table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-bg-type: var(--bs-white) !important;
    border: 1px solid var(--bs-light-border-subtle) !important; }
  .ims-comp-base-table .table > :not(caption) > * > * {
    border: 1px solid var(--bs-light-border-subtle) !important;
    background-color: var(--bs-table-striped-bg); }

.ims-comp-base-tab-navigation.tabNavigation {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */ }
  .ims-comp-base-tab-navigation.tabNavigation a {
    white-space: nowrap;
    margin: var(--ims-spacing--25) var(--ims-spacing--50) var(--ims-spacing--25) var(--ims-spacing--0);
    color: var(--bs-body-color);
    border-bottom: 0.2rem solid transparent; }
    .ims-comp-base-tab-navigation.tabNavigation a.active {
      border-bottom: 0.2rem solid var(--bs-primary-light);
      color: var(--bs-primary);
      padding-bottom: var(--ims-spacing--15); }
  .ims-comp-base-tab-navigation.tabNavigation::-webkit-scrollbar {
    display: none; }
  .ims-comp-base-tab-navigation.tabNavigation.ims-full-width-tabNavigation.ims-full-width-inner-card-container {
    background-color: #f4f4f4;
    border-bottom: 0rem solid var(--bs-dark-border-subtle); }

@media (max-width: 575px) {
  .ims-comp-base-tab-navigation.tabNavigation .tabNavigation {
    margin: 0 auto;
    padding: 0 0.0625rem;
    width: auto;
    display: flex;
    border-bottom: 0.0625rem solid var(--bs-body-color);
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    transition: all 0.4s ease;
    background: transparent;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    transition: all 0.4s ease;
    background-color: rgba(var(--bs-surface-rgb), 1) !important;
    margin-bottom: var(--ims-spacing--30);
    margin-left: -1rem;
    margin-right: -1rem; }
    .ims-comp-base-tab-navigation.tabNavigation .tabNavigation::-webkit-scrollbar {
      display: none; }
    .ims-comp-base-tab-navigation.tabNavigation .tabNavigation.ims-full-width-tabNavigation {
      margin-bottom: 0rem !important;
      margin-left: -1rem;
      margin-right: -1rem;
      width: auto; }
    .ims-comp-base-tab-navigation.tabNavigation .tabNavigation a:first-child {
      margin-left: var(--ims-spacing--35); } }

.ims-comp-base-tree-list-table .ims-treelist-table tbody {
  border: 0.0625rem solid var(--bs-light-border-subtle) !important; }
  .ims-comp-base-tree-list-table .ims-treelist-table tbody td div span.ims-icon.has-childs::before, .ims-comp-base-tree-list-table .ims-treelist-table tbody td div span.has-childs.ims-icon-arrow-left::before, .ims-comp-base-tree-list-table .ims-treelist-table tbody td div span.has-childs.ims-icon-arrow-right::before {
    font-weight: 600 !important; }

.ims-comp-base-tree-list-table .ims-treelist-table .table-primary th {
  background-color: var(--bs-white);
  padding-top: var(--ims-spacing--35);
  padding-bottom: var(--ims-spacing--35);
  border-top: 1px solid var(--bs-light-border-subtle);
  border-bottom: 1px solid var(--bs-light-border-subtle);
  border-left: 1px solid var(--bs-light-border-subtle); }
  .ims-comp-base-tree-list-table .ims-treelist-table .table-primary th:last-child {
    border-right: 1px solid var(--bs-light-border-subtle); }
  .ims-comp-base-tree-list-table .ims-treelist-table .table-primary th:nth-child(2) {
    background-color: var(--bs-white); }
  .ims-comp-base-tree-list-table .ims-treelist-table .table-primary th:nth-last-child(2) {
    background-color: var(--bs-white);
    border-right: 1px solid var(--bs-light-border-subtle); }
  .ims-comp-base-tree-list-table .ims-treelist-table .table-primary th:nth-child(2) {
    padding-left: var(--ims-spacing--40);
    background-color: var(--bs-white); }

.ims-comp-base-tree-list-table .ims-treelist-table > :not(caption) > * > * {
  background-color: var(--bs-white); }

.ims-comp-base-tree-list-table .ims-treelist-table.table td,
.ims-comp-base-tree-list-table .ims-treelist-table.table th {
  border-top: 1px solid var(--bs-light-border-subtle) !important; }

.ims-treelist-table--footer {
  padding: var(--ims-spacing--30) var(--ims-spacing--30);
  border: 0.0625rem solid var(--bs-light-border-subtle) !important;
  border-top: 0rem solid var(--bs-light-border-subtle) !important;
  background-color: var(--bs-white); }

.ims-comp-base-tree-select .vue-treeselect__control,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__control {
  padding-right: var(--ims-spacing--25);
  border-radius: var(--ims-form-input__border-radius) !important;
  background-color: var(--bs-white);
  border-color: var(--bs-border-color) !important; }
  .ims-comp-base-tree-select .vue-treeselect__control input[type="text"],
  .ims-comp-formulate-custom-tree-select-vue .vue-treeselect__control input[type="text"] {
    border-top-left-radius: var(--ims-form-input__border-radius) !important;
    border-bottom-left-radius: var(--ims-form-input__border-radius) !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: transparent; }

.ims-comp-base-tree-select .vue-treeselect__value-container .vue-treeselect__multi-value-item,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__value-container .vue-treeselect__multi-value-item {
  border-radius: var(--ims-form-input__border-radius) !important; }

.ims-comp-base-tree-select .vue-treeselect--searchable .vue-treeselect__input-container,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect--searchable .vue-treeselect__input-container {
  padding-left: var(--ims-spacing--0);
  padding-right: var(--ims-spacing--0); }

.ims-comp-base-tree-select .vue-treeselect--single .vue-treeselect__input,
.ims-comp-base-tree-select .vue-treeselect__multi-value .vue-treeselect__input,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect--single .vue-treeselect__input,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__multi-value .vue-treeselect__input {
  padding: var(--ims-spacing--20) var(--ims-spacing--20);
  border-width: 0 !important;
  border-radius: var(--ims-form-input__border-radius) !important; }

.ims-comp-base-tree-select .vue-treeselect--searchable.vue-treeselect--multi.vue-treeselect--has-value .vue-treeselect__input-container,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect--searchable.vue-treeselect--multi.vue-treeselect--has-value .vue-treeselect__input-container {
  padding-top: 0; }

.ims-comp-base-tree-select .vue-treeselect__multi-value-item-container,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__multi-value-item-container {
  padding-top: var(--ims-spacing--5);
  padding-right: 0; }

.ims-comp-base-tree-select .vue-treeselect__input,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__input {
  line-height: inherit;
  box-sizing: border-box;
  border-color: transparent !important; }

.ims-comp-base-tree-select .vue-treeselect__multi-value .vue-treeselect__input,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__multi-value .vue-treeselect__input {
  box-sizing: border-box;
  border-color: transparent !important; }

.ims-comp-base-tree-select .vue-treeselect__multi-value-item,
.ims-comp-base-tree-select .vue-treeselect__multi-value-item-new,
.ims-comp-base-tree-select .vue-treeselect__multi-value-item-disabled,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__multi-value-item,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__multi-value-item-new,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__multi-value-item-disabled {
  border: 0.0625rem solid var(--bs-primary);
  color: var(--bs-primary);
  background-color: var(--bs-white);
  margin: 0.2rem 0 0.188rem 0.188rem;
  font-size: inherit; }

.ims-comp-base-tree-select .vue-treeselect__multi-value-item.vue-treeselect__multi-value-item-new,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__multi-value-item.vue-treeselect__multi-value-item-new {
  background: var(--bs-surface-high) !important; }

.ims-comp-base-tree-select .vue-treeselect__label-container:hover .vue-treeselect__checkbox--unchecked,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__label-container:hover .vue-treeselect__checkbox--unchecked {
  border-color: var(--bs-dark-border-subtle); }

.ims-comp-base-tree-select .vue-treeselect__value-remove,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__value-remove {
  color: var(--bs-primary); }
  .ims-comp-base-tree-select .vue-treeselect__value-remove:hover,
  .ims-comp-formulate-custom-tree-select-vue .vue-treeselect__value-remove:hover {
    color: var(--bs-primary); }
  .ims-comp-base-tree-select .vue-treeselect__value-remove > svg,
  .ims-comp-formulate-custom-tree-select-vue .vue-treeselect__value-remove > svg {
    width: 0.565rem;
    height: 0.565rem; }

.ims-comp-base-tree-select .vue-treeselect__x,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__x {
  width: 0.565rem;
  height: 0.565rem; }

.ims-comp-base-tree-select .vue-treeselect--has-value .vue-treeselect__multi-value,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect--has-value .vue-treeselect__multi-value {
  margin-bottom: 0; }

.ims-comp-base-tree-select .vue-treeselect__sizer,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__sizer {
  text-indent: var(--ims-spacing--20) !important; }

.ims-comp-base-tree-select .vue-treeselect__x-container,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__x-container {
  color: var(--bs-body-color); }

.ims-comp-base-tree-select .vue-treeselect__control-arrow-container .vue-treeselect__control-arrow,
.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__control-arrow-container .vue-treeselect__control-arrow {
  color: var(--bs-body-color);
  width: 8px !important;
  height: 9px !important; }

.ims-comp-base-tree-select.formulate-input-element--customTreeSelectVue .vue-treeselect__control:hover,
.ims-comp-formulate-custom-tree-select-vue.formulate-input-element--customTreeSelectVue .vue-treeselect__control:hover {
  background-color: var(--ims-form-input__hover); }

.formulate-input-element--customTreeSelect::before {
  opacity: 0; }

.formulate-input-element--customTreeSelect .k-dropdowntree {
  width: 100%; }
  .formulate-input-element--customTreeSelect .k-dropdowntree .k-dropdown-wrap.k-state-default {
    border-radius: var(--ims-form-input__border-radius);
    background-color: var(--bs-white) !important;
    border-color: var(--bs-border-color); }
    .formulate-input-element--customTreeSelect .k-dropdowntree .k-dropdown-wrap.k-state-default.k-state-hover {
      background-color: var(--ims-form-input__hover) !important; }
      .formulate-input-element--customTreeSelect .k-dropdowntree .k-dropdown-wrap.k-state-default.k-state-hover input {
        background-color: var(--ims-form-input__hover) !important; }

.ims-comp-base-switch.ims-switch-secondary {
  color: var(--ims-comp-base-switch-secondary-color);
  border-color: var(--ims-comp-base-switch-secondary-border-color); }
  .ims-comp-base-switch.ims-switch-secondary:focus {
    box-shadow: var(--bs-secondary) !important; }
  .ims-comp-base-switch.ims-switch-secondary input[type="checkbox"]:checked {
    background-image: var(--ims-comp-base-switch-secondary-check-color); }
  .ims-comp-base-switch.ims-switch-secondary .form-check-input:focus {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e") !important;
    border-color: var(--bs-secondary); }
  .ims-comp-base-switch.ims-switch-secondary input[type="checkbox"] {
    color: var(--ims-comp-base-switch-secondary-color);
    border-color: var(--ims-comp-base-switch-secondary-border-color); }
  .ims-comp-base-switch.ims-switch-secondary .form-check-label {
    color: var(--ims-comp-base-switch-secondary-label-color);
    height: var(--ims-font__size--100); }

.ims-comp-base-switch input:hover {
  cursor: pointer; }

img.ims-comp-base-avatar, span.ims-comp-base-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--bs-on-primary);
  line-height: 1;
  font-size: 13px;
  -o-object-fit: cover;
  object-fit: cover;
  background-color: var(--bs-primary); }
  img.ims-comp-base-avatar.h-32px, span.ims-comp-base-avatar.h-32px {
    font-size: 13px; }
  img.ims-comp-base-avatar.h-64px, span.ims-comp-base-avatar.h-64px {
    font-size: 16px; }
  img.ims-comp-base-avatar.h-96px, span.ims-comp-base-avatar.h-96px {
    font-size: 22px; }

div.ims-comp-base-avatar {
  background-color: var(--bs-primary); }
  div.ims-comp-base-avatar svg {
    color: var(--bs-on-primary); }

.ims-comp-base-nav-pills.nav-pills {
  background-color: var(--bs-surface-high); }
  .ims-comp-base-nav-pills.nav-pills .nav-link {
    background-color: var(--bs-surface-high);
    border-radius: var(--ims-border__radius--0); }
    .ims-comp-base-nav-pills.nav-pills .nav-link.active {
      background-color: var(--ims-comp-base-nav-pills-nav-link--active); }
      .ims-comp-base-nav-pills.nav-pills .nav-link.active:hover {
        color: var(--bs-white); }

.ims-comp-sticky-bottom-bar {
  background-color: var(--ims-comp-sticky-bottom__bg-color);
  transition: all 1s ease;
  box-shadow: 0.0025rem 0.1rem 0.4rem var(--bs-dark-border-subtle); }
  .ims-comp-sticky-bottom-bar.fixed-bottom {
    transition: all 1s ease; }
  .ims-comp-sticky-bottom-bar .ims-comp-sticky-bottom-bar-text {
    color: var(--ims-comp-sticky-bottom__text-color) !important;
    width: 25%; }
  .ims-comp-sticky-bottom-bar a.btn, .ims-comp-sticky-bottom-bar .btn {
    color: var(--ims-comp-sticky-bottom__button-text-color) !important;
    background-color: var(--ims-comp-sticky-bottom__button-bg-color) !important;
    border-color: var(--ims-comp-sticky-bottom__button-border-color) !important; }

@media (max-width: 991px) {
  .ims-comp-sticky-bottom-bar .ims-comp-sticky-bottom-bar-text {
    width: 100%; } }

.sideContentArea table ul li {
  list-style: disc;
  margin-left: 1.5rem; }

.ims-comp-formulate-custom-badges .k-select .k-icon, .ims-comp-formulate-custom-badges .k-tool-icon {
  vertical-align: middle; }

.ims-comp-formulate-custom-date-picker .k-picker-wrap, .ims-comp-formulate-custom-date-picker .k-dropdown-wrap {
  background-color: var(--bs-surface-high); }
  .ims-comp-formulate-custom-date-picker .k-picker-wrap.k-state-hover, .ims-comp-formulate-custom-date-picker .k-dropdown-wrap.k-state-hover {
    background-color: rgba(var(--bs-surface-low-rgb), 0.3) !important; }
  .ims-comp-formulate-custom-date-picker .k-picker-wrap .k-select, .ims-comp-formulate-custom-date-picker .k-dropdown-wrap .k-select {
    border-color: var(--bs-border-color); }

.ims-comp-formulate-custom-date-time-picker .k-picker-wrap {
  background-color: var(--bs-surface-high); }
  .ims-comp-formulate-custom-date-time-picker .k-picker-wrap .k-select {
    line-height: 2.8rem;
    border-color: var(--bs-border-color); }
  .ims-comp-formulate-custom-date-time-picker .k-picker-wrap.k-state-hover {
    background-color: rgba(var(--bs-surface-low-rgb), 0.3) !important; }

.ims-comp-formulate-custom-multiselect.formulate-input-element--customMultiselect::before {
  right: var(--ims-spacing--40) !important; }

.ims-comp-formulate-custom-multiselect .k-widget.k-multiselect .k-multiselect-wrap.k-floatwrap {
  border: 0.0625rem solid var(--bs-border-color);
  border-radius: var(--ims-form-input__border-radius);
  background-color: var(--bs-white);
  transition: box-shadow 0.15s ease-in-out;
  min-height: 2.3125rem; }
  .ims-comp-formulate-custom-multiselect .k-widget.k-multiselect .k-multiselect-wrap.k-floatwrap .k-input {
    height: 2.07142857rem;
    height: auto;
    padding: 0;
    margin: 0;
    line-height: 2.07142857rem; }
  .ims-comp-formulate-custom-multiselect .k-widget.k-multiselect .k-multiselect-wrap.k-floatwrap .k-button {
    height: auto !important;
    margin-bottom: 0.0625rem;
    line-height: 1.6rem;
    border-radius: var(--ims-form-input__border-radius);
    text-transform: capitalize;
    background-color: var(--bs-white); }
    .ims-comp-formulate-custom-multiselect .k-widget.k-multiselect .k-multiselect-wrap.k-floatwrap .k-button .k-select .k-icon {
      height: 1.75rem;
      line-height: 2.3125rem;
      vertical-align: middle; }
      .ims-comp-formulate-custom-multiselect .k-widget.k-multiselect .k-multiselect-wrap.k-floatwrap .k-button .k-select .k-icon:before {
        font-size: var(--ims-font__size--60);
        color: var(--bs-primary-lightest); }
  .ims-comp-formulate-custom-multiselect .k-widget.k-multiselect .k-multiselect-wrap.k-floatwrap:hover {
    background-color: var(--ims-form-input__hover); }

.ims-comp-formulate-custom-multiselect .k-widget.k-multiselect.k-state-focused {
  box-shadow: 0px 0px 0px 0.125rem var(--bs-primary-light) !important;
  border-radius: var(--ims-form-input__border-radius); }

.formulate-input-element .k-multiselect-wrap li.k-button {
  border-radius: var(--ims-form-input__border-radius) !important;
  line-height: 1.24rem !important;
  width: auto; }

@media (max-width: 991px) {
  .formulate-input-element .k-multiselect-wrap li.k-button {
    width: 85%; }
    .formulate-input-element .k-multiselect-wrap li.k-button .k-select {
      padding-left: 1rem !important; } }

.ims-comp-formulate-custom-multiselect-user::before {
  right: var(--ims-spacing--60) !important; }

.ims-comp-formulate-custom-multiselect-user .k-clear-value .k-i-x::before {
  margin-top: -0.15rem; }

.ims-comp-formulate-custom-multiselect-user.formulate-input-element--customMultiselectUser .k-multiselect-clearable.k-state-hover .k-clear-value {
  margin-right: var(--ims-spacing--45); }

.ims-comp-formulate-custom-multiselect-user .k-select .k-icon, .ims-comp-formulate-custom-multiselect-user .k-tool-icon {
  vertical-align: middle !important; }

.ims-comp-formulate-custom-multiselect-user .k-multiselect-wrap .k-select {
  top: inherit !important;
  bottom: inherit !important; }

.ims-comp-formulate-custom-select .k-widget.k-dropdown.k-state-border-down .k-dropdown-wrap.k-state-focused,
.ims-comp-formulate-custom-select .k-widget.k-dropdown.k-state-border-down .k-dropdown-wrap.k-state-active {
  box-shadow: 0px 0px 0px 0.125rem var(--bs-primary-light) !important; }

.ims-comp-formulate-custom-select .k-dropdown-wrap.k-state-disabled {
  border-radius: var(--ims-form-input__border-radius) !important;
  pointer-events: none; }
  .ims-comp-formulate-custom-select .k-dropdown-wrap.k-state-disabled input {
    pointer-events: none !important; }

.ims-comp-formulate-custom-select:has(.k-state-disabled) {
  pointer-events: none !important; }

.input-group:has(.ims-comp-formulate-custom-select) .input-group-append .btn {
  height: 2.43rem !important; }

.ims-comp-formulate-custom-time-picker .k-picker-wrap {
  background-color: var(--bs-white); }
  .ims-comp-formulate-custom-time-picker .k-picker-wrap .k-select {
    line-height: 2.8rem;
    border-color: var(--bs-border-color);
    background-color: var(--bs-surface-high); }
  .ims-comp-formulate-custom-time-picker .k-picker-wrap.k-state-hover input {
    background-color: var(--ims-form-input__hover); }
    .ims-comp-formulate-custom-time-picker .k-picker-wrap.k-state-hover input .k-select {
      background-color: var(--ims-form-input__hover); }

.ims-comp-formulate-custom-tree-select .k-dropdown-wrap.k-state-disabled .k-select .k-icon {
  opacity: .6; }

.ims-comp-formulate-custom-tree-select .k-dropdown-wrap.k-state-disabled .k-select .k-icon::before {
  opacity: .6; }

.ims-comp-formulate-custom-tree-select-vue .vue-treeselect__control {
  padding-right: var(--ims-spacing--20); }

.ims-comp-formulate-custom-tree-select-vue::before {
  display: none; }

.ims-comp-formulate-custom-upload .ims-icon::before, .ims-comp-formulate-custom-upload .ims-icon-arrow-left::before, .ims-comp-formulate-custom-upload .ims-icon-arrow-right::before {
  color: var(--bs-body-color) !important; }

.ims-comp-formulate-custom-upload .k-upload .k-button.k-upload-button {
  background-color: #f5f5f5;
  color: var(--bs-body-color); }

.ims-widget-course-catalog .ims-filter-component .ims-comp-base-dropdown button, .resourceSearchResultListWidget .ims-filter-component .ims-comp-base-dropdown button {
  min-width: 8rem; }

.ims-widget-course-catalog .ims-filter-component .ims-comp-base-dropdown .inner-dropdown-wrapper, .resourceSearchResultListWidget .ims-filter-component .ims-comp-base-dropdown .inner-dropdown-wrapper {
  min-width: 300px;
  max-height: 400px;
  overflow: auto; }

.ims-widget-course-catalog .custom-switch-field label, .resourceSearchResultListWidget .custom-switch-field label {
  background-color: transparent;
  color: var(--ims-btn-secondary-subtle__font-color);
  border: 0.0625rem solid var(--ims-btn-secondary__border-color); }

.ims-widget-course-catalog .custom-switch-field input:checked + label, .resourceSearchResultListWidget .custom-switch-field input:checked + label {
  color: var(--ims-btn-secondary__font-color);
  background-color: var(--ims-switch-field__checked-bg); }

.ims-widget-highscore .ims-highscore-list li {
  display: flex;
  border-bottom: 0.0625rem solid var(--bs-dark-border-subtle); }
  .ims-widget-highscore .ims-highscore-list li .text-highlighted {
    font-weight: 700; }
  .ims-widget-highscore .ims-highscore-list li.list-labels {
    border: none;
    padding-bottom: var(--ims-spacing--60);
    padding-top: var(--ims-spacing--80); }
    .ims-widget-highscore .ims-highscore-list li.list-labels + li {
      padding-top: 0; }

.preFilterWidget .ims-basecard {
  box-shadow: var(--ims-widget-prefilter-box-shadow);
  padding: 0; }
  .preFilterWidget .ims-basecard .ims-card-body {
    background-color: var(--ims-widget-prefilter-bg-color);
    padding: 1rem !important;
    color: var(--ims-widget-prefilter-color); }
    .preFilterWidget .ims-basecard .ims-card-body .card-header a:not(.btn) {
      color: var(--ims-widget-prefilter-color-title-link) !important; }
    .preFilterWidget .ims-basecard .ims-card-body .ims-card--title {
      font-size: var(--ims-font__size--300);
      margin-top: 0 !important;
      margin-bottom: 0 !important; }

.preFilterWidget .ims-comp-base-surface.filled.role-surface-container {
  background-color: var(--ims-widget-prefilter-bg-color);
  color: var(--ims-widget-prefilter-color); }
  .preFilterWidget .ims-comp-base-surface.filled.role-surface-container .card-title {
    color: var(--ims-widget-prefilter-color-title-link); }
    .preFilterWidget .ims-comp-base-surface.filled.role-surface-container .card-title a:not(.btn) {
      color: var(--ims-widget-prefilter-color-title-link); }
      .preFilterWidget .ims-comp-base-surface.filled.role-surface-container .card-title a:not(.btn) h5, .preFilterWidget .ims-comp-base-surface.filled.role-surface-container .card-title a:not(.btn) h4 {
        color: var(--ims-widget-prefilter-color-title-link); }

.preFilterWidget .ims-comp-base-surface.bg-surface {
  background-color: var(--ims-widget-prefilter-bg-color) !important;
  box-shadow: var(--ims-widget-prefilter-box-shadow); }
  .preFilterWidget .ims-comp-base-surface.bg-surface .card-header h5, .preFilterWidget .ims-comp-base-surface.bg-surface h4 {
    color: var(--ims-widget-prefilter-color-title-link) !important; }
  .preFilterWidget .ims-comp-base-surface.bg-surface .card-body, .preFilterWidget .ims-comp-base-surface.bg-surface .body {
    color: var(--ims-widget-prefilter-color) !important; }
  .preFilterWidget .ims-comp-base-surface.bg-surface .text-muted {
    color: var(--ims-widget-prefilter-color) !important; }

.preFilterWidget .btn-toolbar .btn {
  margin-top: var(--ims-spacing--20); }

.preFilterWidget .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .preFilterWidget .embed-responsive.embed-responsive-16by9 {
    padding-top: 56.25%; }
  .preFilterWidget .embed-responsive.embed-responsive-21by9 {
    padding-top: 42.857143%; }
  .preFilterWidget .embed-responsive.embed-responsive-4by3 {
    padding-top: 75%; }
  .preFilterWidget .embed-responsive.embed-responsive-1by1 {
    padding-top: 100%; }
  .preFilterWidget .embed-responsive .embed-responsive-item,
  .preFilterWidget .embed-responsive .embed-responsive embed,
  .preFilterWidget .embed-responsive .embed-responsive iframe,
  .preFilterWidget .embed-responsive .embed-responsive object,
  .preFilterWidget .embed-responsive .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.ims-widget-resource-details .ims-panel-header {
  max-width: 18.75rem;
  display: inline-block; }

.ims-widget-resource-details ul {
  padding: revert; }

.ims-widget-resource-details li {
  list-style: revert; }

.ims-widget-streams .router-link-active, .ims-widget-streams .router-link-exact-active.router-link-active {
  font-weight: bold; }

.ims-widget-teaser .ims-comp-base-carousel .carousel-caption {
  background-color: var(--ims-widget-teaser-caption-bg-color); }
  .ims-widget-teaser .ims-comp-base-carousel .carousel-caption .ims-primary-button {
    border-radius: var(--ims-widget-teaser-button_border-radius); }

.ims-highlight {
  color: var(--bs-danger); }
  .ims-highlight.bold {
    font-weight: bold; }

.ims-booking-alert {
  margin-bottom: var(--ims-spacing--50); }
  .ims-booking-alert .ims-icon, .ims-booking-alert .ims-icon-arrow-left, .ims-booking-alert .ims-icon-arrow-right {
    font-size: 150%;
    color: var(--bs-orange); }
  .ims-booking-alert .ims-alert-message {
    padding-bottom: var(--ims-spacing--15);
    font-weight: 600; }

div.ims-row-body hr {
  margin-top: var(--ims-spacing--35);
  margin-bottom: var(--ims-spacing--35);
  border-bottom: 0.0625rem solid var(--bs-border-color); }

.toolButtonArea > span {
  padding: var(--ims-spacing--10) var(--ims-spacing--10);
  margin-left: var(--ims-spacing--15);
  margin-right: var(--ims-spacing--15);
  font-size: 115%; }
  .toolButtonArea > span:hover {
    border-radius: 0.0625rem; }
  .toolButtonArea > span:active {
    background-color: var(--bs-light-bg-subtle); }

.ims-booking-address.ims-summary-block {
  margin-bottom: var(--ims-spacing--40); }

.ims-booking-address.form {
  margin-top: var(--ims-spacing--40); }

label.ims-radio,
.ims-booking-address > span,
.ims-payment-method > span,
span.ims-input-option > a {
  display: block; }

span.ims-input-option {
  display: inline-block; }

.fit-to-parent {
  width: 100%;
  height: 100%;
  border-width: 0; }

.multiBookingWidget {
  margin-top: var(--ims-spacing--50); }
  .multiBookingWidget .collapsible-content-btn {
    display: flex;
    justify-content: flex-end; }
    .multiBookingWidget .collapsible-content-btn button {
      margin-left: var(--ims-spacing--25); }
  .multiBookingWidget hr {
    margin-top: var(--ims-spacing--35);
    margin-bottom: var(--ims-spacing--35); }
  .multiBookingWidget .topV {
    vertical-align: top;
    margin-top: var(--ims-spacing--15); }

/*** navigation / progress bar ***/
.ims-progress-tracker {
  width: 100%; }

.modal-lg .ims-progress-tracker {
  padding-top: 0; }

.ims-progress-point > .ims-bullet-point {
  margin-top: 0.125rem;
  width: 0.5rem;
  height: 0.5rem;
  margin-left: calc(50% - 0.25rem);
  margin-bottom: -0.25rem;
  border-radius: 50%; }

.ims-progress-tracker {
  flex-direction: row; }
  .ims-progress-tracker li {
    margin-bottom: -0.0625rem; }

.progress-bar-icon {
  display: none; }

.done.final h5.active {
  opacity: 1; }

.done h5.active {
  opacity: .5; }

/*** booking info ***/
.responsive-nav-btn {
  margin-bottom: 0.9375rem; }

.ims-booking-details {
  margin-top: var(--ims-spacing--25); }

.ims-course-details-label {
  font-size: 100%;
  color: var(--bs-border-color); }
  .ims-course-details-label:hover {
    border-bottom: 1px solid;
    color: var(--bs-border-color); }
  .ims-course-details-label:active, .ims-course-details-label:focus, .ims-course-details-label:visited {
    color: var(--bs-border-color); }

.ims-booking-course-info p {
  margin: 0;
  padding-right: 4%;
  display: inline-block; }

.ims-booking-course-info label {
  width: 100%; }

.ims-booking-course-info input.k-textbox {
  padding: 0.23438rem 0.46875rem; }

.ims-booking-course-info .ims-icon, .ims-booking-course-info .ims-icon-arrow-left, .ims-booking-course-info .ims-icon-arrow-right {
  font-size: large;
  color: var(--bs-border-color); }

.ims-side-content .ims-booking-course-info p {
  display: block; }

.ims-booking-selection-overview ul {
  padding-left: 0;
  margin-bottom: 0; }

.ims-booking-selection-overview li hr {
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem; }

.ims-currency-unit {
  padding-right: 0; }

.ims-show-supervisors .ims-item-description, .ims-show-supervisors > div {
  margin-top: var(--ims-spacing--25); }

/*** selection ***/
.ims-booking-form h4 {
  padding: 0.3125rem var(--ims-spacing--20);
  display: inline-block;
  font-weight: normal;
  font-size: 85%;
  color: var(--bs-on-primary);
  background-color: var(--bs-primary); }

.ims-booking-form input[type="checkbox"] {
  margin-left: 0.125rem; }

.ims-course-details {
  font-size: 95%; }

.ims-service-block {
  margin-bottom: var(--ims-spacing--75); }

.ims-service-fee, .custom-numeric-label {
  margin-top: 0.4375rem;
  margin-bottom: 0.4375rem; }
  .ims-service-fee[disabled], .custom-numeric-label[disabled] {
    color: var(--bs-border-color); }

/*** collapsible participant adder ***/
.collapsible-content-btn {
  margin-bottom: var(--ims-spacing--50); }

/*** address ***/
.form-group.pull-right a, .form-group.pull-right button, .form-group.pull-right label, .form-group.pull-left a, .form-group.pull-left button, .form-group.pull-left label {
  margin-right: var(--ims-spacing--50); }

/*** summary ***/
.ims-booking-confirm-action {
  margin-top: var(--ims-spacing--60);
  margin-right: var(--ims-spacing--0);
  margin-left: var(--ims-spacing--0); }

/*** confirmation ***/
.ims-booking-confirmation {
  margin-top: var(--ims-spacing--35); }

.ims-progress-tracker li > a {
  color: var(--bs-border-color); }

.ims-summary-block {
  color: var(--bs-border-color); }
  .ims-summary-block span:first-child {
    color: var(--bs-secondary); }

.ims-progress-point.done > .ims-bullet-point {
  background-color: var(--bs-primary); }

.ims-progress-point.to-do > .ims-bullet-point {
  background-color: var(--bs-border-color); }

.ims-progress-tracker li.ims-progress-point.to-do,
.ims-progress-tracker li.ims-progress-point.done,
.ims-progress-tracker {
  border-bottom: 0.0625rem solid var(--bs-dark-border-subtle); }

.ims-progress-tracker li.ims-progress-point.done > a {
  color: var(--bs-success); }

.ims-progress-tracker .ims-progress-point .navigation-step-label {
  color: var(--bs-tertiary-color); }

.ims-progress-tracker .ims-progress-point.to-do .navigation-step-label {
  color: var(--bs-secondary-color); }

.ims-progress-tracker .ims-progress-point.done .navigation-step-label {
  color: var(--bs-primary); }

.ims-progress-tracker li.ims-progress-point.done {
  border-color: var(--bs-primary); }

.ims-progress-tracker li.ims-progress-point.done.last {
  -o-border-image: linear-gradient(to right, var(--bs-primary) 50%, var(--bs-border-color) 50%) 1;
     border-image: linear-gradient(to right, var(--bs-primary) 50%, var(--bs-border-color) 50%) 1; }

.ims-progress-tracker li.ims-progress-point.done.final {
  -o-border-image: linear-gradient(to right, var(--bs-primary) 50%, var(--bs-dark-border-subtle) 50%) 1;
     border-image: linear-gradient(to right, var(--bs-primary) 50%, var(--bs-dark-border-subtle) 50%) 1; }

.ims-progress-tracker li.ims-progress-point.to-do {
  -o-border-image: linear-gradient(to right, var(--bs-primary) 50%, var(--bs-dark-border-subtle) 50%) 1;
     border-image: linear-gradient(to right, var(--bs-primary) 50%, var(--bs-dark-border-subtle) 50%) 1; }

@media only screen and (max-width: 1366px) {
  navigation-step-label.ims-section-label .ims-bullet-point {
    display: inline-block;
    margin-right: var(--ims-spacing--25); }
  navigation-step-label.ims-section-title {
    display: none; } }

@media (max-width: 1199px) {
  .responsive-nav-btn {
    display: none; }
    .responsive-nav-btn.inverted {
      display: flex;
      margin-top: 0.9375rem; } }

@media (min-width: 1200px) {
  .responsive-nav-btn.inverted {
    display: none; } }

@media (max-width: 575px) {
  ol.ims-progress-tracker {
    justify-content: space-between;
    flex-wrap: nowrap; }
    ol.ims-progress-tracker li.ims-progress-point {
      flex: 1;
      padding-top: 0;
      padding-left: 0;
      padding-right: 0; }
      ol.ims-progress-tracker li.ims-progress-point a {
        text-align: center; }
      ol.ims-progress-tracker li.ims-progress-point .progress-bar-icon {
        font-size: var(--ims-font__size--500); }
      ol.ims-progress-tracker li.ims-progress-point .ims-progress-item-label {
        display: none; }
  .progress-bar-icon {
    display: block; } }

.survey-question {
  padding-bottom: 3.125rem;
  border-top: 0.0625rem solid var(--bs-dark-border-subtle); }

.ims-mchoice-item {
  margin-bottom: var(--ims-spacing--25); }
  .ims-mchoice-item svg {
    height: 1.875rem; }
    .ims-mchoice-item svg rect {
      height: 1.875rem; }

.scale-option svg rect {
  fill: var(--bs-primary); }

.mchoice-option {
  min-height: 1.875rem;
  border-bottom: 0.0625rem solid var(--bs-dark-border-subtle); }

.longanswer-option {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-bottom: 0.0625rem solid var(--bs-primary); }
  .longanswer-option .number-label {
    min-width: 2.5rem;
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: var(--ims-spacing--25);
    align-self: stretch;
    background-color: var(--bs-primary);
    color: var(--bs-on-primary); }

.sheet-option .ims-icon, .sheet-option .ims-icon-arrow-left, .sheet-option .ims-icon-arrow-right {
  font-size: 6.25rem; }
  .sheet-option .ims-icon:before, .sheet-option .ims-icon-arrow-left:before, .sheet-option .ims-icon-arrow-right:before {
    margin: 0; }

.sheet-option > * {
  text-align: center; }

.sheet-option .happy-sheet-control-indicator {
  margin-left: var(--ims-spacing--50); }
  .sheet-option .happy-sheet-control-indicator.selected {
    border: 0.0625rem solid var(--bs-primary); }

.scale-question {
  display: flex; }
  .scale-question .ims-small-font {
    font-size: 0.625rem; }
  .scale-question .scale {
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
    .scale-question .scale .scale-option {
      height: 2.5rem;
      width: 15.625rem;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center; }
      .scale-question .scale .scale-option svg {
        height: 100%;
        width: 100%; }
        .scale-question .scale .scale-option svg.vertical-bar {
          display: none; }
          .scale-question .scale .scale-option svg.vertical-bar rect {
            width: 1.875rem;
            height: 100%; }
        .scale-question .scale .scale-option svg.horizontal-bar {
          display: block; }
          .scale-question .scale .scale-option svg.horizontal-bar rect {
            height: 1.875rem;
            width: 100%; }

.scale-option svg rect {
  fill: var(--bs-primary); }

.color1, .ims-icon-meh {
  color: var(--bs-orange); }
  .color1.svg-fill, .ims-icon-meh.svg-fill {
    fill: var(--bs-orange); }

.color2, .ims-icon-frown {
  color: var(--bs-red); }
  .color2.svg-fill, .ims-icon-frown.svg-fill {
    fill: var(--bs-red); }

.color3, .ims-icon-smile {
  color: var(--bs-green); }
  .color3.svg-fill, .ims-icon-smile.svg-fill {
    fill: var(--bs-green); }

.color4 {
  color: var(--bs-blue); }
  .color4.svg-fill {
    fill: var(--bs-blue); }

.color5 {
  color: var(--bs-pink); }
  .color5.svg-fill {
    fill: var(--bs-pink); }

.color6 {
  color: var(--bs-yellow); }
  .color6.svg-fill {
    fill: var(--bs-yellow); }

.color7 {
  color: var(--bs-indigo); }
  .color7.svg-fill {
    fill: var(--bs-indigo); }

.color8 {
  color: var(--bs-purple); }
  .color8.svg-fill {
    fill: var(--bs-purple); }

.color9 {
  color: var(--bs-danger-hover); }
  .color9.svg-fill {
    fill: var(--bs-danger-hover); }

.color10 {
  color: var(--bs-gray); }
  .color10.svg-fill {
    fill: var(--bs-gray); }

@media print {
  .longanswer-option .number-label {
    border: 0.0625rem solid var(--bs-primary);
    background-color: transparent;
    color: var(--bs-primary); } }

.color1, .ims-icon-meh {
  color: var(--bs-orange); }
  .color1.svg-fill, .ims-icon-meh.svg-fill {
    fill: var(--bs-orange); }

.color2, .ims-icon-frown {
  color: var(--bs-red); }
  .color2.svg-fill, .ims-icon-frown.svg-fill {
    fill: var(--bs-red); }

.color3, .ims-icon-smile {
  color: var(--bs-green); }
  .color3.svg-fill, .ims-icon-smile.svg-fill {
    fill: var(--bs-green); }

.color4 {
  color: var(--bs-blue); }
  .color4.svg-fill {
    fill: var(--bs-blue); }

.color5 {
  color: var(--bs-pink); }
  .color5.svg-fill {
    fill: var(--bs-pink); }

.color6 {
  color: var(--bs-yellow); }
  .color6.svg-fill {
    fill: var(--bs-yellow); }

.color7 {
  color: var(--bs-indigo); }
  .color7.svg-fill {
    fill: var(--bs-indigo); }

.color8 {
  color: var(--bs-purple); }
  .color8.svg-fill {
    fill: var(--bs-purple); }

.color9 {
  color: var(--bs-danger-hover); }
  .color9.svg-fill {
    fill: var(--bs-danger-hover); }

.color10 {
  color: var(--bs-gray); }
  .color10.svg-fill {
    fill: var(--bs-gray); }

@media (min-width: 576px) {
  .scale-question {
    align-items: flex-end; }
    .scale-question > label {
      border-top: 0.0625rem solid var(--bs-body-color); }
    .scale-question .scale-option svg {
      border-bottom: 0.0625rem solid var(--bs-body-color); }
    .scale-question .scale {
      flex-direction: row; }
      .scale-question .scale .scale-option {
        height: 9.375rem;
        flex-direction: column;
        justify-content: flex-end;
        width: 2.5rem; }
        .scale-question .scale .scale-option svg.vertical-bar {
          display: block; }
        .scale-question .scale .scale-option svg.horizontal-bar {
          display: none; }
    .scale-question .scale-option svg {
      border-bottom: 0.0625rem solid var(--bs-body-color); }
  .ims-happy-sheet:not(.editable) {
    flex-direction: row-reverse; } }

@media (max-width: 575px) {
  .sheet-option .ims-icon, .sheet-option .ims-icon-arrow-left, .sheet-option .ims-icon-arrow-right {
    font-size: 3.75rem; }
  .sheet-option svg {
    border-left: 0.0625rem solid var(--bs-body-color); }
  .scale-question {
    flex-direction: column; }
  .scale-option svg {
    margin-left: var(--ims-spacing--25);
    margin-right: var(--ims-spacing--25);
    border-left: 0.0625rem solid var(--bs-body-color); }
  .scale-number {
    min-width: 1.125rem;
    text-align: right; }
  .ims-happy-sheet .ims-piechart {
    margin-left: auto;
    margin-right: auto; } }

.ims-scheduler-top-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.9375rem;
  background-color: rgba(var(--bs-surface-rgb), 1);
  color: var(--bs-body-color); }
  .ims-scheduler-top-header .k-widget {
    background-color: transparent;
    color: inherit; }
    .ims-scheduler-top-header .k-widget .k-textbox-container {
      margin-right: var(--ims-spacing--25); }
    .ims-scheduler-top-header .k-widget .k-textbox {
      margin-bottom: var(--ims-spacing--25); }
  .ims-scheduler-top-header input.k-textbox:not([role="combobox"]) {
    color: inherit;
    color: var(--bs-body-color); }
  .ims-scheduler-top-header .k-picker-wrap.k-state-default, .ims-scheduler-top-header .k-picker-wrap.k-state-focus {
    background-color: transparent;
    border: none;
    box-shadow: none; }
    .ims-scheduler-top-header .k-picker-wrap.k-state-default:hover, .ims-scheduler-top-header .k-picker-wrap.k-state-default:active, .ims-scheduler-top-header .k-picker-wrap.k-state-default:focus, .ims-scheduler-top-header .k-picker-wrap.k-state-focus:hover, .ims-scheduler-top-header .k-picker-wrap.k-state-focus:active, .ims-scheduler-top-header .k-picker-wrap.k-state-focus:focus {
      box-shadow: none; }
  .ims-scheduler-top-header .k-datepicker, .ims-scheduler-top-header .k-picker-wrap.k-state-default, .ims-scheduler-top-header .k-datepicker .k-input {
    background-color: rgba(var(--bs-surface-rgb), 1); }
  .ims-scheduler-top-header .scheduler-datepicker {
    width: 12.5rem; }
    .ims-scheduler-top-header .scheduler-datepicker input.k-textbox:not([role="combobox"]), .ims-scheduler-top-header .scheduler-datepicker .k-datepicker, .ims-scheduler-top-header .scheduler-datepicker .k-select {
      border: none; }
    .ims-scheduler-top-header .scheduler-datepicker .k-datepicker .k-state-hover .k-input {
      background-color: transparent; }
    .ims-scheduler-top-header .scheduler-datepicker .k-datepicker .k-input {
      height: auto;
      line-height: normal;
      margin-bottom: var(--ims-spacing--25);
      border-bottom: 0.0625rem solid var(--bs-body-color);
      color: var(--bs-body-color);
      padding-top: 0; }
      .ims-scheduler-top-header .scheduler-datepicker .k-datepicker .k-input:hover, .ims-scheduler-top-header .scheduler-datepicker .k-datepicker .k-input:focus, .ims-scheduler-top-header .scheduler-datepicker .k-datepicker .k-input:active {
        background-color: transparent; }
    .ims-scheduler-top-header .scheduler-datepicker .k-select {
      color: var(--bs-body-color); }
  .ims-scheduler-top-header .material-switch-label, .ims-scheduler-top-header .k-label {
    font-size: var(--ims-font__size--100);
    font-weight: 100;
    transform: scale(0.75); }

.ims-scheduler-toolbar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 0.3125rem; }

.ims-scheduler {
  overflow: auto;
  height: auto;
  position: relative;
  border: solid var(--bs-dark-border-subtle) 0.0625rem;
  max-height: 40rem; }

.ims-scheduler-header {
  position: sticky;
  top: 0;
  z-index: 1; }
  .ims-scheduler-header > * {
    flex-shrink: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 2.1875rem;
    padding: 0.4375rem;
    border-right: solid var(--bs-dark-border-subtle) 0.0625rem;
    border-bottom: solid var(--bs-dark-border-subtle) 0.0625rem;
    background-color: var(--bs-surface); }
    .ims-scheduler-header > *:last-child {
      border-right: none; }

.ims-scheduler-header, .ims-scheduler-body {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap; }

.ims-scheduler-legend {
  background-color: var(--bs-surface); }
  .ims-scheduler-legend > * {
    height: 2.5rem;
    border-bottom: solid var(--bs-dark-border-subtle) 0.0625rem; }
    .ims-scheduler-legend > *.department-row {
      border-left: 0.3125rem solid var(--bs-border-color); }
    .ims-scheduler-legend > * a, .ims-scheduler-legend > * .ims-icon, .ims-scheduler-legend > * .ims-icon-arrow-left, .ims-scheduler-legend > * .ims-icon-arrow-right, .ims-scheduler-legend > * a:hover .ims-icon, .ims-scheduler-legend > * a:hover .ims-icon-arrow-left, .ims-scheduler-legend > * a:hover .ims-icon-arrow-right {
      color: var(--bs-body-color); }
  .ims-scheduler-legend a {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .ims-scheduler-legend a span:not(.ims-icon):not(.ims-icon-arrow-left):not(.ims-icon-arrow-right) {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden; }

.ims-scheduler-legend > * {
  padding: 0.5625rem; }

.ims-scheduler-list {
  height: auto; }
  .ims-scheduler-list .schedule-row > * {
    height: 2.125rem;
    padding: 0.4375rem;
    margin-bottom: var(--ims-spacing--15);
    display: flex;
    justify-content: space-between; }
  .ims-scheduler-list .schedule-row > *:last-child {
    border-right: none; }
  .ims-scheduler-list *:last-child > * {
    border-bottom: none; }
  .ims-scheduler-list .schedule-student-department-free {
    border-color: var(--bs-body-color); }
  .ims-scheduler-list .schedule-block-title, .ims-scheduler-list a, .ims-scheduler-list .schedule-block-actions a {
    color: var(--bs-body-color); }

.schedule-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }

.ims-scheduler-legend, .schedule-row > * {
  border-right: solid var(--bs-dark-border-subtle) 0.0625rem; }

.ims-scheduler-legend,
.ims-scheduler-title {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  min-width: 15.625rem; }

.ims-custom-scheduler .ims-scheduler-legend div {
  display: flex; }
  .ims-custom-scheduler .ims-scheduler-legend div span:first-of-type {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.schedule-block-title {
  white-space: nowrap; }

.schedule-student-unassigned {
  background-color: var(--bs-orange); }

.schedule-student-session {
  background-color: #0281E4; }

.schedule-student-department-session {
  background-color: #47A3EB; }

.schedule-student-department-free {
  background-color: var(--bs-body-color); }

.k-scheduler .k-scheduler-table .k-today {
  background-color: rgba(var(--bs-surface-low-rgb), 0.3); }

.popover {
  min-width: 350px !important;
  /* min width of the popover (depending on the container!) */
  line-height: 1.2 !important;
  white-space: normal !important; }
  .popover .popover-header {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .popover .popover-body div {
    padding-bottom: 10px !important; }

.preFilterContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .preFilterContainer.list-view .list-item {
    border-bottom: 0.0625rem solid var(--bs-dark-border-subtle); }

.preFilterCategory {
  width: 18.75rem;
  margin: var(--ims-spacing--0) var(--ims-spacing--25) var(--ims-spacing--25) var(--ims-spacing--0);
  background-color: var(--ims-widget-prefilter-bg-color); }
  .preFilterCategory.light {
    margin: var(--ims-spacing--0) var(--ims-spacing--60) var(--ims-spacing--60) var(--ims-spacing--0);
    width: 15.625rem;
    background-color: var(--ims-widget-prefilter-bg-color); }
    .preFilterCategory.light .preFilterImageFrame {
      height: calc( 15.625rem / 16 * 9); }
    .preFilterCategory.light div.preFilterInfoBox > * {
      color: var(--ims-widget-prefilter-color-title-link); }

.preFilterImageFrame {
  height: 18.75rem; }

.preFilterImage {
  height: inherit;
  width: inherit;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--bs-light-bg-subtle); }

.preFilterInfoBox h3 {
  margin: 0;
  margin-bottom: 0.125rem;
  line-height: normal; }

div.preFilterInfoBox {
  color: var(--bs-dark-border-subtle);
  white-space: normal;
  padding: var(--ims-spacing--40);
  padding-bottom: 0;
  margin-bottom: 0.9375rem; }
  div.preFilterInfoBox > * {
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-column-width: calc(18.75rem - 1.5rem);
         column-width: calc(18.75rem - 1.5rem);
    color: var(--bs-on-primary); }

.preFilterSubtitle {
  font-size: var(--ims-font__size--200); }

.preFilterDescription {
  margin-top: var(--ims-spacing--25);
  line-height: 1.125rem; }

@media (max-width: 575px) {
  .preFilterCategory {
    width: 100%; } }

.ims-list-item-header-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between; }

.inline-list-details {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-bottom: var(--ims-spacing--25); }
  .inline-list-details > div {
    flex: 3; }

.instructor-evaluation-form {
  width: 100%;
  border: 0.0625rem solid var(--bs-dark-border-subtle); }
  .instructor-evaluation-form th, .instructor-evaluation-form td {
    padding: var(--ims-spacing--15) var(--ims-spacing--15);
    border-bottom: 0.0625rem solid var(--bs-dark-border-subtle);
    border-right: 0.0625rem solid var(--bs-dark-border-subtle); }
    .instructor-evaluation-form th.scale-cell, .instructor-evaluation-form td.scale-cell {
      min-width: 5rem;
      max-width: 12.5rem; }
  .instructor-evaluation-form td {
    vertical-align: middle !important; }
  .instructor-evaluation-form td.comment-section {
    padding: 0; }
    .instructor-evaluation-form td.comment-section textarea {
      width: 100%;
      resize: none;
      border: none;
      margin: var(--ims-spacing--0) var(--ims-spacing--0);
      padding: var(--ims-spacing--15) var(--ims-spacing--15); }
  .instructor-evaluation-form tbody tr td:first-child {
    width: 18rem; }
  .instructor-evaluation-form .scale-element {
    position: relative;
    width: 100%;
    height: 3.75rem;
    cursor: pointer; }
    .instructor-evaluation-form .scale-element input {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0; }
    .instructor-evaluation-form .scale-element .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      vertical-align: middle; }
      .instructor-evaluation-form .scale-element .checkmark:hover:after {
        opacity: .1;
        content: '\e82d'; }
    .instructor-evaluation-form .scale-element .checkmark:after {
      font-family: fontello;
      content: '';
      font-size: 1.875rem;
      line-height: 3.75rem;
      opacity: 0;
      text-align: center;
      display: block;
      color: var(--bs-body-color); }
    .instructor-evaluation-form .scale-element input:checked ~ .checkmark:after {
      opacity: 1;
      content: '\e82d'; }

.instructor-evaluation-form-new th, .instructor-evaluation-form-new td {
  border-top: 1px solid var(--bs-light-border-subtle) !important;
  border-left: 1px solid var(--bs-light-border-subtle); }
  .instructor-evaluation-form-new th.scale-cell, .instructor-evaluation-form-new td.scale-cell {
    min-width: 5rem;
    max-width: 12.5rem; }

.instructor-evaluation-form-new td {
  vertical-align: middle !important; }

.instructor-evaluation-form-new td.comment-section {
  padding: 0; }
  .instructor-evaluation-form-new td.comment-section textarea {
    width: 100%;
    resize: none;
    border: none;
    margin: var(--ims-spacing--0) var(--ims-spacing--0);
    padding: var(--ims-spacing--15) var(--ims-spacing--15); }

.instructor-evaluation-form-new tbody tr td:first-child {
  width: 18rem; }

.instructor-evaluation-form-new .scale-element {
  position: relative;
  width: 100%;
  height: 3.75rem;
  cursor: pointer; }
  .instructor-evaluation-form-new .scale-element input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0; }
  .instructor-evaluation-form-new .scale-element .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    vertical-align: middle; }
    .instructor-evaluation-form-new .scale-element .checkmark:hover:after {
      opacity: .1;
      content: '\e82d'; }
  .instructor-evaluation-form-new .scale-element .checkmark:after {
    font-family: fontello;
    content: '';
    font-size: 1.875rem;
    line-height: 3.75rem;
    opacity: 0;
    text-align: center;
    display: block;
    color: var(--bs-body-color); }
  .instructor-evaluation-form-new .scale-element input:checked ~ .checkmark:after {
    opacity: 1;
    content: '\e82d'; }

.popover {
  color: var(--bs-body-color);
  white-space: pre-wrap;
  height: auto !important; }

.ims-userDashboard-ausbildungsplan a.ims-accordion:hover .custom-accordion-plus:before,
.ims-userDashboard-ausbildungsplan a.ims-accordion:hover .custom-accordion-plus:after {
  border-top-color: var(--bs-primary); }

[class^="ims-icon-"]:before, [class*=" ims-icon-"]:before {
  margin-right: 0;
  margin-left: 0; }

.ims-dashboard-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 0;
  padding-top: 0; }

.additional-course-info > [class^="ims-icon-"]:before,
.additional-course-info > [class*=" ims-icon-"]:before {
  margin-right: var(--ims-spacing--10); }

.additional-course-info > span span::before {
  margin-right: var(--ims-spacing--10); }

.ims-comp-base-button.rounded-circle {
  --bs-btn-padding-x: var(--ims-spacing--20) !important;
  --bs-btn-padding-y: 0.3rem !important; }

.ims-comp-base-button.dropdown_button--button [class^="ims-icon-"]:before,
.ims-comp-base-button.dropdown_button--button [class*=" ims-icon-"]:before {
  margin-left: 0.2rem !important; }

[role="gridcell"] .ims-comp-base-button.rounded-circle {
  --bs-btn-padding-y: var(--ims-spacing--15) !important; }

.global-search-container .ims-icon, .global-search-container .ims-icon-arrow-left, .global-search-container .ims-icon-arrow-right,
.global-search-container .ims-icon-arrow-left,
.global-search-container .ims-icon-arrow-right {
  padding: var(--ims-spacing--0) var(--ims-spacing--35); }

.ims-comp-base-dropdown .ims-comp-base-button:not(.btn-sm) [class^="ims-icon-"]:before,
.ims-comp-base-dropdown .ims-comp-base-button:not(.btn-sm) [class*=" ims-icon-"]:before {
  margin-left: 0.2rem !important; }

.toolArea .ims-dropdown-menu .k-icon::before {
  top: 0rem; }

.ims-grid-container .toolArea .toolArea-btn .ims-icon, .ims-grid-container .toolArea .toolArea-btn .ims-icon-arrow-left, .ims-grid-container .toolArea .toolArea-btn .ims-icon-arrow-right {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex; }
  .ims-grid-container .toolArea .toolArea-btn .ims-icon::before, .ims-grid-container .toolArea .toolArea-btn .ims-icon-arrow-left::before, .ims-grid-container .toolArea .toolArea-btn .ims-icon-arrow-right::before {
    margin-top: 0 !important;
    margin-left: 0 !important; }


.ims-grid-container .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon::before,
.ims-grid-container .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-left::before,
.ims-grid-container .toolArea .k-widget.k-reset.k-menu > .k-item > .k-link .ims-icon-arrow-right::before {
  width: 100%;
  height: 100%;
  margin-top: 0 !important;
  margin-left: 0 !important;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 2.5rem; }

div:not(.containerBar) > .ddMenu ul.k-widget.k-menu > li.k-item {
  justify-content: center;
  align-items: center;
  display: flex; }
  div:not(.containerBar) > .ddMenu ul.k-widget.k-menu > li.k-item span:nth-child(2) {
    margin-left: 0.2rem !important; }

.ims-comp-base-kendo-menu-item .ddMenu > .k-menu > .k-item > .k-link::before {
  padding: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 2.5rem !important; }

.ims-comp-base-navigation-item .nav-link span.ims-icon-angle-down, .ims-comp-base-navigation-item .nav-link span.ims-icon-angle-up {
  margin-right: inherit !important; }

.k-animation-container .ims-comp-base-kendo-menu-item .k-link .ims-icon, .k-animation-container .ims-comp-base-kendo-menu-item .k-link .ims-icon-arrow-left, .k-animation-container .ims-comp-base-kendo-menu-item .k-link .ims-icon-arrow-right {
  margin-right: 0.2rem !important; }

.ims-grid-container .toolArea .searchBoxArea div button.btn:hover {
  background-color: var(--bs-primary-dark) !important; }
  .ims-grid-container .toolArea .searchBoxArea div button.btn:hover span.ims-icon::before, .ims-grid-container .toolArea .searchBoxArea div button.btn:hover span.ims-icon-arrow-left::before, .ims-grid-container .toolArea .searchBoxArea div button.btn:hover span.ims-icon-arrow-right::before {
    opacity: 0.8;
    color: var(--bs-white); }

.ims-grid-container .toolArea .input-element:hover {
  background-color: var(--bs-primary-dark); }
  .ims-grid-container .toolArea .input-element:hover .ims-icon::before, .ims-grid-container .toolArea .input-element:hover .ims-icon-arrow-left::before, .ims-grid-container .toolArea .input-element:hover .ims-icon-arrow-right::before {
    opacity: 0.8; }

.ims-comp-base-page-header .ims-comp-base-link::before {
  margin-right: 0.2rem !important; }

.bigPictureWidget .ims-btn__pulsating-circle--lg div.ims-icon::before, .bigPictureWidget .ims-btn__pulsating-circle--lg div.ims-icon-arrow-left::before, .bigPictureWidget .ims-btn__pulsating-circle--lg div.ims-icon-arrow-right::before,
.bigPictureWidget .ims-btn__pulsating-circle--lg div.ims-icon-arrow-left::before,
.bigPictureWidget .ims-btn__pulsating-circle--lg div.ims-icon-arrow-right::before {
  margin-right: 0.2rem;
  margin-left: 0.2rem; }

.iconButtonsBox {
  margin-top: 0.2rem; }
  .iconButtonsBox .ims-icon::before, .iconButtonsBox .ims-icon-arrow-left::before, .iconButtonsBox .ims-icon-arrow-right::before {
    margin-left: 0.2rem; }

.pageEditor .containerBar .container a.ims-icon.buttonType, .pageEditor .containerBar .container a.buttonType.ims-icon-arrow-left, .pageEditor .containerBar .container a.buttonType.ims-icon-arrow-right {
  margin-right: 0.2rem;
  margin-left: 0.2rem; }

li[role="treeitem"] .k-in a.ims-icon, li[role="treeitem"] .k-in a.ims-icon-arrow-left, li[role="treeitem"] .k-in a.ims-icon-arrow-right {
  margin-right: 0.2rem;
  margin-left: 0.2rem; }

.k-treeview .k-item .k-icon {
  margin-top: 0; }

.tabContentWithoutSaveAction #pageEditorMenue .k-item .k-link .ims-icon::before, .tabContentWithoutSaveAction #pageEditorMenue .k-item .k-link .ims-icon-arrow-left::before, .tabContentWithoutSaveAction #pageEditorMenue .k-item .k-link .ims-icon-arrow-right::before {
  margin-right: 0.2rem; }

/*# sourceMappingURL=maps/ims-core.css.map */

/*IMS theme related styles*/

/*# sourceMappingURL=maps/ims-base.css.map */

:root {
  --ims-sys-elevation-0: 0 0;
  --ims-sys-elevation-1: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
                0 1px 0px -2px rgba(0, 0, 0, 0.2),
                0 0px 3px 0 rgba(0, 0, 0, 0.12);
  --ims-sys-elevation-2: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
              0 3px 1px -2px rgba(0, 0, 0, 0.2),
              0 1px 5px 0 rgba(0, 0, 0, 0.12);
  --ims-sys-elevation-3:  0 3px 4px 0 rgba(0, 0, 0, 0.14),
              0 3px 3px -2px rgba(0, 0, 0, 0.2),
              0 1px 8px 0 rgba(0, 0, 0, 0.12);
  --ims-sys-elevation-4:  0 4px 5px 0 rgba(0, 0, 0, 0.14),
              0 1px 10px 0 rgba(0, 0, 0, 0.12),
              0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --ims-sys-elevation-5:  0 6px 10px 0 rgba(0, 0, 0, 0.14),
              0 1px 18px 0 rgba(0, 0, 0, 0.12),
              0 3px 5px -1px rgba(0, 0, 0, 0.2);
  --ims-sys-elevation-6: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12),
              0 5px 5px -3px rgba(0, 0, 0, 0.2); }

:root {
  --ims-sys-shape-extra-large: 28px;
  --ims-sys-shape-extra-large-top: 28px 28px 0px 0px;
  --ims-sys-shape-extra-small: 4px;
  --ims-sys-shape-extra-small-top: 4px 4px 0px 0px;
  --ims-sys-shape-full: 9999px;
  --ims-sys-shape-large: 16px;
  --ims-sys-shape-large-end: 0px 16px 16px 0px;
  --ims-sys-shape-large-start: 16px 0px 0px 16px;
  --ims-sys-shape-large-top: 16px 16px 0px 0px;
  --ims-sys-shape-medium: 12px;
  --ims-sys-shape-none: 0px;
  --ims-sys-shape-small: 8px; }

:root {
  --ims-sys-state-dragged-state-layer-opacity: 0.16;
  --ims-sys-state-focus-state-layer-opacity: 0.12;
  --ims-sys-state-hover-state-layer-opacity: 0.08;
  --ims-sys-state-pressed-state-layer-opacity: 0.12; }

/*Accessibility-Barrierefreiheit
When we use px, it means that we hardcode the font-size to all of the users. 
The user can't change the value whatsoever. 
This actually troubles the user that wants to have a bigger font-size from the default
*/
.ims-comp-base-surface.bg-primary,
.ims-comp-base-surface .bg-secondary,
.ims-comp-base-surface .bg-danger {
  color: var(--bs-white) !important; }

:root {
  --ims-comp-footer-link-color: var(--bs-body-color);
  --ims-comp-footer-link-color-hover: var(--bs-primary-dark);
  --ims-comp-footer-info-color: var(--bs-body-color);
  --ims-comp-footer-dropdown-bg:var(--bs-white);
  --ims-comp-footer-dropdown-item-bg-hover: var(--bs-primary);
  --ims-comp-footer-dropdown-link-item-color: var(--bs-body-color);
  --ims-comp-footer-dropdown-link-item-color-hover: var(---bs-white); }

:root {
  --ims-widget-prefilter-bg-color: var(--bs-surface);
  --ims-widget-prefilter-color: var(--bs-body-color);
  --ims-widget-prefilter-box-shadow: 0;
  --ims-widget-prefilter-color-title-link: var(--bs-primary); }

:root {
  --ims-widget-teaser-caption-bg-color: rgba(var(--bs-white-rgb), 0.8); }

/* list of all global variables */
/* ------------------------------------------------------------- */
/*
/*  CSS VARIABLES
/*
/* ------------------------------------------------------------- */
:root {
  --ims-body__font-size: 0.875rem;
  --ims-body__font-size-sm: 0.75rem;
  --ims-body__font-size-lg: 1rem;
  --ims-body__font-family: 'Open Sans', Arial, sans-serif;
  --ims-h1__font-size: 2.5rem;
  --ims-h1__font-family: var(--ims-body__font-family);
  --ims-h1__text-transform: none;
  --ims-h2__font-size: 2rem;
  --ims-h2__font-family: var(--ims-body__font-family);
  --ims-h2__text-transform: none;
  --ims-h3__font-size: 1.75rem;
  --ims-h3__font-family: var(--ims-body__font-family);
  --ims-h3__text-transform: none;
  --ims-h4__font-size: 1.5rem;
  --ims-h4__font-family: var(--ims-body__font-family);
  --ims-h4__text-transform: none;
  --ims-h5__font-size: 1.25rem;
  --ims-h5__font-family: var(--ims-body__font-family);
  --ims-h5__text-transform: none;
  --ims-h6__font-size: 1rem;
  --ims-h6__font-family: var(--ims-body__font-family);
  --ims-h6__text-transform: none;
  --ims-link-decoration: none;
  --ims-link-decoration-hover: none; }

:root {
  --ims-widget-teaser-caption-bg-color: var(--bs-white); }

:root {
  --ims-widget-prefilter-bg-color: var(--bs-body-color);
  --ims-widget-prefilter-color: var(--bs-white);
  --ims-widget-prefilter-box-shadow: none;
  --ims-widget-prefilter-color-title-link: var(--bs-white); }

.ims-comp-base-surface.bg-secondary,
.ims-comp-base-surface .bg-tertiary,
.ims-comp-base-surface .bg-danger {
  color: var(--bs-white) !important; }

:root {
  --ims-comp-footer-link-color: var(--bs-white);
  --ims-comp-footer-link-color-hover: var(--bs-primary);
  --ims-comp-footer-info-color: var(--bs-white);
  --ims-comp-footer-dropdown-bg: var(--bs-surface-high);
  --ims-comp-footer-dropdown-item-bg-hover: var(--bs-primary);
  --ims-comp-footer-dropdown-link-item-color: var(--bs-body-color);
  --ims-comp-footer-dropdown-link-item-color-hover: var(--bs-white); }

:root {
  --ims-h4__font-size: 1.25rem;
  --ims-h5__font-size: 1.125rem;
  --ims-h6__font-size: 1rem; }

:root {
  --ims-border__radius--0: 0rem;
  --ims-border__radius--25: 0.125rem;
  --ims-border__radius--50: 0.25rem;
  --ims-border__radius--75: 0.375rem;
  --ims-border__radius--100: 0.5rem;
  --ims-border__radius--160: 0.75rem;
  --ims-border__radius--175: 0.875rem;
  --ims-border__radius--200: 1rem;
  --ims-border__radius--250: 1.25rem;
  --ims-border__radius--300: 1.5rem;
  --ims-border__radius--400: 2rem;
  --ims-border__radius--625: 3.125rem;
  --ims-border__width--0: 0rem;
  --ims-border__width--1: 0.0625rem;
  --ims-border__width--2: 0.125rem;
  --ims-border__width--3: 0.1875rem;
  --ims-border__width--4: 0.25rem;
  --ims-elevation__dp--10: 0 0.25rem 0.75rem -0.125rem rgba(0, 0, 0, 0.2);
  --ims-elevation__dp--20: 0 0.25rem 0.75rem -0.125rem rgba(0, 0, 0, 0.32);
  --ims-elevation__dp--25: 0 0 0 0.125rem rgba(70, 140, 220, 0.5);
  --ims-spacing--0: 0;
  --ims-spacing--5: 0.125rem;
  --ims-spacing--10: 0.25rem;
  --ims-spacing--15: 0.375rem;
  --ims-spacing--20: 0.5rem;
  --ims-spacing--25: 0.625rem;
  --ims-spacing--30: 0.75rem;
  --ims-spacing--35: 0.875rem;
  --ims-spacing--40: 1rem;
  --ims-spacing--45: 1.125rem;
  --ims-spacing--50: 1.25rem;
  --ims-spacing--60: 1.5rem;
  --ims-spacing--75: 1.875rem;
  --ims-spacing--80: 2rem;
  --ims-spacing--100: 2.5rem;
  --ims-spacing--120: 3rem;
  --ims-spacing--150: 3.75rem;
  --ims-spacing--160: 4rem;
  --ims-spacing--200: 5rem;
  --ims-spacing--240: 6rem;
  --ims-spacing--320: 8rem;
  --ims-spacing--400: 10rem;
  --ims-spacing--640: 16rem;
  --ims-border__radius--100: 0.5rem;
  --ims-border__radius--300: 1.5rem;
  --ims-body__font-family: Open Sans, Arial, sans-serif;
  --ims-font__size--60: 0.75rem;
  --ims-font__size--80: 0.8125rem;
  --ims-font__size--100: 0.875rem;
  --ims-font__size--200: 1rem;
  --ims-font__size--300: 1.125rem;
  --ims-font__size--400: 1.25rem;
  --ims-font__size--500: 1.5rem;
  --ims-font__size--600: 1.75rem;
  --ims-font__size--700: 2rem;
  --ims-font__size--800: 2.25rem;
  --ims-font__size--900: 2.5rem;
  --ims-font__weight--medium: 500;
  --ims-font__weight--semibold: 600;
  --ims-font__weight--bold: 700;
  --ims-font__line-height--reset: 100%;
  --ims-font__line-height--heading: 125%;
  --ims-font__line-height--label: 125%;
  --ims-font__line-height--body: 150%;
  --ims-buttonBorderRadius: 0.25rem;
  --ims-buttonBorderRadius-custom: 0.25rem;
  --ims-buttonBorderWidth: 1px;
  --ims-buttonPaddingX: var(--ims-spacing--40);
  --ims-buttonPaddingY: var(--ims-spacing--20);
  --ims-inputBorderWidth: 1px;
  --ims-inputPaddingX: 1rem;
  --ims-inputPaddingY: 0.5rem;
  --ims-colInputFocus: var(--bs-primary);
  --ims-colInputFocus-rgb: var(--bs-primary-rgb);
  --ims-form-label__color: var(--bs-border-color);
  --ims-form-bg__color--hovered: var(--bs-border-color-subtle);
  --ims-containerBorderRadius: 0rem;
  --ims-card__border-color: var(--bs-on-primary);
  --ims-card__bg-color: var(--bs-on-primary);
  --ims-card__spacing-all: var(--ims-spacing--40);
  --ims-card__shadow: var(--ims-elevation__dp--0);
  --ims-imsHeaderNavDefaultBackgroundColor: var(--bs-primary);
  --ims-imsHeaderSubnavColor: Rvar(--bs-surface-high);
  --ims-imsHeaderSubnavColor-variant: var(--bs-border-color-subtle);
  --ims-imsHeaderNavFontHoverColor: var(--bs-on-primary);
  --ims-imsHeaderColorTheme: var(--bs-border-color-subtle);
  --ims-imsHeaderRibbonColorTheme: var(--bs-on-primary);
  --ims-btn-primary__font-color: var(--bs-white);
  --ims-btn-primary__font-color--hovered: var(--bs-white);
  --ims-btn-primary__border-color: var(--bs-black);
  --ims-btn-primary__border-color--hovered: var(--bs-primary);
  --ims-btn-primary__background: var(--bs-black);
  --ims-btn-primary__background--hovered: var(--bs-primary);
  --ims-btn-primary__background--active: var(--bs-primary-600);
  --ims-btn-primary-subtle__font-color: #969696;
  --ims-btn-primary-subtle__font-color--hovered: var(--bs-white);
  --ims-btn-primary-subtle__border-color: #969696;
  --ims-btn-primary-subtle__border-color--hovered: var(--bs-primary);
  --ims-btn-primary-subtle__background: var(--bs-btn-disabled-bg);
  --ims-btn-primary-subtle__background--hovered: var(--bs-tertiary);
  --ims-btn-primary-subtle__background--active: var(--bs-primary);
  --ims-btn-secondary__font-color: var(--bs-white);
  --ims-btn-secondary__font-color--hovered: var(--bs-white);
  --ims-btn-secondary__border-color: #969696;
  --ims-btn-secondary__border-color--hovered: var(--bs-tertiary);
  --ims-btn-secondary__background: var(--bs-secondary);
  --ims-btn-secondary__background--hovered: var(--bs-tertiary);
  --ims-btn-secondary__background--active: rgba(var(--bs-secondary-rgb), 0.6);
  --ims-btn-secondary-subtle__font-color: #969696;
  --ims-btn-secondary-subtle__font-color--hovered: var(--bs-white);
  --ims-btn-secondary-subtle__border-color: var(--bs-secondary);
  --ims-btn-secondary-subtle__border-color: var(--bs-secondary);
  --ims-btn-secondary-subtle__background: var(--bs-btn-disabled-bg);
  --ims-btn-secondary-subtle__background--hovered: var(--bs-secondary);
  --ims-btn-secondary-subtle__background--active: var(--bs-secondary);
  --ims-btn-tertiary__font-color: var(--bs-dark);
  --ims-btn-tertiary__font-color--hovered: var(--bs-dark);
  --ims-btn-tertiary__border-color: var(--bs-tertiary);
  --ims-btn-tertiary__border-color--hovered: var(--bs-tertiary-hover);
  --ims-btn-tertiary__background: var(--bs-tertiary);
  --ims-btn-tertiary__background--hovered: var(--bs-tertiary-hover);
  --ims-btn-tertiary__background--active: var(--bs-tertiary-hover);
  --ims-btn-light__font-color: #969696;
  --ims-btn-light__font-color--hovered: var(--bs-dark);
  --ims-btn-light__border-color: transparent;
  --ims-btn-light__border-color--hovered: #ced4da;
  --ims-btn-light__background: rgba(206, 212, 218, 0.8);
  --ims-btn-light__background--hovered: #ced4da;
  --ims-btn-light__background--active: #ced4da;
  --ims-comp-base-switch-secondary-color: var(--bs-secondary);
  --ims-comp-base-switch-secondary-border-color: var(--bs-secondary);
  --ims-comp-base-switch-secondary-label-color: var(--bs-secondary);
  --ims-comp-base-switch-secondary-check-color: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  --ims-btn-menu__background: var(--bs-primary);
  --ims-dd-menu__spacing: var(--ims-spacing--0) var(--ims-spacing--0);
  --ims-dd-menu__dd-item__spacing: var(--ims-spacing--15) var(--ims-spacing--40);
  --ims-dd-menu__dd-item__font-color--hovered: var(--bs-on-primary);
  --ims-btn-link__text--hovered: var(--bs-primary-dark);
  --ims-switch-field__checked-bg: var(--ims-btn-secondary__background);
  --ims-size--50: 4rem;
  --ims-size--100: 8rem;
  --ims-header__top-row__bg-color: var(--bs-tertiary);
  --ims-header__second-row__bg-color: transparent;
  --ims-header__top-row__bg-color: var(--bs-tertiary);
  --ims-header__menu-padding-l: var(--ims-spacing--80);
  --ims-header__menu-padding-r: var(--ims-spacing--80);
  --ims-header__menu-item__margin-r: var(--ims-spacing--50);
  --ims-header-sticked__menu-item__margin-r: var(--ims-spacing--25);
  --ims-header__menu-item__font-color: var(--bs-body-color);
  --ims-header__menu-item__font-color--active: var(--bs-body-color);
  --ims-header-mobile__bg-color: var(--bs-surface-high);
  --ims-header-mobile-top-spacing: 2.4rem;
  --ims-header-mobile-height-calc: 4.2rem;
  --ims-sticky-header-ribbon-top-spacing: 10.68rem;
  --ims-main-nav-link-item--hover: var(--bs-body-color);
  --ims-main-nav-link-item-dropdown--hover: var(--bs-body-color);
  --ims-comp-base-nav-pills-nav-link--active: var(--bs-primary-light);
  --ims-header-nav-bar-toggler__color: var(--bs-body-color);
  --ims-header-nav-bar-toggler__border-color: var(--bs-body-color);
  --ims-header-nav-bar-toggler__bg-color: var(--ims-header-nav-bar-toggler__bg-color);
  --ims-footer__main-row__bg-color: var(--bs-body-color);
  --ims-comp-sticky-bottom__bg-color: var(--bs-white);
  --ims-comp-sticky-bottom__text-color: var(--bs-body);
  --ims-comp-sticky-bottom__button-text-color: var(--ims-btn-primary__font-color);
  --ims-comp-sticky-bottom__button-bg-color: var(--ims-btn-primary__background);
  --ims-comp-sticky-bottom__button-border-color: var(--ims-btn-primary__border-color);
  --ims-cs__main__bg-color: var(--bs-surface-low);
  --ims-card__bg-color--neutral: var(--bs-border-color-subtle);
  --ims-kpi__bg-hovered:var(--bs-border-color-subtle);
  --ims-widget-teaser-button_border-radius:2rem;
  --ims-sidebar__navitem-bg: #64a0e6;
  --ims-sidebar__navitem-bg--hover: #4989cd;
  --ims-btn__pulsating-circle-color-before: var(--ims-color__primary--100);
  --ims-btn__pulsating-circle-color-after: var(--ims-color__primary--100); }

/* -------------------------------------- */
/*
/*  Core Overrides - common customization controlled via SASS variables
/*  this is mainly used when customization via simple css variables is not possible (i.e. flags / conditionals)
/*
/* -------------------------------------- */
.ims-comp-base-image.brand {
  border-top-left-radius: var(--ims-panel__border-radius) !important;
  border-top-right-radius: var(--ims-panel__border-radius) !important; }
  .ims-comp-base-image.brand span {
    border-radius: 0 0 0 0 !important; }

.ims-comp-base-card .image-flex.horizontal .ims-comp-base-image.brand {
  border-top-left-radius: var(--ims-panel__border-radius) !important;
  border-bottom-left-radius: var(--ims-panel__border-radius) !important; }
  .ims-comp-base-card .image-flex.horizontal .ims-comp-base-image.brand span {
    border-radius: 0 0 0 0 !important; }

/* --------------- shared variable + function ---------------- */
/* ----------------------------------------------------------- */
/* list of all mixins */
/* list of all extends */
h1, .ims-widgetHeader h1 {
  font-size: 1.875rem !important;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: lighter !important; }

hr {
  margin-top: var(--ims-spacing--50) !important;
  margin-bottom: var(--ims-spacing--50) !important; }
  hr.footer-divide {
    margin-top: var(--ims-spacing--0) !important;
    margin-bottom: var(--ims-spacing--0) !important; }

b, strong {
  font-weight: bold !important; }

.img-responsive {
  height: 2.1875rem; }

#ims-logo {
  padding-top: var(--ims-spacing--25);
  padding-bottom: var(--ims-spacing--25); }

#pageHeaderDesktop .dropdown-menu {
  overflow: inherit !important; }

#pageHeaderDesktop .dropdown-menu::before {
  border-bottom: 9px solid var(--bs-surface-high);
  border-left: 9px solid rgba(0, 0, 0, 0);
  border-right: 9px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  left: 5%;
  /* position */
  position: absolute;
  top: -9px; }

#pageHeaderDesktop .dropdown-menu::after {
  border-bottom: 8px solid var(--bs-surface-high);
  border-left: 9px solid rgba(0, 0, 0, 0);
  border-right: 9px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  left: 5%;
  /* position */
  position: absolute;
  top: -9px; }

#pageHeaderDesktop .dropdown-item {
  background-color: var(--bs-surface-high); }
  #pageHeaderDesktop .dropdown-item .nav-link {
    border-bottom: 0.1rem solid transparent; }
  #pageHeaderDesktop .dropdown-item:hover .nav-link {
    transition: all .5s;
    border-bottom: 0.1rem solid var(--bs-body-color);
    color: var(--bs-body-color) !important; }

#pageHeaderDesktop #ims-headerNav .dropdown-menu::before {
  left: 45% !important; }

#pageHeaderDesktop #ims-headerNav .dropdown-menu::after {
  left: 45% !important; }

#pageHeaderDesktop #ims-headerNav .nav-item .nav-link {
  display: flex; }

.ims-main-nav {
  padding: var(--ims-spacing--50) var(--ims-spacing--0) !important; }

#ims-headerMenuBar {
  animation: none !important; }

.sticky-header.sticky-navigation,
.sticky-header.sticky-navigation.sticked {
  margin-bottom: 0rem !important; }

#ims-mainContentArea {
  margin-top: 1rem; }

@media (min-width: 1200px) and (max-width: 1399px) {
  #ims-mainContentArea,
  header.sticky-header ~ #ims-mainContentArea {
    margin-top: 1rem; } }

@media (max-width: 1199px) {
  #ims-mainContentArea,
  header.sticky-header ~ #ims-mainContentArea {
    margin-top: 1rem; } }

#pageHeaderDesktop .d-flex.align-items-center.justify-content-between .navbar {
  padding: var(--ims-spacing--20) var(--ims-header__menu-padding-l); }

.sticky-header.sticky-navigation.sticked #ims-logo {
  display: block; }

.sticky-header.sticky-navigation.sticked .d-flex.align-items-center.justify-content-between .navbar {
  padding: var(--ims-spacing--20) var(--ims-header__menu-padding-l) !important; }

.sticky-header.sticky-navigation.sticked #ims-headerMenuBar {
  animation: none !important;
  position: relative !important; }

.sticky-header.sticky-navigation.sticked #ims-mainNav .nav-item > .nav-link {
  margin-right: var(--ims-header__menu-item__margin-r) !important; }

#pageHeaderMobile .navbar-collapse .dropdown-item:hover {
  background-color: var(--bs-white) !important; }
  #pageHeaderMobile .navbar-collapse .dropdown-item:hover .nav-link {
    color: var(--bs-body-color) !important;
    background-color: transparent; }

@media (min-width: 1200px) {
  #pageHeaderDesktop .d-flex.align-items-center.justify-content-between .navbar {
    padding: var(--ims-spacing--20) var(--ims-header__menu-padding-l); } }

@media (max-width: 1199px) {
  #ims-logo {
    display: none; } }

#ims-mainNav .nav-item > .nav-link.router-link-active {
  color: var(--ims-header__menu-item__font-color--active) !important;
  border-bottom: 0.2rem solid var(--ims-header__menu-item__font-color--active); }

.ims-widget-teaser .carousel-caption {
  right: 0%;
  width: 30%;
  height: 100%;
  left: 70%;
  color: var(--bs-body-color);
  padding: var(--ims-spacing--120);
  text-align: left;
  padding-top: 5%; }
  .ims-widget-teaser .carousel-caption .ims-primary-button {
    background-color: var(--bs-body-color);
    border-color: var(--bs-body-color); }
    .ims-widget-teaser .carousel-caption .ims-primary-button:hover {
      background-color: var(--bs-body-color) !important;
      border-color: var(--bs-body-color) !important; }
  .ims-widget-teaser .carousel-caption h3 {
    padding: 0;
    font-weight: normal;
    padding-bottom: var(--ims-spacing--40); }
  .ims-widget-teaser .carousel-caption p {
    padding-top: var(--ims-spacing--0); }

.ims-widget-teaser .carousel .carousel-control-next .carousel-control-next-icon,
.ims-widget-teaser .carousel .carousel-control-prev .carousel-control-prev-icon {
  background-color: var(--bs-body-color);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  padding-bottom: 1rem; }

.ims-widget-teaser .carousel .carousel-indicators button {
  background-color: var(--bs-body-color); }

.ims-widget-teaser .carousel-indicators {
  margin-left: 70%;
  margin-right: 0%;
  margin-bottom: 1rem;
  z-index: 1; }

.ims-widget-teaser .carousel .carousel-control-next, .ims-widget-teaser .carousel .carousel-control-prev {
  align-items: end !important;
  margin-bottom: 1.5rem; }

.ims-widget-teaser .carousel .carousel-control-prev {
  left: 70%;
  margin-left: var(--ims-spacing--20); }

.ims-widget-teaser .carousel .carousel-control-next {
  margin-right: var(--ims-spacing--20); }

@media (max-width: 1199px) {
  .ims-widget-teaser .carousel-caption {
    right: 0%;
    width: 50%;
    left: 50%;
    color: var(--bs-body-color);
    padding: var(--ims-spacing--60);
    padding-top: 5% !important; }
  .ims-widget-teaser .carousel-control-prev {
    left: 50% !important; }
  .ims-widget-teaser .carousel-control-next {
    margin-right: var(--ims-spacing--10); }
  .ims-widget-teaser .carousel-indicators {
    margin-left: 50% !important; } }

@media (max-width: 991px) {
  .ims-widget-teaser .carousel-caption {
    right: 0%;
    width: 70%;
    left: 30%;
    color: var(--bs-body-color);
    padding: var(--ims-spacing--50);
    padding-top: 5% !important; }
  .ims-widget-teaser .carousel-control-prev {
    left: 30% !important; }
  .ims-widget-teaser .carousel-control-next {
    margin-right: var(--ims-spacing--10); }
  .ims-widget-teaser .carousel-indicators {
    margin-left: 30% !important; } }

@media (max-width: 575px) {
  .ims-widget-teaser .carousel-caption {
    right: 0%;
    width: 100%;
    left: 0%;
    color: var(--bs-body-color);
    padding: var(--ims-spacing--50);
    padding-top: 2% !important;
    display: block !important; }
  .ims-widget-teaser .carousel-control-prev {
    left: 0% !important;
    margin-bottom: 0.2rem !important; }
  .ims-widget-teaser .carousel-control-next {
    margin-right: var(--ims-spacing--10);
    margin-bottom: 0.2rem !important; }
  .ims-widget-teaser .carousel-indicators {
    margin-left: 0% !important;
    margin-bottom: 0.2rem !important; }
  .ims-widget-teaser .ims-comp-base-carousel .carousel-caption {
    opacity: 0.9 !important; } }

.htmlEditorWidget h3 {
  margin-top: var(--ims-spacing--50);
  margin-bottom: var(--ims-spacing--25); }

.htmlEditorWidget h1 {
  margin-bottom: var(--ims-spacing--25); }

.htmlEditorWidget p {
  margin-bottom: var(--ims-spacing--25); }

.htmlEditorWidget ul {
  margin-bottom: var(--ims-spacing--25); }

.htmlEditorWidget .ims-tile h3 {
  margin-top: var(--ims-spacing--0); }

.preFilterWidget .card-block {
  background-color: var(--bs-body-color); }
  .preFilterWidget .card-block .card-title {
    color: var(--bs-on-primary);
    font-size: 1.125rem;
    font-weight: lighter !important; }
  .preFilterWidget .card-block .card-sub-title {
    color: var(--bs-on-primary);
    font-weight: lighter !important; }
  .preFilterWidget .card-block .card-text {
    color: var(--bs-on-primary);
    font-weight: lighter !important; }

.preFilterWidget .card {
  background-color: var(--bs-body-color); }

.generalDashboardWidget .card-title, .generalDashboardWidget .card-sub-title, .generalDashboardWidget .card-text {
  color: var(--bs-body-color); }

@media (max-width: 767px) {
  .ims-widget-teaser .carousel .carousel-control-next {
    margin-right: var(--ims-spacing--0); }
  .ims-widget-teaser .carousel .carousel-control-prev {
    left: 0% !important; }
  .ims-widget-teaser .carousel .carousel-indicators {
    left: 0% !important;
    margin-bottom: 0;
    margin-left: 0% !important; } }

/*# sourceMappingURL=maps/theme.css.map */
